var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["radio-button", { _checked: _vm.checked }] }, [
    _c("input", {
      attrs: { id: _vm.value, type: "radio", name: "radio" },
      domProps: { value: _vm.value, checked: _vm.checked },
      on: {
        change: function ($event) {
          return _vm.$emit("change", $event.target.value)
        },
      },
    }),
    _c("span", { staticClass: "radio-button__checkmark" }),
    _c(
      "div",
      { staticClass: "radio-button__label" },
      [_vm._v(" " + _vm._s(_vm.label) + " "), _vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }