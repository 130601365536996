import axios from 'axios'
import responseErrorHandler from "@/assets/js/axios/responseErrorHandler";
import responseSuccessHandler from "@/assets/js/axios/responseSuccessHandler";
import store from "@/store";
import initFromLocalStorage from "@/assets/js/axios/initFromLocalStorage";

axios.defaults.baseURL = process.env.VUE_APP_BACK_URL

axios.interceptors.request.use(async function (config) {
  if (config.headers.skipAuthToken) {
    delete config.headers.skipAuthToken
    return config
  }

  if (!store.state.user.accessToken) { // first request exec, after load app
    initFromLocalStorage()
  }

  if(store.state.user.accessToken){
    config.headers.common['Authorization'] = `Bearer ${store.state.user.accessToken}`
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(
  response => {
    responseSuccessHandler(response)
    return response
  },
  error => {
    responseErrorHandler(error)
    return Promise.reject(error)
  }
)
