import get from 'lodash/get'
import moment from "moment";

export default class TickerSimple {
  constructor(init) {
    this.ticker = get(init, 'ticker', '')

    this.dateTimeStrDate = null
    this.dateTimeStrTime = null
    this.dateTime = get(init, 'dateTime', '')
    const dateTimeObj = moment(this.dateTime).utc(true)
    if (this.dateTime) {
      this.dateTimeUnix = Number(dateTimeObj.format('x'))
      this.dateTimeStrDate = dateTimeObj.format('DD.MM')
      this.dateTimeStrTime = dateTimeObj.format('HH:mm')
    }

    this.trend = get(init, 'trend', '')
  }
}
