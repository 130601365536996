export default function (to, from, next) {
  if(to.query.ref){
    if(!localStorage.getItem('savedRef')){
      localStorage.setItem('savedRef', to.query.ref)
    }
    localStorage.setItem('currentRef', to.query.ref)
  }
  if(next){
    next()
  }
}
