import {getAltSearchTickersValue} from "@/assets/js/searchDataTickers";

export default class PairItem {
  constructor(init = {}) {
    this.id = init?.id || null
    this.label = init?.label || init?.ticker || ''
    this.ticker = init?.base || init?.ticker || ''
    this.disabledOnTrends = init?.disabledOnTrends || false
    this.disabledOnScreener = init?.disabledOnScreener || false
    this.labelAlt = getAltSearchTickersValue(this.label || this.ticker)
    this.is_futures = init?.is_futures || false
    this.exchange = init.exchange || ''
  }
}
