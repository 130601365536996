<template>
  <div
    class="d-flex align-items-center"
  >
    <label class="mb-0" @click="handleSubscribe">
      {{ $t('lk.account.browserNotifications') }}
    </label>
    <div
      v-c-tooltip="{
        content: subscribeTooltipText,
        appendToBody: true,
        popperOptions: {
          strategy: 'fixed',
        },
        html: true,
      }"
      class="d-flex align-items-center"
    >
      <CIcon
        name="bell"
        class="cil-bell-icon ml-1 flex-shrink-0"
        :class="bellColorClass"
      />
      <CSwitch
        ref="subscribeSwitch"
        color="primary"
        shape="pill"
        :checked="isSubscribed"
        :disabled="disabledSwitch"
        class="ml-3 my-0"
        @update:checked="handleSubscribe"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "WebPushToggle",
  computed: {
    ...mapState({
      swRegistration: state => state.browserNotify.swRegistration,
      swIsBlocked: state => state.browserNotify.swIsBlocked,
      isSubscribed: state => state.browserNotify.isSubscribed,
      isLoading: state => state.browserNotify.isLoading,
      isSafari: state => state.browserNotify.isSafari,
    }),
    subscribeTooltipText() {
      switch (true) {
        case this.swIsBlocked:
          return this.$t('lk.account.browserNotificationsBlocked')
        case !this.swRegistration && !this.isSafari:
          return this.$t('lk.account.browserNotificationsNotSupported')
        case this.isSubscribed && this.isSafari:
          return this.$t('lk.account.browserNotificationsSafari')
        case this.isSubscribed:
          return this.$t('lk.account.browserNotificationsActive')
        default:
          return this.$t('lk.account.browserNotificationsDisabled')
      }
    },
    bellColorClass() {
      switch (true) {
        case this.swIsBlocked || (!this.swRegistration && !this.isSafari):
          return '_blocked'
        case this.isSubscribed:
          return '_active'
        default:
          return ''
      }
    },
    disabledSwitch() {
      return (!this.swRegistration && !this.isSafari)
        || this.isLoading || this.swIsBlocked || (this.isSubscribed && this.isSafari)
    },
  },
  watch: {
    swIsBlocked(val) {
      if (val) {
        this.updateSubscribeSwitchState()
      }
    },
    isLoading(val) {
      if (!val && !this.isSubscribed) {
        this.updateSubscribeSwitchState()
      }
    },
  },
  methods: {
    ...mapActions({
      unsubscribeUser: 'browserNotify/unsubscribeUser',
      subscribeUser: 'browserNotify/subscribeUser',
    }),
    handleSubscribe() {
      if (this.disabledSwitch) return

      if (this.isSubscribed) {
        this.unsubscribeUser();
      } else {
        this.subscribeUser();
      }
    },
    updateSubscribeSwitchState() {
      this.$set(this.$refs.subscribeSwitch, 'state', false)
    },
  }
}
</script>

<style scoped lang="scss">
.cil-bell-icon {
  width: 1.5rem !important;
  height: auto !important;

  &._blocked {
    opacity: .5;
  }

  &._active {
    color: var(--primary)
  }
}
</style>
