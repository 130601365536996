var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c-app w-100 h-100 position-absolute moveBanner justify-content-center align-items-center",
      class: { "c-dark-theme": _vm.$store.getters.darkMode },
    },
    [
      _c(
        "CCard",
        { staticClass: "px-5 pt-5 pb-5 overflow-auto" },
        [
          _c("CSelect", {
            staticClass: "moveBanner__localSelect",
            attrs: {
              label: _vm.$t("lk.account.selectLang"),
              value: _vm.locale,
              options: _vm.$store.state.localeOptions,
            },
            on: {
              "update:value": function ($event) {
                _vm.locale = $event
              },
            },
          }),
          _c("h1", { staticClass: "text-center mb-4" }, [
            _vm._v(" " + _vm._s(_vm.contemt[_vm.locale].title) + " "),
          ]),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.contemt[_vm.locale].string1) },
          }),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.contemt[_vm.locale].string2) },
          }),
          _c("p", { staticClass: "text-center mb-3" }, [
            _vm._v(" " + _vm._s(_vm.contemt[_vm.locale].subTitle) + " "),
          ]),
          _c("p", {
            staticClass: "mb-3",
            domProps: { innerHTML: _vm._s(_vm.contemt[_vm.locale].string3) },
          }),
          _c("p", { staticClass: "text-right" }, [
            _vm._v(" " + _vm._s(_vm.contemt[_vm.locale].string4) + " "),
            _c("br"),
            _vm.$store.getters.darkMode
              ? _c("img", {
                  attrs: {
                    src: `${_vm.BUILD_PUBLIC_PATH}/img/svg/cryptovisorLogo.svg`,
                  },
                })
              : _c("img", {
                  attrs: {
                    src: `${_vm.BUILD_PUBLIC_PATH}/img/svg/cryptovisorLogoLight.svg`,
                  },
                }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }