import onboardingDeltaData from "@/assets/js/onboarding/onboardingDeltaDataRu";

export default {
  system: {
    inDev: "В разработке",
  },
  "errors": {
    "buyError": "Ошибка покупки"
  },
  "promo": {
    "available": "Доступно только для <span class='text-uppercase'>{tariff}</span>"
  },
  "marketplace": {
    "subscribeDurationTooltip": "Невозможно подписаться на уведомление сроком больше чем остаток дней на вашем тарифе",
    "subscribeEndDate": "Дата окончания подписки",
    "botPopover": "Имя автора группового оповещения меняется на System bot в случае - если автор удалил созданный им групповое оповещение",
    "reCreateTooltip": "Повторить оповещение",
    "reSubscribeTooltip": "Повторить подписку",
    "coinCountWarning": "Количество монет в оповещении должно быть ровно или меньше количества монет в списке FUTURES.",
    "expire": "Истекает",
    "created": "Создано",
    "assignTokenTooltip": "Для подписки на групповое оповещение требуется 1 токен FOLLOWER",
    "tokenPopoverDescFollower": "Token Follower: Начисляется за тарифный план 5шт. Автоматически возобновляемый. Используется, чтобы подписаться на публичные оповещения.",
    "tokenPopoverDescInfluencer": "Token Influencer: Начисляется +1 если ваше публичное оповещение достигло >10 подписчиков, можно использовать на создание групповых оповещений.",
    "tokenPopoverDescCreator": "Token Creator: Начисляется за тарифный план 5шт. Автоматически возобновляемый. Используется, чтобы создать или подписаться на публичные оповещения.",
    "tokens": "Токены",
    "tokenLimitWarning": "Вы использовали все токены {type}",
    "actveTariffTooltip": "У вас не активирован тариф {tariff}",
    "title": "Маркетплейс",
    "addNotification": "Добавить оповещение",
    "sort": "Сортировать",
    "subscribersNumberAbbr": "Кол-во подписчиков",
    "subscribersNumber": "Количество подписчиков",
    "publicationDate": "Дата публикации",
    "notificationName": "Наименование оповещения",
    "subscribe": "Подписаться",
    "coinsNumber": "Пары",
    "createdAt": "Дата создания",
    "terms": "Условия",
    "totalSigned": "Всего подписано",
    "author": "Автор",
    "repeats": "Повторения",
    "unsubscribe": "Отписаться",
    "show": "Показать",
    "activity": "Активность",
    "deleteGroupNotificationText": "Данное действие удалит уведомление. Удаляем?",
    "complain": "Пожаловаться",
    "returnPublication": "Вернуть публикацию",
    "removePublication": "Снять с публикации"
  },
  "general": {
    "copied": "Скопировано",
    "howItWorks": "Как это работает",
    "apply": "Применить",
    "confirm": "Подтвердить",
    "all": "Все",
    "forever": "Навсегда",
    "deleteAll": "Удалить все",
    "nick": "Ник",
    "unbind": "Отвязать",
    "copyLink": "Скопируйте ссылку",
    "share": "Поделиться",
    "clearLogs": "Очистить логи ",
    "verify": "Верифицировать",
    "inBot": "боте",
    "show": "Показывать",
    "referralLink": "Реферальная ссылка",
    "answerQuestion": "Ответьте на вопрос",
    "single": "Одиночный",
    "group": "Групповой",
    "public": "Публичное",
    "filters": "Фильтры",
    "upgradeTo": "Обновить до",
    "discount": "Скидка",
    'active': "Действующий",
    "logs": "Логи",
    "free": "Бесплатно",
    "year": "год",
    "monthAbbr": "мес.",
    "cancelAll": "Отменить все",
    "close": "Закрыть",
    "open": "Открыть",
    "save": "Сохранить",
    "remove": "Удалить",
    "delete": "Удалить",
    "add": "Добавить",
    "requiredField": "Обязательное поле",
    "notValidate": "Данные не валидны",
    "dataSaved": "Данные сохранены",
    "yes": "Да",
    "no": "Нет",
    "noData": "Нет данных",
    "cancel": "Отменить",
    "saveChanges": "Сохранить изменения",
    "saveChangesAndToLineRunner": "Сохранить и использовать как бегущую строку",
    "emptySearch": "По вашему запросу ничего не найдено. Попробуйте еще раз.",
    "error": "Ошибка",
    "dataChanged": "Данные изменены",
    "search": "Поиск",
    "inputPlaceholder": "Введите... (мин. 3 симв.)",
    "datePlaceholder": "дд.мм.гггг",
    "reset": "Сбросить",
    "until": "до",
    "edit": "Редактировать",
    "color": "Цвет",
    "serverIsNotAvailable": "Сервер не доступен",
    "lineRunnerAdded": "Вотчлист успешно добавлен в бегущую строку",
    "price": "Цена",
    "h": "Ч",
    "m": "М",
    "w": "Н",
    "d": "Д",
    'deselect': 'Отменить выбор',
    'writeTheText': 'Введите текст: ',
    "remove_data": "Удалить {item} ?",
    "created": "Создано",
    "updated": "Обновлено",
    "deleted": "Удалено",
    "isActive": "Активен",
    "isNotActive": "Не активен",
    "Active": "Активен",
    dontSaveEmptyArray: 'Нельзя сохранить пустой массив',
  },
  "crubs": {
    "tariningview": "Обучение"
  },
  "header": {
    "delta": "Дельта",
    "screener": "Скринер",
    "trends": "Тренды",
    "aboutUs": "О нас",
    "ourProjects": "Наши проекты",
    "account": "Учётная запись",
    "settings": "Настройки",
    "permissions": "Доступы",
    "subscribe": "Подписка",
    "subscribeConstructor": "Конструктор подписок",
    "training": "Курсы",
    "videoEncrypt": "Видео",
    "videoPublic": "Публичные видео",
    "coinList": "Настройка монет",
    "inviteCode": "Инвайт коды",
    "payments": "История платежей",
    "transactions": "История транзакций",
    "analytics": "Аналитика",
    "dashboard": "Дашборд",
    "logout": "Выйти",
    "education": "Обучение",
    "curator": "Куратор",
    "authors": "Публичные профили",
    "author": "Публичный профиль",
    "guide": {
      "label": "Справочник",
      "path": "https://docs.cvizor.com/en/v/ru/"
    }
  },
  "lk": {
    "header": {
      "account": "Учетная запись",
      "permission": "Доступы",
      "userActivity": "Активность пользователей",
      "notifications": "Оповещения",
      "subscribe": "Подписка",
      "subscribeConstructor": "Конструктор подписок",
      "training": "Обучение",
      "videoEncrypt": "Видео",
      "videoPublic": "Публичные видео",
      "coinSettings": "Настройка монет (Скринер)",
      "coinSettingsTrends": "Настройка монет (Тренды)",
      "payments": "История платежей",
      "analytics": "Аналитика",
      "whitelist": "Вайтлист",
      "settings": "Настройки",
      "listOfCourses": "Список курсов",
    },
    "account": {
      "pauseUpdateOnWitoutMA": "Для включения фильтра добавьте оба столбца МА в настройку таблицы скринера.",
      "pauseUpdateOnChartHint": "При открытом графике таблица скринера не будет обновляться в фоновом режиме.",
      "pauseUpdateOnChart": "Пауза при активном графике",
      "isShowMAWithoutMA": "Для включения фильтра добавьте оба столбца МА в настройку таблицы скринера.",
      "isShowMA": "Фильтр по зонам МА",
      "telegramStudenets": "Вступить в чат учеников",
      "tabMainScore": "Основной",
      "tabReferalScore": "Реферальный",
      "tabSubscription": "Подписка",
      "tabTraining": "Обучение",
      "telegram": "Телеграм канал",
      "feedback": "Обратная связь",
      "feedbackLink": 'https://docs.google.com/forms/d/e/1FAIpQLSfPw7rnfzikEPP7AMom70sT05dDfPzbkbXswMh_SNbG26Mk7A/viewform',
      "formName": "Ваш никнейм",
      "formEmail": "Ваш E-mail",
      "formNameList": "Название",
      "formTickers": "Tickers...",
      "changePassword": "Изменить пароль",
      "save": "Сохранить",
      "connectTelegram": "Подключить Telegram",
      "confirmAccount": "Подтвердите учетную запись через Telegram",
      "listName": "Набор",
      "download": "Загрузить",
      "selectLang": "Выберите язык",
      "verifiedTelegram": "Верифицирован телеграм",
      "verified2FA": "2FA включена",
      "telegramTooltip": "Пройдите верификацию в Telegram",
      "browserNotifications": "Оповещения в браузере",
      "browserNotificationsEnable": "Включение оповещении в браузере Brave",
      "browserNotificationsDisabled": "Оповещения в браузере отключены",
      "browserNotificationsActive": "Оповещения в браузере разрешены",

      "browserNotificationsBlocked": "Оповещения в браузере отключены. Для разрешения оповещений перейдите в настройки вашего браузера",
      "browserNotificationsBraveErrorStart": `В браузере Brave, помимо разрешения на уведомления в браузере, необходимо так же разрешить "Использовать сервисы Google для обмена push-сообщениями".<br>
          Для разрешения данной опции скопируйте и откройте в браузере `,
      "browserNotificationsBraveErrorEnd": ` включите ползунок напротив "Использовать сервисы Google для обмена push-сообщениями"
      и перезагрузите браузер. После вы сможете получать push-уведомления в браузер.`,
      "browserNotificationsNotSupported": "Оповещения в браузере не поддерживаются",
      "browserNotificationsSafari": "Оповещения в браузере разрешены. Для отключения уведомлений перейдите в настройки своего браузера",
      "signOutAll": "Выйти со всех устройств",
      "promoCodeBtn": "Получить доступ",
      "resetLinkOnEmail": "Ссылка для сброса пароля отправлена, проверьте почту",
      "registrationSuccessfully": "Регистрация прошла успешно",
      "resetLinkError": "В ссылке отсутствует параметр {name}",
      "loginCompleted": "Вход выполнен",
      "unbindTgMessage": "Аккаунт Telegram был отвязан",
      "confirmAccountSuccessfully": "Аккаунт telegram подтвержден успешно",
      "activateTrendsSuccessfully": "Тренды успешно активированы, если не отобразилась вкладка - обновите страницу",
      "currentBalance": "Текущий баланс",
      "refBalance": "Текущий реферальный баланс",
      "currentTariff": "Действующий тариф:",
      "dueTo": "Действует до:",
      "expires": "Осталось:",
      "noTariff": "У вас не подключен тариф",
      "gotoTariffs": "К тарифам",
      "fillBalance": "Пополнить баланс",
      "changeEmailHint": "Для редактирования почты подключите 2FA аутентификацию",
      "6digitCode": "6-ти значный код",
      "enterCodeFromApp": "Введите код из приложения",
      "configure2FA": "Управление 2FA",
      "modal2FA": {
        "title": "Для подключения 2fa на нашем портале установите на свой смартфон приложение Google Authenticator. Скачайте его из Google Play или App Store и пройдите по инструкции ниже",
        "secretTitle": "Ваш секретный ключ",
        "copyButton": "Скопировать",
        "install": {
          "title": "Установите приложение-генератор кодов",
          "inst1": "Скопируйте секретный ключ",
          "inst2": "Откройте приложение-генератор кодов и вставьте секретный ключ",
          "inst3": "Получите одноразовый код и вернитесь на сайт",
          "saveKey": "Сохраните ваш секретный ключ в надежном месте",
        },
        "enterCode": "Введите код из приложения для генерации кодов",
        "enterCodeMin": "Введите код из приложения",
        "2FAenabled": "Двухфакторная аутентификация подключена. Хотите отключить?",
        "2FAdisabled": "Двухфакторная аутентификация отключена. Хотите включить?",
        "continue": "Продолжить",
        "back": "Назад",
        "enable2FA": "Включить 2fa",
        "disable2FA": "Отключить 2fa",
        "toast": {
          "codeCopied": "Код скопирован в буфер обмена",
          "2FAenabled": "2FA аутентификация успешно настроена",
          "errorConfirm": "Ошибка настройки аутентификации. Проверьте введенный код",
          "errorChanging": "Ошибка изменения 2fa",
          "2FAdisabled": "2FA аутентификация отключена",
        }
      }
    },
    "settings": {
      "watchListNameWarning": "Максимальное кол-во символов в имени вотчлиста 24",
      "uploadFile": "Загрузить файл",
      "changeNameNote": "Заменить имя вотчлиста на имя файла?(ограничение в названии файла 24 символа, в случае если имя файла больше 24 символов -  оно будет обрезано)",
      "downloadWatchlist": "Скачать вотч лист в формате txt",
      "resetWatchlist": "Сбросить содержимое вотч листа",
      "watchlistUploadedSuccessfully": "Ваш вотч лист успешно выгружен",
      "resetWatchlistSuccessfully": "Вотч лист {name} очищен",
      "deleteWatchlist": "Удалить вотч лист",
      "addWatchlist": "Добавить вотч лист",
      "changeColorWatchlist": "Изменить цвет",
      "deleteWatchlistModal": {
        "title": 'Удаление {name}',
        "subtitle": 'Данное действие удалит вотчлист. Удаляем?',
      },
      "editLineRunnerModal": {
        "title": "Изменить бегущую строку?",
        "subtitle": "Использование вотч листа в бегущий строке заменит текущие данные. Продолжить?"
      },
      tableScreener: {
        table: {
          price: 'Текущая цена актива.',
          cdWeek: 'Кумулятивная дельта за неделю.',
          delta: 'Значение дельты за выбранный таймфрейм.',
          volat: 'Амплитуда изменения цены относительно выбранного таймфрейма.',
          ma50: 'Скользящее среднее за выбранный период.',
          ma50Label: 'Младшая МА',
          ma200: 'Скользящее среднее за выбранный период.',
          ma200Label: 'Старшая МА',
          rsi: 'Индекс относительной силы (14 периодов).',
          volume: 'Объем торгов в USDT.',
          bci: 'Индекс корреляции биткоина.',
          openInterests: 'Общий объем всех открытых позиций на фьючерсах',
          liquidation: 'Ликвидации на фьючерсном рынке за выбранный таймфрейм (Long/Short).',
          funding: 'Ставка финансирования на фьючерсном рынке.',
          trades: 'Количество сделок за выбранный таймфрейм.',
        },
      },
    },
    "training": {
      "viewAllCourses": "Посмотреть все доступные курсы",
      "preview": "Превью",
      "students": "Ученики",
      "curators": "Кураторы",
      "materials": "Материалы",
      "flows": "Потоки",
      "settings": "Настройки",
      "topic": "Тема дня",
      "accessFlows": "Доступ только потокам",
      "accessFlowsAll": "Доступен всем",
      "accessFlowsFlows": "Не доступен никому, пересохраните",
      "order": "Порядок сортировки",
      "name": "Название",
      "link": "Ссылка на трансляцию",
      "start_at": "Дата старта",
      "finish_at": "Дата окончания",
      "homeWork": "Домашнее задание",
      "description": "Короткое описание",
      "active": "Активен",
      "notActive": "Не активен",
      "btnSave": "Сохранить",
      "loadTitle": "Загрузите запись стрима",
      "aboutBtn": "Подробнее про обучение",
      "readMore": "Подробнее",
      "editChatFile": "Заменить файл чата",
      "editChatFileBtn": "Заменить файл",
      "addChatFile": "Добавить файл чата",
      "addChatFileBtn": "Добавить файл",
      "nameTraining": "Название",
      "slugTraining": "Слуг",
      "descTraining": "Описание",
      "tg_group_url": "Ссылка на чат",
      "route_check": "Апи url проверки",
      "route_approved": "Апи url хука",
      "managersTraining": "Менеджеры",
      "addManager": "Добавить менеджера",
      "authorTraining": "Автор",
      "addAuthor": "Добавить автора",
      "replaceAuthor": "Заменить автора",
      "availableToBuyFrom": "Доступен для покупки с (UTC)",
      "availableForTrainingFrom": "Доступен для обучения с (UTC)",
      "coverPhoto": "Обложка курса листинг (608x640)",
      "widePhoto": "Обложка курса в карточке (1300x500)",
      "errorLoadData": "Ошибка! Не удалось загрузить данные",
      "errorSave": "Ошибка! Не удалось сохранить данные",
      "successSave": "Изменения сохранены",
      "descriptionTitle": "Описание курса",
      "linksTitle": "Ссылки",
      "programTitle": "Программа курса",
      "labelBackBtn": "Назад",
      "curatorAuthorsTitle": "Кураторы",
      "tariffsTitle": "Тарифы",
      "btnSaveCourse": "Сохранить",
      "btnCreateTitle": "Создать курс",
      "btnCreateCourse": "Создать",
      "errorNameCourse": "Введите название курса",
      "errorCreateCourse": "Ошибка создания курса",
      "courseMarkerLabel": "Активна до",
      "usefulLinks": {
        "header": "Полезные ссылки",
        "inputTitle": "Текст",
        "inputLink": "Ссылка",
        "add": "Добавить ссылку"
      },
      "headers": [
        "Превью", "Список учеников", "Список кураторов", "Материалы", "Потоки", "Настройки"
      ],
      "studentTable": {
        "dateBuy": "Дата покупки",
        "name": "Имя в сервисе",
        "email": "Почта",
        "tariff": "Тариф",
        "chatApproved": "Чат",
        "tgName": "Telegram",
        "curator": "Куратор",
        "flow": "Поток"
      },
      "flowTable": {
        "addFlow": "Добавить поток",
        "editFlow": "Редактировать поток",
        "flowNameInputPlaceholder": "Введите название потока",
        "programInputPlacholder": "Поток по какой программе?",
        "programInputLabel": "Программа обучения",
        "dateInputLabel": "Даты проведения",
        "dates": "Даты",
        "addStudents": "Добавить учеников",
        "studentsListLabel": "Выберите ученика из списка",
        "deleteStudents": "Удалить учеников"
      },
      "curatorTable": {
        "createdAt": "Дата появления в таблице",
        "login": "Логин в системе",
        "name": "Имя",
        "telegram": "Телеграм",
        "count": "Кол-во учеников",
        "subTable": {
          "name": "Имя в сервисе",
          "email": "Почта",
          "tg": "Telegram",
          "tariff": "Тариф"
        }
      },
      "curatorModal": {
        "titleAdd": "Добавление куратора",
        "titleUpdate": "Обновление куратора",
        "emailPlaceholder": "Email (мин. 3 симв.)",
        "login": "Логин в системе",
        "name": "Имя",
        "telegram": "Телеграм",
        "loginPlaceholder": "E-mail",
        "namePlaceholder": "Введите имя",
        "telegramPlaceholder": "Ник в телеграме",
        "btnAdd": "Добавить",
        "btnUpdate": "Обновить",
        "completeAdd": "Куратор успешно добавлен",
        "completeUpdate": "Куратор успешно обновлен"
      },
      "managerModal": {
        "titleAdd": "Добавление менеджера",
        "emailPlaceholder": "Email (мин. 3 симв.)",
        "login": "Логин в системе",
        "name": "Имя",
        "telegram": "Телеграм",
        "loginPlaceholder": "E-mail",
        "namePlaceholder": "Введите имя",
        "telegramPlaceholder": "Ник в телеграме",
        "btnAdd": "Добавить",
        "completeAdd": "Менеджер добавлен, сохраните изменения"
      },
      "authorModal": {
        "titleAdd": "Добавление автора",
        "placeholder": "поиск (мин. 3 симв.)",
        "btnAdd": "Добавить",
        "completeAdd": "Автор добавлен, сохраните изменения"
      },
      "userAuthorModal": {
        "titleAdd": "Привязка пользователя",
        "placeholder": "поиск (мин. 3 симв.)",
        "btnAdd": "Добавить",
        "completeAdd": "Пользователь добавлен, сохраните изменения"
      },
      "deleteCuratorModal": {
        "title": "Удаление куратора",
        "confirm": "Подтвердите удаление",
        "complete": "Куратор удален"
      },
      "addToNewModal": {
        "title": "Отметка NEW",
        "desc": "В урок были внесены изменеия. Установить отметку NEW?",
        "yes": "Да",
        "no": "Нет",
      },
      "appointCuratorModal": {
        "title": "Назначить куратора",
        "select": "Выберите куратора из списка:",
        "selectPlaceholder": "Выберите из списка",
        "btnAdd": "Назначить",
        "complete": "Куратор успешно назначен",
      },
      "deleteStudentModal": {
        "title": "Подтвердите удаление",
        "text": "Будет удалено {count} учеников | Будет удален {count} ученик | Будет удалено {count} ученика | Будет удалено {count} учеников",
        "deleteComplete": "Ученик успешно удален"
      },
      "editTariffFinishDate": {
        "modalTitle": "Редактировать дату окончания тарифа",
        "changesSaved": "Изменения сохранены"
      },
      "editTariff": {
        "modalTitle": "Редактировать тариф",
      },
      "addStudentUser": {
        "btnText": "Добавить/обновить",
        "modalTitle": "Добавить/обновить ученика",
        "finishedAt": "Дата окончания",
        "searchPlaceholder": "поиск (мин. 3 симв.)",
        "datePlaceholder": "дд/мм/гг чч:мм",
        "changesSaved": "Изменения сохранены"
      },
      "trainingView": {
        "courseDescription": "Описание курса",
        "courseProgramm": "Программа курса",
        "courseCurator": "Ваш куратор",
      },
      'timeLeft': 'До конца акции:',
    },
    "authors": {
      "title": "Публичные профили",
      "aboutTitle": "Об авторе",
      "coursesTitle": "Курсы",
      "smallAuthorLabel": "Автор",
      "links": "Ссылки",
      "social_networks": "Социальные сети",
      "video": "Видео",
      "store": {
        "surname": "Фамилия",
        "first_name": "Имя",
        "avatar": "Аватар",
        "linkText": "Текст",
        "link": "Ссылка",
        "user": "Пользователь",
        "replaceUser": "Заменить пользователя",
        "addUser": "Привязать пользователя",
        "createNewAuthorToCurator": "Создание страницы автора",
      }
    },
    "curator": {
      "header": "Куратор",
      "complete": "Сохранено",
      "homeWorkShort": "Дом. задание",
      "homeWorkAbbr": "ДЗ",
      "student": "Ученик",
      "buyDate": "Дата",
      "hwTooltip": "Работа была принята",
      "noStudents": "У этого куратора нет учеников",
      "tg": "Telegram",
      "tariff": "Тариф"
    },
    "videoEncrypt": {
      "timeCodes": "Tаймкоды",
      "title": "Заголовок",
      "additionalMaterials": "Дополнительные материалы",
      "replaceVideo": "Заменить видео",
      "moreVideo": "Еще видео",
      "selectedVideo": "Выбрано видео",
      "chooseVideo": "Выбрать видео",
      "header": "Видео",
      "addVideo": "Добавить видео",
      "table": {
        "poster": "Постер",
        "video": "Видео",
        "name": "Название",
        "description": "Описание",
        "filename": "Файл",
        "hls_time": "Длина сегмента (сек)",
        "status": "Статус",
        "statuses": {
          "new": "Новое",
          "uploaded": "Загружено",
          "queue": "В очереди",
          "encrypting": "Шифрование",
          "encrypted": "Зашифровано"
        }
      },
      "UploadVideoModal": {
        "title": "Загрузка видео",
        "file": "Видеофайл до 20ГБ .mp4, .m4v, .mkv, .avi, .webm, .mov",
        "filePoster": "Файл .jpg, .png",
        "btnUpload": "Загрузить",
        "btnCancel": "Отмена",
        "uploaded": "Файл загружен",
        "type": "Тип"
      },
      "UploadPosterModal": {
        "title": "Загрузка постера: ",
        "file": "Файл .jpg, .png",
        "btnUpload": "Загрузить",
        "btnCancel": "Отмена",
        "uploaded": "Файл загружен"
      },
      "EditModal": {
        "title": "Параметры: ",
        "name": "Название",
        "namePlaceholder": "Название",
        "description": "Описание",
        "descriptionPlaceholder": "Описание",
        "hls_time": "Длина сегмента (сек)",
        "hls_timePlaceholder": "Длина сегмента (сек)",
        "btnSubmit": "Обновить",
        "btnCancel": "Отмена",
        "updated": "Обновлено"
      },
      "RemoveConfirmModal": {
        "title": "Удалить: ",
        "fieldVideoId": "Напишите id видео: ",
        "btnSubmit": "Удалить",
        "btnCancel": "Отмена",
        "deleted": "Удалено"
      },
      "EncryptModal": {
        "title": "Шифрование: ",
        "hls_time": "Длина сегмента (сек)",
        "btnSubmit": "Отправить в очередь",
        "btnClose": "Закрыть",
        "btnCancel": "Отмена",
        "btnStatus": "Лог",
        "updated": "Зашифровано",
        "sent_queue": "Отправлено в очередь",
        "error": "Ошибка!!!"
      },
      "PlayerModal": {
        "playlist": "Плейлист",
        "poster": "Постер",
        "hash": "Хеш ключа"
      }
    },
    "videoPublic": {
      "header": "Публичные видео",
      "addVideo": "Добавить видео",
      "url": "URL",
      "file": "Файл",
      "RemoveConfirmModalText": "Напишите имя файла: "
    },
    "curatorTable": {
      "view": "Отображено | Отображен | Отображено | Отображено",
      "curators": "кураторов | куратор | куратора | кураторов",
      "addCurator": "Добавить куратора",
      "spreadCurators": "Распределить учеников",
      "export": "Экспорт в Excel",
    },
    "studentTable": {
      "appoint": "Назначить",
      "students": "учеников | ученик | ученика | учеников",
      "selected": "Выбрано | Выбран | Выбрано | Выбрано",
      "export": "Выгрузить в Excel",
      "appointCurators": "Назначить куратора",
      "delete": "Удалить"
    },
    "subscribe": {
      "blackFriday": {
        "title": "BLACK FRIDAY",
        "subtitle": "скидки с 25 ноября по 1 декабря",
        "buyButton": "Купить сейчас",
        "tariffs": {
          "proAlerts": {
            "title": "PRO + ALERTS",
            "discount": "ВЫГОДА до 55%",
            "description": "Идеальный выбор для тех, кто хочет полный доступ к алертам и премиум-функциям.\nПолучите 18 месяцев по цене 12, что означает $22 вместо $49 ежемесячно, с экономией 55%",
            "bonus": "+6 месяцев бесплатно!",
          },
          "pro": {
            "title": "PRO",
            "discount": "ВЫГОДА до 58%",
            "description": "Получите 18 месяцев подписки PRO по цене 12.\nСэкономьте 58% ежемесячно и платите всего $16. Пользуйтесь всеми основными функциями длительный срок. ",
            "bonus": "+6 месяцев бесплатно!",
          },
        },
      },
      "actveTrial": "Триал тариф действует до",
      "tgModalPoint4": "Быть участником чата Tradium",
      "tgModalPoint3": "Подписка на телеграм канал Tradium",
      "tgModalPoint2": "Подписка на канал скринера Cryptovizor",
      "tgModalPoint1": "Авторизоваться в нашем телеграм боте",
      "tgModalTitle": "Требования для подключения тарифа {tariff}:",
      "basicTooltip": "Вы сможете переключится на тариф BASIC, только в случае если у вас кончилась подписка оплаченного тарифа",
      "subtitle": "Вы можете выбрать один из следующих тарифов:",
      "payment": "Оплата за:",
      "monthly": "Месяц",
      "3months": "3 месяца",
      "6months": "6 месяцев",
      "12months": "12 месяцев",
      "pay": "Выбрать",
      "prolong": "Продлить",
      "fulfilled": "Заполнено 100\\100",
      "features": "Основные преимущества:",
      "benefits": "Дополнительные преимущества:",
      "active": "Активна",
      "inactive": "Доступно",
      "save": "Сохраните",
      "waitTransaction": "Ожидание транзакции",
      "toDate": "до",
      "complite": {
        "title": "Поздравляем!",
        "content": "Поздравляем с приобретением курса Daytrading Futures! 🎉\n" +
          "\n" +
          "Мы рады, что вы присоединились к нашей команде. В верхней панели появилась новая вкладка ОБУЧЕНИЕ, там представлены все необходимые материалы.\n" +
          " \n" +
          "После покупки курса, вам начислен кэшбек в виде 3-х месяцев PRO подписки на скринер, он уже активирован.\n" +
          "\n" +
          "А еще, вам открылся секретный модуль Тренды, которым вы можете начать пользоваться прямо сейчас! 😉",
      },
      "modal": {
        "agreeLabel": "Подключить тариф автоматически, после пополнения счета?",
        "tariffChange": "Смена тарифа",
        "tariffTrainingBuy": "Покупка тарифа обучение",
        "tariffBuy": "Покупка тарифа",
        "currentTariff": "Текущий тариф:",
        "newTariff": "Новый тариф",
        "overPayAmount": "Нужно доплатить",
        "balance": "Баланс:",
        "payWithBalance": "Оплатить с баланса (USDT):",
        "maxAmountOnBalance": "Максимальная сумма доступная на балансе:",
        "enterFullSum": "Ввести всю сумму",
        "payWithRef": "Оплатить реф. баллами",
        "payRefHint": "Вы можете оплатить баллами часть суммы или всю сумму целиком",
        "totalSum": "Итого к покупке:",
        "balanceReturn": "На баланс вернется:",
        "totalWithdrawalCost": "Итого будет списано:",
        "buyButton": "Приобрести",
        "changeTariffButton": "Сменить тариф",
        "payButton": "Оплатить",
        "goToBuy": "Переход к оплате",
        "daysLeft": "Дней оставалось",
        "daysLeftDetail": "дней | день | дня | дней",
        "errorBuyBonuses": "Не удалось выставить счет",
        "errorReceivingData": "Ошибка получения данных",
        "amountLeft": "Остаток",
        "amountResult": "Для перехода на выбранный тариф, необходимо заплатить",
        "message": "Для перехода на выбранный тариф, оплата не требуется",
        "switch": "Перейти",
        "cancel": "Отмена",
        "pageNoOpen": "Если переход не произошёл, нажмите на ссылку ниже"
      },
      "switchToPayPage": "Переход на страницу оплаты, подождите",
      "changeTariffSuccess": "Переход на тариф выполнен успешно",
      "reloadingStatus": "Обновление статуса",
      "reloadedStatus": "Статус обновлён",
      "trial": {
        "trialToDate": "Подключен Триал тариф",
        "trialPeriod": "Попробовать",
        "trialProAlertsPeriod": "Бонус",
        "modalTitle": "Активировать пробный период",
        "modalSubtitle": "Для получения пробного периода вам необходимо подтвердить учетную запись через Телеграм бота",
        "modalSubtitleTg1": "После активации вам будет выдан пробный период тарифа PRO+ALERTS сроком 15 дней. Вы получите полный функционал сервиса. По окончанию пробного периода вы сможете оформить подписку и продолжить работу в нашем сервисе.",
        "modalSubtitleProAlerts": "После активации срок действия вашего тарифа PRO+ALERTS будет продлен на 15 дней.",
        "modalSubtitleTg2": "Обратите внимание, что в случае оплаты тарифа в период пробного доступа - бесплатные дни будут списаны",
        "confirm": "Подтвердить запись",
        "confirmSuccess": "Аккаунт telegram подтвержден успешно",
        "cancel": "Отмена",
        "activate": "Активировать",
        "activateSuccess": "Тариф Trial Pro+Alerts успешно активирован, если не отобразились вкладки - обновите страницу"
      },
      "paymentWasSuccessful": "Оплата прошла успешно. Обновление статуса...",
      "statusUpdate": "Обновление статуса",
      "statusUpdated": "Статус обновлён",
      "paymentDeclined": "Оплата отклонена",
      "selectMounth": "Выберите срок подписки:",
      "selectCurator": "Выберите куратора",
      "tabTraining": "Обучение",
      "tabSubscribe": "Подписка",
      "showMoreBTN": "Показать больше",
      "teacherLabel": "Преподаватель:",
      "infoAboutTeacherLabel": "Информация о преподователе",
      "learnListLabel": "Чему вы научитесь",
      "cousreProgrmmLabel": "Программа курса",
      "maximalDialyProfitLabel": "Максимальный дневной профит:",
      "fullCourseInfoLabel": "Полная информация о курсе",
      "viewLabelBTN": "Ознакомиться",
      "subscribeTariningData": [{
        "learnList": [],
        "teacherInfo": "Из альткоин холдера в альткоин шортера. Первый миллион рублей заработал в 22 года",
        "cardList": [
          {
            "title": 'Практикуйтесь ежедневно',
            "description":
              'Получайте домашние задания, а после их выполнения будет обратная связь от преподавателя',
          },
          {
            "title": 'Проверка домашних заданий',
            "description":
              'У вас будет постоянная связь с наставниками проекта и практические домашние задания по каждой теме',
          },
          {
            "title": '30+ часов обучающих занятий',
            "description":
              'Каждое занятие проводится вживую, с обратной связью',
          },
          {
            "title": 'Занятия проводятся онлайн',
            "description": 'Также они доступны в записи в течение трех месяцев',
          },
          {
            "title": 'Все виды стратегий и инструментов',
            "description":
              'Преподаватель демонстрирует на графиках в реальном времени и отвечает на возникающие вопросы',
          },
          {
            "title": 'Доступ в закрытую команду',
            "description": 'Доступ в закрытую группу потока c кураторами',
          },
        ],
      }, {
        "learnList": [],
        "teacherInfo": "Из альткоин холдера в альткоин шортера. Первый миллион рублей заработал в 22 года",
        "cardList": [
          {
            "title": 'Практикуйтесь ежедневно',
            "description":
              'Получайте домашние задания, а после их выполнения будет обратная связь от преподавателя',
          },
          {
            "title": 'Проверка домашних заданий',
            "description":
              'У вас будет постоянная связь с наставниками проекта и практические домашние задания по каждой теме',
          },
          {
            "title": '30+ часов обучающих занятий',
            "description":
              'Каждое занятие проводится вживую, с обратной связью',
          },
          {
            "title": 'Занятия проводятся онлайн',
            "description": 'Также они доступны в записи в течение трех месяцев',
          },
          {
            "title": 'Все виды стратегий и инструментов',
            "description":
              'Преподаватель демонстрирует на графиках в реальном времени и отвечает на возникающие вопросы',
          },
          {
            "title": 'Доступ в закрытую команду',
            "description": 'Доступ в закрытую группу потока c кураторами',
          },
        ],
      }]
    },
    "bonuses": {
      "buyBonusesTitle": "Пополнить баланс",
      "buyBonusesInput": "Пополнить баланс на:",
      "buyBonusesButton": "Пополнить",
    },
    "payments": {
      "name": "Имя",
      "email": "Почта",
      "paymentDate": "Дата создания платежа",
      "paymentID": "ID платежа",
      "invoiceID": "ID инвойса",
      "price": "Цена",
      "currency": "Валюта/сумма платежа",
      "actuallyPaid": "Внесено",
      "status": "Локальный статус",
      "merchantStatus": "Статус мерчанта",
      "bundle": "Тариф",
      "dueDate": "Срок",
      "search": "Поиск",
      "searchPlaceholder": "Email (мин. 3 симв.)",
      "new": "Новый",
      "succeeded": "Выполнен",
      "pending": "В обработке",
      "canceled": "Отменен",
      "paid": "Оплачено"
    },
    "analytics": {
      "conversionRates": "Конверсионные коэффициенты",
      "userTimeSpent": "Время, проведенное пользователем в приложении/на сайте",
      "churnRate": "Уровень оттока (Churn Rate)",
      "activeUserCount": "Общее количество активных пользователей",
      "ltvGraphTitle": "Пожизненная ценность клиента (LTV)",
      "monthArpu": "Средний доход на пользователя (ARPU) помесячно",
      "countUsers": "Количество пользователей",
      "cumulativeIncome": "Кумулятивный доход",
      "monthlyIncome": "Месячный доход",
      "totalRegisteredUser": "Общее количество зарегистрированных пользователей",
      "numberOfNewAndRepeatSubscribe": "Количество новых и повторных подписок в месяц",
      "monthlyUserRegIncrease": "Месячный прирост регистраций пользователей"
    },
    "modalPassword": {
      "title": "Изменение пароля",
      "current": "Текущий пароль",
      "new": "Новый пароль",
      "repeat": "Повторите новый пароль",
      "change": "Изменить пароль",
      "changeSuccess": "Пароль изменён",
    },
    "referrals": {
      "navigation": {
        "mine": "Личный баланс",
        "bonusTransfer": "Запросы на вывод",
        "refList": "Список рефералов",
      },
      "title": "Рефералы",
      "set_status": "Задать статус",
      "info": "Создать заявку на вывод можно только при реферальном балансе от",
      "status": {
        "pending": "В обработке",
        "new": "Не оплачено",
        "succeeded": "Оплачено",
        "cancel": "Отклоненые",
        "subscriptionStatus": "Платеж за подписку",
      },
      "select_all_not_paid": "Отметить все неоплаченные",
      "upload_selected": "Выгрузить",
      "upload_hint": "Только выбранные будут выгружены",
      "table": {
        "header": {
          "telegram": "Telegram",
          "status": "Статус",
          "system_login": "Логин в системе",
          "cash_cost": "Сумма на вывод",
          "wallet": "Кошелёк",
          "date": "Дата запроса",
          "name": "Имя",
          "email": "Почта",
          "balance": "Баланс",
          "refBalance": "Реферальный баланс",
          "refIncome": "Вознаграждение за все время",
          "refCount": "Количество рефералов",
          "cancel": "Отменить"
        }
      },
      "referral_count": "Количество рефералов",
      "referral_payed_count": "Кол-во рефералов с оплатой",
      "balance": "Текущий баланс",
      "bonus_all_time": "Вознаграждение за все время",
      "referral_link": "Твоя ссылка для приглашения рефералов",
      "referral_link_hint": "Делитесь вашей реферальной ссылкой с друзьями! <br> Вы и каждый пользователь, зарегистрированный по вашей ссылке получите кешбэк 10% за первую подписку.",
      "min_transaction": "Минимальная сумма для вывода",
      "create_transaction": "Оформить заявку на выплату",
      "transaction_history": "История транзакций",
      "copy_referral_link": "Ссылка скопирована",
      "copy_referral_link_fail": "Ваш браузер не поддерживает системное копирование. Приносим свои извинения.",
      "referral_link_update": "Реферальный код обновлен",
      "cash_modal": {
        "cash_cost": "Сумма для вывода",
        "title": "Заявка на выплату",
        "wallet": "Ваш кошелёк",
        "min": "Минимум",
        "cash_all": "Ввести всю сумму",
        "available": "Доступно",
        "request_cash": "Заказать выплату",
        "cancel": "Отменить",
        "success_requires": "Запрос на вывод бонусов успешно создан.",
        "disclaimer": "Вы можете вывести реферальные бонусы с оплаты скринера на свой кошелек. Однако реферальные бонусы, полученные от оплаты обучения, можно использовать только для оплаты скринера и они не подлежат выводу."
      },
      "history_modal": {
        "title": "История выплат"
      }
    },
    "admin_bonus_transfer": {
      "title": "Запросы на вывод",
      xlsx: {
        'id': "ИД",
        'status': "Статус",
        'email': "Электронная почта",
        'withdrawalAmount': "Размер вывода",
        'wallet': "Электронный кошелёк",
        'requestDate': "Дата запроса",
        'uploadingBonusTransactions': "Выгрузка транзакций бонусов",
      },
    },
    "tg_notifications": {
      "title": "Рассылка в telegram",
      "empty": "Нет рассылок",
      "edit": "Изменить",
      "delete": "Удалить",
      "add": "Добавить",
      "titleModal": "Рассылка",
      "message": "Сообщение",
      "save": "Сохранить",
      "send": "Разослать",
      "cancel": "Отменить",
      "titleModal2": "Уверены?",
      "status": "Статус",
      "whoSent": "Кто отправил",
      "dateTimeOfDispatch": "Дата и время отправки",
      "delivered": "Доставлено",
      "errors": "Ошибок",
      "errorsList": "Список ошибок",
      "list": "Список",
      fieldsErrors: {
        user: 'Пользователь',
        error: 'Ошибка',
      },
    },
    "notifications": {
      "marketplace": {
        "title": 'Рынок',
        "types": {
          "all": 'Все',
          "spot": "SPOT",
          "futures": "FUTURES",
        }
      },
      "market": "Рынок",
      "copyFinandyConfig": "Скопировать настройку Finandy",
      "ryptovizorIp": "IP сервера Cryptovizor",
      "syncedFutures": "Синхронизировано с вотчлистом FUTURES",
      "syncFutures": "Синхронизировать с вотчлистом FUTURES",
      "signalUrl": "URL сигнала",
      "templates": "Шаблоны",
      "template": "Шаблон",
      "webHookResponse": "Ответ от сервера интеграции",
      "confirmEnableHook": "Подтвердите активацию хука",
      "hookLogs": "Логи вебхуков",
      "hooks": "Вебхуки",
      "hook": "Вебхук",
      "confirmDisableHook": "Подтвердите деактивацию хука",
      "confirmDeleteHook": "Подтвердите удаление хука",
      "confirmAction": "Подтвердите действие",
      "enterValidUrl": "Введите валидный url",
      "enterValidJson": "Введите валидный json",
      "validJson": "Валидный json",
      "createHook":"Создание хука",
      "signalMessage": "Сообщение сигнала",
      "createSignal": "Создать оповещение",
      "triggerConditions": "Условия срабатывания",
      "saveOnGraph": "Хранить отметки на графике",
      "plotOnChartDuration": "Сколько отмечать на графики оповещения",
      "plotOnTheChart": "Отмечать на графике",
      "notifications": "Оповещений",
      "isInActive": "Неактивное",
      "active": "Активные",
      "inActive": "Неактивные",
      "savedInDraft": "Сохранено в черновике.",
      "saveAsDraft": "Сохранить как черновик",
      "draftDeleted": "Черновик удален",
      "drafts": "Черновики",
      "source": "Источник",
      "groupNotificationName": "Название группового оповещения",
      "notificationName": "Название оповещения",
      "unit": "Единица измерения",
      "positive": "Positive «+»",
      "negative": "Negative «-»",
      "unitLabel1": "K-тысяч",
      "unitLabel2": "M-миллионов",
      "unitLabel3": "B-миллиарды",
      "tokenTooltip": "Для создания оповещения требуется 1 CREATOR или INFLUENCER токен.",
      "modalTitle": "Куда отправлять уведомления",
      "title": "Настройка оповещений",
      "addBtn": "Добавить оповещение",
      "coin": "Монета",
      "placeholder": "Введите для поиска",
      "typeOfNotification": "Тип оповещения",
      "deleteNotification": "Удалить оповещение",
      "deleteNotificationText": "Точно удалить?",
      "timeframe": "Таймфрейм",
      "variable": "Переменная",
      "input": "Введите значение",
      "addMore": "Добавить ещё",
      "repeat": {
        title: 'Повторять',
        once: 'Один раз',
        every: 'Постоянно',
      },
      "comment": "Комментарий",
      "activeListTitle": "Активные оповещения",
      "inactiveListTitle": "История оповещений",
      "emptyActiveTable": "Список оповещений пуст",
      "emptyInactiveTable": "Журнал оповещений пуст",
      notificationSettings: 'Настройка уведомлений',
      "trendsBotText": "Для получения уведомлений в телеграм активируйте бота по",
      "trendsBotLink": "ссылке",
      "trendsBotLinkText": "Ссылка на бота",
      creatingNotification: 'Создание оповещения',
      notification: 'Оповещение',
      viewNotification: 'Просмотр оповещения',
      editNotification: 'Редактирование оповещения',
      "important": "Важно",
      "warning": "Вы уже ранее указали пару с другой биржи, в модуле оповещения нельзя смешивать несколько разных бирж в одно групповое оповещение",
      target: {
        title: 'Куда отправлять',
        tg: 'В Telegram',
        web: 'В браузер',
      },
      errorMessages: {
        hook: "Для того что бы воспользоваться функционалом веб-хуков подключить 2fa",
        tg: 'Для получения уведомлений в телеграм бота, вам необходимо пройти верификацию. Для этого нажмите "Подтвердите учетную запись через Telegram".',
        web: 'Для получения уведомлений в ваш браузер разрешите оповещения в браузере.',
        allDisabled: 'Для создания оповещений вам необходимо пройти верификацию в телеграм боте, а так же вы можете получать оповещения в браузер.',
      },
      create: 'Дата создания',
      met: 'Дата срабатывания',
      unpublished: "Оповещение было снято с публикации.",
      published: "Оповещение было опубликовано.",
      deleteNotificationNote: 'Оповещение было удалено.',
      subscribeSuccess: "Вы подписались на оповещение.",
      unsubscribeSuccess: "Вы отписаны от оповещения.",
      addSuccess: 'Оповещение добавлено',
      removeSuccess: 'Оповещение удалено',
      signalChanged: 'Оповещение изменено.',
      "single": "Одиночные",
      "group": "Групповые"
    },
    "coinList": {
      "coinSettingsUpdated": "Настройки монет обновлены",
      "coinsToDisplayInScreener": "Монеты для вывода в Скринере",
      "import": "импорт",
      "show": "отображать spot",
      "showPerp": "отображать perp",
      "screener": "скринер",
      "trends": "тренды",
      "coinsToDisplayInTrends": "Монеты для вывода в Трендах",
    },
    "permission": {
      "accessGrantedSuccessfully": "Доступ выдан успешно",
      "giveAccess": "Выдать доступ",
      "emailPlaceholder": "Email (мин. 3 симв.)",
      "selectAnExpirationDate": "Выберите дату истечения доступа",
      "numberOfDaysOfAccess": "Количество дней доступа",
      "subscription": "Подписка",
      "chooseAPlan": "Выберите тариф",
      "users": "Пользователи",
      "telegramAttached": "У этого пользователя не привязан телеграм",
      "changeBalance": {
        "title": "Изменить баланс",
        "inputLabel": "Баланс пользователя:",
        "calculatedInputLabel": "Изменится на:",
        "buttonSave": "Изменить",
        "buttonCancel": "Отменить",
        "invalidFeedback": "Введите число",
        "successResponse": "Баланс пользователя изменен",
        "errorResponse": "Ошибка пополнения",
      },
      "table": {
        "name": "Имя",
        "email": "Почта",
        "tariff": "Тариф",
        "tariffDaysLeft": "Дней осталось",
        "tariffExpired": "Дата истечения",
        "trends": "Тренды",
        "lastLoginAt": "Последний запрос",
        "createdAt": "Дата регистрации",
        "active": "Заблок.",
        "tgLink": "Сброс tg.",
        "balance": "Баланс",
        "refBalance": "Реферальный баланс",
        "referrer": "Имя реферала",
        "referrer_link": "Реф ссылка",
      }
    },
    subscribeConstructor: {
      name: 'Название',
      subscriptionName: 'Название подписки',
      subscriptionID: 'ID подписки',
      requiredForTheServerSide: 'Необходимо для серверной части',
      chooseColor: 'Выберите цвет',
      subscriptionDescription: 'Описание подписки',
      description: 'Описание',
      subscriptionCost: 'Стоимость подписки',
      price: 'Цена',
      oldPrice: 'Старая<br>цена',
      amountOfDiscount: 'Размер скидки',
      mainFunctions: 'Основные функции',
      addLine: 'Добавить строку',
      availablePages: 'Доступные страницы',
    },
    whitelist: {
      codeListDownload: 'Загрузка списка кодов',
      download: 'Загрузить',
      table: {
        user: 'Пользователь',
        tariff: 'Тариф',
        term: 'Срок',
        trends: 'Тренды'
      },
      inputHint: {
        downloadFileHint: 'Подсказка по файлу для загрузки',
        example: 'Пример',
        enterTheRates: '      <p class="font-weight-bold">Тарифы в файле указывать так:</p>' +
          '      <p>delta, screener, delta_screener</p>' +
          '      <p>Срок выдачи тарифа указывается в секундах.</p>' +
          '      <p class="font-weight-bold">Сроки в секундах:</p>' +
          '      <ul>' +
          '        <li>1 месяц - 2592000</li>' +
          '        <li>3 месяца - 7776000</li>' +
          '        <li>6 месяцев - 15552000</li>' +
          '        <li>12 месяцев - 31104000</li>' +
          '      </ul>' +
          '      <p>В таблице xls после числа не должно быть пробела.</p>',
      },
    },
  },
  "aboutUs": {
    "title": "О нас",
    "1_listTitle": "Опыт и доверие",
    "1_listSubtitle": "Работаем с 2016 года. За это время мы создали ряд крупнейших проектов:",
    "1_listItem_1": "крупнейший канал о криптотрейдинге",
    "1_listItem_2": "решения для криптопроектов и консалтинг",
    "1_listItem_3": "онлайн академия",
    "1_listItem_4": "собственный софт для торговли",
    "2_listTitle": "Более 60 тыс. подписчиков",
    "2_listSubtitle": "С 2017 года мы делимся аналитикой и торговыми идеями бесплатно в нашем канале",
    "3_listTitle": "Консалтинг и обучение",
    "3_listSubtitle": "Профессионально обучаем начинающих и опытных трейдеров; за 4 года обучения более 900 учеников",
    "partners": "Партнеры",
    "media": "Медиа",
    "contacts": "Контакты",
    tgTTTools: 'https://t.me/cvizor',
    "chatLink": "Чат",
    "groupLink": "Группа",
    tgTTToolschat: 'https://t.me/cvizorchat',
    "fb_link": "https://www.facebook.com/cvizorcrypto/",
    "inst_link": "https://www.instagram.com/cvizorcom/",
    "twitter_link": "https://twitter.com/cvizorcom",
    "youtube_link": "https://www.youtube.com/channel/UCrFeHq_1nXqwh00aE9fevrA",
    "writeToUs": "Свяжитесь с нами",
    "form": {
      "name": "Имя",
      "tg": "Телеграм",
      "email": "Почта",
      "subject": "Тема",
      "question": "Ваш вопрос",
      "send": "Отправить",
      "response": "Ваша заявка отправлена",
      "info": "Оставляя заявку на сайте вы даёте согласие на  <a target='_blank' href='https://cvizor.com/ru/policy'>обработку персональных данных</a>",
    }
  },
  "screener": {
    "tables": "Таблицы",
    "timeframes": "Таймфреймы",
    "tableScreener": "Table Screener",
    "search": "Поиск",
    "searchPlaceholder": "Название (начните вводить)",
    "searchClear": "Очистить",
    "typing": "Начните вводить, для поиска валюты",
    "stats": "показатели",
    openGraph: 'Открыть график',
    "accessTooltip": "Доступно в PRO и PRO+alerts",
    "tablesView": {
      "sortByHintABS": "Сортировать по абсолютному значению",
      "sortByHint%": "Сортировать по процентному значению",
      "emptyListTitle": "По выбранному фильтру нет подходящих результатов",
      "sort": "Сортировать по:",
      "sortMobile": "Сорт.:",
      "slidingVolume": "Скользящий объём",
      "colorFilterHint": {
        "danger": "Красная зона цена под МА= Нисходящий тренд",
        "warning": "Желтая зона цена между МА = АЗТ (активная зона трейдера)",
        "success": "Зеленая зона цена над МА = Восходящий тренд"
      },
      "volatDesc": "Волатильность рассчитывается как стандартное отклонение изменений цены",
      "volumeDesc": "Volume - это объем торгов. Объем каждой монеты указан в USDT.<hr style='margin: 0' />Методы сортировки в столбце Volume:<br />При нажатии на Volume происходит сортировка по объемам в USDT.<br />При нажатии на стрелочку рядом с Volume происходит сортировка по изменению объемов."
    },
    "graphsView": {
      "panel": "Панель инструментов",
      "panelMobile": "Пан. инстр.",
      "ticker": "Тикер",
      "open": "Цена на начало периода",
      "current": "Текущая цена",
      "change": "Изменение цены",
      "volat": "Волатильность",
      "volatDesc": "Волатильность рассчитывается как стандартное отклонение изменений цены",
      "numberOfCharts": "Кол-во графиков"
    },
    "trendsView": {
      "inDetail": "Подробно",
      "trendSummary": "Сводка трендов",
      "bounceDesc": "Если на периодах от 1 дня до 3 часов везде один тренд, а на периодах от 15м до 2 часов другой, то эта монета попадает в список Отскоки. То есть, монета идёт один день в одну сторону, и на ближайшем часе пошла в другую"
    },
    "treemap": {
      "numberOfCoins": "Кол-во монет",
      "parameter": "Параметр",
    },
    "deleteWatchlistModal": {
      "title": "Очистка {name}",
      "subtitle": "Данное действие очистит вотчлист от тикеров внутри него. Очищаем?",
    },
    "playPauseBtn": 'При нажатии на знак паузы, запросы в скринер остановятся. Если вы хотите возобновить запросы, то нажмите кнопку Play или обновите страницу',
  },
  "formLogin": {
    "title": "Авторизация",
    "subtitle": "Войдите в ваш аккаунт",
    "email": "Введите E-mail",
    "password": "Введите пароль",
    "forgotPassword": "Забыли пароль?",
    "signIn": "Войти",
    "signUp": "Зарегистрироваться",
    "MainPage": "На главную",
    "registerTitle": "Регистрация",
    "registerSubtitle": "Если у вас еще нет аккаунта, то вы можете зарегистрироваться прямо сейчас",
    "greeting": "Привет,",
    "2FAinfo": "Для входа введи код из приложения",
  },
  "formRegister": {
    "title": "Регистрация",
    "subtitle": "Создайте новый аккаунт",
    "name": "Введите никнейм",
    "email": "Введите E-mail",
    "password": "Введите пароль",
    "repeatPassword": "Повторите пароль",
    "signUp": "Регистрация",
    "goBack": "Назад",
    "text": "Нажимая кнопку зарегистрироваться вы соглашаетесь с",
    "linkText": "политикой конфиденциальности"
  },
  "formRecovery": {
    "title": "Восстановление пароля",
    "subtitle": "Введите E-mail  и мы отправим  ссылку для сброса пароля",
    "back": "Назад",
    "reset": "Восстановить",
    "setNewPassword": "Введите новый пароль",
    "new": "Введите пароль",
    "repeat": "Подтвердите пароль"
  },
  "formSignUp": {
    "title": "Регистрация",
    "subtitle": "В течение 15 минут на вашу почту поступит письмо. Перейдите по ссылке для подтверждения регистрации ",
    "confirm": "Подтвердите, пожалуйста email",
    "sendLink": "Отправить ссылку для подтверждения email",
    "logout": "Выйти из профиля",
    "signIn": "Вход",
    "confirmationTitle": "Подтверждение email",
    "expiredText": "К сожалению время отведенное на переход по ссылке истекло. Для завершения регистрации попробуйте авторизоваться и нажмите кнопку с повторным заказом письма",
  },
  "delta": {
    "nav": "Навигация",
    "navDescription": "Навигация по стакану через нажатие в область над или под свечей",
    "datePlaceholder": "дд/мм/гг чч:мм ➜ дд/мм/гг чч:мм",
    "datePlaceholderMobile": "Выбрать период",
    "expand": "Развернуть",
    "quoteVolumeDelta": "Разница между объемом на покупку и продажу (рыночные ордера)",
    "quoteVolume": "Объем торгов в USD+USDT",
    quoteDate: 'Недоступность дат в календаре означает отутствие загруженных данных на графике. Перейдите к нужной дате на графике для загрузки данных.',
  },
  "trends": {
    "sendByTrend": "Отправлять по тренду",
    "datePlaceholder": "дд/мм/гг чч:мм",
    "perfectlyFit": "Четко по страте",
    "onlyPositive": "Только вверх",
    "onlyNegative": "Только вниз",
    "summary": "Сводка",
    "table": "Таблица",
    "up": "Вверх",
    "down": "Вниз",
    "notifactionGroup": "Групировать сообщения",
    "notifactionGroupHints": "Получать изменения трендов вы будете раз в 5 минут",
    "notifactionGroupHintsSecond": "Будет отправляться каждое изменение тренда монет по отдельности"
  },
  "lineRunner": {
    "notallowedTooltip": "Бегущая строка доступна для PRO подписки",
    "title": "Бегущая строка",
    "common": "Общие параметры",
    "emptyParams": "Параметры не выбраны",
    "param": "Параметр",
    "addParam": "Добавить параметр",
    "coins": "Монеты",
    "addCoin": "Добавить монету",
    "emptyCoins": "Монеты не добавлены",
    "coin": "Монета",
    "setupParams": "Настроить показатели",
    "show": 'Отображение бегущей строки',
    "save": "Сохранить",
    "requiredField": "Обязательное поле",
    "notValidate": "Данные не валидны"
  },
  "maSettings": {
    "notallowedTooltip": "Доступно в PRO и PRO+ALERTS",
    "description": "В настройке заданы ограничения по значениям, минимальное значение-5, максимальное значение-365. Старшая МА не может быть меньше Младшей МА",
    "low": "Младшая МА",
    "high": "Старшая МА",
    "validateRange": "Минимальное значение-5, максимальное значение-365",
    "validateOrder": "Старшая МА не может быть меньше Младшей МА",
    "maDisplayType1": "Показывать абсолютные значения",
    "maDisplayType2": "Показывать сравнение в %",
    "maType1": "Показывать расстояние в % от МА до Price",
    "maType2": "Показывать расстояние в % от Price до МА",
    "maTypeTitle": "Способ расчета процентов",
    "maDisplayTypeError": "Должен быть выбран хотя бы один параметр отображения"
  },
  "onboarding": {
    "back": "Назад",
    "next": "Далее",
    "of": "из",
    "deltaPage": onboardingDeltaData
  },
  "referral": {
    "have_code": "У меня есть реферальный код",
    "input": {
      "placeholder": "Реферальный код"
    }
  },
  emojiPicker: {
    search: 'Поиск...',
    categories: {
      recents: 'Недавние',
      smileys: 'Эмоции',
      people: 'Люди',
      animals: 'Животные и природа',
      food: 'Еда',
      activities: 'Виды деятельности',
      travel: 'Путешествия и места',
      objects: 'Объекты',
      symbols: 'Символы',
      flags: 'Флаги',
      custom: 'Пользовательские'
    },
    notFound: 'Не найдено'
  },
  validateMessages: {
    coinIsRequired: 'Монета - обязательное поле',
    sendingTypeIsRequired: 'Тип отправки - обязательное поле',
    repeatabilityIsRequired: 'Повторяемость - обязательное поле',
    missingAlertType: 'Отсутствует тип оповещения',
    timeframeIsRequired: 'Таймфрейм - обязательное поле',
    parameterIsRequired: 'Параметр - обязательное поле',
    valueIsRequired: 'Значение - обязательное поле',
  },
  pageNotFound: {
    title: 'Ошибка',
    subtitle: 'Страница не найдена',
    link: 'Перейти на стартовую страницу',
  },
  pagePermissionDenied: {
    title: 'Ошибка',
    subtitle: 'Доступ к странице отклонён',
    link: 'Перейти на стартовую страницу',
  },
  pageTechnicalWorks: {
    title: 'На сервисе проходят технические работы. Сервис будет не доступен с 18:00 до 19:00 мск.',
  },
  tvPropsDropdown: {
    tooltip: 'Оповещения',
    hideNotifications: 'Скрыть оповещения',
    hideSignalsPlot: 'Скрыть метки сигналов',
    showNotifications: 'Показать оповещения',
    showSignalsPlot: 'Показать метки сигналов',
    removeSignalsPlot: 'Удалить метки сигналов',
    signalsPlotRemoved: 'Метки сигналов {pair} удалены',
    signalsPlotRemoveNone: 'Нет меток сигналов для<br>{pair}',
    confirmRemoveSignalsPlot: 'Удаление меток сигналов',
    confirmRemoveSignalsPlotDesc: 'Подтвердите удаление меток сигналов с графика',
  },
  pluralization: {
    days: "дней | день | дня | дней",
    hours: "часов | час | часа | часов",
    minutes: "минут | минута | минуты | минут",
    seconds: "секунд | секунда | секунды | секунд",
  },
  screenType: {
    spot: 'СПОТ',
    futures: 'ФЬЮЧЕРСЫ',
  }
}
