/**
 * @import PairItem from './screenerSettings/PairItem.class'
 *
 * @typedef {Partial<Pick<PairItem, 'label' | 'labelAlt' | 'ticker'>>} LikePairItem
 * @typedef {string|LikePairItem} Signs
 */

/**
 * Класс для поиска пары.
 */
export class PairMap
{
  /** @private @type {Map<string, PairItem>} */ _pairsMap                 = new Map()
  /** @private @type {Map<string, string>}   */ _helperLabelByAltLabelMap = new Map()
  /** @private @type {Map<string, string>}   */ _helperLabelByTickerMap   = new Map()

  /**
   * @param {PairItem[]} pairs - Массив пар.
   */
  constructor(pairs) {
    for (const pair of pairs) {
      this._pairsMap.set(pair.label?.replace?.(/\.P|USDT/g, ''), pair)
      this._helperLabelByAltLabelMap.set(pair.labelAlt, pair.label)
      this._helperLabelByTickerMap.set(pair.ticker?.replace?.(/\.P|USDT/g, ''), pair.label)
    }
  }

  /**
   * Проверяет, что переданные признаки являются объектом.
   *
   * @private
   * @param {Signs} signs - Признаки искомой пары.
   * @returns {boolean}
   */
  _signIsObject (signs) {
    return typeof signs === 'object' && !Array.isArray(signs) && signs !== null
  }

  /**
   * Проверить существование пары.
   *
   * @public
   * @param {Signs} signs - Признаки искомой пары.
   * @returns {boolean}
   */
  exist (signs) {
    // Поиск по строке:
    if (typeof signs === 'string')
      return this._pairsMap.has(signs)
          || this._pairsMap.has(this._helperLabelByAltLabelMap.get(signs))
          || this._pairsMap.has(this._helperLabelByTickerMap.get(signs))
          || false

    // Поиск по объекту:
    if (this._signIsObject(signs))
      return this._pairsMap.has(signs.label)
          || this._pairsMap.has(signs.labelAlt)
          || this._pairsMap.has(signs.ticker)
          || false

    return false
  }

  /**
   * Находит пару по признаку. Если передана строка, то будет искать
   * по лейблу, алт. лейблу или тикеру. Если передать **объект**, то для поиска
   * будут использоваться параметры `label`, `labelAlt` или `ticker`.
   *
   * @public
   * @param {Signs} signs - Признаки искомой пары.
   * @returns {PairItem | null}
   */
  findOne (signs) {
    // Поиск по строке:
    if (typeof signs === 'string')
      return this._pairsMap.get(signs)
          || this._pairsMap.get(this._helperLabelByAltLabelMap.get(signs))
          || this._pairsMap.get(this._helperLabelByTickerMap.get(signs))
          || null

    // Поиск по объекту:
    if (this._signIsObject(signs))
      return this._pairsMap.get(signs.label)
          || this._pairsMap.get(signs.labelAlt)
          || this._pairsMap.get(signs.ticker)
          || null

    return null
  }

  /**
   * Находит несколько пар по признаку. Если передана строка, то будет искать
   * по лейблу, алт. лейблу, тикеру или символу пару. Если передать объект,
   * то для поиска будут использоваться параметры `label`, `labelAlt` или
   * `ticker`.
   *
   * @public
   * @param {Signs} signsArray - Массив признаков искомых пар.
   * @returns {PairItem[]}
   */
  findMany (signsArray) {
    return signsArray.map(sign => this.findOne(sign))
  }
}
