import { get, isNumber } from 'lodash'
import screenerDataAdapter from "@/assets/js/screenerDataAdapter";

export class TimeRange {
  constructor(init,coin) {
    this.rsi = get(init, 'rsi14', '—')
    this.colorRsi = get(init, 'color_rsi14', '')
    this.rsiPct = get(init, 'rsi14_pct', '—')
    this.rsiPctStr = this.rsiPct !== '—' ? this.rsiPct + '%' : this.rsiPct //typeof this.rsiPct === 'number' ? this.rsiPct + '%' : this.rsiPct
    this.colorRsiPct = get(init, 'color_rsi14_pct', '')
    this.start = get(init, 'start', '—')
    this.ma50_price_difference_perc = get(init, 'ma50_price_difference_perc', '—')
    this.ma50_price_difference_perc_str = this.ma50_price_difference_perc !== '—' ? this.ma50_price_difference_perc + '%' : this.ma50_price_difference_perc
    this.price_ma50_difference_perc = get(init, 'price_ma50_difference_perc', '—')
    this.price_ma50_difference_perc_str = this.price_ma50_difference_perc !== '—' ? this.price_ma50_difference_perc + '%' : this.price_ma50_difference_perc
    this.ma50 = get(init, 'ma50', '—')
    this.ma50trend = get(init, 'trend_ma50')
    this.ma200_price_difference_perc = get(init, 'ma200_price_difference_perc', '—')
    this.ma200_price_difference_perc_str = this.ma200_price_difference_perc !== '—' ? this.ma200_price_difference_perc + '%' : this.ma200_price_difference_perc
    this.price_ma200_difference_perc = get(init, 'price_ma200_difference_perc', '—')
    this.price_ma200_difference_perc_str = this.price_ma200_difference_perc !== '—' ? this.price_ma200_difference_perc + '%' : this.price_ma200_difference_perc
    this.ma200 = get(init, 'ma200', '—')
    this.ma200trend = get(init, 'trend_ma200')
    this.volatility = get(init, 'volatility', '—')
    this.price = get(init, 'close', '—')
    this.pricePct = init.close_pct || '—' //get(init, 'close_pct', '—')
    this.pricePctStr = this.pricePct !== '—' ? this.pricePct + '%' : this.pricePct//typeof this.pricePct === 'number' ? this.pricePct + '%' : this.pricePct
    this.colorPricePct = get(init, 'color_close_pct', '')
    this.volume = get(init, 'quote_volume', '—')
    this.volumeStr = get(init, 'quote_volume_str', '—')
    this.volumePct = get(init, 'quote_volume_pct', '—')
    this.volumePctStr = this.volumePct !== '—' ? this.volumePct + '%' : this.volumePct//typeof this.volumePct === 'number' ? this.volumePct + '%' : this.volumePct
    this.colorVolumePct = get(init, 'color_quote_volume_pct', '')
    this.slidingVolume = get(init, 'tf_quote_volume', '—')
    this.slidingVolumeStr = get(init, 'tf_quote_volume_str', '—')
    this.slidingVolumePct = get(init, 'tf_quote_volume_pct', '—')
    this.slidingVolumePctStr = this.slidingVolumePct !== '—' ? this.slidingVolumePct + '%' : this.slidingVolumePct//typeof this.slidingVolumePct === 'number' ? this.slidingVolumePct + '%' : this.slidingVolumePct
    this.colorSlidingVolumePct = get(init, 'color_tf_quote_volume_pct', '')

    this.tf1w_quote_volume_delta = get(init, 'tf1w_quote_volume_delta', '—')
    this.tf1w_quote_volume_delta_str = get(init, 'tf1w_quote_volume_delta_str', '—')

    this.tf1w_quote_volume_delta_pct = get(init, 'tf1w_quote_volume_delta_pct', '—')
    this.tf1wQuoteVolumeDeltaPctStr = isNumber(this.tf1w_quote_volume_delta_pct) ? this.tf1w_quote_volume_delta_pct + '%'
      : this.tf1w_quote_volume_delta_pct

    this.tf1w_quote_volume_delta_change_pct = get(init, 'tf1w_quote_volume_delta_change_pct', '—')
    this.tf1wQuoteVolumeDeltaChangePctStr = isNumber(this.tf1w_quote_volume_delta_change_pct) ? this.tf1w_quote_volume_delta_change_pct + '%'
      : this.tf1w_quote_volume_delta_change_pct

    this.tf1w_quote_volume_delta_change_volume_pct = get(init, 'tf1w_quote_volume_delta_change_volume_pct', '—')
    this.tf1wQuoteVolumeDeltaChangeVolumePctStr = isNumber(this.tf1w_quote_volume_delta_change_volume_pct) ? this.tf1w_quote_volume_delta_change_volume_pct + '%'
      : this.tf1w_quote_volume_delta_change_volume_pct


    this.tf_quote_volume_delta = get(init, 'tf_quote_volume_delta', '—')
    this.tf_quote_volume_delta_str = get(init, 'tf_quote_volume_delta_str', '—')

    this.tf_quote_volume_delta_pct = get(init, 'tf_quote_volume_delta_pct', '—')
    this.tfQuoteVolumeDeltaPctStr = isNumber(this.tf_quote_volume_delta_pct) ? this.tf_quote_volume_delta_pct + '%'
      : this.tf_quote_volume_delta_pct

    this.tf_quote_volume_delta_change_pct = get(init, 'tf_quote_volume_delta_change_pct', '—')
    this.tfQuoteVolumeDeltaChangePctStr = isNumber(this.tf_quote_volume_delta_change_pct) ? this.tf_quote_volume_delta_change_pct + '%'
      : this.tf_quote_volume_delta_change_pct

    this.tf_quote_volume_delta_change_volume_pct = get(init, 'tf_quote_volume_delta_change_volume_pct', '—')
    this.tfQuoteVolumeDeltaChangeVolumePctStr = isNumber(this.tf_quote_volume_delta_change_volume_pct) ? this.tf_quote_volume_delta_change_volume_pct + '%'
      : this.tf_quote_volume_delta_change_volume_pct

    this.prev_tf_quote_volume_delta_str = get(init, 'prev_tf_quote_volume_delta_str', '—')
    this.prev_tf1w_quote_volume_delta_str = get(init, 'prev_tf1w_quote_volume_delta_str', '—')

    this.ma50class = ''
    if (this.ma50trend) {
      this.ma50class = 'trend_' + this.ma50trend
    }
    this.ma200class = ''
    if (this.ma50trend) {
      this.ma200class = 'trend_' + this.ma200trend
    }
  }

  get getVolume () {
    return screenerDataAdapter.isSlidingVolume ? this.slidingVolume : this.volume
  }
  get getVolumePct () {
    return screenerDataAdapter.isSlidingVolume ? this.slidingVolumePct : this.volumePct
  }
  get getVolumeStr () {
    return screenerDataAdapter.isSlidingVolume ? this.slidingVolumeStr : this.volumeStr
  }
  get getVolumePctStr () {
    return screenerDataAdapter.isSlidingVolume ? this.slidingVolumePctStr : this.volumePctStr
  }
  get getColorVolumePct () {
    return screenerDataAdapter.isSlidingVolume ? this.colorSlidingVolumePct : this.colorVolumePct
  }

  get deltaWColor () {
    return this.getDeltaColor(this.tf1w_quote_volume_delta)
  }

  get deltaColor () {
    return this.getDeltaColor(this.tf_quote_volume_delta)
  }

  getDeltaColor(delta) {
    switch (true) {
      case delta < 0:
        return 'rgba(248, 108, 107, 0.5)'
      case delta === 0:
        return 'rgba(255, 193, 7, 0.5)'
      case delta > 0:
        return 'rgba(77, 189, 116, 0.5)'
      default:
        return ''
    }
  }
}
