<template>
  <div class="form-group">
    <div class="input-group">
      <div v-if="$slots.prepend" class="input-group-prepend">
        <div class="input-group-text">
          <slot name="prepend"></slot>
        </div>
      </div>

      <v-select
        ref="vselect"
        v-model="selectValue"
        :options="options"
        class="v-select-input form-control"
        :class="classes"
        v-bind="filteredAttrs"
        v-on="$listeners"
      >
        <template #no-options>
          <slot name="no-options" />
        </template>
        <template #option="slotScope">
          <slot name="option" v-bind:option="slotScope" />
        </template>
        <template #selected-option="slotScope">
          <slot name="selected-option" v-bind:option="slotScope" />
        </template>
        <template #selected-option-container="slotScope">
          <slot name="selected-option-container" v-bind:option="slotScope" />
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import VSelect from "vue-select";
export default {
  name: "VSelectInput",
  components: {
    VSelect
  },
  props: {
    value: {
      type: Object|Number,
    },
    options: {
      type: Array,
      default: () => ([])
    },
    classes: String
  },
  data () {
    return {
    }
  },
  computed: {
    selectValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    filteredAttrs () {
      const result = {}
      Object.keys(this.$attrs).forEach(key => {
        if (
          key !== 'value' &&
          key !== 'options' &&
          key !== 'classes'
        ) {
          result[key] = this.$attrs[key]
        }
      })
      return result
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.v-select-input {
  /deep/ .vs__clear,
  /deep/ .vs__open-indicator {
    fill: var(--dark);
    cursor: pointer;
  }
}
</style>
