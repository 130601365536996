import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    list: [],
    select: [],
    generalParams: [],
    // cancelEvent: null,
    showLineRunner: true,
    reloadLineRunner: false,
  }),
  actions: {
    getSelect({state}) {
      return axios.get('/api/v1/users/settings').then(({data}) => {
        state.select = data?.data?.lineRunner?.select
      })
    },

    getList({state, commit, rootState}) {
      // if (state.cancelEvent) {
      //   state.cancelEvent()
      // }
      if (rootState.cancelToken) {
        rootState.cancelToken.cancel()
      }
      commit('setCancelToken', axios.CancelToken.source(), { root: true })

      return axios.get('/api/v2/screener/tables', {
        // cancelToken: new axios.CancelToken((c) => {
        //   commit('SET_CANCEL_EVENT', c)
        // })
        cancelToken: rootState.cancelToken.token
      })
        .then(res => {
          commit('setTablesData', res?.data?.data, { root: true })
          const arr = rootState.tablesData
          if (arr && Array.isArray(arr)) {
            commit('SET_LIST', arr)
          }
          return res
        })
        .finally(() => {
          // commit('SET_CANCEL_EVENT', null)
        })
    },
    setSelect({commit}, payload) {
      commit('SET_SELECT', payload)
    },
    setGeneralParams({commit}, payload) {
      commit('SET_GENERAL_PARAMS', payload)
    }
  },
  mutations: {
    SET_LIST (state, payload) {
      state.list = payload
    },
    // SET_CANCEL_EVENT (state, payload) {
    //   state.cancelEvent = payload
    // },
    SET_SELECT (state, payload) {
      state.select = payload
    },
    SET_GENERAL_PARAMS (state, payload) {
      state.generalParams = payload
    },
    SET_SHOW_LINE_RUNNER (state, payload) {
      state.showLineRunner = payload
    },
    SET_RELOAD_LINE_RUNNER (state, payload) {
      state.reloadLineRunner = payload
    }
  },
  getters: {
    reloadLineRunner (state) {
      return state.reloadLineRunner
    },

    selectLineRunner (state) {
      return state.select
    },

    lineRunnerArr (state) {
      if (!state.select.length || !state.list.length) return []

      return state.select.map(selectItem => {
        if(selectItem?.coin) {
          const entry = state.list.find(listItem => listItem.coin.label.includes(selectItem.coin.label))
          const params = selectItem.params.map(paramItem => {
            return {
              label: paramItem.label,
              value: entry?.[paramItem.tf]?.[paramItem.label]
            }
          })
          if(entry?.coin?.label && entry?.coin?.price){
            return {
              label: entry.coin.label,
              params: params,
              price: entry.coin.price
            }
          }
      }
      }).filter(el => el)
    },
    tfOptions (state) {
      const list = state.list
      if (!list.length) return []
      const arr = []
      Object.keys(list[0]).forEach(key => {
        if (key !== 'coin') {
          arr.push(key)
        }
      })
      return arr
    }
  }
}
