<template>
  <div class="screenerTableSettings">
    <!-- Табы первого уровня: -->
    <div class="screenerTableSettings__header">
      <div class="screenerTableSettings__tabs">
        <TabSelector
          v-model="selectedScreenType"
          :class="'tabSelector'"
          :options="screensList"
          :hide-title="true"
        />
      </div>
    </div>

    <!-- Контент табов первого уровня: -->
    <CCard
      :class="'ccard shadow-none'"
      :color="'second-card-bg'"
    >
      <CCardBody :class="'ccard-body'">
        <!-- Табы второго уровня: -->
        <div class="screenerTableSettings__header">
          <div class="screenerTableSettings__tabs w-100">
            <TabSelector
              v-model="selectedTab"
              :class="'tabSelector _level-2'"
              :options="tabsList"
              :hide-title="true"
            />
          </div>
        </div>

        <!-- Контент табов второго уровня: -->
        <CCard
          :class="'ccard shadow-none'"
          :color="'third-card-bg'"
          :key="selectedScreenType"
        >
          <CCardBody :class="'ccard-body'">
            <TimeFrameSettings
              v-show="selectedTab === 0"
              :screen-type="selectedScreenType"
              :active-settings-count="activeSettingsCount"
              @change-time-frame-settings="setActiveTfSettingsCount"
            />
            <ScreenerColumnsSettings
              v-show="selectedTab === 1"
              :screen-type="selectedScreenType"
              :active-settings-count="activeSettingsCount"
              @change-screener-columns-settings="setActiveColumnSettingsCount"
            />
          </CCardBody>
        </CCard>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
// Компоненты:
import { CCard, CCardBody } from '@coreui/vue-pro';
import TabSelector from '@/components/training/tabSelector.vue';
import TimeFrameSettings from '@/components/account/TimeFrameSettings.vue';
import ScreenerColumnsSettings from '@/components/account/ScreenerColumnsSettings.vue';
import {SCREENER_PAGE_FUTURES} from "@/shared/constants";

export default {
  data () {
    return {
      selectedTab: 0,
      selectedScreenType: 'spot',
      activeTfSettingsCount: 0,
      activeTfSettingsCountFutures: 0,
      activeColumnSettingsCount: 0,
      activeColumnSettingsCountFutures: 0,
      tabsList: [
        { label: this.$t('screener.timeframes'), key: 0, iconName: 'cil-clock' },
        { label: this.$t('screener.tables'), key: 1, iconName: 'cil-spreadsheet' }
      ],
      screensList: [
        { label: 'SPOT', key: 'spot' },
        { label: 'FUTURES', key: 'futures' }
      ]
    }
  },

  computed: {
    /**
     * Сумма активных настроек.
     */
    activeSettingsCount () {
      if (this.isFutures) {
        return this.activeTfSettingsCountFutures + this.activeColumnSettingsCountFutures
      } else {
        return this.activeTfSettingsCount + this.activeColumnSettingsCount
      }
    },
    isFutures() {
      return this.selectedScreenType === SCREENER_PAGE_FUTURES
    },
  },

  methods: {
    /**
     * Установить кол-во активных настроек.
     * @param items - массив активных настроек.
     */
    setActiveTfSettingsCount (items) {
      if (this.isFutures) {
        this.activeTfSettingsCountFutures = items.length
      } else {
        this.activeTfSettingsCount = items.length
      }
    },
    /**
     * Установить кол-во активных настроек.
     * @param items - массив активных настроек.
     */
    setActiveColumnSettingsCount (items) {
      if (this.isFutures) {
        this.activeColumnSettingsCountFutures = items.length
      } else {
        this.activeColumnSettingsCount = items.length
      }
    },
  },
  components: {
    CCard,
    CCardBody,
    TabSelector,
    TimeFrameSettings,
    ScreenerColumnsSettings,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.screenerTableSettings{
  &__header{
    display: flex;
    justify-content: space-between;
  }
  &__tabs{
    display: flex;
    gap: 30px;
  }
}
.ccard {
  .ccard-header {
    padding: 1.25rem 2rem;
  }
  .ccard-body {
    padding: 1rem;
  }
}

.input-file-wrapper {
  display: flex;
  align-items: center;
}

.btn:disabled {
  cursor: not-allowed;
}

.tabSelector {
  /deep/ .btn-group {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    flex-direction: row !important;
  }

  /deep/ .button-custom {
    border-bottom-right-radius: 0;
    background: transparent;
    display: flex;
    gap: 5px;

    &._active {
      background: var(--second-card-bg);
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }

  &._level-2 /deep/ ._active {
    background: map-get($theme-colors, $key: 'third-card-bg');

    .c-dark-theme & {
      background: map-get($theme-colors__dt, $key: 'third-card-bg');
    }
  }
}
</style>
