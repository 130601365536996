<template>
  <CModal
    v-if="show"
    :show="show"
    centered
    size="xl"
    class="loginModal"
  >
    <CIcon
      class="loginModal__close"
      name="cil-x"
      @click.native="close"
    />
    <Signin
      :inModal="true"
      :redirect="false"
      @loggedIn="logInHandler"
    />
  </CModal>
</template>

<script>
import Signin from '@/pages/auth/Signin'
import { mapActions } from "vuex";
export default {
  name: "LoginModal",
  components: { Signin },
  props:{
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{

    }
  },
  computed:{

  },
  mounted(){
  },
  methods:{
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    close(){
      this.toggleShowLoginModal({show: false})
    },
    logInHandler(){
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.loginModal{
  z-index: 9999;
  position: relative;
  /deep/ .modal-header{
    display: none;
  }
  /deep/ .modal-body{
    padding: 0;
  }
  /deep/ .modal-content{
    overflow: hidden;
  }
  /deep/ .modal-footer{
    display: none;
  }
  &__close{
    z-index: 999999;
    position: absolute;
    cursor: pointer;
    user-select: none;
    right: 1rem;
    top: 1rem;
    &:hover {
      color: $danger;
    }
  }
}
</style>
