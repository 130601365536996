var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "screenerColumnsSettings" }, [
    _c(
      "div",
      { staticClass: "screenerColumnsSettings__controls" },
      [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm._v(" " + _vm._s(_vm.activeSettingsCount) + "/15 "),
        ]),
        _c("ScreenerTableSettingsPausedGraphToggle", {
          staticClass: "ml-auto",
          attrs: {
            disabled:
              _vm.disablePausedGraphToggle ||
              _vm.isShowMALoading ||
              _vm.isGuest,
            "is-show-m-a": _vm.isShowMA,
          },
          on: {
            "update:isShowMA": function ($event) {
              _vm.isShowMA = $event
            },
            "update:is-show-m-a": function ($event) {
              _vm.isShowMA = $event
            },
          },
        }),
        _c(
          "div",
          [
            _c(
              "CButton",
              {
                staticClass:
                  "mr-1 mb-1 screenerColumnsSettings__controls-button",
                attrs: {
                  disabled: _vm.loading || !_vm.isChanged,
                  color: "secondary",
                },
                on: { click: _vm.resetSettings },
              },
              [
                _c("CIcon", { attrs: { name: "cilReload" } }),
                _vm._v(" " + _vm._s(_vm.$t("general.reset")) + " "),
              ],
              1
            ),
            _c(
              "CButton",
              {
                staticClass: "mb-1 screenerColumnsSettings__controls-button",
                attrs: {
                  disabled: _vm.loading || !_vm.isChanged,
                  color: "success",
                },
                on: {
                  click: function ($event) {
                    return _vm.saveSettings()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "screenerColumnsSettings__drugAndDrop" }, [
      _c(
        "div",
        { staticClass: "screenerColumnsSettings__section" },
        [
          _c(
            "Draggable",
            _vm._b(
              {
                staticClass: "screenerColumnsSettings__list-group",
                attrs: { tag: "div" },
                on: {
                  start: function ($event) {
                    _vm.activeListDrag = true
                  },
                  end: function ($event) {
                    _vm.activeListDrag = false
                  },
                  remove: _vm.onRemove,
                },
                model: {
                  value: _vm.list1,
                  callback: function ($$v) {
                    _vm.list1 = $$v
                  },
                  expression: "list1",
                },
              },
              "Draggable",
              _vm.activeListDragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    type: "transition",
                    name: !_vm.activeListDrag ? "flip-list" : null,
                  },
                },
                _vm._l(_vm.list1, function (element) {
                  return _c(
                    "div",
                    {
                      key: element.id,
                      staticClass:
                        "screenerColumnsSettings__list-group-item --active",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "screenerColumnsSettings__list-group-item-section-left",
                        },
                        [
                          _c("CIcon", { attrs: { name: "appsIcon" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                element.labelId
                                  ? _vm.$t(element.labelId)
                                  : element.label
                              ) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: { content: _vm.$t(element.hintId) },
                                  expression: "{content: $t(element.hintId)}",
                                },
                              ],
                              staticClass:
                                "rounded-circle popover-wrap d-inline-flex justify-content-center ml-2",
                            },
                            [_vm._v(" ? ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "screenerColumnsSettings__list-group-item-section-right",
                            { _disable: _vm.disableDragInActiveList },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(element)
                            },
                          },
                        },
                        [_vm._v(" x ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "screenerColumnsSettings__section-divider" }),
      _c(
        "div",
        { staticClass: "screenerColumnsSettings__section" },
        [
          _c(
            "Draggable",
            _vm._b(
              {
                staticClass: "screenerColumnsSettings__list-group",
                attrs: { tag: "div" },
                on: {
                  start: function ($event) {
                    _vm.listDrag = true
                  },
                  end: function ($event) {
                    _vm.listDrag = false
                  },
                  remove: _vm.onRemove,
                },
                model: {
                  value: _vm.list2,
                  callback: function ($$v) {
                    _vm.list2 = $$v
                  },
                  expression: "list2",
                },
              },
              "Draggable",
              _vm.listDragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    type: "transition",
                    name: !_vm.listDrag ? "flip-list" : null,
                  },
                },
                _vm._l(_vm.list2, function (element) {
                  return _c(
                    "div",
                    {
                      key: element.id,
                      staticClass:
                        "screenerColumnsSettings__list-group-item --inactive",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "screenerColumnsSettings__list-group-item-section-left",
                        },
                        [
                          _c("CIcon", { attrs: { name: "appsIcon" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                element.labelId
                                  ? _vm.$t(element.labelId)
                                  : element.label
                              ) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: { content: _vm.$t(element.hintId) },
                                  expression: "{content: $t(element.hintId)}",
                                },
                              ],
                              staticClass:
                                "rounded-circle popover-wrap d-inline-flex justify-content-center ml-2",
                            },
                            [_vm._v(" ? ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "screenerColumnsSettings__list-group-item-section-right",
                          on: {
                            click: function ($event) {
                              return _vm.addItem(element)
                            },
                          },
                        },
                        [_vm._v(" + ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }