import axios from 'axios'
import User from "@/assets/js/User.class"
import {has, merge} from "lodash"
import WatchListsItem from "@/assets/js/screenerSettings/WatchListsItem.class"
import screenerSettings from "@/assets/js/screenerSettings"
import debounce from "lodash/debounce"
import i18n from "@/i18n"

const initSettings = (rootState) => {
  return {
    global: {
      showLoginModal: false,
      darkMode: has(rootState.settings, 'global.darkMode') ? rootState.settings.global.darkMode : true,
      locale: rootState.settings?.global?.locale || 'ru',
    }
  }
}

export default {
  namespaced: true,
  state: () => ({
    showLoginModal: false,
    userData: new User(),
    accessToken: '',
    telegramVerifyTimer: null,
    telegramSubscribeTimer: null
  }),
  actions: {
    toggleShowLoginModal({commit}, {show}) {
      commit('SET_SHOW_LOGIN_MODAL', show)
    },
    getCurrent({commit, dispatch, state}) {
      if (state.userData?.isGuest) {
        return Promise.resolve({
          status: 200,
          data: {
            data: state.userData
          }
        }).then(res => res)
      }
      return axios.get('/api/v1/users/current?locale=' + i18n.locale)
        .then(res => {
          commit('SET_USER_DATA', res?.data?.data)
          commit('SET_USER_WATCHLISTS', res?.data?.data?.createdWatchlists)
          commit('SET_USER_WATCHLISTS_BINANCE_PERP', res?.data?.data?.createdWatchlistsBinancePerp)
          dispatch('setInit', res?.data?.data.settings, {root: true})
          return res
        })
    },
    confirmChange({}, params) {
      return axios.post(`/api/v1/confirm_change/${params.data}?expires=${params.expires}&signature=${params.signature}`)
    },
    register({commit}, params = {}) {
      return axios.post('/api/v1/register?locale=' + i18n.locale, null, {
        params,
        headers: {
          'skipAuthToken': true
        }
      })
        .then(res => {
          if (!res?.data) return

          commit('SET_USER_DATA', res.data.user)
          commit('SET_USER_WATCHLISTS', res?.data?.user.createdWatchlists)
          commit('SET_USER_WATCHLISTS_BINANCE_PERP', res?.data?.user.createdWatchlistsBinancePerp)
          commit('SET_ACCESS_TOKEN', res?.data?.token)
          return res
        })
    },
    login({commit, dispatch, rootState}, params = {}) {
      return axios.post('/api/v1/login?locale=' + i18n.locale, params, {
        headers: {
          'skipAuthToken': true
        }
      })
        .then(async res => {
          if (!res?.data) return
          if (res?.data.code) return res
          commit('SET_USER_DATA', res.data?.user)
          commit('SET_USER_WATCHLISTS', res.data?.user.createdWatchlists)
          commit('SET_USER_WATCHLISTS_BINANCE_PERP', res.data?.user.createdWatchlistsBinancePerp)
          commit('SET_ACCESS_TOKEN', res.data?.token)

          if (res.data?.user) {
            if (res.data.user.locale) {
              commit('SET_KEY', {
                namespace: 'global',
                key: 'locale',
                value: res.data.user.locale
              }, {root: true})
            }
            if (has(res.data.user, 'settings.global.darkMode')) {
              commit('SET_KEY', {
                namespace: 'global',
                key: 'darkMode',
                value: res.data.user.settings.global.darkMode
              }, {root: true})
            }
          }

          await dispatch('saveRequest', 'global', {root: true})
            .then(() => {
              dispatch('setInit', merge(res?.data?.user?.settings, initSettings(rootState)), {root: true})
            })
            .catch(() => {
              dispatch('setInit', res?.data?.user?.settings, {root: true})
            })
          return res
        })
    },
    logout({commit, rootState}) {
      return axios.post('/api/v1/logout')
        .then(res => {
          commit('SET_USER_DATA', null)
          commit('SET_ACCESS_TOKEN', null)
          commit('SET_INIT', initSettings(rootState), {root: true})
          return res
        })
    },
    logoutAll({commit, rootState}) {
      return axios.post('/api/v1/logout-all')
        .then(res => {
          commit('SET_USER_DATA', null)
          commit('SET_ACCESS_TOKEN', null)
          commit('SET_INIT', initSettings(rootState), {root: true})
          return res
        })
    },
    update({commit, state}, params = {}) {
      const id = state.userData.id
      return axios.put('/api/v1/users/' + id, {
        ...params
      })
        .then(res => {
          commit('SET_USER_DATA', res?.data?.data)
          commit('SET_USER_WATCHLISTS', res?.data?.data.createdWatchlists)
          commit('SET_USER_WATCHLISTS_BINANCE_PERP', res?.data?.data.createdWatchlistsBinancePerp)
          return res
        })
    },
    updateSelf({commit}, params = {}) {
      return axios.put('/api/v1/users', {
        ...params
      })
        .then(res => {
          commit('SET_USER_DATA', res?.data?.data)
          commit('SET_USER_WATCHLISTS', res?.data?.data.createdWatchlists)
          commit('SET_USER_WATCHLISTS_BINANCE_PERP', res?.data?.data.createdWatchlistsBinancePerp)
          return res
        })
    },
    getUserSettings({commit, state}) {
      const isGuest = state.userData?.isGuest

      return Promise.all([
        isGuest ? Promise.resolve({
          status: 200,
          data: {data: state.userData.createdWatchlists}
        }) : axios.get(`/api/v2/watchlists?creator_ids[]=${state.userData.id}`),
        isGuest ? Promise.resolve({
          status: 200,
          data: {data: state.userData.createdWatchlistsBinancePerp}
        }) : axios.get(`/api/v3/binance_perp/watchlists?creator_ids[]=${state.userData.id}`)
      ]).then(([res1, res2]) => {
        commit('SET_USER_WATCHLISTS', res1.data.data)
        commit('SET_USER_WATCHLISTS_BINANCE_PERP', res2.data.data)
      })
    },
    setWatchLists({commit, dispatch}, payload) {
      commit('SET_USER_WATCHLISTS', payload)
      return dispatch('saveWatchLists', payload)
    },
    setWatchListsBinancePerp({commit, dispatch}, payload) {
      commit('SET_USER_WATCHLISTS_BINANCE_PERP', payload)
      return dispatch('saveWatchListsBinancePerp', payload)
    },
    setWatchListsImmediately({dispatch, commit}, payload) {
      commit('SET_USER_WATCHLISTS', payload)
      return dispatch('saveWatchListsImmediately', payload)
    },
    setWatchListsBinancePerpImmediately({dispatch, commit}, payload) {
      commit('SET_USER_WATCHLISTS_BINANCE_PERP', payload)
      return dispatch('saveWatchListsBinancePerpImmediately', payload)
    },
    saveWatchLists: debounce(({dispatch}, payload) => {
      return dispatch('saveWatchListsRequest', payload)
    }, 1000),
    saveWatchListsBinancePerp: debounce(({dispatch}, payload) => {
      return dispatch('saveWatchListsBinancePerpRequest', payload)
    }, 1000),
    saveWatchListsImmediately({dispatch}, payload) {
      return dispatch('saveWatchListsRequest', payload)
    },
    saveWatchListsBinancePerpImmediately({dispatch}, payload) {
      return dispatch('saveWatchListsBinancePerpRequest', payload)
    },
    saveWatchListsRequest({state}, payload) {
      if (state.userData?.isGuest) {
        return Promise.resolve({
          status: 200,
          data: {data: payload}
        })
      }
      return axios.post('/api/v2/watchlists/sync', {
        watchlists: payload
      })
    },
    saveWatchListsBinancePerpRequest({state}, payload) {
      if (state.userData?.isGuest) {
        return Promise.resolve({
          status: 200,
          data: {data: payload}
        })
      }
      return axios.post('/api/v3/binance_perp/watchlists/sync', {
        watchlists: payload
      })
    }
  },
  mutations: {
    SET_SHOW_LOGIN_MODAL(state, payload) {
      state.showLoginModal = payload
    },
    SET_USER_WATCHLISTS(state, payload) {
      if (payload?.length > 0) {
        const arr = payload.map(a => new WatchListsItem(a))
        state.userData.createdWatchlists = arr
        screenerSettings._watchLists = arr
      } else {
        const defaultArray = [
          new WatchListsItem({id: 'list1', name: 'List 1', color: 'danger'}),
          new WatchListsItem({id: 'list2', name: 'List 2', color: 'success'}),
          new WatchListsItem({id: 'list3', name: 'List 3', color: 'watch-list-primary'}),
          new WatchListsItem({id: 'list4', name: 'List 4', color: 'warning'})
        ]
        state.userData.createdWatchlists = defaultArray
        screenerSettings._watchLists = defaultArray
      }
    },
    SET_USER_WATCHLISTS_BINANCE_PERP(state, payload) {
      if (payload?.length > 0) {
        const arr = payload.map(a => new WatchListsItem(a))
        state.userData.createdWatchlistsBinancePerp = arr
        screenerSettings._watchListsBinancePerp = arr
      } else {
        const defaultArray = [
          new WatchListsItem({id: 'list1', name: 'List 1', color: 'danger'}),
          new WatchListsItem({id: 'list2', name: 'List 2', color: 'success'}),
          new WatchListsItem({id: 'list3', name: 'List 3', color: 'watch-list-primary'}),
          new WatchListsItem({id: 'list4', name: 'List 4', color: 'warning'})
        ]
        state.userData.createdWatchlistsBinancePerp = defaultArray
        screenerSettings._watchListsBinancePerp = defaultArray
      }
    },
    SET_USER_DATA(state, payload) {
      state.userData = new User(payload)
      if (!state.userData.isGuest) {
        const isRegistered = localStorage.getItem('isRegistered')
        if (!isRegistered) {
          localStorage.setItem('isRegistered', '1')
        }
      }
    },
    SET_ACCESS_TOKEN(state, payload) {
      this.dispatch('promo/toggleShowPromoModal', {show: false});
      if (payload) {
        state.accessToken = payload
        localStorage.setItem('accessToken', payload)
      } else {
        state.accessToken = null
        localStorage.removeItem('accessToken')
      }
    },
    SET_TELEGRAM_VERIFY_TIMER(state, payload) {
      state.telegramVerifyTimer = payload
    },
    SET_TELEGRAM_SUBSCRIBE_TIMER(state, payload) {
      state.telegramSubscribeTimer = payload
    },
  },
  getters: {
    showLoginModal: store => store.showLoginModal,
    getPermissions: store => part => {
      return !!store.userData?.permissions?.[part]
    },
    getTariffPermissions: store => permission => {
      const perm = store.userData?.tariff?.access.filter(item => item.key === permission)
      return perm?.[0] && perm[0].enabled === true
    },
    isLogged(store) {
      // return false
      return !!store.accessToken && ((store.userData?.id || 0) > 0)
    },
    id(store) {
      return store.userData?.id
    },
    isGuest(store) {
      return !store.userData || store.userData.isGuest || false
    },
    isVerify(store) {
      // return false
      return !!store.userData?.emailVerifiedAt
    },
    homepage: (store, getters) => {
      const getPermission = getters['getPermissions']
      if (getPermission('view_delta')) {
        return {name: 'delta'}
      } else if (getPermission('view_screener')) {
        return {name: 'screener'}
      } else if (getPermission('view_trends')) {
        return {name: 'trends'}
      }
      return {name: 'lk-subscribe'}
    },
    tariff: store => {
      if (store.userData.tariffIsActive) {
        return store.userData.tariff.id
      }

      return null
    },
    eduTariffs: store => {
      return (store.userData.events_users || []).map(item => item.tariff?.id)
    },
    authorId: store => store.userData?.author_id || null,
    telegramVerify: state => {
      return !state.userData?.tgLink
    },
    userData: state => state.userData
  }
}
