import { cloneDeep, get } from 'lodash'
import { GraphData } from "@/assets/js/screenerGraphClasses/GraphData.class";
import store from "@/store";

function randomData () {
  return [...Array(250).keys()].map(i => {
    return new GraphData({id: i, pair: `BTCUSD${i}`}, true)
  })
}

export default {
  _data: [],
  _sortCol: null,
  _sortType: null,

  get sortCol () {
    return this._sortCol
  },
  set sortCol (val) {
    this._sortCol = val
    store.dispatch("setKey", {
      namespace: 'graphsView',
      key: 'sortCol',
      value: val
    })
  },
  get sortType () {
    return this._sortType
  },
  set sortType (val) {
    this._sortType = val
    store.dispatch("setKey", {
      namespace: 'graphsView',
      key: 'sortType',
      value: val
    })
  },
  get data () {
    return this.sortedData
  },
  set data (val) {
    if (val === 'random') {
      this._data = randomData()
    } else if (val && Array.isArray(val)) {
      this._data = val.map(a => new GraphData(a))
    } else {
      this._data = []
    }
  },
  getDataGroup (size = 4) {
    const arrResult = []
    const arrOriginal = cloneDeep(this.data)
    for (let i = 0; i < arrOriginal.length; i += size) {
      arrResult.push({
        id: '', // this.data[i].id,
        arr: arrOriginal.slice(i,i + size)
      })
    }
    return arrResult
  },
  get sortedData () {
    if (this.sortCol && this.sortType) {
      const arr = cloneDeep(this._data)

      let more = null
      let less = null
      switch (this.sortType) {
        case 'asd':
          more = 1
          less = -1
          break
        case 'desc':
        default:
          more = -1
          less = 1
          break
      }
      if (this.sortCol === 'pair') {
        more *= -1
        less *= -1
      }

      arr.sort((a, b) => {
        const { valA, valB } = convertSort(a, b, this.sortCol)

        if (valA === valB) return 0

        return valA < valB ? less : more
      })

      return arr
    } else {
      return this._data
    }
  }
}


function convertSort(a, b, colName) {
  let valA = null
  let valB = null

  const graphDataVal = get(a, colName, null)
  if (graphDataVal || graphDataVal === 0) {
    valA = graphDataVal
    valB = get(b, colName, null)
  }

  return {
    valA,
    valB
  }
}
