var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center overflow-hidden" },
    [
      !_vm.hideTitle
        ? _c(
            "span",
            { staticClass: "title-custom font-weight-bold text-nowrap mr-2" },
            [_vm._v(" " + _vm._s(_vm.$t("lk.subscribe.payment")) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "overflow-auto w-100 btn-group" },
        _vm._l(_vm.options, function (item, i) {
          return _c(
            "CButton",
            {
              key: i,
              class:
                _vm.value === item.key
                  ? "btn shadow-none button-custom text-white _active"
                  : "btn shadow-none button-custom text-white",
              attrs: {
                color: "scroll-handler",
                variant: _vm.value === item.key ? "" : "outline",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("input", item.key)
                },
              },
            },
            [
              item.iconName
                ? _c("CIcon", { attrs: { name: item.iconName } })
                : _vm._e(),
              _vm._v(" " + _vm._s(item.label) + " "),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }