<template>
  <div class="screenerColumnsSettings">
    <div class="screenerColumnsSettings__controls">
      <div class="d-flex align-items-center">
        {{activeSettingsCount}}/15
      </div>

      <ScreenerTableSettingsPausedGraphToggle
        :disabled="disablePausedGraphToggle || isShowMALoading || isGuest"
        :is-show-m-a.sync="isShowMA"
        class="ml-auto"
      />

      <div>
        <CButton
          :disabled="loading || !isChanged"
          color="secondary"
          class="mr-1 mb-1 screenerColumnsSettings__controls-button"
          @click="resetSettings"
        >
          <CIcon name="cilReload"/>
          {{$t('general.reset')}}
        </CButton>
        <CButton
          :disabled="loading || !isChanged"
          color="success"
          class="mb-1 screenerColumnsSettings__controls-button"
          @click="saveSettings()"
        >
          {{$t('general.save')}}
        </CButton>
      </div>
    </div>
    <div class="screenerColumnsSettings__drugAndDrop">
      <div class="screenerColumnsSettings__section">
        <Draggable
          class="screenerColumnsSettings__list-group"
          tag="div"
          v-model="list1"
          v-bind="activeListDragOptions"
          @start="activeListDrag = true"
          @end="activeListDrag = false"
          @remove="onRemove"
        >
          <transition-group type="transition" :name="!activeListDrag ? 'flip-list' : null">
            <div
              class="screenerColumnsSettings__list-group-item --active"
              v-for="element in list1"
              :key="element.id"
            >
              <div class="screenerColumnsSettings__list-group-item-section-left">
                <CIcon name="appsIcon"/>
                {{ element.labelId ? $t(element.labelId) : element.label }}
                <span
                  v-tooltip="{content: $t(element.hintId)}"
                  class="rounded-circle popover-wrap d-inline-flex justify-content-center ml-2"
                >
                  ?
                </span>
              </div>
              <div
                @click="removeItem(element)"
                :class="['screenerColumnsSettings__list-group-item-section-right', { _disable: disableDragInActiveList }]"
              >
                x
              </div>
            </div>
          </transition-group>
        </Draggable>
      </div>
      <div class="screenerColumnsSettings__section-divider">

      </div>
      <div class="screenerColumnsSettings__section">
        <Draggable
          class="screenerColumnsSettings__list-group"
          tag="div"
          v-model="list2"
          v-bind="listDragOptions"
          @start="listDrag = true"
          @end="listDrag = false"
          @remove="onRemove"
        >
          <transition-group type="transition" :name="!listDrag ? 'flip-list' : null">
            <div
              class="screenerColumnsSettings__list-group-item --inactive"
              v-for="element in list2"
              :key="element.id"
            >
              <div class="screenerColumnsSettings__list-group-item-section-left">
                <CIcon name="appsIcon"/>
                {{ element.labelId ? $t(element.labelId) : element.label }}
                <span
                  v-tooltip="{content: $t(element.hintId)}"
                  class="rounded-circle popover-wrap d-inline-flex justify-content-center ml-2"
                >
                  ?
                </span>
              </div>
              <div
                @click="addItem(element)"
                class="screenerColumnsSettings__list-group-item-section-right"
              >
                +
              </div>
            </div>
          </transition-group>
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { propsScreenType } from "@/mixins/props-screen-type";

// Компоненты:
import Draggable from "vuedraggable";
import {
  SCREENER_COLUMNS_FUTURES_SETTINGS_KEY, SCREENER_COLUMNS_SETTINGS_IDS,
  SCREENER_COLUMNS_SETTINGS_KEY,
  SCREENER_PAGE_FUTURES
} from "@/shared/constants";
import ScreenerTableSettingsPausedGraphToggle from "@/components/account/ScreenerTableSettingsPausedGraphToggle.vue";
import {showMa} from "@/mixins/show-ma";
import cloneDeep from "lodash/cloneDeep";


const MINIMUM_ACTIVE_TIMEFRAMES_COUNT = 3

export default {
  emits: [
    'change-screener-columns-settings'
  ],
  mixins: [
    propsScreenType,
    showMa,
  ],
  props: {
    activeSettingsCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      defaultSettings: [
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.price,
          label: 'Price',
          hintId: 'lk.settings.tableScreener.table.price',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.cdWeek,
          label: 'CD Week',
          hintId: 'lk.settings.tableScreener.table.cdWeek',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.delta,
          label: 'Delta',
          hintId: 'lk.settings.tableScreener.table.delta',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.volatility,
          label: 'Volatility',
          hintId: 'lk.settings.tableScreener.table.volat',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.ma50,
          label: 'MA50',
          labelId: 'lk.settings.tableScreener.table.ma50Label',
          hintId: 'lk.settings.tableScreener.table.ma50',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.ma200,
          label: 'MA200',
          labelId: 'lk.settings.tableScreener.table.ma200Label',
          hintId: 'lk.settings.tableScreener.table.ma200',
        },
        {
          id: SCREENER_COLUMNS_SETTINGS_IDS.volume,
          label: 'Volume',
          hintId: 'lk.settings.tableScreener.table.volume',
        },
      ],
      list1: [],
      list2: [],
      listDrag: false,
      activeListDrag: false,
      disablePausedGraphToggle: null,
    }
  },
  watch: {
    list1: {
      deep: true,
      handler (value) {
        this.$emit('change-screener-columns-settings', value)
        this.setDisablePausedGraphToggle(value)
      }
    },
  },
  computed: {
    disableDragInActiveList () {
      return this.list1.length <= MINIMUM_ACTIVE_TIMEFRAMES_COUNT
    },
    initialActiveSettings(){
      return this.getMappedSettings()
    },
    initialInActiveSettings(){
      return this.defaultSettings.filter(item => !this.initialActiveSettings.some(setting => setting.id === item.id))
    },
    listDragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    activeListDragOptions() {
      return {
        ...this.listDragOptions,
        group: !this.listDrag && this.disableDragInActiveList ? 'description-only-order' : 'description',
      }
    },
    isChanged(){
      return JSON.stringify(this.initialActiveSettings) !==  JSON.stringify(this.list1);
    },
    isFutures() {
      return this.screenType === SCREENER_PAGE_FUTURES
    },
    screenerColumnsSettingsKey() {
      return this.isFutures ? SCREENER_COLUMNS_FUTURES_SETTINGS_KEY : SCREENER_COLUMNS_SETTINGS_KEY
    },
  },
  methods: {
    resetSettings(){
      this.list1 = [...this.initialActiveSettings]
      this.list2 = [...this.initialInActiveSettings]
    },
    saveSettings(value){
      const dataToSave = cloneDeep(value || this.list1).map(el => ({ id: el.id }))
      if(!dataToSave.length){
        this.$toastr.warning(this.$t('general.dontSaveEmptyArray'))
        return
      }
      this.loading = true
        this.$store.commit('SET_KEY', {
          namespace: 'screener',
          key: this.screenerColumnsSettingsKey,
          value:  dataToSave
        })
        this.$store.dispatch('saveRequest', ['screener']).then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('general.dataSaved'))
          }
        }).finally(() => {
          this.loading = false
        })
    },
    onRemove (obj) {
      const {to,from,item} = obj
      if(to!==from){
        item.classList.add('display-none')
      }
    },
    removeItem(curItem){
      if (this.disableDragInActiveList) return
      this.list1 = this.list1.filter(item => item.id !== curItem.id)
      this.list2.push(curItem)
    },
    addItem(curItem){
      this.list2 = this.list2.filter(item => item.id !== curItem.id)
      this.list1.push(curItem)
    },
    /**
     * Установить флаг, можно ли переключать тагглен паузы обновления графика.
     * @param items - массив активных настроек.
     */
    setDisablePausedGraphToggle (items) {
      const originActiveColumnSettings = items || this.getMappedSettings()
      const activeColumnSettingsIds = [...originActiveColumnSettings]?.map(el => el.id) || [];
      this.disablePausedGraphToggle = !activeColumnSettingsIds.includes(5) || !activeColumnSettingsIds.includes(6)

      if (this.disablePausedGraphToggle) {
        this.isShowMA = false
      }
    },
    getMappedSettings() {
      const list1 = this.$store.state.settings.screener?.[this.screenerColumnsSettingsKey]

      return cloneDeep((Array.isArray(list1) ? list1 : [])
        .map(item => this.defaultSettings.find(el => el.id === item.id))
        .filter(item => !!item))
    },
  },
  mounted(){
    if(!this.isGuest && (!this.$store.state.settings.screener || !this.$store.state.settings.screener?.hasOwnProperty(this.screenerColumnsSettingsKey))){
      this.saveSettings(this.defaultSettings)
    }

    this.list1 = this.getMappedSettings()
    this.list2 = [...this.initialInActiveSettings]
  },
  components: {
    ScreenerTableSettingsPausedGraphToggle,
    Draggable,
  },
}
</script>

<style lang="scss" scoped>
.screenerColumnsSettings {
  display: flex;
  flex-direction: column;

  &__controls{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__controls-button{
    height: 36px !important;
  }
  &__drugAndDrop{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      display: flex;
    }
  }
  &__section{
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
  &__section-divider{
    margin: 10px 0;
    height: 1px;
    width: 100%;
    background: rgba(105, 117, 130, 0.51);

    @include media-breakpoint-up(md) {
      margin: 0 10px;
      width: 1px;
      height: auto;
    }
  }
  &__list-group{
    min-height: 20px;
    height: 100%;
    span{
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
  &__list-group-item {
    cursor: move;
    border-radius: 8px;
    padding: 15px 10px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    user-select: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .--inactive{
    border-color: rgba(105, 117, 130, 0.51);
    .popover-wrap{
      color: var(--white);
      border-color: var(--white);
    }
  }
  .--active{
    background: var(--white);
    color: var(--header-bg);

    svg {
      color: var(--header-bg);
    }
  }

  &__list-group-item-section-right{
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 16px;
    font-size: 16px;
    font-weight: 900;
    transition: color 0.3s ease-out;
    &:hover {
      color: rgba(105, 117, 130, 0.51);
    }
    &._disable {
      cursor: not-allowed;
      color: rgba(105, 117, 130, 0.51);
    }
  }
  &__list-group-item-section-left{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
// .flip-list-move {
//   transition: transform 0.5s;
// }

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #656F81;
}

.display-none {
  display: none !important;
  transition: 0s !important;
}
.popover-wrap{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--header-bg);
  border: 1.5px solid var(--header-bg);;
  width: .8rem;
  flex-shrink: 0;
  height: .8rem !important;
  font-size: 10px;
}
</style>
