var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("label", { staticClass: "mb-0", on: { click: _vm.handleSubscribe } }, [
      _vm._v(" " + _vm._s(_vm.$t("lk.account.browserNotifications")) + " "),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "c-tooltip",
            rawName: "v-c-tooltip",
            value: {
              content: _vm.subscribeTooltipText,
              appendToBody: true,
              popperOptions: {
                strategy: "fixed",
              },
              html: true,
            },
            expression:
              "{\n      content: subscribeTooltipText,\n      appendToBody: true,\n      popperOptions: {\n        strategy: 'fixed',\n      },\n      html: true,\n    }",
          },
        ],
        staticClass: "d-flex align-items-center",
      },
      [
        _c("CIcon", {
          staticClass: "cil-bell-icon ml-1 flex-shrink-0",
          class: _vm.bellColorClass,
          attrs: { name: "bell" },
        }),
        _c("CSwitch", {
          ref: "subscribeSwitch",
          staticClass: "ml-3 my-0",
          attrs: {
            color: "primary",
            shape: "pill",
            checked: _vm.isSubscribed,
            disabled: _vm.disabledSwitch,
          },
          on: { "update:checked": _vm.handleSubscribe },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }