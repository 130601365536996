// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['25 24', `
<g clip-path="url(#clip0_7565_78607)">
<path d="M4.5 4H6.5V6H4.5V4Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 18H6.5V20H4.5V18Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 4H20.5V6H18.5V4Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 11H20.5V13H18.5V11Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 11H13.5V13H11.5V11Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 11H6.5V13H4.5V11Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 4H13.5V6H11.5V4Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 18H13.5V20H11.5V18Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 18H20.5V20H18.5V18Z" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_7565_78607">
<rect width="24" height="24" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
`]
