import get from 'lodash/get'
import store from "@/store"
import moment from "moment";

export default class Ticker {
  constructor(init) {
    const keys = store.getters["trendsPage/percentsKeys"]
    this._classes = ''
    this.id = get(init, 'id', '')
    this.label = init?.label || ''
    this.labelAlt = init?.labelAlt || ''
    this.notify = get(init, 'notify', false)

    this.dateTimeStrDate = null
    this.dateTimeStrTime = null
    this.dateTime = get(init, 'dateTime', '')
    const dateTimeObj = moment(this.dateTime).utc(true)
    if (this.dateTime) {
      this.dateTimeUnix = Number(dateTimeObj.format('x'))
      this.dateTimeStrDate = dateTimeObj.format('DD.MM')
      this.dateTimeStrTime = dateTimeObj.format('HH:mm')
    }

    this.ticker = get(init, 'ticker', '')
    this._cellClasses = {}

    keys.forEach(key => {
      this[key] = get(init, key, '')
      this._cellClasses[key] = ''
    })

    this.trend = null
    if (this.onlyPositive) {
      this.trend = 'up'
    } else if (this.onlyNegative) {
      this.trend = 'down'
    }
  }

  get onlyPositive () {
    const keys = store.getters["trendsPage/percentsKeys"]
    return keys.every(key => this[key] === 'up')
  }
  get onlyNegative () {
    const keys = store.getters["trendsPage/percentsKeys"]
    return keys.every(key => this[key] === 'down')
  }
  // _all_tf
}
