import moment from "moment";

require('./assets/js/axiosInit')
import('./assets/js/toastrInit')

window.Datafeeds = require('@/assets/js/udfAdapter/dist/bundle')

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import {get, has} from 'lodash'
import CLoadingButtonCustom from '@/components/CLoadingButtonCustom'
import MoveToTop from "@/assets/js/plugins/MoveToTop"
import VTooltip from 'v-tooltip'
import PortalVue from 'portal-vue'
import { polyfill } from "seamless-scroll-polyfill";
import quasar, {QVirtualScroll} from 'quasar'
import VShowSlide from 'v-show-slide'
import { initVideoJsPlugin } from '@/assets/js/plugins/videojsPlugins/videojs-markers.js'
import checkLangHandler from '@/assets/js/routerFunctions/checkLangHandler'
polyfill();
initVideoJsPlugin()

if (process.env.NODE_ENV === 'production') {
  Object.keys(console).forEach((i) => {
    if(i !== 'clear' && typeof console[i] === 'function') {
      //console[i] = console.clear || function () {}
    }
  })
}

Vue.use(PortalVue)
Vue.use(VTooltip, {
  defaultClass: 'v-tooltip',
  popover:  {
    defaultClass: 'v-tooltip',
  },
})
Vue.use(CoreuiVuePro)
Vue.use(MoveToTop)
Vue.use(quasar, {
  components: {
    QVirtualScroll
  },
  plugins: {}
})
Vue.use(VShowSlide)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$get = get
Vue.prototype.$has = has

Vue.mixin({
  beforeRouteEnter: checkLangHandler,
  beforeRouteUpdate: checkLangHandler,
})

Vue.component('CLoadingButtonCustom', CLoadingButtonCustom)

Vue.filter('formatDate', function (value, format = 'DD.MM.YYYY') {
  if (!value) return ''
  value = moment(value)
  if (!value.isValid()) return '-'
  return value.format(format)
})

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})

store.commit('setIsBrowserTabActive', !document.hidden)
