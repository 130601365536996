// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['90 90', `
<g clip-path="url(#clip0)">
  <path d="M45 40.5004C44.781 40.5004 44.5635 40.4524 44.361 40.3579L18.861 28.3579C18.336 28.1104 18 27.5809 18 27.0004C18 26.4199 18.336 25.8904 18.861 25.6429L44.361 13.6429C44.766 13.4524 45.234 13.4524 45.639 13.6429L71.139 25.6429C71.664 25.8904 72 26.4199 72 27.0004C72 27.5809 71.664 28.1104 71.139 28.3579L45.639 40.3579C45.4365 40.4524 45.219 40.5004 45 40.5004ZM23.0235 27.0004L45 37.3429L66.9765 27.0004L45 16.6579L23.0235 27.0004Z" fill="#20A8D8"/>
  <path d="M45 52.4996C44.7705 52.4996 44.5395 52.4471 44.3295 52.3421L26.3295 43.3421C25.821 43.0871 25.5 42.5681 25.5 41.9996V30.5291C25.5 30.0161 25.7625 29.5376 26.196 29.2631C26.6295 28.9871 27.174 28.9556 27.6375 29.1716L45 37.3421L62.361 29.1716C62.826 28.9541 63.3705 28.9871 63.8025 29.2631C64.2375 29.5376 64.5 30.0161 64.5 30.5291V41.9996C64.5 42.5681 64.179 43.0871 63.6705 43.3421L45.6705 52.3421C45.4605 52.4471 45.2295 52.4996 45 52.4996ZM28.5 41.0726L45 49.3226L61.5 41.0726V32.8931L45.639 40.3571C45.234 40.5476 44.766 40.5476 44.361 40.3571L28.5 32.8931V41.0726Z" fill="#20A8D8"/>
  <path d="M70.5 28.5H45C44.172 28.5 43.5 27.828 43.5 27C43.5 26.172 44.172 25.5 45 25.5H70.5C71.328 25.5 72 26.172 72 27C72 27.828 71.328 28.5 70.5 28.5Z" fill="#20A8D8"/>
  <path d="M70.5 42C69.672 42 69 41.328 69 40.5V27C69 26.172 69.672 25.5 70.5 25.5C71.328 25.5 72 26.172 72 27V40.5C72 41.328 71.328 42 70.5 42Z" fill="#20A8D8"/>
  <path d="M45 31.5C44.172 31.5 43.5 30.828 43.5 30V24C43.5 23.172 44.172 22.5 45 22.5C45.828 22.5 46.5 23.172 46.5 24V30C46.5 30.828 45.828 31.5 45 31.5Z" fill="#20A8D8"/>
  <path d="M67.4986 46.4996C67.2136 46.4996 66.9241 46.4186 66.6676 46.2476C65.9791 45.7886 65.7931 44.8571 66.2521 44.1671L69.2521 39.6671C69.7111 38.9771 70.6411 38.7896 71.3326 39.2516C72.0211 39.7106 72.2071 40.6421 71.7481 41.3321L68.7481 45.8321C68.4586 46.2656 67.9831 46.4996 67.4986 46.4996Z" fill="#20A8D8"/>
  <path d="M73.5016 46.4996C73.0171 46.4996 72.5416 46.2656 72.2521 45.8321L69.2521 41.3321C68.7931 40.6436 68.9791 39.7106 69.6676 39.2516C70.3561 38.7896 71.2876 38.9771 71.7481 39.6671L74.7481 44.1671C75.2071 44.8556 75.0211 45.7886 74.3326 46.2476C74.0761 46.4186 73.7866 46.4996 73.5016 46.4996Z" fill="#20A8D8"/>
  <path d="M45 45C44.172 45 43.5 44.328 43.5 43.5V39C43.5 38.172 44.172 37.5 45 37.5C45.828 37.5 46.5 38.172 46.5 39V43.5C46.5 44.328 45.828 45 45 45Z" fill="#20A8D8"/>
  <path d="M88.5 58.5H1.5C0.672 58.5 0 57.828 0 57V7.5015C0 4.1925 2.6925 1.5 6.0015 1.5H83.9985C87.3075 1.5 90 4.1925 90 7.5015V57C90 57.828 89.328 58.5 88.5 58.5ZM3 55.5H87V7.5015C87 5.8455 85.6545 4.5 83.9985 4.5H6.0015C4.3455 4.5 3 5.8455 3 7.5015V55.5Z" fill="currentColor"/>
  <path d="M83.9985 70.5H6.0015C2.6925 70.5 0 67.8075 0 64.4985V57C0 56.172 0.672 55.5 1.5 55.5H88.5C89.328 55.5 90 56.172 90 57V64.4985C90 67.8075 87.3075 70.5 83.9985 70.5ZM3 58.5V64.4985C3 66.1545 4.3455 67.5 6.0015 67.5H83.9985C85.6545 67.5 87 66.1545 87 64.4985V58.5H3Z" fill="currentColor"/>
  <path d="M49.5 64.5H40.5C39.672 64.5 39 63.828 39 63C39 62.172 39.672 61.5 40.5 61.5H49.5C50.328 61.5 51 62.172 51 63C51 63.828 50.328 64.5 49.5 64.5Z" fill="#20A8D8"/>
  <path d="M82.5 58.5H7.5C6.672 58.5 6 57.828 6 57V9C6 8.172 6.672 7.5 7.5 7.5H82.5C83.328 7.5 84 8.172 84 9V57C84 57.828 83.328 58.5 82.5 58.5ZM9 55.5H81V10.5H9V55.5Z" fill="currentColor"/>
  <path d="M64.5 88.5H25.5C23.019 88.5 21 86.481 21 84C21 81.519 23.019 79.5 25.5 79.5H64.5C66.981 79.5 69 81.519 69 84C69 86.481 66.981 88.5 64.5 88.5ZM25.5 82.5C24.6735 82.5 24 83.1735 24 84C24 84.8265 24.6735 85.5 25.5 85.5H64.5C65.3265 85.5 66 84.8265 66 84C66 83.1735 65.3265 82.5 64.5 82.5H25.5Z" fill="currentColor"/>
  <path d="M61.5 82.5H28.5C27.672 82.5 27 81.828 27 81C27 80.172 27.672 79.5 28.5 79.5C33.477 79.5 36 75.9675 36 69C36 68.172 36.672 67.5 37.5 67.5H52.5C53.328 67.5 54 68.172 54 69C54 75.9675 56.523 79.5 61.5 79.5C62.328 79.5 63 80.172 63 81C63 81.828 62.328 82.5 61.5 82.5ZM35.8065 79.5H54.195C52.347 77.454 51.2595 74.4075 51.042 70.5H38.9595C38.7405 74.4075 37.6545 77.454 35.8065 79.5Z" fill="currentColor"/>
</g>
<defs>
  <clipPath id="clip0">
    <rect width="90" height="90" fill="currentColor"/>
  </clipPath>
</defs>
`]
