import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default {
  install (Vue) {
    Vue.directive('move-to-top', {
      inserted (el, {value}) {
        const defaultPosition = { bottom: 16, right: -37}
        const {position: valuePosition, parent} = value || {}
        const position = valuePosition || defaultPosition

        const button = document.createElement('img');
        button.id = 'move-to-top-arrow'
        button.setAttribute('src', BUILD_PUBLIC_PATH + '/img/svg/move-to-top-arrow.svg')
        button.addEventListener('click', buttonClick.bind(el))
        Object.keys(position).forEach(key => {
          if (key === 'top' || key === 'left') {
            button.style[key] = el.getBoundingClientRect()[key] + position[key] + 'px'
          } else if (key === 'right') {
            button.style[key] =
              document.documentElement.clientWidth - el.getBoundingClientRect().right +
              position[key] + 'px'
          } else if (key === 'bottom') {
            button.style[key] =
              document.documentElement.clientHeight - el.getBoundingClientRect().bottom +
              position[key] + 'px'
          }
        })

        el.append(button)
        el.addEventListener('scroll', scrollEvent.bind(el))
        el.customArrowBtn = button
      },
      unbind (el) {
        el.customArrowBtn.removeEventListener('click', buttonClick)
        el.removeChild(el.customArrowBtn)

        el.removeEventListener('scroll', scrollEvent)
      }
    })
  }
}

function buttonClick () {
  this.scrollTo({top: 0, behavior: 'smooth'})
}

function scrollEvent () {
  if (this.scrollTop > 100) {
    this.customArrowBtn.style.display = 'block'
  } else {
    this.customArrowBtn.style.display = 'none'
  }
}
