var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "timeFrameSettings" }, [
    _c("div", { staticClass: "timeFrameSettings__controls" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm._v(" " + _vm._s(_vm.activeSettingsCount) + "/15 "),
      ]),
      _c(
        "div",
        [
          _c(
            "CButton",
            {
              staticClass: "mr-1 mb-1 timeFrameSettings__controls-button",
              attrs: {
                disabled: _vm.loading || !_vm.isChanged,
                color: "secondary",
              },
              on: { click: _vm.resetSettings },
            },
            [
              _c("CIcon", { attrs: { name: "cilReload" } }),
              _vm._v(" " + _vm._s(_vm.$t("general.reset")) + " "),
            ],
            1
          ),
          _c(
            "CButton",
            {
              staticClass: "mb-1 timeFrameSettings__controls-button",
              attrs: {
                disabled: _vm.loading || !_vm.isChanged,
                color: "success",
              },
              on: {
                click: function ($event) {
                  return _vm.saveSettings()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "timeFrameSettings__drugAndDrop" }, [
      _c(
        "div",
        { staticClass: "timeFrameSettings__section" },
        [
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "timeFrameSettings__list-group",
                attrs: { tag: "div" },
                on: {
                  start: function ($event) {
                    _vm.activeListDrag = true
                  },
                  end: function ($event) {
                    _vm.activeListDrag = false
                  },
                  remove: _vm.onRemove,
                },
                model: {
                  value: _vm.list1,
                  callback: function ($$v) {
                    _vm.list1 = $$v
                  },
                  expression: "list1",
                },
              },
              "draggable",
              _vm.activeListDragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                { attrs: { name: !_vm.activeListDrag ? "flip-list" : null } },
                _vm._l(_vm.list1, function (element) {
                  return _c(
                    "div",
                    {
                      key: element.id,
                      staticClass:
                        "timeFrameSettings__list-group-item --active",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "timeFrameSettings__list-group-item-section-left",
                        },
                        [
                          _c("CIcon", { attrs: { name: "appsIcon" } }),
                          _vm._v(" " + _vm._s(element.label) + " "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "timeFrameSettings__list-group-item-section-right",
                            { _disable: _vm.disableDragInActiveList },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(element)
                            },
                          },
                        },
                        [_vm._v(" x ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "timeFrameSettings__section-divider" }),
      _c(
        "div",
        { staticClass: "timeFrameSettings__section" },
        [
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "timeFrameSettings__list-group",
                attrs: { tag: "div" },
                on: {
                  start: function ($event) {
                    _vm.listDrag = true
                  },
                  end: function ($event) {
                    _vm.listDrag = false
                  },
                  remove: _vm.onRemove,
                },
                model: {
                  value: _vm.list2,
                  callback: function ($$v) {
                    _vm.list2 = $$v
                  },
                  expression: "list2",
                },
              },
              "draggable",
              _vm.listDragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                { attrs: { name: !_vm.listDrag ? "flip-list" : null } },
                _vm._l(_vm.list2, function (element) {
                  return _c(
                    "div",
                    {
                      key: element.id,
                      staticClass:
                        "timeFrameSettings__list-group-item --inactive",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "timeFrameSettings__list-group-item-section-left",
                        },
                        [
                          _c("CIcon", { attrs: { name: "appsIcon" } }),
                          _vm._v(" " + _vm._s(element.label) + " "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "timeFrameSettings__list-group-item-section-right",
                          on: {
                            click: function ($event) {
                              return _vm.addItem(element)
                            },
                          },
                        },
                        [_vm._v(" + ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }