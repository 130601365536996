<template>
  <CButton
    ref="button"
    :active="active"
    :activeClass="activeClass"
    :append="append"
    :block="block"
    :class="buttonClass"
    :color="color"
    :disabled="disabled"
    :event="event"
    :exact="exact"
    :exactActiveClass="exactActiveClass"
    :href="href"
    :pressed="pressed"
    :rel="rel"
    :replace="replace"
    :routerTag="routerTag"
    :shape="shape"
    :size="size"
    :target="target"
    :to="to"
    :type="type"
    :variant="variant"
    data-coreui="loading-button"
    v-on:click="methodOnClick"
  >
    <div
      v-if="progress"
      :style="progressStyle"
      aria-hidden="true"
      class="c-loading-button-progress"
      role="progressbar"
    >
    </div>
    <span
      v-show="didspinner"
      :class="spinnerClass"
      aria-hidden="true"
      role="status"
    >
    </span>
    <slot></slot>
  </CButton>
</template>
<script>

const props = {
  spinnerType: {
    type: String,
    default: 'border' //grow
  },
  loading: Boolean,
  progress: {
    type: [Boolean, String],
    default: false
  },
  //waitOnEnd: Boolean,
  timeout: {
    type: [String, Number],
    default: 1000
  },
  percent: {
    type: [Number],
    default: 0
  },
  onChange: Function,
  //variant: String, //['left-to-right'] //double use
  spinner: {
    type: Boolean,
    default: true
  },

  //###############################################
  // button props
  //###############################################
  block: Boolean,
  shape: {
    type: String,
    validator: shape => ['', 'pill', 'square'].includes(shape)
  },
  variant: {
    type: String,
    validator: val => ['', 'ghost', 'outline'].includes(val)
  },
  size: {
    type: String,
    validator: value => ['', 'sm', 'lg'].includes(value)
  },
  color: String,
  type: {
    type: String,
    default: 'button'
  },
  pressed: {
    type: Boolean,
    default: null
  },
  //###############################################
  // link props
  //###############################################
  href: String,
  rel: String,
  target: {
    type: String,
    default: '_self'
  },
  disabled: Boolean,
  active: Boolean,
  //these are router-link component props (default active class changed)
  activeClass: {
    type: String,
    default: 'router-link-active'
  },
  append: Boolean,
  event: {
    type: [String, Array],
    default: 'click'
  },
  exact: Boolean,
  exactActiveClass: {
    type: String,
    default: 'router-link-exact-active'
  },
  replace: Boolean,
  routerTag: {
    type: String,
    default: 'a'
  },
  to: [String, Object]
}

export default {
  name: 'CLoadingButtonCustom',
  props,
  data: function () {
    return {
      clickedInterval: null,
      partOfProgressWidthValue: 0,
      didspinner: false,
      progressStyle: 'width: 0%;',
      spinnerClass: '',
      // eslint-disable-next-line vue/no-reserved-keys
      _percent: 0,
      // eslint-disable-next-line vue/no-reserved-keys
      _timeout: 1000,
      buttonClass: '',
    }
  },
  methods: {
    progressBarBg: function () {
      // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
      const yiqContrastedThreshold = 150
      const color = window.getComputedStyle(this.$refs.button).getPropertyValue('background-color') === 'rgba(0, 0, 0, 0)' ? 'rgb(255, 255, 255)' : window.getComputedStyle(this.$refs.button).getPropertyValue('background-color')

      const rgb = color.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)

      const r = parseInt(rgb[1], 10)
      const g = parseInt(rgb[2], 10)
      const b = parseInt(rgb[3], 10)

      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

      if (yiq > yiqContrastedThreshold) {
        return 'rgba(0, 0, 0, .2)'  //dark
      }

      return 'rgba(255, 255, 255, .2)'  //light
    },
    methodOnClick: function () {
      /*let self = this
      // let time = Number(this.timeout)/1000
      this._timeout = Number(this.timeout)
      if (this.percent) {
        this._percent = Number(this.percent)
      } else {
        this._percent = 0
      }
      if (this.clickedInterval === null) {
        if (self.spinner === true) {
          self.didspinner = true;
        }
        this.$nextTick(() => {
          setTimeout(function () {
            self.progressStyle = `background-color: ${self.progressBarBg()}; width: 0%;`
            self.buttonClass = 'c-loading-button c-loading-button-loading'
            let clickedInterval = setInterval(() => {
              if (self._percent >= 100) {
                self._timeout = self.timeout
                if (self.percent) {
                  self._percent = self.percent
                } else {
                  self.percent = 0
                }
                if (self.onChange) {
                  self.onChange('end');
                }
                clearInterval(clickedInterval)
                self.clickedInterval = null
                self.didspinner = false
                self.buttonClass = 'c-loading-button'
                self.progressStyle = `background-color: ${self.progressBarBg()}; width: 0%;`
                return
              }
              const frames = self._timeout / (100 - self._percent) / 10
              self._percent = Math.round((self._percent + (1 / frames)) * 100) / 100
              self._timeout -= 10
              self.progressStyle = `background-color: ${self.progressBarBg()}; width: ${self._percent}%;`
            }, 10)
          }, 1)
        })
      }*/
    },
  },
  watch: {
    loading: function (val) {
      if (val === true) {
        // this.methodOnClick()
        this.didspinner = true
        this.buttonClass = 'c-loading-button c-loading-button-loading'
      } else {
        this.didspinner = false
        this.buttonClass = 'c-loading-button'
      }
    }
  },
  mounted: function () {
    this.buttonClass = 'c-loading-button'
    this.spinnerClass = 'c-loading-button-spinner'
    if (this.spinnerType === 'border') {
      this.spinnerClass = this.spinnerClass + ' spinner-border spinner-border-sm'
    } else if (this.spinnerType === 'grow') {
      this.spinnerClass = this.spinnerClass + ' spinner-grow spinner-grow-sm'
    }
  }
}
</script>

<style scoped>

</style>
