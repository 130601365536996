<template>
  <div
    class="d-flex align-items-center"
    @click="tryChangeChecked"
  >
    <!-- Подпись: -->
    <label
      class="mb-0"
      for="is-graph-paused-switch"
    >
      {{ $t('lk.account.isShowMA') }}
    </label>

    <!-- Таглер -->
    <CSwitch
      ref="switch"
      :id="'is-graph-paused-switch'"
      :class="'ml-2 flex-shrink-0'"
      :checked="isShowMA"
      :color="'primary'"
      :shape="'pill'"
      :disabled="disabled"
      @update:checked="$emit('update:isShowMA', $event)"
    />
  </div>
</template>

<script>
// Компоненты:
import { CSwitch } from '@coreui/vue-pro';

export default {
  props: {
    disabled: Boolean,
    isShowMA: Boolean,
  },
  emits: ['update:isShowMA'],
  methods: {
    /**
     * Переключить значение тагглера. Вывести сообщени если он отключен.
     * @param value - значение.
     */
    tryChangeChecked () {
      if (this.disabled) {
        const msg = this.$t('lk.account.isShowMAWithoutMA')
        this.$toastr.error(msg);
        this.$refs.switch.$data.state = false;
      }
    },
  },

  components: {
    CSwitch,
  },

  data: () => ({})
};
</script>

<style scoped lang="scss">
.popover-wrap {
  cursor: pointer;
  width: 1rem;
  flex-shrink: 0;
  height: 1rem;
  font-size: .75rem;
  background-color: #5a5c6c;
}
</style>
