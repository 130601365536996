var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "title-wrap align-items-center" }, [
        _c("div", { staticClass: "mr-auto d-flex align-items-center" }, [
          _c("h1", { staticClass: "mb-0 mr-3 title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lk.header.settings")) + " "),
          ]),
        ]),
      ]),
      _c(
        "CRow",
        [
          !_vm.isGuest
            ? _c(
                "CCol",
                { staticClass: "mb-3", attrs: { col: "12" } },
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("user/logoutAll")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.account.signOutAll")) + " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "CCol",
            { staticClass: "mb-3", attrs: { col: "12", md: "3" } },
            [
              _c("CSelect", {
                staticClass: "mb-0",
                attrs: {
                  label: _vm.$t("lk.account.selectLang"),
                  value: _vm.locale,
                  options: _vm.$store.state.localeOptions,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.locale = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm.$store.getters["user/getPermissions"]("view_notifications")
            ? _c(
                "CCol",
                {
                  staticClass:
                    "mb-3 d-flex justify-content-center flex-column notify-wrap",
                  attrs: { col: "12", md: "3" },
                },
                [
                  _c("web-push-toggle"),
                  false
                    ? [
                        _c("CTextarea", {
                          staticClass: "mb-0 mt-1",
                          attrs: { placeholder: "JSON", rows: "5" },
                          model: {
                            value: _vm.notifyJson,
                            callback: function ($$v) {
                              _vm.notifyJson = $$v
                            },
                            expression: "notifyJson",
                          },
                        }),
                        _c(
                          "CLoadingButtonCustom",
                          {
                            staticClass: "shadow-none",
                            attrs: {
                              color: "primary",
                              loading: _vm.loadingSendNotify,
                              disabled:
                                !_vm.notifyJson || _vm.loadingSendNotify,
                              block: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.sendNotify.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "CCol",
            {
              staticClass: "mt-3 d-flex align-items-center",
              attrs: { col: "12", md: "4" },
            },
            [
              _c(
                "label",
                { staticClass: "mb-0", attrs: { for: "isGraphPausedSwitch" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("lk.account.pauseUpdateOnChart")) + " "
                  ),
                ]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.$t("lk.account.pauseUpdateOnChartHint"),
                      },
                      expression:
                        "{content: $t('lk.account.pauseUpdateOnChartHint')}",
                    },
                  ],
                  staticClass:
                    "rounded-circle popover-wrap d-inline-flex justify-content-center ml-2",
                },
                [_vm._v(" ? ")]
              ),
              _c("CSwitch", {
                staticClass: "mr-2 ml-1",
                attrs: {
                  color: "primary",
                  shape: "pill",
                  disabled: _vm.isGraphPausedLoading || _vm.isGuest,
                  checked: _vm.isGraphPaused,
                  id: "isGraphPausedSwitch",
                },
                on: {
                  "update:checked": function ($event) {
                    _vm.isGraphPaused = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "mb-4 mt-0" }),
      _c("time-range-selector", {
        staticClass: "mb-4",
        attrs: { hideTitle: true, options: _vm.filteredTabList },
        model: {
          value: _vm.selectedTab,
          callback: function ($$v) {
            _vm.selectedTab = $$v
          },
          expression: "selectedTab",
        },
      }),
      _vm.selectedTab === 0 ? _c("watch-lists") : _vm._e(),
      _vm.selectedTab === 1 ? _c("line-runner-setup") : _vm._e(),
      _vm.selectedTab === 2 ? _c("ma-settings") : _vm._e(),
      _vm.selectedTab === 3 ? _c("screener-table-settings") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }