<template>
  <div class="timeFrameSettings">
    <div class="timeFrameSettings__controls">
      <div class="d-flex align-items-center">
        {{activeSettingsCount}}/15
      </div>
      <div>
        <CButton
          :disabled="loading || !isChanged"
          color="secondary"
          class="mr-1 mb-1 timeFrameSettings__controls-button"
          @click="resetSettings"
        >
          <CIcon name="cilReload"/>
          {{$t('general.reset')}}
        </CButton>
        <CButton
          :disabled="loading || !isChanged"
          color="success"
          class="mb-1 timeFrameSettings__controls-button"
          @click="saveSettings()"
        >
          {{$t('general.save')}}
        </CButton>
      </div>
    </div>
    <div class="timeFrameSettings__drugAndDrop">
      <div class="timeFrameSettings__section">
        <draggable
          class="timeFrameSettings__list-group"
          tag="div"
          v-model="list1"
          v-bind="activeListDragOptions"
          @start="activeListDrag = true"
          @end="activeListDrag = false"
          @remove="onRemove"
        >
          <transition-group :name="!activeListDrag ? 'flip-list' : null">
            <div
              class="timeFrameSettings__list-group-item --active"
              v-for="element in list1"
              :key="element.id"
            >
              <div class="timeFrameSettings__list-group-item-section-left">
                <CIcon name="appsIcon"/>
                {{ element.label }}
              </div>
              <div
                @click="removeItem(element)"
                :class="['timeFrameSettings__list-group-item-section-right', { _disable: disableDragInActiveList }]"
              >
                x
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="timeFrameSettings__section-divider">

      </div>
      <div class="timeFrameSettings__section">
        <draggable
          class="timeFrameSettings__list-group"
          tag="div"
          v-model="list2"
          v-bind="listDragOptions"
          @start="listDrag = true"
          @end="listDrag = false"
          @remove="onRemove"
        >
          <transition-group :name="!listDrag ? 'flip-list' : null">
            <div
              class="timeFrameSettings__list-group-item --inactive"
              v-for="element in list2"
              :key="element.id"
            >
              <div class="timeFrameSettings__list-group-item-section-left">
                <CIcon name="appsIcon"/>
                {{ element.label }}
              </div>
              <div
                @click="addItem(element)"
                class="timeFrameSettings__list-group-item-section-right"
              >
                +
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {mapGetters} from "vuex"
import {propsScreenType} from "@/mixins/props-screen-type";
import {SCREENER_PAGE_FUTURES, TIME_FRAME_FUTURES_SETTINGS_KEY, TIME_FRAME_SETTINGS_KEY} from "@/shared/constants";
import cloneDeep from "lodash/cloneDeep";

const MINIMUM_ACTIVE_TIMEFRAMES_COUNT = 2

export default {
  name: 'TimeFrameSettings',
  components: {
    draggable
  },
  props: {
    activeSettingsCount: {
      type: Number,
      default: 0
    }
  },
  mixins: [
    propsScreenType,
  ],
  data () {
    return {
      loading: false,
      defaultSettings: [
        {
          id: 1,
          label: '5M',
          value: "5m"

        },
        {
          id: 2,
          label: '15M',
          value: "15m"
        },
        {
          id: 3,
          label: '30M',
          value: "30m"
        },
        {
          id: 4,
          label: '1H',
          value: "1h"
        },
        {
          id: 5,
          label: '2H',
          value: "2h"
        },
        {
          id: 6,
          label: '4H',
          value: "4h"
        },
        {
          id: 7,
          label: '12H',
          value: "12h"
        },
        {
          id: 8,
          label: '1D',
          value: "1d"
        }
      ],
      list1: [],
      list2: [],

      listDrag: false,
      activeListDrag: false,
    }
  },
  mounted(){
    if(!this.isGuest && (!this.$store.state.settings.screener || !this.$store.state.settings.screener?.hasOwnProperty(this.timeFrameSettingsKey))){
      this.saveSettings(this.defaultSettings)
    }

    this.list1 = this.getMappedSettings()
    this.list2 = [...this.initialInActiveSettings]
  },
  methods: {
    resetSettings(){
      this.list1 = [...this.initialActiveSettings]
      this.list2 = [...this.initialInActiveSettings]
    },
    saveSettings(value){
      const dataToSave = cloneDeep(value || this.list1).map(el => ({ id: el.id, value: el.value }))
      if(!dataToSave.length){
        this.$toastr.warning(this.$t('general.dontSaveEmptyArray'))
        return
      }
      this.loading = true
        this.$store.commit('SET_KEY', {
          namespace: 'screener',
          key: this.timeFrameSettingsKey,
          value: dataToSave
        })
        this.$store.dispatch('saveRequest', ['screener']).then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('general.dataSaved'))
          }
        }).finally(() => {
          this.loading = false
        })
    },
    onRemove (obj) {
      const {to,from,item} = obj
      if(to!==from){
        item.classList.add('display-none')
      }
    },
    removeItem(curItem){
      if (this.disableDragInActiveList) return
      this.list1 = this.list1.filter(item => item.id !== curItem.id)
      this.list2.push(curItem)
    },
    addItem(curItem){
      this.list2 = this.list2.filter(item => item.id !== curItem.id)
      this.list1.push(curItem)
    },
    getMappedSettings() {
      const list1 = this.$store.state.settings.screener?.[this.timeFrameSettingsKey]

      return cloneDeep((Array.isArray(list1) ? list1 : [])
        .map(item => this.defaultSettings.find(el => el.id === item.id))
        .filter(item => !!item))
    },
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    disableDragInActiveList () {
      return this.list1.length <= MINIMUM_ACTIVE_TIMEFRAMES_COUNT
    },
    initialActiveSettings(){
      return this.getMappedSettings()
    },
    initialInActiveSettings(){
      return this.defaultSettings.filter(item => !this.initialActiveSettings.some(setting => setting.id === item.id))
    },
    listDragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    activeListDragOptions() {
      return {
        ...this.listDragOptions,
        group: !this.listDrag && this.disableDragInActiveList ? 'description-only-order' : 'description',
      }
    },
    isChanged(){
      return JSON.stringify(this.initialActiveSettings) !==  JSON.stringify(this.list1);
    },
    isFutures() {
      return this.screenType === SCREENER_PAGE_FUTURES
    },
    timeFrameSettingsKey() {
      return this.isFutures ? TIME_FRAME_FUTURES_SETTINGS_KEY : TIME_FRAME_SETTINGS_KEY
    },
  },
  watch:{
    list1: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$emit('change-time-frame-settings', value)
      }
    },
  },
  emits: [
    'change-time-frame-settings'
  ]
}
</script>

<style lang="scss" scoped>
.timeFrameSettings {
  display: flex;
  flex-direction: column;

  &__controls{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;
  }
  &__controls-button{
    height: 36px !important;
  }
  &__drugAndDrop{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      display: flex;
    }
  }
  &__section{
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
  &__section-divider{
    margin: 10px 0;
    height: 1px;
    width: 100%;
    background: rgba(105, 117, 130, 0.51);

    @include media-breakpoint-up(md) {
      margin: 0 10px;
      width: 1px;
      height: auto;
    }
  }
  &__list-group{
    min-height: 20px;
    height: 100%;
    span{
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
  &__list-group-item {
    cursor: move;
    border-radius: 8px;
    padding: 15px 10px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    user-select: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .--inactive{
    border-color: rgba(105, 117, 130, 0.51);
  }
  .--active{
    background: var(--white);
    color: var(--header-bg);

    svg {
      color: var(--header-bg);
    }
  }

  &__list-group-item-section-right{
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 16px;
    font-size: 16px;
    font-weight: 900;
    transition: color 0.3s ease-out;
    &:hover {
      color: rgba(105, 117, 130, 0.51);
    }
    &._disable {
      cursor: not-allowed;
      color: rgba(105, 117, 130, 0.51);
    }
  }
  &__list-group-item-section-left{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
// .flip-list-move {
//   transition: transform 0.5s;
// }

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #656F81;
}

.display-none {
  display: none !important;
  transition: 0s !important;
}
</style>
