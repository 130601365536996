import {mapGetters} from "vuex";
import {
  IS_SHOW_MA_FUTURES_KEY,
  IS_SHOW_MA_KEY,
  SCREENER_COLUMNS_FUTURES_SETTINGS_KEY,
  SCREENER_COLUMNS_SETTINGS_IDS,
  SCREENER_COLUMNS_SETTINGS_KEY,
} from "@/shared/constants";

export const showMa = {
  data: () => ({
    isShowMALoading: false,
  }),

  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    isShowMA: {
      get() {
        if (this.isGuest) {
          return true
        } else {
          if (this.$store.state.settings?.screener?.hasOwnProperty(this.isShowMAKey)) {
            const isShowMA = this.$store.state.settings?.screener?.[this.isShowMAKey]
            const screenerColumnsSettings = this.$store.state.settings.screener?.[this.screenerColumnsSettingsKey]

            if (Array.isArray(screenerColumnsSettings)) {
              return isShowMA
                && screenerColumnsSettings.some((column) => SCREENER_COLUMNS_SETTINGS_IDS.ma50 === column.id)
                && screenerColumnsSettings.some((column) => SCREENER_COLUMNS_SETTINGS_IDS.ma200 === column.id)
            } else {
              return isShowMA
            }
          } else {
            return true
          }
        }
      },
      set(newValue) {
        this.isShowMALoading = true

        this.$store.commit('SET_KEY', {
          namespace: 'screener',
          key: this.isShowMAKey,
          value: newValue
        })

        this.$store.dispatch('saveRequest', ['screener']).finally(() => {
          this.isShowMALoading = false
        })
      }
    },
    isShowMAKey() {
      return this.isFutures || this.isActiveFutures ? IS_SHOW_MA_FUTURES_KEY : IS_SHOW_MA_KEY
    },
    screenerColumnsSettingsKey() {
      return this.isFutures || this.isActiveFutures ? SCREENER_COLUMNS_FUTURES_SETTINGS_KEY : SCREENER_COLUMNS_SETTINGS_KEY
    },
  }
}
