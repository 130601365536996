var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("portal-target", { attrs: { name: "onboarding-overlay" } }),
      _vm.showMoveBanner ? _c("move-banner") : _vm._e(),
      _c("router-view"),
      _c("portal-target", { attrs: { name: "onboarding-footer" } }),
      _vm.$store.state.routerSpinner
        ? _c(
            "div",
            { attrs: { id: "get-current-mixin-wrap" } },
            [
              _c("CSpinner", {
                attrs: { id: "get-current-mixin-loader", color: "primary" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "p",
        {
          staticClass: "app-copyright",
          class: { "_dark-theme": _vm.$store.getters.darkMode },
        },
        [
          _vm._v(
            " © Copyright " +
              _vm._s(_vm.year) +
              " Cryptovizor. All rights reserved "
          ),
          _vm.isNotProduction
            ? _c("span", [_vm._v(". sha commit: " + _vm._s(_vm.hash))])
            : _vm._e(),
        ]
      ),
      _vm.isBrave
        ? _c("portal", { attrs: { to: "brave-notify-modal" } }, [
            _c(
              "div",
              [
                _c(
                  "CModal",
                  {
                    attrs: {
                      title: `${_vm.$t(
                        "lk.account.browserNotificationsEnable"
                      )}`,
                      scrollable: "",
                      centered: "",
                      size: "xl",
                      show: _vm.showBraveModal,
                    },
                    on: { "update:show": _vm.hideBraveModal },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "CButton",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.hideBraveModal },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      810239535
                    ),
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "lk.account.browserNotificationsBraveErrorStart"
                              )
                            ),
                          },
                        }),
                        _c(
                          "CButton",
                          {
                            staticClass: "notificationInfoModal__action-button",
                            attrs: { color: "secondary" },
                            on: { click: _vm.copyLink },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("general.copyLink")) + " "
                            ),
                            _c("CIcon", { attrs: { name: "copy" } }),
                          ],
                          1
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "lk.account.browserNotificationsBraveErrorEnd"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isGuest || _vm.showLoginModal
        ? _c(
            "portal",
            { attrs: { to: "login" } },
            [_c("login-modal", { attrs: { show: _vm.showLoginModal } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }