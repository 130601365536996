var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
      on: { click: _vm.tryChangeChecked },
    },
    [
      _c(
        "label",
        { staticClass: "mb-0", attrs: { for: "is-graph-paused-switch" } },
        [_vm._v(" " + _vm._s(_vm.$t("lk.account.isShowMA")) + " ")]
      ),
      _c("CSwitch", {
        ref: "switch",
        class: "ml-2 flex-shrink-0",
        attrs: {
          id: "is-graph-paused-switch",
          checked: _vm.isShowMA,
          color: "primary",
          shape: "pill",
          disabled: _vm.disabled,
        },
        on: {
          "update:checked": function ($event) {
            return _vm.$emit("update:isShowMA", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }