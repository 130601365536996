/** @import { PropType } from 'vue' */
/** @typedef {typeof ALLOWED_SCREEN_TYPES[number]} ScreenType */
/** @typedef {PropType<ScreenType>} ScreenTypeProp */

// Разрешенные значения:
const ALLOWED_SCREEN_TYPES = /** @type {const} */(
  ['spot', 'futures']
);

/**
 * Валидация значения типа экрана.
 * @param {ScreenType} value - Тип экрана.
 */
export function screenTypePropValidator (value) {
  const valueIsValid = ALLOWED_SCREEN_TYPES.includes(value);
  if (!valueIsValid) {
    throw new TypeError(`Значение ${value} невалидно. Должно быть одно из ${ALLOWED_SCREEN_TYPES}.`)
  }

  return valueIsValid;
}

/**
 * Миксин для проверки типа экрана.
 */
export const propsScreenType = {
  props: {
    /**
     * Тип экрана.
     * @type {ScreenTypeProp}
     */
    screenType: {
      type: String,
      required: true,
      validator: screenTypePropValidator,
    },
  }
}
