import {has} from 'lodash'
const toastr = require('toastr')

export default function (response) {
  if(has(response, 'data.errorText')){
    toastr.error(response.data.errorText)
  }
  if(has(response, 'data.successText')){
    toastr.success(response.data.successText)
  }
  if(has(response, 'data.warningText')){
    toastr.warning(response.data.warningText)
  }
}
