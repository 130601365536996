import { render, staticRenderFns } from "./ScreenerTableSettingsPausedGraphToggle.vue?vue&type=template&id=80304024&scoped=true&"
import script from "./ScreenerTableSettingsPausedGraphToggle.vue?vue&type=script&lang=js&"
export * from "./ScreenerTableSettingsPausedGraphToggle.vue?vue&type=script&lang=js&"
import style0 from "./ScreenerTableSettingsPausedGraphToggle.vue?vue&type=style&index=0&id=80304024&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80304024",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/user0/cvizor/cvizor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('80304024')) {
      api.createRecord('80304024', component.options)
    } else {
      api.reload('80304024', component.options)
    }
    module.hot.accept("./ScreenerTableSettingsPausedGraphToggle.vue?vue&type=template&id=80304024&scoped=true&", function () {
      api.rerender('80304024', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/account/ScreenerTableSettingsPausedGraphToggle.vue"
export default component.exports