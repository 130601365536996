export const CLIENT_ID_DEFAULT =  'candles'
export const CLIENT_ID_CANDLES_BINANCE_FUTURES =  'candles_binance_futures'
export const SCREENER_PAGE_FUTURES =  'futures'
export const TIME_FRAME_SETTINGS_KEY = 'timeFrameSettings'
export const TIME_FRAME_FUTURES_SETTINGS_KEY = 'timeFrameFuturesSettings'
export const SCREENER_COLUMNS_SETTINGS_KEY = 'screenerColumnsSettings'
export const SCREENER_COLUMNS_FUTURES_SETTINGS_KEY = 'screenerColumnsFuturesSettings'
export const IS_SHOW_MA_KEY = 'isShowMA'
export const IS_SHOW_MA_FUTURES_KEY = 'isShowMAFutures'
export const SCREENER_COLUMNS_SETTINGS_IDS = {
  price: 1,
  cdWeek: 2,
  delta: 3,
  volatility: 4,
  ma50: 5,
  ma200: 6,
  volume: 7,
}
