var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile && _vm.smallOnMobile
    ? _c(
        "svg",
        {
          class: { _auth: _vm.auth, "logo logo--small": true },
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 1302 1302",
            width: "25",
            height: "25",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M615.93 7C615.93 3.13 619.07 0 622.93 0L684.35 0C688.22 0 691.35 3.13 691.35 7L691.35 395.24C691.35 399.11 688.22 402.24 684.35 402.24L622.93 402.24C619.07 402.24 615.93 399.11 615.93 395.24L615.93 7Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M1091.2 666.21C1087.33 666.21 1084.2 663.07 1084.14 659.21C1082.33 547.55 1037.18 440.85 958.09 361.76C877.34 281.01 767.83 235.65 653.63 235.65C539.44 235.65 429.93 281.01 349.18 361.76C270.09 440.85 224.94 547.55 223.13 659.21C223.07 663.07 219.94 666.21 216.07 666.21L157.83 666.21C153.97 666.21 150.83 663.07 150.88 659.21C152.7 528.39 205.46 403.31 298.1 310.67C392.39 216.38 520.28 163.41 653.63 163.41C786.99 163.41 914.88 216.38 1009.17 310.67C1101.81 403.32 1154.57 528.39 1156.39 659.21C1156.44 663.07 1153.3 666.21 1149.44 666.21L1091.2 666.21Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M1140.62 759.32C1144.41 760.05 1146.91 763.71 1146.13 767.5C1127.33 858.93 1083.43 943.38 1019.29 1011.33C955.15 1079.28 873.38 1127.99 783.19 1152.04C779.45 1153.03 775.65 1150.76 774.7 1147.01L760.48 1090.53C759.54 1086.78 761.81 1082.98 765.55 1081.98C842.4 1061.29 912.06 1019.69 966.75 961.75C1021.44 903.8 1058.95 831.85 1075.17 753.93C1075.95 750.15 1079.61 747.66 1083.41 748.38L1140.62 759.32Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M223.87 748.38C227.67 747.66 231.33 750.15 232.12 753.93C252.06 849.79 304.11 936.07 379.76 998.44C455.42 1060.81 550.04 1095.44 647.94 1096.74C651.8 1096.79 654.95 1099.91 654.96 1103.77L655.14 1162.01C655.15 1165.88 652.02 1169.03 648.15 1168.99C533.41 1167.73 422.46 1127.26 333.81 1054.18C245.16 981.09 184.27 879.9 161.15 767.5C160.37 763.72 162.87 760.05 166.66 759.32L223.87 748.38Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M915.8 610.36C915.8 606.5 918.94 603.36 922.8 603.36L1294.28 603.36C1298.15 603.36 1301.28 606.5 1301.28 610.36L1301.28 680.16C1301.28 684.03 1298.15 687.16 1294.28 687.16L922.8 687.16C918.94 687.16 915.8 684.03 915.8 680.16L915.8 610.36Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M0 616.65C0 612.78 3.13 609.65 7 609.65L363.82 609.65C367.68 609.65 370.82 612.78 370.82 616.65L370.82 678.07C370.82 681.93 367.68 685.07 363.82 685.07L7 685.07C3.13 685.07 0 681.93 0 678.07L0 616.65Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M615.93 956.04C615.93 952.18 619.07 949.04 622.93 949.04L684.35 949.04C688.22 949.04 691.35 952.18 691.35 956.04L691.35 1294C691.35 1297.87 688.22 1301 684.35 1301L622.93 1301C619.07 1301 615.93 1297.87 615.93 1294L615.93 956.04Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M671.72 397.92C671.46 401.78 668.13 404.69 664.26 404.53C614.23 402.5 564.61 414.85 521.31 440.2C476 466.73 439.7 506.27 417.14 553.69C394.58 601.1 386.8 654.21 394.79 706.1C402.79 758 426.2 806.29 461.99 844.71C497.78 883.14 544.29 909.92 595.49 921.57C646.69 933.23 700.21 929.23 749.1 910.09C798 890.95 840.01 857.55 869.69 814.23C898.05 772.84 913.89 724.22 915.41 674.17C915.53 670.3 918.66 667.18 922.53 667.2L979.74 667.4C983.61 667.42 986.74 670.57 986.64 674.43C985.05 738.76 964.86 801.31 928.43 854.48C890.69 909.58 837.25 952.05 775.06 976.4C712.87 1000.74 644.8 1005.83 579.68 991.01C514.56 976.18 455.4 942.12 409.88 893.25C364.36 844.38 334.58 782.95 324.41 716.95C314.24 650.94 324.15 583.4 352.84 523.09C381.54 462.79 427.69 412.5 485.33 378.75C540.95 346.19 604.77 330.48 669.05 333.46C672.92 333.64 675.83 336.99 675.57 340.84L671.72 397.92Z",
            },
          }),
          _c("path", {
            attrs: {
              fill: "#3686f6",
              d: "M653.64 720.68C616.57 720.68 586.6 690.71 586.6 653.64C586.6 616.57 616.57 586.6 653.64 586.6C690.72 586.6 720.68 616.57 720.68 653.64C720.68 690.71 690.72 720.68 653.64 720.68Z",
            },
          }),
        ]
      )
    : _c(
        "svg",
        {
          staticClass: "logo",
          attrs: {
            viewBox: "0 0 2347 1479",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M442.173 334.753C433.036 366.767 418.369 393.213 398.172 414.092C377.975 434.507 353.931 449.586 326.04 459.329C298.148 468.608 267.372 473.248 233.71 473.248C185.621 473.248 144.025 463.969 108.92 445.41C73.8158 426.387 46.8862 399.245 28.1317 363.983C9.37725 328.722 0 286.269 0 236.624C0 186.979 9.37725 144.526 28.1317 109.265C46.8862 74.003 73.8158 47.0928 108.92 28.5341C144.025 9.51135 185.381 0 232.989 0C266.17 0 296.706 4.63968 324.597 13.919C352.488 22.7344 376.052 36.4215 395.287 54.9803C414.522 73.539 428.228 97.4334 436.403 126.663L327.482 169.117C320.75 140.35 309.69 120.632 294.301 109.961C279.394 99.2893 260.399 93.9536 237.316 93.9536C214.715 93.9536 194.999 99.2893 178.168 109.961C161.818 120.632 149.074 136.639 139.937 157.981C131.281 178.86 126.953 205.074 126.953 236.624C126.953 267.71 131.041 293.924 139.216 315.267C147.391 336.609 159.654 352.616 176.004 363.287C192.835 373.959 213.513 379.294 238.038 379.294C261.12 379.294 280.596 373.263 296.465 361.2C312.815 348.672 323.876 329.65 329.646 304.131L442.173 334.753Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M502.522 466.288V118.312H610.721L615.771 193.475C624.907 167.493 638.372 147.31 656.165 132.927C674.438 118.544 697.521 111.352 725.412 111.352C734.549 111.352 742.243 112.048 748.495 113.44C755.227 114.832 760.517 116.688 764.364 119.008L752.101 216.441C747.292 214.585 741.041 213.194 733.347 212.266C726.134 210.874 717.237 210.178 706.658 210.178C692.231 210.178 678.766 213.194 666.263 219.225C653.76 225.257 643.662 234.304 635.968 246.367C628.755 257.967 625.148 272.814 625.148 290.908V466.288H502.522Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1175.29 118.312L1044.01 466.288C1030.55 502.942 1015.88 532.172 1000.01 553.978C984.143 575.785 965.389 591.56 943.749 601.303C922.109 611.047 896.141 615.918 865.845 615.918C845.648 615.918 827.134 613.83 810.303 609.655C793.472 605.479 778.805 599.679 766.302 592.256L790.828 512.221C799.483 518.253 808.62 522.429 818.238 524.748C827.856 527.532 840.118 528.924 855.026 528.924C868.971 528.924 880.272 526.604 888.928 521.965C898.065 517.325 905.278 508.51 910.568 495.518L919.945 473.944L878.108 379.294L769.909 118.312H904.797L960.339 316.659L977.651 390.43L997.848 313.875L1053.39 118.312H1175.29Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1218.92 612.439V118.312H1329.28L1335.77 244.279L1321.35 229.664C1324.71 206.466 1332.17 186.051 1343.71 168.421C1355.25 150.79 1370.16 136.871 1388.43 126.663C1406.7 116.456 1427.62 111.352 1451.19 111.352C1481 111.352 1506.97 118.544 1529.09 132.927C1551.21 146.846 1568.28 167.261 1580.3 194.171C1592.81 220.617 1599.06 252.863 1599.06 290.908C1599.06 328.954 1592.81 361.663 1580.3 389.038C1567.8 416.412 1550.25 437.29 1527.65 451.673C1505.53 466.056 1479.56 473.248 1449.74 473.248C1417.04 473.248 1390.59 463.969 1370.4 445.41C1350.2 426.387 1336.73 402.493 1330 373.727L1341.54 357.024V612.439H1218.92ZM1407.91 386.254C1429.06 386.254 1445.65 378.83 1457.68 363.983C1469.7 348.672 1475.71 324.778 1475.71 292.3C1475.71 259.822 1469.7 236.16 1457.68 221.313C1446.14 206.002 1429.79 198.347 1408.63 198.347C1395.16 198.347 1383.38 202.058 1373.28 209.482C1363.18 216.441 1355.25 226.881 1349.48 240.8C1344.19 254.719 1341.54 271.886 1341.54 292.3C1341.54 311.787 1343.95 328.722 1348.76 343.105C1354.05 357.024 1361.74 367.695 1371.84 375.119C1381.94 382.542 1393.96 386.254 1407.91 386.254Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1819.8 7.65546V342.409C1819.8 359.112 1823.89 371.175 1832.06 378.598C1840.72 385.558 1852.5 389.038 1867.41 389.038C1878.95 389.038 1888.81 387.878 1896.98 385.558C1905.64 382.774 1913.33 378.83 1920.06 373.727L1933.05 446.802C1921.03 455.153 1905.88 461.649 1887.6 466.288C1869.33 470.928 1850.58 473.248 1831.34 473.248C1803.93 473.248 1780.13 469.536 1759.93 462.113C1740.21 454.689 1724.83 442.858 1713.77 426.619C1703.19 410.38 1697.66 388.806 1697.17 361.895V40.3652L1819.8 7.65546ZM1927.28 118.312V202.522H1635.14V118.312H1927.28Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M2155.59 111.352C2193.58 111.352 2226.77 118.544 2255.14 132.927C2283.99 146.846 2306.35 167.493 2322.22 194.867C2338.57 221.777 2346.75 254.255 2346.75 292.3C2346.75 330.346 2338.57 363.055 2322.22 390.43C2306.35 417.34 2283.99 437.986 2255.14 452.369C2226.77 466.288 2193.58 473.248 2155.59 473.248C2118.57 473.248 2085.63 466.288 2056.77 452.369C2027.92 437.986 2005.32 417.34 1988.97 390.43C1973.1 363.055 1965.16 330.346 1965.16 292.3C1965.16 254.255 1973.1 221.777 1988.97 194.867C2005.32 167.493 2027.92 146.846 2056.77 132.927C2085.63 118.544 2118.57 111.352 2155.59 111.352ZM2155.59 195.563C2141.65 195.563 2129.63 198.811 2119.53 205.306C2109.43 211.802 2101.74 222.241 2096.45 236.624C2091.16 251.007 2088.51 269.566 2088.51 292.3C2088.51 315.035 2091.16 333.593 2096.45 347.976C2101.74 361.896 2109.43 372.335 2119.53 379.294C2129.63 385.79 2141.65 389.038 2155.59 389.038C2170.02 389.038 2182.28 385.79 2192.38 379.294C2202.48 372.335 2210.17 361.896 2215.46 347.976C2220.75 333.593 2223.4 315.035 2223.4 292.3C2223.4 269.566 2220.75 251.007 2215.46 236.624C2210.17 222.241 2202.48 211.802 2192.38 205.306C2182.28 198.811 2170.02 195.563 2155.59 195.563Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M393.153 756.694H520.107L390.268 1104.67H243.839L114 756.694H243.117L317.414 1021.85L393.153 756.694Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M624.372 716.329C599.366 716.329 581.093 711.921 569.552 703.106C558.491 693.827 552.961 679.443 552.961 659.957C552.961 640.006 558.491 625.623 569.552 616.808C581.093 607.528 599.366 602.889 624.372 602.889C648.897 602.889 666.93 607.528 678.472 616.808C690.013 625.623 695.784 640.006 695.784 659.957C695.784 679.443 690.013 693.827 678.472 703.106C666.93 711.921 648.897 716.329 624.372 716.329ZM685.685 756.694V1104.67H563.06V756.694H685.685Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M754.222 1104.67V1032.29L897.766 875.006L935.997 838.121L879.012 839.513H755.665V756.694H1071.61V829.073L930.226 984.967L890.553 1023.24L954.03 1021.85H1078.1V1104.67H754.222Z",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1970.49 1104.67V756.694H2078.69L2083.74 831.857C2092.88 805.875 2106.34 785.692 2124.13 771.309C2142.41 756.926 2165.49 749.735 2193.38 749.735C2202.52 749.735 2210.21 750.431 2216.46 751.823C2223.2 753.214 2228.49 755.07 2232.33 757.39L2220.07 854.824C2215.26 852.968 2209.01 851.576 2201.32 850.648C2194.1 849.256 2185.21 848.56 2174.63 848.56C2160.2 848.56 2146.74 851.576 2134.23 857.607C2121.73 863.639 2111.63 872.686 2103.94 884.75C2096.72 896.349 2093.12 911.196 2093.12 929.291V1104.67H1970.49Z",
              fill: "currentColor",
            },
          }),
          _c("rect", {
            attrs: {
              x: "1494.14",
              y: "366",
              width: "63.7728",
              height: "344.121",
              rx: "7",
              fill: "#3686F6",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1944.27 935.876C1948.14 935.876 1951.28 932.738 1951.22 928.873C1949.42 817.323 1904.85 710.73 1826.75 631.713C1747.02 551.044 1638.88 505.725 1526.12 505.725C1413.36 505.725 1305.22 551.044 1225.49 631.713C1147.39 710.73 1102.82 817.323 1101.02 928.873C1100.96 932.738 1104.1 935.876 1107.97 935.876H1155.05C1158.92 935.876 1162.05 932.739 1162.12 928.874C1163.9 833.716 1202.04 742.841 1268.69 675.414C1336.96 606.335 1429.56 567.527 1526.12 567.527C1622.68 567.527 1715.28 606.335 1783.55 675.414C1850.2 742.841 1888.34 833.716 1890.12 928.874C1890.19 932.739 1893.32 935.876 1897.19 935.876H1944.27Z",
              fill: "#3686F6",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1936.87 1015.35C1940.65 1016.08 1943.14 1019.73 1942.36 1023.51C1926.35 1101.36 1889.31 1173.25 1835.3 1231.15C1781.33 1289.01 1712.58 1330.55 1636.74 1351.23C1633 1352.25 1629.17 1349.96 1628.24 1346.19L1616.7 1299.85C1615.77 1296.11 1618.04 1292.33 1621.75 1291.31C1686.34 1273.52 1744.88 1238.04 1790.88 1188.72C1836.89 1139.41 1868.52 1078.23 1882.34 1011.96C1883.13 1008.17 1886.81 1005.66 1890.61 1006.4L1936.87 1015.35Z",
              fill: "#3686F6",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1115.41 1015.35C1111.63 1016.08 1109.14 1019.74 1109.92 1023.51C1129.61 1119.28 1181 1205.48 1255.71 1267.8C1330.4 1330.09 1423.79 1364.69 1520.43 1366.01C1524.3 1366.06 1527.42 1362.91 1527.41 1359.04L1527.27 1311.24C1527.26 1307.38 1524.11 1304.26 1520.25 1304.19C1437.85 1302.84 1358.26 1273.24 1294.57 1220.11C1230.86 1166.98 1186.96 1093.56 1169.93 1011.96C1169.14 1008.17 1165.47 1005.66 1161.66 1006.4L1115.41 1015.35Z",
              fill: "#3686F6",
            },
          }),
          _c("rect", {
            attrs: {
              x: "1752.86",
              y: "882.139",
              width: "325.949",
              height: "71.6916",
              rx: "7",
              fill: "#3686F6",
            },
          }),
          _c("rect", {
            attrs: {
              x: "973.41",
              y: "887.546",
              width: "313.549",
              height: "64.5227",
              rx: "7",
              fill: "#3686F6",
            },
          }),
          _c("rect", {
            attrs: {
              x: "1494.26",
              y: "1177.89",
              width: "63.7728",
              height: "301.106",
              rx: "7",
              fill: "#3686F6",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1544.57 658.561C1544.83 654.707 1541.91 651.365 1538.06 651.199C1481.61 648.769 1425.68 663.574 1377.58 693.772C1327.5 725.214 1288.42 771.76 1265.87 826.853C1243.31 881.947 1238.4 942.811 1251.84 1000.88C1265.27 1058.94 1296.37 1111.27 1340.76 1150.51C1385.14 1189.75 1440.57 1213.91 1499.25 1219.59C1557.92 1225.28 1616.87 1212.19 1667.81 1182.19C1718.75 1152.18 1759.09 1106.77 1783.17 1052.34C1806.3 1000.05 1813.3 941.976 1803.34 885.694C1802.67 881.874 1798.94 879.423 1795.15 880.209L1749.86 889.585C1746.09 890.366 1743.67 894.049 1744.32 897.848C1751.78 941.655 1746.21 986.779 1728.21 1027.45C1709.28 1070.24 1677.56 1105.95 1637.51 1129.54C1597.47 1153.13 1551.11 1163.42 1504.98 1158.95C1458.85 1154.48 1415.27 1135.49 1380.38 1104.64C1345.48 1073.79 1321.03 1032.64 1310.47 986.989C1299.9 941.337 1303.76 893.484 1321.5 850.168C1339.23 806.852 1369.95 770.257 1409.32 745.536C1446.71 722.064 1490.12 710.42 1533.98 712.004C1537.85 712.144 1541.19 709.236 1541.45 705.374L1544.57 658.561Z",
              fill: "#3686F6",
            },
          }),
          _c("ellipse", {
            attrs: {
              cx: "1526.06",
              cy: "925.22",
              rx: "56.6867",
              ry: "57.3533",
              fill: "#3686F6",
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }