<template>
  <div class="mt-3">
    <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
      <h3 class="mb-0 mr-3">{{ $t("lineRunner.common") }}</h3>
      <CLoadingButtonCustom
        color="success"
        :class="{'--disabled': !hasAccess}"
        :loading="loadingRequest"
        v-tooltip="toolTipContent"
        :disabled="loadingRequest || isGuest"
        @click.native="save"
        class="mt-3 mt-md-0"
      >
        <CIcon name="cil-save" />
        {{ $t("lineRunner.save") }}
      </CLoadingButtonCustom>
      <div class="mt-4 mb-3 mt-md-0 mb-md-0 ml-md-3 d-flex align-items-center">
        <label class="d-block mb-0 mr-3">
          {{ $t("lineRunner.show") }}
        </label>
        <CSwitch
          color="primary"
          shape="pill"
          v-tooltip="toolTipContent"
          :disabled="!hasAccess"
          :checked="$store.state.lineRunner.showLineRunner"
          @update:checked="toggleLineRunner"
          class="flex-shrink-0"
        />
      </div>
    </div>
    <p v-if="!generalParams.length">{{ $t("lineRunner.emptyParams") }}</p>
    <CRow class="mb-3">
      <CCol
        col="12"
        md="6"
        lg="4"
        v-for="(param, index) in generalParams"
        :key="index"
        class="param"
      >
        <CRow>
          <CCol col="4">
            <CSelect
              size="sm"
              class="mb-0"
              :value.sync="param.tf"
              label="TF"
              placeholder="TF"
              :is-valid="$get(param, 'errors.tf') ? false : null"
              :invalid-feedback="$get(param, 'errors.tf')"
              :options="tfOptions"
              @change="param.validateTf()"
            />
          </CCol>
          <CCol col="6" md="5">
            <CSelect
              size="sm"
              class="mb-0"
              :value.sync="param.label"
              :label="$t('lineRunner.param')"
              :is-valid="$get(param, 'errors.label') ? false : null"
              :invalid-feedback="$get(param, 'errors.label')"
              placeholder="param"
              :options="paramOptions"
              @change="param.validateLabel()"
            />
          </CCol>
          <CCol col="2" md="3" class="indent-btn-sm">
            <CButton
              color="danger"
              size="sm"
              @click="generalParams.splice(index, 1)"
            >
              <CIcon name="cil-x" />
            </CButton>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="12" md="6" lg="4" v-if="generalParams.length < 3">
        <CButton
          :class="{ 'indent-btn-sm': generalParams.length }"
          color="primary"
          size="sm"
          @click="generalParams.push(new Param())"
        >
          <CIcon name="cil-plus" />
          {{ $t("lineRunner.addParam") }}
        </CButton>
      </CCol>
    </CRow>

    <h3>{{ $t("lineRunner.coins") }}</h3>
    <p v-if="!coins.length">{{ $t("lineRunner.emptyCoins") }}</p>

    <CRow v-for="(item, i) in coins" :key="i" class="item">
      <CCol col="12">
        <CRow class="mb-3">
          <CCol col="10" md="6">
            <div class="form-group mb-0">
              <label>{{ $t("lineRunner.coin") }}</label>
              <v-select-input
                class="mb-0"
                :classes="$get(item, 'errors.coin') ? 'is-invalid' : ''"
                v-model="item.coin"
                :options="coinsOptions"
                :clearable="false"
                placeholder="coin"
                :loading="isLoadingCoins"
                :disabled="isLoadingCoins"
                @input="toListCoin($event, item)"
              >
                <template #no-options>
                  {{ $t("general.emptySearch") }}
                </template>
                <template v-slot:option="slotScope">
                  <div
                    :style="{color: slotScope.option.isWatchList ? slotScope.option.color : null}"
                    class="select_options"
                  >
                    {{ slotScope.option.label }}
                    <div
                      class="circle"
                      v-if="slotScope.option.color"
                      :style="{ color: slotScope.option.color }"
                    >
                      ●
                    </div>
                  </div>
                </template>
              </v-select-input>
              <div class="invalid-feedback" v-if="$get(item, 'errors.coin')">
                {{ item.errors.coin }}
              </div>
            </div>
          </CCol>
          <CCol col="2" md="1">
            <CButton
              class="indent-btn"
              color="danger"
              @click="coins.splice(i, 1)"
            >
              <CIcon name="cil-x" />
            </CButton>
          </CCol>
          <CCol
            col="12"
            md="5"
            class="
              indent-switch
              d-flex
              flex-md-column
              align-items-center align-items-md-start
              mt-3 mt-md-2
            "
          >
            <label class="d-block mb-0 mr-3 mb-md-2">{{
              $t("lineRunner.setupParams")
            }}</label>
            <CSwitch
              color="primary"
              shape="pill"
              :checked.sync="item.customParams"
              @update:checked="setCustomParams(item)"
              class="flex-shrink-0"
            />
          </CCol>
        </CRow>
      </CCol>

      <CCol col="12">
        <p
          v-if="!item.params.length"
          :class="{ 'text-danger': item.errors.params }"
        >
          {{ $t("lineRunner.emptyParams") }}
        </p>
        <CRow v-if="item.customParams" class="mb-3">
          <CCol
            col="12"
            md="6"
            lg="4"
            v-for="(param, index) in item.params"
            :key="index"
            class="param"
          >
            <CRow>
              <CCol col="4">
                <CSelect
                  size="sm"
                  class="mb-0"
                  :value.sync="param.tf"
                  label="TF"
                  placeholder="TF"
                  :options="tfOptions"
                  :is-valid="$get(param, 'errors.tf') ? false : null"
                  :invalid-feedback="$get(param, 'errors.tf')"
                  @change="param.validateTf()"
                />
              </CCol>
              <CCol col="6" md="5">
                <CSelect
                  size="sm"
                  class="mb-0"
                  :value.sync="param.label"
                  :label="$t('lineRunner.param')"
                  placeholder="param"
                  :options="paramOptions"
                  :is-valid="$get(param, 'errors.label') ? false : null"
                  :invalid-feedback="$get(param, 'errors.label')"
                  @change="param.validateLabel()"
                >
                  <template #no-options>
                    {{ $t("general.emptySearch") }}
                  </template>
                </CSelect>
              </CCol>
              <CCol col="2" md="3">
                <CButton
                  class="indent-btn-sm"
                  color="danger"
                  size="sm"
                  @click="item.params.splice(index, 1)"
                >
                  <CIcon name="cil-x" />
                </CButton>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" md="6" lg="4" v-if="item.params.length < 3">
            <CButton
              :class="{ 'indent-btn-sm': item.params.length }"
              color="primary"
              size="sm"
              @click="item.params.push(new Param())"
            >
              <CIcon name="cil-plus" />
              {{ $t("lineRunner.addParam") }}
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <div class="controls">
      <CButton
        color="primary"
        class="mr-3"
        @click="coins.push(new Coin({ params: generalParams }))"
      >
        <CIcon name="cil-plus" />
        {{ $t("lineRunner.addCoin") }}
      </CButton>

      <CLoadingButtonCustom
        color="success"
        :class="{'--disabled': !hasAccess}"
        :loading="loadingRequest"
        v-tooltip="toolTipContent"
        :disabled="loadingRequest || isGuest"
        @click.native="save"
      >
        <CIcon name="cil-save" />
        {{ $t("lineRunner.save") }}
      </CLoadingButtonCustom>
    </div>
  </div>
</template>

<script>
import Coin from "@/assets/js/lineRunner/Coin.class";
import Param from "@/assets/js/lineRunner/Param.class";
import cloneDeep from "lodash/cloneDeep";
import VSelectInput from "@/components/VSelectInput";
import {mapGetters} from "vuex";
import axios from "axios";
export default {
  name: "LineRunnerSetup",
  components: {
    VSelectInput,
  },
  data() {
    return {
      Coin,
      Param,
      coins: [],
      paramOptions: [
        { label: "RSI", value: "rsi14" },
        { label: "RSI color", value: "color_rsi14" },
        { label: "RSI change", value: "rsi14_pct" },
        { label: "Volatility", value: "volatility" },
        { label: "MA Trend", value: "trend_ma200" },
        { label: "Volume", value: "tf_quote_volume_str" },
        { label: "Volume change", value: "tf_quote_volume_pct" },
        { label: "Price change", value: "close_pct" },
        { label: "Delta", value: "tf_quote_volume_delta_str" },
        { label: "CD Week", value: "tf1w_quote_volume_delta_str" },
      ],
      setColor: {"danger":"#e55353","success":"#4DBD74","watch-list-primary":"#4A90E2","warning":"#e1a82d"},
      generalParams: [],
      loadingRequest: false,
      lastItem: null,
      isLoadingCoins: false,
      coinsList: [],
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    toolTipContent() {
      if (!this.hasAccess) {
        return {content: this.$t('lineRunner.notallowedTooltip')}
      }

      return {content: false}
    },
    user(){
      return this.$store.state.user.userData
    },
    hasAccess(){
      return this.user.getAccess(1)
    },
    coinsOptions() {
      const list = this.coinsList;
      const coinsIdArr = this.coins.reduce((acc, i) => {
        if (i.coin?.id) acc.push(i.coin.id);
        return acc;
      }, []);

      const filterArr = list.reduce((acc, listItem) => {
        if (!coinsIdArr.includes(listItem.coin.id)) {
          acc.push({
            id: listItem.coin.id,
            label: listItem.coin.label,
          });
        }
        return acc;
      }, []);

      filterArr.sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
      this.getWatchList.forEach((list) => {
        if(list.pairs.length ? list.pairsString ? !!list.pairsString.trim().length : false : false ) {
           filterArr.unshift({
              isWatchList: true,
              label: list.name,
              color: list.color ? this.setColor[list.color] : list.customColor,
            });
        }
        list.pairs.forEach((pair) => {
          let find = filterArr.find((el) => el.label == pair.label);
          if (find) {
            find["color"] = list.color
              ? this.setColor[list.color]
              : list.customColor;
          }
        });
      });
      filterArr.map((el, idx) => el['idx'] = idx)

      return filterArr;
    },
    tfOptions() {
      return this.$store.getters["lineRunner/tfOptions"];
    },
    getWatchList() {
      return JSON.parse(JSON.stringify(this.$store.state.user.userData.createdWatchlists)).sort(
        (a, b) => +(a.name < b.name) || -(a.name > b.name)
      );
    },
    getSettings() {
      return this.$store.getters['lineRunner/selectLineRunner']
    }
  },
  mounted() {
    this.initData();
    this.getCoinsList()
  },
  methods: {
    initData() {
      const generalParamsClone = cloneDeep(
        this.$store.state.lineRunner.generalParams
      );
      this.generalParams = generalParamsClone.map((i) => new Param(i));

      const coinsClone = cloneDeep(this.$store.state.lineRunner.select);
      this.coins = coinsClone.map((i) => new Coin(i));
    },
    save() {
      if(this.isGuest){
        return
      }
      if(!this.hasAccess) return
      this.setGeneralParams();
      let successValidateGeneral = true;
      this.generalParams.forEach((param) => {
        if (!param.validate()) {
          successValidateGeneral = false;
        }
      });

      let successValidateCoins = true;
      this.coins.forEach((coin) => {
        if (!coin.validate()) {
          successValidateCoins = false;
        }
      });

      if (!successValidateGeneral || !successValidateCoins) {
        this.$toastr.error(this.$t("lineRunner.notValidate"));
      } else {
        const dataToServer = this.coins.map((i) => i.toSave());

        const arrSelect = cloneDeep(dataToServer);
        this.$store.dispatch("lineRunner/setSelect", arrSelect);
        this.$store.commit("SET_KEY", {
          namespace: "lineRunner",
          key: "select",
          value: arrSelect,
        });

        const arrGeneralParams = cloneDeep(this.generalParams);
        this.$store.dispatch("lineRunner/setGeneralParams", arrGeneralParams);
        this.$store.commit("SET_KEY", {
          namespace: "lineRunner",
          key: "generalParams",
          value: arrGeneralParams,
        });

        this.send();
      }
    },
    send() {
      this.loadingRequest = true;
      this.$store
        .dispatch("saveRequest", "lineRunner")
        .then(({ status }) => {
          if (status < 300) {
            this.$toastr.success(this.$t("general.dataSaved"));
          }
        })
        .finally(() => {
          this.loadingRequest = false;
        });
    },
    toListCoin(e, item) {
      if(e.isWatchList && this.lastItem != e.label) {
        this.lastItem = e.label
        if(this.getWatchList.some(el => el.name == e.label)) {
          this.coins.splice(this.coins.findIndex(el => el.coin.label == this.lastItem), 1)
        }
        this.getWatchList.find(el => el.name == e.label)?.pairs.forEach(el => {
          if(!this.coins.find(coin => coin.coin.label == el.label)) {
            this.coins.push(new Coin({ params: this.generalParams, coin: el}))
          }
        })
        item.validateCoin()
      } else {
        item.validateCoin()
      }
    },
    setGeneralParams() {
      this.coins.forEach((coin) => {
        if (!coin.customParams) {
          coin.params = cloneDeep(this.generalParams);
        }
      });
    },
    setCustomParams(item) {
      item.params = cloneDeep(this.generalParams);
    },
    toggleLineRunner(val) {
      if(!this.hasAccess) return
      this.$store.commit("lineRunner/SET_SHOW_LINE_RUNNER", val);
      this.$store.commit("SET_KEY", {
        namespace: "lineRunner",
        key: "showLineRunner",
        value: val,
      });
      this.send();
    },
    getCoinsList() {
      if (this.isLoadingCoins) return

      this.isLoadingCoins = true

      axios.get('/api/v2/screener/tables')
        .then(res => {
          if(Array.isArray(res?.data?.data)) {
            this.coinsList = res.data.data
          }
        })
        .finally(() => {
          this.isLoadingCoins = false
        })
    }
  },
  watch: {
    generalParams: {
      deep: true,
      handler: function () {
        this.setGeneralParams();
      },
    },
    getSettings: {
      deep: true,
      handler: function() {
        this.initData()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.--disabled{
  opacity: .65;
  cursor: not-allowed !important;
}
.item {
  border-bottom: 1px solid var(--dark);
  margin-bottom: 1rem;
}
.param {
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    border-right: 1px solid var(--dark);
    &:last-child,
    &:nth-child(2) {
      border: none;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;

    &:nth-child(2) {
      border-right: 1px solid var(--dark);
    }
  }
}
.indent-btn {
  margin-top: 1.7rem;
}
.indent-btn-sm {
  margin-top: 2.1rem;
}

.select_options {
  display: flex;
  align-items: center;
}

.select_options .circle {
  margin: 0;
  margin-left: 3px;
  margin-bottom: 4px;
  line-height: 15px;
  font-size: 36px;
}
</style>
