export class EnableDataClass {
  constructor(init = {}) {
    try {
      init = init?.toJSON()
      // eslint-disable-next-line no-empty
    } catch (e) {}

    this.endpoint = init?.endpoint
    this.key = init?.keys?.p256dh
    this.token = init?.keys?.auth
    this.expirationTime = init?.expirationTime
  }
}
