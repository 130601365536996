<template>
  <div class="mt-3">
    <h3 class="title">
      MA
    </h3>
    <p>{{ $t('maSettings.description') }}</p>
<!--      <span
      class="rounded-circle bg-second-card-bg popover-wrap d-inline-flex justify-content-center"
      v-c-tooltip="{
        header: 'ma',
        content: 'tooltip',
        appendToBody: true,
        popperOptions: {
          strategy: 'fixed'
        }
      }"
    >
      ?
    </span>-->

    <CRow>
      <CCol col="12" sm="6">
        <CInput
          class="ma-input"
          v-tooltip="toolTipContent"
          :disabled="notAllowedChangeMa"
          :value="low"
          :label="$t('maSettings.low')"
          :invalid-feedback="errors.low"
          :is-valid="detectErrorField(errors.low)"
          @change="changeLow"
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          class="ma-input"
          v-tooltip="toolTipContent"
          :disabled="notAllowedChangeMa"
          :value="high"
          :label="$t('maSettings.high')"
          :invalid-feedback="errors.high"
          :is-valid="detectErrorField(errors.high)"
          @change="changeHigh"
        />
      </CCol>
    </CRow>
    <div class="ma-display-types">
      <CInputCheckbox
          @change="handleCheckbox('absolute', $event.srcElement.checked)"
          :checked="maDisplayValue.includes('absolute')"
          :label="$t('maSettings.maDisplayType1')"
        />
      <CInputCheckbox
        @change="handleCheckbox('percent', $event.srcElement.checked)"
        :checked="maDisplayValue.includes('percent')"
        :label="$t('maSettings.maDisplayType2')"
      />
    </div>
    <div class="ma-type-wrapper">
      <span class="ma-type-wrapper__title">
        {{$t('maSettings.maTypeTitle')}}:
      </span>
      <div class="ma-type-wrapper__types">
        <VRadioButton
        value="ma_price"
        :current-value="maType"
        name="maType"
        @change="radioChangeHandler">
        <div class="d-flex">
          <div class="ma-type-wrapper__item_img select">
            <img src="@/assets/img/maToPrice.png" alt="Ma to Price"/>
          </div>
          <div class="ma-type-wrapper__item_img">
            <img src="@/assets/img/maToPriceDesc.png" alt="Ma to Price screen"/>
          </div>
        </div>
            <span>
              {{$t('maSettings.maType1')}}
            </span>
        </VRadioButton>
        <VRadioButton
        value="price_ma"
        :current-value="maType"
        name="maType"
        @change="radioChangeHandler">
          <div class="d-flex">
            <div class="ma-type-wrapper__item_img select">
              <img src="@/assets/img/priceToMa.png" alt="Price to MA"/>
            </div>
            <div class="ma-type-wrapper__item_img">
              <img src="@/assets/img/priceToMaDesc.png" alt="Price to MA screen"/>
            </div>
          </div>
          <span>
            {{$t('maSettings.maType2')}}
          </span>
          </VRadioButton>
      </div>

    </div>

    <CLoadingButtonCustom
      color="success"
      :loading="loadingRequest"
      :disabled="loadingRequest"
      @click.native="save"
    >
      {{$t('general.save')}}
    </CLoadingButtonCustom>
  </div>
</template>

<script>
import { detectErrorField } from '@/assets/js/axios/responseErrorHandler';
import VRadioButton from '@/components/VRadioButton';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MaSettings",
  components: { VRadioButton },
  data () {
    return {
      detectErrorField,
      high: this.$store.state.settings?.ma?.high,
      low: this.$store.state.settings?.ma?.low,
      maType: this.$store.state.settings?.ma_price_dependence?.value || 'ma_price',
      maDisplayValue: this.$store.state.settings?.ma_price_dependence?.display_value || ['absolute', 'percent'],
      loadingRequest: false,
      errors: {
        high: null,
        low: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    toolTipContent() {
      if (this.notAllowedChangeMa) {
        return {content: this.$t('maSettings.notallowedTooltip')}
      }

      return {content: false}
    },
    user(){
      return this.$store.state.user.userData
    },
    ...mapGetters({
      tariff: 'user/tariff',
    }),
    notAllowedChangeMa(){
      return !this.user.getAccess(1)
    }
  },
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    radioChangeHandler(val){
      this.maType = val
    },
    handleCheckbox(val, checked){
      if(checked){
        this.maDisplayValue.push(val)
      }else{
        this.maDisplayValue = this.maDisplayValue.filter(item => item !== val)
      }
    },
    save () {
      if(this.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      if(!this.maDisplayValue.length){
        this.$toastr.error(this.$t('maSettings.maDisplayTypeError'))
        return
      }
      if (Object.values(this.errors).some(val => {
        if (val) this.$toastr.error(this.$t('general.notValidate'))
        return val
      })) return
      if(!this.notAllowedChangeMa){
        this.$store.commit('SET_KEY', {
        namespace: 'ma',
        key: 'low',
        value: Number(this.low)
        })
        this.$store.commit('SET_KEY', {
          namespace: 'ma',
          key: 'high',
          value: Number(this.high)
        })
      }


      this.$store.commit('SET_KEY', {
        namespace: 'ma_price_dependence',
        key: 'value',
        value: this.maType
      })

      this.$store.commit('SET_KEY', {
        namespace: 'ma_price_dependence',
        key: 'display_value',
        value: this.maDisplayValue
      })

      this.loadingRequest = true
      this.$store.dispatch('saveRequest', ['ma', 'ma_price_dependence'])
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('general.dataSaved'))
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    },
    changeLow (val) {
      this.low = Number(val)
      this.errors.low = this.validation(this.low)
      this.errors.high = this.validation(this.high)
    },
    changeHigh (val) {
      this.high = Number(val)
      this.errors.low = this.validation(this.low)
      this.errors.high = this.validation(this.high)
    },
    validation (val) {
      if (!val && val !== 0) {
        return this.$t('general.requiredField')
      }
      if (val < 5 || val > 365) {
        return this.$t('maSettings.validateRange')
      }
      if (this.low > this.high) {
        return this.$t('maSettings.validateOrder')
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.ma-input{
  /deep/.form-control:disabled{
    opacity: .65;
    cursor: not-allowed;
  }
}
.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
}
.ma-display-types{
  display: flex;
  flex-wrap: wrap;
  column-gap: 36px;
  margin-bottom: 25px;
  margin-top: 5px;
  &:deep(.form-check){
    & *{
      cursor: pointer;
    }

  }
}
.ma-type-wrapper{
  margin-bottom: 3.7rem;
  &:deep(.radio-button__label){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &:deep(.radio-button){
    align-items: center;
  }
  &:deep(.radio-button__checkmark){
    margin-bottom: 28px;
  }
  &:deep(._checked){
    .radio-button__label{
      .ma-type-wrapper__item_img.select{
        border-color: #3686f6;
      }
    }
  }
  &__title{
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 0.7rem;
    display: inline-block;
  }
  &__types{
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    @include media-breakpoint-up(md) {
      column-gap: 3rem;
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      column-gap: 10rem;
    }
    @include media-breakpoint-up(xl) {
      column-gap: 19rem;
    }
  }
  &__item{
    display: flex;
    align-items: center;
    &._active{
      .form-check-label{
        .ma-type-wrapper__item_img.select{
          border-color: #3686f6;
        }
      }
    }
    & *{
      cursor: pointer;
    }
    .form-check-input{
      position: initial;
      margin-left: 0;
      margin-top: -1.62rem;
    }
    .form-check-label{
      display: flex;
      flex-direction: column;
      align-items: center;
      & span{
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
    &_img{
        border: 0.3rem solid transparent;
        border-radius: 23px;
        transition: 0.2s;
        max-width: 17.5rem;
        height: 9rem;
        margin-bottom: 7px;
        overflow: hidden;
        & img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  }
}

</style>
