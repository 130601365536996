import get from "lodash/get";
import PairItem from "@/assets/js/screenerSettings/PairItem.class";
import generateId from "@/assets/js/generateId";

export default class WatchListsItem {
  constructor(init = {}) {
    this.isWatchList = true
    this.color = init?.color || null
    this.customColor = init?.customColor || null
    this.selectId = generateId()
    this.id = init?.id || null
    this.name = init?.name || 'List'
    this.pairs = get(init, 'pairs') ? init.pairs.map(a => new PairItem({
      ...a,
      label: a.symbol || a.label
    })) : []
    this.pairsString = init?.pairsString || ''
  }
}
