var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "div",
      { staticClass: "input-group" },
      [
        _vm.$slots.prepend
          ? _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "div",
                { staticClass: "input-group-text" },
                [_vm._t("prepend")],
                2
              ),
            ])
          : _vm._e(),
        _c(
          "v-select",
          _vm._g(
            _vm._b(
              {
                ref: "vselect",
                staticClass: "v-select-input form-control",
                class: _vm.classes,
                attrs: { options: _vm.options },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-options",
                      fn: function () {
                        return [_vm._t("no-options")]
                      },
                      proxy: true,
                    },
                    {
                      key: "option",
                      fn: function (slotScope) {
                        return [_vm._t("option", null, { option: slotScope })]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (slotScope) {
                        return [
                          _vm._t("selected-option", null, {
                            option: slotScope,
                          }),
                        ]
                      },
                    },
                    {
                      key: "selected-option-container",
                      fn: function (slotScope) {
                        return [
                          _vm._t("selected-option-container", null, {
                            option: slotScope,
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectValue,
                  callback: function ($$v) {
                    _vm.selectValue = $$v
                  },
                  expression: "selectValue",
                },
              },
              "v-select",
              _vm.filteredAttrs,
              false
            ),
            _vm.$listeners
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }