import get from "lodash/get";

export default class Tariff {
  constructor(init = {}) {
    this.id = get(init, 'id', null)
    this.name = get(init, 'name', '')
    this.color = get(init, 'color', '')
    this.access = get(init, 'access', [])
  }
}
