import { get, intersectionBy } from 'lodash'
import { DataItem } from "@/assets/js/screenerTrendClasses/DataItem.class"
import screenerSettings from "@/assets/js/screenerSettings"
import store from "@/store";

function randomData () {
  return [...Array(20).keys()].map(i => {
    return new DataItem({coin: {pair: `BTCUSD${i}`, id: i}}, true)
  })
}

export default {
  _data: [],
  _sortCol: null,
  _sortType: null,

  get sortCol () {
    return this._sortCol
  },
  set sortCol (val) {
    this._sortCol = val
    store.dispatch("setKey", {
      namespace: 'trendsView',
      key: 'sortCol',
      value: val
    })
  },
  get sortType () {
    return this._sortType
  },
  set sortType (val) {
    this._sortType = val
    store.dispatch("setKey", {
      namespace: 'trendsView',
      key: 'sortType',
      value: val
    })
  },
  get data () {
    return intersectionBy(this.sortedData, this.filteredData, 'id')
  },
  set data (val) {
    if (val === 'random') {
      this._data = randomData()
    } else if (val && Array.isArray(val)) {
      this._data = val.map(a => new DataItem(a))
    } else {
      this._data = []
    }
  },
  get sortedData () {
    if (this.sortCol && this.sortType) {
      const arr = [].concat(this._data)

      let more = null
      let less = null
      switch (this.sortType) {
        case 'asd':
          more = 1
          less = -1
          break
        case 'desc':
        default:
          more = -1
          less = 1
          break
      }
      if (this.sortCol === 'coin.label') {
        more *= -1
        less *= -1
      }

      arr.sort((a, b) => {
        const {valA, valB} = convertSort(a, b, this.sortCol)

        if (valA === valB) return 0

        return valA < valB ? less : more
      })

      return arr
    } else {
      return this._data
    }
  },
  get filteredData () {
    if (screenerSettings.selectedPairs.length) {
      return this._data.filter(item => {
        const coinLabel = get(item, 'coin.label', null)
        if (coinLabel) {
          return screenerSettings.selectedPairs
            .map(coin => coin.label)
            .includes(coinLabel)
        } else {
          return false
        }
      })
    } else {
      return this._data
    }
  },
  get coinList () {
    return this._data.map(item => item.coin)
  },
  get allTfPositiveItems () {
    return this.data.filter(item => item.all_tf === 1)
  },
  get allTfNegativeItems () {
    return this.data.filter(item => item.all_tf === -1)
  },
  get bounceItems () {
    return this.data.filter(item => item.bounce === -1 || item.bounce === 1)
  }
}


function convertSort(a, b, colName) {
  let valA = null
  let valB = null

  const value = get(a, `${colName}`, null)
  if (value || value === 0) {
    valA = value
    valB = get(b, `${colName}`, null)
  }

  return {
    valA,
    valB
  }
}
