import Vue from 'vue'
import Router from 'vue-router'
import beforeEnterPermission from "@/assets/js/routerFunctions/beforeEnterPermission"
import beforeEnterHandler from "@/assets/js/routerFunctions/beforeEnterHandler"
import i18n from "@/i18n";
import store from '@/store.js';
import saveRefHandler from '@/assets/js/routerFunctions/saveRefHandler';

const PageNotFound = () => import('@/pages/PageNotFound')
const PagePermissionDenied = () => import('@/pages/PagePermissionDenied')
const PageTechnicalWorks = () => import('@/pages/PageTechnicalWorks')

const LayoutLk = () => import('@/layouts/Lk')
const PageAccount = () => import('@/pages/lk/Account')
const PageSettings = () => import('@/pages/lk/Settings')
const Notifications = () => import('@/pages/lk/Notifications')
const PagePermission = () => import('@/pages/lk/Permission')
const PageLkSubscribe = () => import('@/pages/lk/Subscribe')
const TrainingDetail = () => import('@/pages/lk/TrainingDetail')
const PageSubscribeConstructor = () => import('@/pages/lk/SubscribeConstructor')
const PageListTraining = () => import("@/pages/lk/PageListTraining")
const PageListEducation = () => import("@/pages/lk/PageListEducation")
const PageTraining = () => import("@/pages/lk/PageTraining")
const PageAuthorsList = () => import("@/pages/lk/PageAuthorsList")
const PageAuthorDetail = () => import("@/pages/lk/PageAuthorDetail")
const PageAuthorStore = () => import("@/pages/lk/PageAuthorStore")
const PageCurator = () => import("@/pages/lk/PageCurator")
const PageListCurator = () => import("@/pages/lk/PageListCurator")
const PageVideoEncrypt = () => import("@/pages/lk/PageVideoEncrypt")
const PageVideoPublic = () => import("@/pages/lk/PageVideoPublic")
const PageViewTraining = () => import("@/pages/PageViewTraining")
const PageViewMarketplace = () => import("@/pages/marketplace/PageViewMarketplace")
const ExpiredHash = () =>  import("@/pages/auth/ExpiredHash");
const PageCoinList = () => import('@/pages/lk/CoinList/PageCoinList')
const TrendsCoinList = () => import('@/pages/lk/CoinList/TrendsCoinList')
const ScreenerCoinList = () => import('@/pages/lk/CoinList/ScreenerCoinList')
const PageInviteCode = () => import('@/pages/lk/InviteCode')
const PagePayments = () => import('@/pages/lk/Payments')
const Transactions = () => import('@/pages/lk/Transactions')
const PageAnalytics = () => import('@/pages/lk/Analytics')
const PageWhitelist = () => import('@/pages/lk/Whitelist')
const PageLkReferralsLayout = () => import('@/layouts/PageLkReferralsLayout')
const PageLkReferralsList = () => import('@/pages/lk/PageLkReferralsList')
const PageLkReferrals = () => import('@/pages/lk/Referrals')
const PageLkAdminBonusTransfer = () => import('@/pages/lk/AdminBonusTransfer')
const TgNotifications = () => import('@/pages/lk/TgNotifications')
const AddTrends = () => import('@/pages/lk/AddTrends')

const LayoutGraph = () => import('@/layouts/Graph')
const PageScreener = () => import('@/pages/graph/Screener')
const PageDelta = () => import('@/pages/graph/Delta')
const PageTrends = () => import('@/pages/graph/Trends.vue')
const PageTablesView = () => import('@/pages/graph/screener/TablesView')
const PageGraphsView = () => import('@/pages/graph/screener/GraphsView')
const PageTrendsView = () => import('@/pages/graph/screener/TrendsView.vue')
const PageTreemap = () => import('@/pages/graph/screener/Treemap')

const TrainingLayout = () => import('@/layouts/trainingLayout')

const LayoutAuth = () => import('@/layouts/Auth')
const PageSignin = () => import('@/pages/auth/Signin')
const PageRegister = () => import('@/pages/auth/Register')
const PagePasswordRecovery = () => import('@/pages/auth/Recovery')
const PagePasswordReset = () => import('@/pages/auth/Reset')
const PageSubscribe = () => import('@/pages/auth/Subscribe')
const PageVerifyEmail = () => import('@/pages/auth/VerifyEmail')

const UserActivity = () => import('@/pages/lk/UserActivity')

const LayoutGeneral = () => import('@/layouts/General')
const PageAboutUs = () => import('@/pages/static/AboutUs')

export const documentTitleDefault = 'Cryptovizor'

const forceRoute = process.env.VUE_APP_FORCE_ROUTE

const forceRouteComponent = () => {
  switch (forceRoute) {
    case 'technical_works':
      return PageTechnicalWorks
    default:
      return PageNotFound
  }
}

const routerMatch = Router.prototype.match
Router.prototype.match = function match(raw, current, redirectedFrom) {
  const to = routerMatch.call(this, raw, current, redirectedFrom)
  if(!store.state.initLocale){
    return to
  }
  const rLang = routeLang(to)
  if(rLang && rLang !== i18n.locale){
    const newRaw = {
      name: to.name,
      params: to.params,
      query: to.query,
      hash: to.hash
    }
    newRaw.params.lang = i18n.locale === 'ru' ? 'ru' : undefined
    if(typeof raw === 'object' && 'replace' in raw){
      newRaw.replace = raw.replace
    }
    return routerMatch.call(this, newRaw, current, redirectedFrom)
  }
  return to
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: forceRoute ?
    [{
      path: '*',
      component: forceRouteComponent()
    }] : [
      {
        path: '/:lang(ru)?/auth',
        redirect: '/:lang?/auth/signin',
        name: 'auth',
        component: LayoutAuth,
        beforeEnter: saveRefHandler,
        children: [
          {
            path: 'signin',
            name: 'signin',
            component: PageSignin
          },
          {
            path: 'register',
            name: 'register',
            component: PageRegister
          },
          {
            path: 'ref/:code',
            name: 'referral-link',
            beforeEnter: (to, from, next) => {
              next({
                name: 'register',
                query: {
                  referral: to.params.code
                }
              })
            },
          },
          {
            path: 'password-recovery',
            name: 'password-recovery',
            component: PagePasswordRecovery
          },
          {
            path: 'password-recovery/reset',
            name: 'password-reset',
            component: PagePasswordReset
          },
          {
            path: 'expired-hash',
            name: 'expired-hash',
            component: ExpiredHash,
          },
          {
            path: 'subscribe',
            name: 'subscribe',
            component: PageSubscribe
          },
          {
            path: 'verify-email',
            name: 'verify-email',
            component: PageVerifyEmail
          }
        ]
      },
      {
        path: '/:lang(ru)?/marketplace',
        component: LayoutGraph,
        beforeEnter: beforeEnterHandler,
        meta: {
          isAccessAny: true,
        },
        children: [
          {
            path: '/',
            name: 'PageViewMarketplace',
            component: PageViewMarketplace,
            meta: {
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('marketplace.title'),
              isAccessAny: true,
            },
          }
        ]
      },
      {
        path: '/:lang(ru)?',
        redirect: '/:lang?/delta',
        name: 'graph',
        component: LayoutGraph,
        beforeEnter: beforeEnterHandler,
        children: [
          {
            path: ':exchange-:screen(spot|futures)/screener',
            name: 'screener',
            redirect: ':exchange-:screen/screener/tablesview',
            component: PageScreener,
            meta: {
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.screener'),
            },
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('view_screener', to, from, next)
            },
            children: [
              {
                path: 'tablesview',
                name: 'tablesview',
                component: PageTablesView,
                meta: {
                  isAccessAny: true,
                }
              },
              {
                path: 'graphsview',
                name: 'graphsview',
                component: PageGraphsView,
                meta: {
                  isAccessAny: true,
                }
              },
              {
                path: 'trendsview',
                name: 'trendsview',
                component: PageTrendsView,
                meta: {
                  isAccessAny: true,
                }
              },
              {
                path: 'treemap',
                name: 'treemap',
                component: PageTreemap,
                beforeEnter: (to, from, next) => {
                  beforeEnterPermission('view_heat_map', to, from, next)
                },
                meta: {
                  isAccessAny: true,
                }
              }
            ]
          },
          {
            path: 'delta',
            name: 'delta',
            component: PageDelta,
            meta: {
              isAccessAny: true,
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.delta')
            },
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('view_delta', to, from, next)
            }
          },
          {
            path: ':exchange-:screen(spot|futures)/trends',
            name: 'trends',
            component: PageTrends,
            meta: {
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.trends')
            },
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('view_trends', to, from, next)
            }
          }
        ]
      },
      {
        path: '/lk',
        redirect: '/lk/account',
        name: 'lk',
        component: LayoutLk,
        meta: {
          defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.account')
        },
        beforeEnter: beforeEnterHandler,
        children: [
          {
            path: 'account',
            name: 'account',
            component: PageAccount,
            meta: {
              isAccessAny: true
            }

          },
          {
            path: 'settings',
            name: 'settings',
            component: PageSettings,
            meta: {
              isAccessAny: true
            }

          },
          {
            path: 'notifications',
            name: 'notifications',
            component: Notifications,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('view_notifications', to, from, next)
            }
          },
          {
            path: 'permission',
            name: 'permission',
            component: PagePermission,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'user-activity',
            name: 'user-activity',
            component: UserActivity,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'subscribe',
            name: 'lk-subscribe',
            component: PageLkSubscribe,
            meta: {
              isAccessAny: true,
            }
          },
          {
            path: 'subscribe/:slug',
            name: 'training-detail',
            component: TrainingDetail,
            meta: {
              isAccessAny: true
            },
          },
          {
            path: 'subscribe-constructor',
            name: 'subscribe-constructor',
            component: PageSubscribeConstructor,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_tariffs', to, from, next)
            }
          },
          {
            path: 'training',
            name: 'training',
            component: PageListTraining,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control-education', to, from, next)
            },
          },
          {
            path: 'training/:id',
            name: 'item-training',
            component: PageTraining,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control-education', to, from, next)
            }
          },
          {
            path: 'curator',
            name: 'curators',
            component: PageListCurator,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('show_curator', to, from, next)
            }
          },
          {
            path: 'curator/:slug',
            name: 'curator',
            component: PageCurator,
            props: (route) => ({ eventId: route.params.id ? Number(route.params.id) : null, slug: route.params.slug }),
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('show_curator', to, from, next)
            }
          },
          {
            path: 'video-encrypt',
            name: 'video-encrypt',
            component: PageVideoEncrypt,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_tariffs', to, from, next)
            }
          },
          {
            path: 'video-public',
            name: 'video-public',
            component: PageVideoPublic,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_tariffs', to, from, next)
            }
          },
          {
            path: 'coin-list',
            name: 'coin-list',
            component: PageCoinList,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_coins', to, from, next)
            },
            redirect: {name: 'screener-list'},
            children: [
              {
                path: 'screener-list',
                name: 'screener-list',
                component: ScreenerCoinList,
                beforeEnter: (to, from, next) => {
                  beforeEnterPermission('control_coins', to, from, next)
                },
              },
              {
                path: 'trends-coins',
                name: 'trends-coins',
                component: TrendsCoinList,
                beforeEnter: (to, from, next) => {
                  beforeEnterPermission('control_coins', to, from, next)
                },
              }
            ],
          },
          {
            path: 'invite-code',
            name: 'invite-code',
            component: PageInviteCode,
            beforeEnter: (to, from, next) => {
              next(false)
              // beforeEnterPermission('control_invite_codes', to, from, next)
            }
          },
          {
            path: 'payments',
            name: 'payments',
            component: PagePayments
          },
          {
            path: 'transactions',
            name: 'transactions',
            component: Transactions,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('update-transactions', to, from, next)
            }
          },
          {
            path: 'analytics',
            name: 'analytics',
            component: PageAnalytics,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('show_analytics', to, from, next)
            }
          },
          {
            path: 'whitelist',
            name: 'whitelist',
            component: PageWhitelist,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('show_whitelist', to, from, next)
            }
          },
          {
            path: 'referrals',
            name: 'lk-referrals',
            component: PageLkReferralsLayout,
            redirect: {name: 'mine-referrals'},
            children: [
              {
                path: 'mine',
                name: 'mine-referrals',
                component: PageLkReferrals,
                meta: {
                  isAccessAny: true
                }
              },
              {
                path: 'transfer',
                name: 'lk-admin-bonus-transfer',
                component: PageLkAdminBonusTransfer,
                beforeEnter: (to, from, next) => {
                  beforeEnterPermission('view-page-admin-bonus-transfer', to, from, next)
                }
              },
              {
                path: 'list',
                name: 'lk-admin-referrals-list',
                component: PageLkReferralsList,
                beforeEnter: (to, from, next) => {
                  beforeEnterPermission('view-page-admin-bonus-transfer', to, from, next)
                }
              }
            ]
          },
          {
            path: 'tg-notifications',
            name: 'tg_notifications',
            component: TgNotifications,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'add-trends',
            name: 'add_trends',
            component: AddTrends,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'study',
            name: 'lk-study',
            component: PageListEducation,
            meta: {
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.education')
            },
            beforeEnter: (to, from, next) => {
              if(store.getters['user/getPermissions']('control-education') || store.getters['user/getPermissions']('show_curator')){
                next()
              } else {
                beforeEnterPermission('view-education', to, from, next)
              }
            },
          },
          {
            path: 'curators/:curator_id/author',
            name: 'edit-curator-author',
            component: PageAuthorStore,
            beforeEnter: (to, from, next) => {
              if(store.getters['user/getPermissions']('control-education') || store.getters['user/getPermissions']('show_curator')){
                next()
              } else {
                beforeEnterPermission('control_users', to, from, next)
              }
            }
          },
          {
            path: 'authors',
            name: 'authors',
            component: PageAuthorsList,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'authors/create',
            name: 'create-author',
            component: PageAuthorStore,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'authors/:id/edit',
            name: 'edit-author',
            component: PageAuthorStore,
            beforeEnter: (to, from, next) => {
              beforeEnterPermission('control_users', to, from, next)
            }
          },
          {
            path: 'authors/:id',
            name: 'item-author',
            component: PageAuthorDetail,
            meta: {
              isAccessAny: true
            },
          },
          {
            path: 'author',
            name: 'self-author',
            component: PageAuthorDetail,
            beforeEnter: (to, from, next) => {
              if(store.getters['user/authorId']){
                to.params.id = store.getters['user/authorId']
                to.params.selfUpdate = '1'
                next()
              }else{
                next(store.getters["user/homepage"])
              }
            }
          },
          {
            path: 'author/edit',
            name: 'edit-self-author',
            component: PageAuthorStore,
            beforeEnter: (to, from, next) => {
              if(store.getters['user/authorId']){
                to.params.id = store.getters['user/authorId']
                to.params.selfUpdate = '1'
                next()
              }else{
                next(store.getters["user/homepage"])
              }
            }
          },
        ]
      },
      {
        path: '/lk/study',
        name: 'study',
        component: TrainingLayout,
        beforeEnter: beforeEnterHandler,
        children: [
          {
            path: ':slug',
            name: 'PageViewTraining',
            component: PageViewTraining,
          }
        ]
      },
      {
        path: '/:lang(ru)?/general',
        redirect: '/:lang?/general/about-us',
        name: 'general',
        component: LayoutGeneral,
        beforeEnter: beforeEnterHandler,
        children: [
          {
            path: 'about-us',
            name: 'about-us',
            component: PageAboutUs,
            meta: {
              isAccessAny: true,
              defaultTitle: () => documentTitleDefault + ' - ' + i18n.t('header.aboutUs')
            },
          }
        ]
      },
      {
        path: '/:lang(ru)?/403',
        name: '403',
        component: PagePermissionDenied,
      },
      {
        path: '/:lang(ru)?/404',
        name: '404',
        component: PageNotFound,
      },
      {
        path: '/:lang(ru)?/*',
        component: PageNotFound,
      }
    ]
})

export default router

export function routeLang (route = null) {
  if(route === null){
    route = router && router.currentRoute || null
  }
  if(route && route.matched && route.matched.length && route.matched[route.matched.length - 1].path && route.matched[route.matched.length - 1].path.indexOf('/:lang(ru)?') === 0){
    return route.params['lang'] === 'ru' ? 'ru' : 'en'
  }
  return null
}

export function routeWithLang (location, route = null) {
  if(typeof location === 'object'){
    const lang = routeLang(route)
    if(route){
      if(typeof location.params !== 'object'){
        location.params = {}
      }
      location.params.lang = lang === 'ru' ? 'ru' : undefined
    }
  }
  return location
}
