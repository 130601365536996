export const getDecimalCount = (num) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

export const convertCyrillicToLatin = (text) => {
  let keyboardMap = {
    'й': 'q', 'ц': 'w', 'у': 'e', 'к': 'r', 'е': 't', 'н': 'y', 'г': 'u', 'ш': 'i', 'щ': 'o', 'з': 'p',
    'х': '[', 'ъ': ']', 'ф': 'a', 'ы': 's', 'в': 'd', 'а': 'f', 'п': 'g', 'р': 'h', 'о': 'j', 'л': 'k',
    'д': 'l', 'ж': ';', 'э': "'", 'я': 'z', 'ч': 'x', 'с': 'c', 'м': 'v', 'и': 'b', 'т': 'n', 'ь': 'm',
    'б': ',', 'ю': '.', '.': '/', 'ё': '`'
  };

  return text.replace(/[йцукенгшщзхъфывапролджэячсмитьбюё]/ig, (char) => keyboardMap[char.toLowerCase()] || char);
}

export const formatted = (text) => {
  return text ? parseTextHttp(changeLineBreakToBr(text)) : ''
}

export const changeLineBreakToBr = (text) => {
  return text.replace(/(\r\n|\r|\n)/g, '<br>')
}

export const parseTextHttp = (text) => {
  const linkArr = []

  const tagMatch = text.match(/(<a[^>]+>|<a>|<\/a>)/g);
  let defaultText = text
  if(tagMatch) {
    tagMatch.forEach(el => {
      if(el.indexOf('href="', 0) >= 0) {
        // Пушим ссылку из href
        linkArr.push(el.substr(el.indexOf('href="', 0) + 6, el.indexOf('"', el.indexOf('href="', 0) + 6) - el.indexOf('href="', 0) - 6))
      }
    })
    while(defaultText.includes('<a ')) { //Удаляем из текста что пришел все теги <a>

      defaultText = defaultText.replace(/<a[^>]*?>[\s\S]*?<\/a>/gi, '*|=|*')
    }
  }

  let match = defaultText.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig); //ищем есть ли ссылки в тексте
  if (match) {
    match.forEach(el => { //меняем ссылку на тег <a>
      defaultText = defaultText.split(' ' + el + ' ').join(" <a href=\""+el+"\" target=\"_BLANK\">"+el+"</a> ")
      // Разные вариатны положения ссылок.
      defaultText = defaultText.split(' ' + el).join(" <a href=\""+el+"\" target=\"_BLANK\">"+el+"</a>")
      defaultText = defaultText.split(el + ' ').join("<a href=\""+el+"\" target=\"_BLANK\">"+el+"</a> ")
      defaultText = defaultText.split('<br>' + el + ' ').join("<a href=\""+el+"\" target=\"_BLANK\">"+el+"</a> ")
      defaultText = defaultText.split(el + '<br>').join("<a href=\""+el+"\" target=\"_BLANK\">"+el+"</a>")
      defaultText = defaultText.split(' ' + el + '<br>').join(" <a href=\""+el+"\" target=\"_BLANK\">"+el+"</a>")
      defaultText = defaultText.split('<br>' + el + '<br>').join("<a href=\""+el+"\" target=\"_BLANK\">"+el+"</a>")
    })
  }

  linkArr.forEach(el => { //Добавляем в текст теги <a> которые удалил выше
    defaultText = defaultText.replace('*|=|*', "<a href=\""+el+"\" target=\"_BLANK\">"+el+"</a>")
  })

  return defaultText
}

export const sortByAlphabet = (arr, key) => {
  const sortedList = [].concat(arr);

  sortedList.sort((a, b) => {
    if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
      return 1;
    }

    if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
      return -1;
    }

    return 0;
  });

  return sortedList;
};

export const validateUrl = (url)=> {
    const urlRegex = /^(?:https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:(?!-)[a-z0-9-]{1,63}(?:\.(?!-)[a-z0-9-]{1,63})+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]{1,63})\])(?::\d{2,5})?(?:\/[^\s]*)?$/i;

    return urlRegex.test(url);
}

export const validateJson = (json)=> {
  try {
      JSON.parse(json);
      return true;
  } catch (error) {
      return false;
  }
}
