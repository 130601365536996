import onboardingDeltaData from "@/assets/js/onboarding/onboardingDeltaDataEn";

export default {
  system: {
    inDev: "Is under development",
  },
  "errors": {
    "buyError": "Buying error"
  },
  "promo": {
    "available": "Available for <span class='text-uppercase'>{tariff}</span> only"
  },
  "marketplace": {
    "subscribeDurationTooltip": "It is not possible to subscribe to a notification for a period longer than the remaining days on your tariff.",
    "subscribeEndDate": "Subscription end date",
    "botPopover": "The name of the author of a group alert changes to System bot in the case - if the author deleted the group alert he created",
    "reCreateTooltip": "Repeat notification",
    "reSubscribeTooltip": "Re-subscribe",
    "coinCountWarning": "The number of coins in the alert must be equal to or less than the number of coins in the FUTURES list.",
    "expire": "Expire",
    "created": "Created",
    "assignTokenTooltip": "Requires 1 token FOLLOWER to subscribe to a group alert.",
    "tokenPopoverDescFollower": "Token Follower: Credited for subscription 5pcs. Automatically renewable, used to follow public alerts.",
    "tokenPopoverDescInfluencer": "Token Influencer: Credited +1 for each one if your public alert reaches over 10 followers, can be used to create group alerts.",
    "tokenPopoverDescCreator": "Token Creator: Credited for subscription 5pcs. Automatically renewable, used to create and follow up other alerts.",
    "tokens": "Tokens",
    "tokenLimitWarning": "You have used all {type} tokens",
    "actveTariffTooltip": "You do not have an activated subscription {tariff}",
    "title": "Marketplace",
    "addNotification": "Add notification",
    "sort": "Sort",
    "subscribersNumberAbbr": "Number of followers",
    "subscribersNumber": "Number of followers",
    "publicationDate": "Date of publication",
    "notificationName": "Notification name",
    "subscribe": "Subscribe",
    "coinsNumber": "Pairs",
    "createdAt": "Created at",
    "terms": "Terms",
    "totalSigned": "Total followers",
    "author": "Author",
    "repeats": "Repeats",
    "unsubscribe": "Unsubscribe",
    "show": "Show",
    "activity": "Activity",
    "deleteGroupNotificationText": "The following action remove the chosen notification. Should we proceed?",
    "complain": "Complain",
    "returnPublication": "Return publication",
    "removePublication": "Remove from publication"
  },
  "general": {
    "copied": "Copied",
    "howItWorks": "How it works",
    "apply": "Apply",
    "confirm": "Confirm",
    "all": "All",
    "forever": "Forever",
    "deleteAll": "Delete all",
    "nick": "Username",
    "unbind": "Unbind",
    "copyLink": "Copy link",
    "share": "Share",
    "clearLogs": "Clear logs",
    "verify": "Verify",
    "inBot": "bot",
    "show": "Show",
    "referralLink": "Referral link",
    "answerQuestion": "Answer to the question",
    "single": "Single",
    "group": "Group",
    "public": "Public",
    "logs": "Logs",
    "active": "Active",
    "upgradeTo": "Upgrade to",
    "discount": "Discount",
    "free": "Free",
    "year": "year",
    "monthAbbr": "month",
    "cancelAll": "Cancel all",
    "filters": "Filters",
    "close": "Close",
    "open": "Open",
    "save": "Save",
    "remove": "Remove",
    "delete": "Delete",
    "add": "Add",
    "requiredField": "Required field",
    "notValidate": "The data is not valid",
    "dataSaved": "Data saved",
    "yes": "Yes",
    "no": "No",
    "noData": "No data",
    "cancel": "Cancel",
    "saveChanges": "Save changes",
    "saveChangesAndToLineRunner": "Save and use as a running line",
    "emptySearch": "No results were found for your search. Try again.",
    "error": "Error",
    "dataChanged": "Data changed",
    "search": "Search",
    "inputPlaceholder": "Enter... (min 3 chars)",
    "datePlaceholder": "dd.mm.yyyy",
    "reset": "Reset",
    "until": "до",
    "edit": "Edit",
    "color": "Color",
    "serverIsNotAvailable": "Server is not available",
    "lineRunnerAdded": "The watchlist has been successfully added to the running line",
    "price": "Price",
    "h": "h",
    "m": "m",
    "w": "W",
    "d": "D",
    'deselect': 'Deselect',
    'writeTheText': 'Write the text: ',
    "remove_data": "Remove {item} ?",
    "created": "Created",
    "updated": "Updated",
    "deleted": "Deleted",
    "isActive": "Active",
    "isNotActive": "Not active",
    "Active": "Active",
    dontSaveEmptyArray: 'Cannot save empty array',
  },
  "crubs": {
    "tariningview": "Training"
  },
  "header": {
    "delta": "Delta",
    "screener": "Screener",
    "trends": "Trends",
    "aboutUs": "About us",
    "ourProjects": "Our projects",
    "account": "Account",
    "settings": "Settings",
    "permissions": "Permissions",
    "subscribe": "Subscription",
    "subscribeConstructor": "Subscription constructor",
    "training": "Courses",
    "videoEncrypt": "Encrypt video",
    "videoPublic": "Public video",
    "coinList": "Coin settings",
    "inviteCode": "Invite codes",
    "payments": "Payment history",
    "transactions": "Transactions history",
    "analytics": "Analytics",
    "dashboard": "Dashboard",
    "logout": "Logout",
    "education": "Education",
    "curator": "Curator",
    "authors": "Public profiles",
    "author": "Public profile",
    "guide": {
      "label": "Manual",
      "path": "https://docs.cvizor.com/en/"
    }
  },
  "lk": {
    "header": {
      "account": "Account",
      "permission": "Permissions",
      "userActivity": "User activity",
      "subscribe": "Subscription",
      "subscribeConstructor": "Subscription constructor",
      "training": "Training",
      "videoEncrypt": "Encrypt video",
      "videoPublic": "Public video",
      "notifications": "Notifications",
      "coinSettings": "Coin settings (Screener)",
      "coinSettingsTrends": "Coin settings (Trends)",
      "payments": "Payment history",
      "analytics": "Analytics",
      "whitelist": "Whitelist",
      "settings": "Settings",
      "listOfCourses": "List of courses",
    },
    "account": {
      "pauseUpdateOnWitoutMA": "To enable the filter, add both MA columns to the screener table setting.",
      "pauseUpdateOnChartHint": "When the chart is open, the screener table will not refresh in the background.",
      "pauseUpdateOnChart": "Pause Updates on Chart Active",
      "isShowMAWithoutMA": "To enable the filter, add both MA columns to the screener table setting.",
      "isShowMA": "Filter by MA zones",
      "telegramStudenets": "Join the student chat",
      "tabMainScore": "Main",
      "tabReferalScore": "Referal",
      "tabSubscription": "Subscription",
      "tabTraining": "Training",
      "telegram": "Telegram channel",
      "feedback": "Feedback",
      feedbackLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdaULh2tWbJ3aBcNsFBEfAKHfWZ5hAc0O_qduku3IMUjZrnIw/viewform?usp=sf_link',
      "formName": "Your nickname",
      "formEmail": "Your e-mail",
      "formNameList": "List name",
      "formTickers": "Tickers...",
      "changePassword": "Change password",
      "save": "Save",
      "connectTelegram": "Connect Telegram",
      "confirmAccount": "Confirm account from Telegram",
      "listName": "List",
      "download": "Upload",
      "selectLang": "Choose language",
      "verifiedTelegram": "Telegram verification approved",
      "verified2FA": "2FA enabled",
      "telegramTooltip": "Пройдите верификацию в Telegram",
      "browserNotifications": "Browser notifications",
      "browserNotificationsEnable": "Enable notifications in Brave browser",
      "browserNotificationsDisabled": "Browser notifications disabled",
      "browserNotificationsActive": "Browser notifications allowed",
      "browserNotificationsBlocked": "Browser notifications blocked. To enable notifications, go to your browser settings",
      "browserNotificationsBraveErrorStart": `Brave browser notification setting should be set to allowed as well as to grant the permission for the Google
            services to be enabled for the push-messaging.<br> To turn this feature on you need click `,
      "browserNotificationsBraveErrorEnd": ` toggle
      “Use Google for push messaging -> On”  and restart the browser.
      You will be able to receive push notifications afterwards`,
      "browserNotificationsNotSupported": "Browser notifications not supported",
      "browserNotificationsSafari": "Browser notifications allowed. To disable notifications, go to your browser settings",
      "signOutAll": "Sign out from all devices",
      "promoCodeBtn": "Get access",
      "resetLinkOnEmail": "Password reset link sent, please check your email",
      "registrationSuccessfully": "Registration completed successfully",
      "resetLinkError": "The link is missing the {name} parameter",
      "loginCompleted": "Login completed",
      "unbindTgMessage": "Telegram account has been unbind",
      "confirmAccountSuccessfully": "Telegram account verified successfully",
      "activateTrendsSuccessfully": "Trends are successfully activated, if the tab is not displayed - refresh the page",
      "currentBalance": "Balance",
      "refBalance": "Referral balance",
      "currentTariff": "Current subscription plan:",
      "dueTo": "Active till:",
      "expires": "Days left:",
      "noTariff": "You have no active tariff",
      "gotoTariffs": "Go to tariffs",
      "fillBalance": "Top-up balance",
      "changeEmailHint": "You should enable 2FA for changing your email",
      "6digitCode": "There has to be 6 digits",
      "enterCodeFromApp": "Please, enter the code from the code generator app",
      "configure2FA": "Manage 2FA",
      "modal2FA": {
        "title": "Google Authenticator App would be needed to enable 2FA on our website. Please download and install the application from AppStore or Google Play and follow the instructions in the app.",
        "secretTitle": "Your secret key",
        "copyButton": "Copy",
        "install": {
          "title": "Please, install the code generator app",
          "inst1": "Copy the secret key",
          "inst2": "Open code generator app and paste it.",
          "inst3": "Get the verification code and come back to the website.",
          "saveKey": "Please, keep your security key in a safe place.",
        },
        "enterCode": "Please, enter the code from the code generator app.",
        "enterCodeMin": "Enter the code from the app",
        "2FAenabled": "2FA enabled. Want to disable?",
        "2FAdisabled": "2FA disabled. Want to activate?",
        "continue": "Continue",
        "back": "Back",
        "enable2FA": "Enable 2FA",
        "disable2FA": "Disable 2FA",
        "toast": {
          "codeCopied": "Code copied to clipboard",
          "2FAenabled": "2FA has been successfully enabled",
          "errorConfirm": "Authentication set up error. Please, double-check the code you entered",
          "errorChanging": "2FA setup error",
          "2FAdisabled": "2FA has been disabled",
        }
      }
    },
    "settings": {
      "watchListNameWarning": "The maximum number of characters in a watchlist name is 24",
      "uploadFile": "Upload file",
      "changeNameNote": "Replace the name of the wotchlist with the file name? (The limit in the file name is 24 characters, in case the file name is more than 24 characters - it will be truncated)",
      "downloadWatchlist": "Download watchlist in txt format",
      "resetWatchlist": "Reset watchlist contents",
      "watchlistUploadedSuccessfully": "Your watchlist has been uploaded successfully",
      "resetWatchlistSuccessfully": "Watchlist {name} cleared",
      "deleteWatchlist": "Delete watchlist",
      "addWatchlist": "Add watchlist",
      "changeColorWatchlist": "Change color",
      deleteWatchlistModal: {
        "title": "Remove {name} watchlist",
        "subtitle": "The following action remove the chosen watchlist. Should we proceed?",
      },
      "editLineRunnerModal": {
        "title": "Change the running line?",
        "subtitle": "Using the watch list in the running line will replace the current data. Continue?"
      },
      tableScreener: {
        table: {
          price: 'Current price of the asset.',
          cdWeek: 'Cumulative delta for the week.',
          delta: 'Delta value for the selected timeframe.',
          volat: 'Price change amplitude relative to the selected timeframe.',
          ma50: 'Moving average for the selected period.',
          ma50Label: 'Low МА',
          ma200: 'Moving average for the selected period.',
          ma200Label: 'High МА',
          rsi: 'Relative Strength Index (14 periods).',
          volume: 'Trading volume in USDT.',
          bci: 'Bitcoin Correlation Index.',
          openInterests: 'Total volume of open positions in the futures market',
          liquidation: 'Liquidations in the futures market for the selected timeframe (Long/Short).',
          funding: 'Funding rate in the futures market.',
          trades: 'Number of trades executed in the selected timeframe.',
        },
      },
    },
    "training": {
      "viewAllCourses": "View all available courses",
      "preview": "Preview",
      "students": "Students",
      "curators": "Curators",
      "materials": "Materials",
      "flows": "Flows",
      "settings": "Settings",
      "topic": "Topic of the day",
      "accessFlows": "Flows-Only Access",
      "accessFlowsAll": "All",
      "accessFlowsFlows": "Not available to anyone, resave",
      "order": "Order",
      "name": "Name",
      "link": "Link to the broadcast",
      "start_at": "Start at",
      "finish_at": "Finish at",
      "homeWork": "Home work",
      "description": "Description",
      "active": "Active",
      "notActive": "Not active",
      "btnSave": "Save",
      "loadTitle": "Upload a stream recording",
      "aboutBtn": "More about training",
      "readMore": "Read More",
      "editChatFile": "Edit chat file",
      "editChatFileBtn": "Edit file",
      "addChatFile": "Add chat file",
      "addChatFileBtn": "Add file",
      "nameTraining": "Name",
      "slugTraining": "Slug",
      "descTraining": "Description",
      "tg_group_url": "Chat link",
      "route_check": "Api url verification",
      "route_approved": "Api hook url",
      "managersTraining": "Managers",
      "addManager": "Add manager",
      "authorTraining": "Author",
      "addAuthor": "Add author",
      "replaceAuthor": "Replace author",
      "availableToBuyFrom": "Available for buy from (UTC)",
      "availableForTrainingFrom": "Available for training from (UTC)",
      "coverPhoto": "Course cover listing (608x640)",
      "widePhoto": "Course cover in card (1300x500)",
      "errorLoadData": "Error! Failed to load data",
      "errorSave": "Error! Failed to save data",
      "successSave": "Changes saved",
      "descriptionTitle": "Course description",
      "linksTitle": "Links",
      "programTitle": "Course program",
      "labelBackBtn": "Back",
      "curatorAuthorsTitle": "Curators",
      "tariffsTitle": "Rates",
      "btnSaveCourse": "Save",
      "btnCreateTitle": "Create a course",
      "btnCreateCourse": "Create",
      "errorNameCourse": "Enter course name",
      "errorCreateCourse": "Error creating course",
      "courseMarkerLabel": "Active until",
      "usefulLinks": {
        "header": "Useful links",
        "inputTitle": "Text",
        "inputLink": "Link",
        "add": "Add link"
      },
      "headers": [
        "Preview", "List of students", "List of curators", "Materials", "Flows", "Settings"
      ],
      "studentTable": {
        "dateBuy": "Date of purchase",
        "name": "Name in the service",
        "email": "E-Mail",
        "tariff": "Tariff",
        "chatApproved": "Chat",
        "tgName": "Telegram",
        "curator": "Curator",
        "flow": "Flow"
      },
      "flowTable": {
        "addFlow": "Add flow",
        "editFlow": "Edit flow",
        "flowNameInputPlaceholder": "Enter the name of the stream",
        "programInputPlacholder": "What program is the flow on?",
        "programInputLabel": "Training program",
        "dateInputLabel": "Dates of the event",
        "dates": "Dates",
        "addStudents": "Add students",
        "studentsListLabel": "Select a student from the list",
        "deleteStudents": "Delete students"
      },
      "curatorTable": {
        "createdAt": "Date of completion",
        "login": "Login in the service",
        "name": "Name",
        "telegram": "Telegram",
        "count": "Number of students",
        "subTable": {
          "name": "Name in service",
          "email": "E-Mail",
          "tg": "Telegram",
          "tariff": "Tariff"
        }
      },
      "curatorModal": {
        "titleAdd": "Adding a Curator",
        "titleUpdate": "Adding a Curator",
        "emailPlaceholder": "Email (min 3 chars)",
        "login": "Login in the service",
        "name": "Name",
        "telegram": "Telegram",
        "loginPlaceholder": "E-mail",
        "namePlaceholder": "Enter name",
        "telegramPlaceholder": "Enter telegram nickname",
        "btnAdd": "Add",
        "btnUpdate": "Update",
        "completeAdd": "Curator added successfully",
        "completeUpdate": "Curator updated successfully"
      },
      "managerModal": {
        "titleAdd": "Adding a Manager",
        "emailPlaceholder": "Email (min 3 chars)",
        "login": "Login in the service",
        "name": "Name",
        "telegram": "Telegram",
        "loginPlaceholder": "E-mail",
        "namePlaceholder": "Enter name",
        "telegramPlaceholder": "Enter telegram nickname",
        "btnAdd": "Add",
        "completeAdd": "Manager added, save the changes",
      },
      "authorModal": {
        "titleAdd": "Adding a Author",
        "placeholder": "search (min 3 chars)",
        "btnAdd": "Add",
        "completeAdd": "Author added, save the changes"
      },
      "userAuthorModal": {
        "titleAdd": "Bind user",
        "placeholder": "search (min 3 chars)",
        "btnAdd": "Add",
        "completeAdd": "User added, save the changes"
      },
      "deleteCuratorModal": {
        "title": "Deleteing a Curator",
        "confirm": "Confirm delete",
        "complete": "Curator deleted"
      },
      "addToNewModal": {
        "title": "Mark NEW",
        "desc": "Changes were made to the lesson. Set the mark NEW?",
        "yes": "Yes",
        "no": "No",
      },
      "appointCuratorModal": {
        "title": "Appoint a curator",
        "select": "Select a curator from the list:",
        "selectPlaceholder": "Select from the list",
        "btnAdd": "Appoint",
        "complete": "The curator has been successfully appointed",
      },
      "deleteStudentModal": {
        "title": "Confirm delete",
        "text": "{count} students will be deleted",
        "deleteComplete": "Student deleted"
      },
      "editTariffFinishDate": {
        "modalTitle": "Edit tariff expiration date",
        "changesSaved": "Changes have been saved"
      },
      "editTariff": {
        "modalTitle": "Edit tariff",
      },
      "addStudentUser": {
        "btnText": "Add/update",
        "modalTitle": "Add/update a student",
        "finishedAt": "Finished date",
        "searchPlaceholder": "search (min 3 chars)",
        "datePlaceholder": "dd/mm/yy hh:mm",
        "changesSaved": "Changes have been saved"
      },
      "trainingView": {
        "courseDescription": "Course description",
        "courseProgramm": "Course programm",
        "courseCurator": "Your teacher"
      },
      'timeLeft': 'Time Left:',
    },
    "authors": {
      "title": "Public profiles",
      "aboutTitle": "About the author",
      "coursesTitle": "Courses",
      "smallAuthorLabel": "Author",
      "links": "Links",
      "social_networks": "Social Networks",
      "video": "Video",
      "store": {
        "surname": "Surname",
        "first_name": "First Name",
        "avatar": "Avatar",
        "linkText": "Text",
        "link": "Link",
        "user": "User",
        "replaceUser": "Replace user",
        "addUser": "Bind user",
        "createNewAuthorToCurator": "Creating an author page",
      }
    },
    "curator": {
      "header": "Curator",
      "complete": "Saved",
      'homeWorkShort': "Homework",
      'homeWorkAbbr': "HW",
      "student": "Student",
      "buyDate": "Buy date",
      "hwTooltip": "Homework has been accepted",
      "noStudents": "This curator has no students",
      "tg": "Telegram",
      "tariff": "Tariff"
    },
    "videoEncrypt": {
      "timeCodes": "Time codes",
      "title": "Title",
      "additionalMaterials": "Additional materials",
      "replaceVideo": "Replace video",
      "moreVideo": "More video",
      "selectedVideo": "Selected video",
      "chooseVideo": "Choose video",
      "header": "Video",
      "addVideo": "Add video",
      "table": {
        "poster": "Poster",
        "video": "Video",
        "name": "Name",
        "description": "Description",
        "filename": "File",
        "hls_time": "Segment length (sec)",
        "status": "Status",
        "statuses": {
          "new": "New",
          "uploaded": "Uploaded",
          "queue": "Queue",
          "encrypting": "Encrypting",
          "encrypted": "Encrypted"
        }
      },
      "UploadVideoModal": {
        "title": "Upload video",
        "file": "Video-file up to 20GB .mp4, .m4v, .mkv, .avi, .webm, .mov",
        "filePoster": "FIle .jpg, .png",
        "btnUpload": "Upload",
        "btnCancel": "Cancel",
        "uploaded": "File uploaded",
        "type": "Type"
      },
      "UploadPosterModal": {
        "title": "Upload poster: ",
        "file": "File .jpg, .png",
        "btnUpload": "Upload",
        "btnCancel": "Cancel",
        "uploaded": "File uploaded"
      },
      "EditModal": {
        "title": "Parameters: ",
        "name": "Name",
        "namePlaceholder": "Name",
        "description": "Description",
        "descriptionPlaceholder": "Description",
        "hls_time": "Segment length (sec)",
        "hls_timePlaceholder": "Segment length (sec)",
        "btnSubmit": "Update",
        "btnCancel": "Cancel",
        "updated": "Updated"
      },
      "RemoveConfirmModal": {
        "title": "Delete: ",
        "fieldVideoId": "Write the video id: ",
        "btnSubmit": "Delete",
        "btnCancel": "Cancel",
        "deleted": "Deleted"
      },
      "EncryptModal": {
        "title": "Encryption: ",
        "hls_time": "Segment length (sec)",
        "btnSubmit": "Send to Queue",
        "btnClose": "Close",
        "btnCancel": "Cancel",
        "btnStatus": "Log",
        "updated": "Encrypted",
        "sent_queue": "Sent to queue",
        "error": "Error!!!"
      },
      "PlayerModal": {
        "playlist": "Playlist",
        "poster": "Poster",
        "hash": "Key"
      }
    },
    "videoPublic": {
      "header": "Public video",
      "addVideo": "Add video",
      "url": "URL",
      "file": "File",
      "RemoveConfirmModalText": "Write the video filename: "
    },
    "curatorTable": {
      "view": "Displayed",
      "curators": "curators",
      "addCurator": "Add curator",
      "spreadCurators": "Spread students",
      "export": "Export to Excel",
    },
    "studentTable": {
      "appoint": "To appoint",
      "students": "students",
      "selected": "Selected ",
      "export": "Export to Excel",
      "appointCurators": "Appoint a curator",
      "delete": "Delete"
    },
    "subscribe": {
      "blackFriday": {
        "title": "BLACK FRIDAY",
        "subtitle": "discounts from November 25 to December 1",
        "buyButton": "Buy now",
        "tariffs": {
          "proAlerts": {
            "title": "PRO + ALERTS",
            "discount": "BENEFIT to 55%",
            "description": "An ideal choice for those who want full access to alerts and premium features.\nGet 18 months for the price of 12, which means $22 instead of $49 monthly, with savings of 55%",
            "bonus": "+6 months for free!",
          },
          "pro": {
            "title": "PRO",
            "discount": "BENEFIT to 58%",
            "description": "Get an 18-month PRO subscription for the price of 12.\nSave 58% monthly and pay only $16. Enjoy all the basic features for a long time.",
            "bonus": "+6 months for free!",
          },
        },
      },
      "actveTrial": "The trial tariff is valid until",
      "tgModalPoint4": "Be a member of the Tradium chat",
      "tgModalPoint3": "Subscribe to the Tradium telegram",
      "tgModalPoint2": "Subscribe to the Cryptovizor telegram",
      "tgModalPoint1": "Authorize in our Telegram bot",
      "tgModalTitle": "Requirements for {tariff} subscription:",
      "basicTooltip": "You can switch to BASIC only after your paid plan has expired",
      "subtitle": "You can choose one of the following bundles:",
      "payment": "Payment:",
      "monthly": "Monthly",
      "3months": "3 months",
      "6months": "6 months",
      "12months": "12 months",
      "pay": "Choose",
      "prolong": "Get",
      "fulfilled": "Fulfilled 100\\100",
      "features": "Main advantages:",
      "benefits": "Additional benefits:",
      "active": "active",
      "inactive": "Available",
      "save": "Save",
      "waitTransaction": "Wait transaction",
      "toDate": "until",
      "complite": {
        "title": "Congratulate!",
        "content": "Congratulations on your purchase of the Daytrading Futures course! 🎉\n" +
          "\n" +
          "We are glad that you have joined our team. There is a new LESSON tab in the top panel, where you can find all the materials you need.\n" +
          " \n" +
          "After purchasing the course, you are credited a cashback in the form of a 3-month PRO subscription to the screener, it is already activated.\n" +
          "\n" +
          "Also, the secret Trends module has been revealed to you and you can start using it right now! 😉",
      },
      "modal": {
        "agreeLabel": "Activate subscription automatically once the account is topped up?",
        "tariffChange": "Tariff change",
        "tariffTrainingBuy": "Purchase of the training plan",
        "tariffBuy": "Purchase of a tariff",
        "currentTariff": "Current tariff:",
        "newTariff": "Target tariff",
        "overPayAmount": "Need to top-up",
        "balance": "Balance:",
        "payWithBalance": "Balance payment (USDT):",
        "maxAmountOnBalance": "Max amount:",
        "enterFullSum": "Enter the entire amount",
        "payWithRef": "Payment by Referral points",
        "payRefHint": "Split payment (referral points and / or crypto)",
        "totalSum": "Total:",
        "balanceReturn": "Cash back:",
        "totalWithdrawalCost": "Total amount to be charged:",
        "buyButton": "Purchase",
        "changeTariffButton": "Change tariff",
        "payButton": "Purchase",
        "goToBuy": "Go to payment",
        "daysLeft": "Days left",
        "daysLeftDetail": "day | days",
        "errorBuyBonuses": "Failed to issue invoice",
        "errorReceivingData": "Error while receiving data",
        "amountLeft": "Amount left",
        "amountResult": "Amount to be paid to switch to the new bundle ",
        "message": "To switch to the selected tariff, no payment is required",
        "switch": "Change",
        "cancel": "Cancel",
        "pageNoOpen": "If the page did not open, click on the link below"
      },
      "switchToPayPage": "Go to the payment page, please wait",
      "changeTariffSuccess": "Tariff change successful",
      "reloadingStatus": "Updating status",
      "reloadedStatus": "Status updated",
      "trial": {
        "trialToDate": "Trial tariff is activated",
        "trialPeriod": "Try",
        "trialProAlertsPeriod": "Bonus",
        "modalTitle": "Activating the trial period",
        "modalSubtitle": "To receive a trial period, you need to confirm your account through the Telegram bot",
        "modalSubtitleTg1": "After activation, you will be given a trial period of the PRO+ALERTS subscription for 15 days. You will get full functionality of the service. At the end of the trial period you will be able to subscribe and continue to work in our service.",
        "modalSubtitleProAlerts": "After activation, your PRO+ALERTS tariff will be extended for 15 days.",
        "modalSubtitleTg2": "Note that if you pay during the trial period - free days will be deducted",
        "confirm": "Confirm",
        "confirmSuccess": "Telegram account confirmed successfully",
        "cancel": "Cancel",
        "activate": "Activate",
        "activateSuccess": "The Trial Pro+Alerts tariff has been successfully activated, if the tabs are not displayed - refresh the page"
      },
      "paymentWasSuccessful": "Payment was successful. Status update...",
      "statusUpdate": "Status update",
      "statusUpdated": "Status updated",
      "paymentDeclined": "Payment declined",
      "selectMounth": "Select the subscription period:",
      "selectCurator": "Select curator",
      "tabTraining": "Education",
      "tabSubscribe": "Subscription",
      "showMoreBTN": "Show more",
      "teacherLabel": "Teacher:",
      "infoAboutTeacherLabel": "Information about the teacher",
      "learnListLabel": "What you will learn",
      "cousreProgrmmLabel": "Course program",
      "maximalDialyProfitLabel": "Maximum daily profit:",
      "fullCourseInfoLabel": "Full course information",
      "viewLabelBTN": "View",
      "subscribeTariningData": [{
        "learnList": [],
        "teacherInfo": "From altcoin holder to altcoin shorter. I earned my first million rubles at the age of 22",
        "cardList": [
          {
            "title": 'Practice daily',
            "description":
              'Get homework assignments, and after they are completed, there will be feedback from the teacher',
          },
          {
            "title": 'Checking homework assignments',
            "description":
              'You will have constant communication with the mentors of the project and practical homework on each topic',
          },
          {
            "title": '30+ hours of training sessions',
            "description":
              'Each lesson is conducted live, with feedback',
          },
          {
            "title": 'Classes are held online',
            "description": 'They are also available on record for three months',
          },
          {
            "title": 'All kinds of strategies and tools',
            "description":
              'The teacher demonstrates on graphs in real time and answers any questions that arise',
          },
          {
            "title": 'Access to a closed team',
            "description": 'Access to a closed flow group with curators',
          },
        ],
      }, {
        "learnList": [],
        "teacherInfo": "From altcoin holder to altcoin shorter. I earned my first million rubles at the age of 22",
        "cardList": [
          {
            "title": 'Practice daily',
            "description":
              'Get homework assignments, and after they are completed, there will be feedback from the teacher',
          },
          {
            "title": 'Checking homework assignments',
            "description":
              'You will have constant communication with the mentors of the project and practical homework on each topic',
          },
          {
            "title": '30+ hours of training sessions',
            "description":
              'Each lesson is conducted live, with feedback',
          },
          {
            "title": 'Classes are held online',
            "description": 'They are also available on record for three months',
          },
          {
            "title": 'All kinds of strategies and tools',
            "description":
              'The teacher demonstrates on graphs in real time and answers any questions that arise',
          },
          {
            "title": 'Access to a closed team',
            "description": 'Access to a closed flow group with curators',
          },
        ],
      },]
    },
    "bonuses": {
      "buyBonusesTitle": "Top-up balance",
      "buyBonusesInput": "Top-up balance on:",
      "buyBonusesButton": "Purchase",
    },
    "payments": {
      "name": "Name",
      "email": "E-mail",
      "paymentDate": "Payment Date",
      "paymentID": "Payment ID",
      "invoiceID": "Invoice ID",
      "price": "Price",
      "currency": "Currency / total amount paid",
      "actuallyPaid": "Actually paid",
      "status": "Status",
      "merchantStatus": "Merchant Status",
      "bundle": "Bundle",
      "dueDate": "Due date",
      "search": "Search",
      "searchPlaceholder": "Email (min. 3 symbols)",
      "new": "New",
      "succeeded": "Succeeded",
      "pending": "Pending",
      "canceled": "Canceled",
      "paid": "Paid"
    },
    "analytics": {
      "conversionRates": "Conversion rates",
      "userTimeSpent": "Time spent by the user in the app/on the website",
      "churnRate": "Churn Rate",
      "activeUserCount": "Total number of active users",
      "ltvGraphTitle": "Lifetime customer value (LTV)",
      "monthArpu": "Average revenue per user (ARPU) by month",
      "countUsers": "Сount гsers",
      "numberOfNewAndRepeatSubscribe": "Number of new and repeat subscriptions per month",
      "totalRegisteredUser": "Total number of registered users",
      "monthlyIncome": "Monthly income",
      "cumulativeIncome": "Cumulative income",
      "monthlyUserRegIncrease": "Monthly increase in user registrations",
    },
    "modalPassword": {
      "title": "Change password",
      "current": "Current password",
      "new": "New password",
      "repeat": "New password again",
      "change": "Change password",
      "changeSuccess": "Password changed",
    },
    "referrals": {
      "navigation": {
        "mine": "Own ref balance",
        "bonusTransfer": "Withdrawal request",
        "refList": "Referrals list",
      },
      "title": "Referrals",
      "set_status": "Set status",
      "info": "You can create a withdrawal request only with a referral balance from",
      "status": {
        "pending": "In processing",
        "new": "Not paid",
        "succeeded": "Paid",
        "cansel": "Canceled",
        "subscriptionStatus": "Платеж за подписку",
      },
      "select_all_not_paid": "Mark all not paid",
      "upload_selected": "Upload",
      "upload_hint": "Only selected will be uploaded",
      "table": {
        "header": {
          "telegram": "Telegram",
          "status": "Status",
          "system_login": "Login in the system",
          "cash_cost": "Amount to withdraw",
          "wallet": "Purse",
          "date": "Request date",
          "name": "Name",
          "email": "Email",
          "balance": "Balance",
          "refBalance": "Referral balance",
          "refIncome": "Remuneration for all time",
          "refCount": "Referrals count",
          "cancel": "Cancel"
        }
      },
      "referral_count": "Number of referrals",
      "referral_payed_count": "Number of referrals with payment",
      "balance": "Current balance",
      "bonus_all_time": "All time reward",
      "referral_link": "Your referral link",
      "referral_link_hint": "You and each user who registered using your invite link will get 10% cash back on the first subscription payment. <br>Feel free to share 🙂",
      "min_transaction": "Minimum withdrawal amount",
      "create_transaction": "Submit a request for payment",
      "transaction_history": "Transactions history",
      "copy_referral_link": "Link copied",
      "copy_referral_link_fail": "Your browser does not support system copy. We apologize.",
      "referral_link_update": "Referral code updated",
      "cash_modal": {
        "cash_cost": "Withdrawal amount",
        "title": "Application for payment",
        "wallet": "Your wallet",
        "min": "Minimum",
        "cash_all": "Enter the whole amount",
        "available": "Available",
        "request_cash": "Order a payout",
        "cancel": "Cancel",
        "success_requires": "Bonus withdrawal request successfully created.",
        "disclaimer": "You can withdraw referral bonuses from screener payments directly to your wallet. However, referral bonuses earned from education payments can only be used to pay for the screener and are not eligible for withdrawal."
      },
      "history_modal": {
        "title": "Payment history"
      }
    },
    "admin_bonus_transfer": {
      "title": "Withdrawal requests",
      xlsx: {
        'id': "ID",
        'status': "Status",
        'email': "Email",
        'withdrawalAmount': "Withdrawal amount",
        'wallet': "Wallet",
        'requestDate': "Request date",
        'uploadingBonusTransactions': "Uploading bonus transactions",
      },
    },
    "tg_notifications": {
      "title": "Telegram Notification",
      "empty": "No newsletters",
      "edit": "Change",
      "delete": "Delete",
      "add": "Add",
      "titleModal": "Newsletter",
      "message": "Message",
      "save": "Save",
      "send": "Send out",
      "cancel": "Cancel",
      "titleModal2": "Are you sure?",
      "status": "Status",
      "whoSent": "Who sent",
      "dateTimeOfDispatch": "Date and time of dispatch",
      "delivered": "Delivered",
      "errors": "Errors",
      "errorsList": "Errors list",
      "list": "List",
      fieldsErrors: {
        user: 'User',
        error: 'Error',
      },
    },
    "notifications": {
      "marketplace": {
        "title": 'Marketplace',
        "types": {
          "all": 'All',
          "spot": "SPOT",
          "futures": "FUTURES",
        }
      },
      "market": "Market",
      "copyFinandyConfig": "Copy Finandy config",
      "ryptovizorIp": "Cryptovizor server IP",
      "syncedFutures": "Synchronised with the FUTURES watchlist",
      "syncFutures": "Synchronise with the FUTURES watchlist",
      "signalUrl": "Signal URL",
      "templates": "Templates",
      "template": "Template",
      "webHookResponse": "Response from the integration server",
      "confirmEnableHook": "Confirm webhook activation",
      "confirmDisableHook": "Confirm webhook deactivation",
      "hookLogs": "Webhook logs",
      "hooks": "Webhooks",
      "hook": "Webhook",
      "confirmDeleteHook": "Confirm deletion of the webhook",
      "confirmAction": "Confirm action",
      "enterValidUrl": "Enter a valid url",
      "enterValidJson": "Enter valid json",
      "validJson": "Valid json",
      "createHook":"Webhook creation",
      "signalMessage": "Signal message",
      "createSignal": "Create notification",
      "triggerConditions": "Trigger conditions",
      "saveOnGraph": "Retain markers on the chart",
      "plotOnChartDuration": "Chart plot duration",
      "plotOnTheChart": "Plotted on the chart",
      "notifications": "Notifications",
      "isInActive": "Inactive",
      "active": "Active",
      "inActive": "Inactive",
      "savedInDraft": "Saved in draft.",
      "saveAsDraft": "Save as draft",
      "draftDeleted": "Draft deleted",
      "drafts": "Drafts",
      "source": "Source",
      "groupNotificationName": "Group notification name",
      "notificationName": "Notification name",
      "unit": "Unit",
      "positive": "Positive «+»",
      "negative": "Negative «-»",
      "unitLabel1": "K-thousands",
      "unitLabel2": "M-million",
      "unitLabel3": "B-billions",
      "tokenTooltip": "1 CREATOR or INFLUENCER token is required to create an alert.",
      "modalTitle": "Where to send notifications",
      "title": "Notification settings",
      "addBtn": "Add notification",
      "coin": "Coin",
      "placeholder": "Enter to search",
      "typeOfNotification": "Notification type",
      "deleteNotification": "Delete notification",
      "deleteNotificationText": "Are you sure you want to delete it?",
      "timeframe": "Timeframe",
      "variable": "Variable",
      "input": "Enter value",
      "addMore": "Add more",
      "repeat": {
        title: 'Repeat',
        once: 'Once',
        every: 'Every time',
      },
      "comment": "Comment",
      "activeListTitle": "Active alerts",
      "inactiveListTitle": "Alerts history",
      "emptyActiveTable": "Notification list is empty",
      "emptyInactiveTable": "Notification log is empty",
      notificationSettings: 'Notification settings',
      "trendsBotText": "For receiving trends notification, activate telegram bot via",
      "trendsBotLink": "link",
      "trendsBotLinkText": "Bot link",
      creatingNotification: 'Creating a notification',
      notification: 'Notification',
      viewNotification: 'View notification',
      editNotification: 'Edit notification',
      "important": "Important",
      "warning": "You have previously indicated a pair from another exchange, the notification module can not mix several different exchanges in one group notification",
      target: {
        title: 'Where to send',
        tg: 'In Telegram',
        web: 'To browser',
      },
      errorMessages: {
        hook: "In order to use the functionality of webhooks to connect 2fa",
        tg: 'To receive Telegram Bot notifications you need to verify the account. Please, click the “Verify your account through Telegram”.',
        web: 'Please, allow the browser notifications in order to receive them.',
        allDisabled: 'You can receive the browser notifications as well as the ones in telegram bot after the verification is done.',
      },
      create: 'Creation date',
      met: 'Activation date',
      published: "The notification has been published.",
      unpublished: "The notification has been removed from publication.",
      deleteNotificationNote: 'The notification has been deleted.',
      subscribeSuccess: "You have subscribed to the notification.",
      unsubscribeSuccess: "You have been unsubscribed from the notification.",
      addSuccess: 'Notification added',
      removeSuccess: 'Notification deleted',
      signalChanged: 'Notification updated',
      "single": "Single",
      "group": "Group"
    },
    "coinList": {
      "coinSettingsUpdated": "Coin settings updated",
      "coinsToDisplayInScreener": "Coins to display in Screener",
      "import": "import",
      "screener": "screener",
      "show": "show spot",
      "showPerp": "show perp",
      "trends": "trends",
      "coinsToDisplayInTrends": "Coins to display in Trends",
    },
    "permission": {
      "accessGrantedSuccessfully": "Доступ выдан успешно",
      "giveAccess": "Give access",
      "emailPlaceholder": "Email (min 3 chars)",
      "selectAnExpirationDate": "Select an expiration date",
      "numberOfDaysOfAccess": "Number of days of access",
      "subscription": "Subscription",
      "chooseAPlan": "Choose a plan",
      "users": "Users",
      "telegramAttached": "This user has no telegram attached",
      "changeBalance": {
        "title": "Change balance",
        "inputLabel": "User balance:",
        "calculatedInputLabel": "Will be changed",
        "buttonSave": "Change",
        "buttonCancel": "Cancel",
        "invalidFeedback": "Enter number",
        "successResponse": "Balance successfully changed",
        "errorResponse": "Error while changing balance",
      },
      "table": {
        "name": "Name",
        "email": "Email",
        "tariff": "Tariff",
        "tariffDaysLeft": "Days left",
        "trends": "Trends",
        "tariffExpired": "Expiration date",
        "lastLoginAt": "Last request",
        "createdAt": "Registration date",
        "active": "Block",
        "tgLink": "Reset tg.",
        "balance": "Balance",
        "refBalance": "Referral balance",
        "referrer": "Referral name",
        "referrer_link": "Referral link",
      }
    },
    subscribeConstructor: {
      name: 'Name',
      subscriptionName: 'Subscription name',
      subscriptionID: 'Subscription ID',
      requiredForTheServerSide: 'Required for the server side',
      chooseColor: 'Choose a color',
      subscriptionDescription: 'Subscription description',
      description: 'Description',
      subscriptionCost: 'Subscription cost',
      price: 'Price',
      oldPrice: 'Old<br>price',
      amountOfDiscount: 'Amount of discount',
      mainFunctions: 'Main functions',
      addLine: 'Add line',
      availablePages: 'Available pages',
    },
    whitelist: {
      codeListDownload: 'Code list download',
      download: 'Download',
      table: {
        user: 'User',
        tariff: 'Tariff',
        term: 'Term',
        trends: 'Trends'
      },
      inputHint: {
        downloadFileHint: 'Download file hint',
        example: 'Example',
        enterTheRates: '      <p class="font-weight-bold">Enter the rates in the file like this:</p>' +
          '      <p>delta, screener, delta_screener</p>' +
          '      <p>The term for issuing the tariff is indicated in seconds.</p>' +
          '      <p class="font-weight-bold">Timing in seconds:</p>' +
          '      <ul>' +
          '        <li>1 month - 2592000</li>' +
          '        <li>3 months - 7776000</li>' +
          '        <li>6 months - 15552000</li>' +
          '        <li>12 months - 31104000</li>' +
          '      </ul>' +
          '      <p>In the xls table, there should not be a space after the number.</p>',
      },
    },
  },
  "aboutUs": {
    "title": "About us",
    "1_listTitle": "Deep experience and trust",
    "1_listSubtitle": "Our team is in the market since 2016. We have launched and still managing some big projects:",
    "1_listItem_1": "One of the biggest communities of crypto traders",
    "1_listItem_2": "Consulting Agency",
    "1_listItem_3": "Trading Academy",
    "1_listItem_4": "Launching own trading tools",
    "2_listTitle": "More than 60k subscribers",
    "2_listSubtitle": "We share our analytics and trading ideas since 2017 free of charge. We value our  community of like-minded people who are ready to learn something new",
    "3_listTitle": "Consultancy and academy ",
    "3_listSubtitle": "We share our experience with new and experienced traders. We have more than 900 alumn",
    "partners": "Partners",
    "media": "Our media",
    "contacts": "Contacts",
    tgTTTools: 'https://t.me/cvizor_en',
    "chatLink": "Chat",
    "groupLink": "Group",
    tgTTToolschat: 'https://t.me/cvizor_eng',
    "fb_link": "https://www.facebook.com/cvizorcrypto/",
    "inst_link": "https://www.instagram.com/cvizorcom/",
    "twitter_link": "https://twitter.com/cvizorcom",
    "youtube_link": "https://www.youtube.com/channel/UCrFeHq_1nXqwh00aE9fevrA",
    "writeToUs": "Write to ",
    "form": {
      "name": "Name",
      "tg": "Telegram",
      "email": "Email",
      "subject": "Subject",
      "question": "Your question",
      "send": "Send",
      "response": "Your request sent",
      "info": "By submitting an application on the website, you consent to the <a target='_blank' href='https://cvizor.com/policy'>processing of personal data</a>",
    }
  },
  "screener": {
    "tables": "Tables",
    "timeframes": "Timeframes",
    "tableScreener": "Table Screener",
    "search": "Search",
    "searchPlaceholder": "Ticker",
    "searchClear": "Clear",
    "typing": "Start typing to find currency",
    "stats": "stats",
    openGraph: 'Open chart',
    "accessTooltip": "Available in PRO and PRO+alerts",
    "tablesView": {
      "sortByHintABS": "Sort by absolute value",
      "sortByHint%": "Sort by percentage value",
      "emptyListTitle": "There are no matching results for the selected filter",
      "sort": "Sort by:",
      "sortMobile": "Sort:",
      "slidingVolume": "Sliding volume",
      "colorFilterHint": {
        "danger": "Red Zone Price under MA = Downtrend",
        "warning": "Yellow Zone Price between МА = Actively Traded Zone",
        "success": "Green Zone Price above MA = Uptrend"
      },
      "volatDesc": "Volatility is calculated as a standard price change average",
      "volumeDesc": "Trading volume is set in USDT.<hr style='margin: 0' />The sort option automatically sorts the volume into a descending sequence by clicking Volume.<br />The arrow on the right side sorts the volume change."
    },
    "graphsView": {
      "panel": "Tools panel",
      "panelMobile": "Tools panel",
      "ticker": "Ticker",
      "open": "Opening price",
      "current": "Current price",
      "change": "Price change",
      "volat": "Volatility",
      "volatDesc": "Volatility is calculated as a standard price change average",
      "numberOfCharts": "Number of charts"
    },
    "trendsView": {
      "inDetail": "In detail",
      "trendSummary": "Trend summary",
      "bounceDesc": "The coin is placed into the “Bounce” tab if the trends are the same in the 1D to 3H time frames and different in 15m until 2H time frames. It means we can see the uptrend in the 1D time frame and the opposite (downtrend) in the lower time frames (15m - 2H)"
    },
    "treemap": {
      "numberOfCoins": "Number of coins",
      "parameter": "Parameter",
    },
    "deleteWatchlistModal": {
      "title": "Cleaning UP {name} watchlist",
      "subtitle": "The following action cleans out the chosen watchlist. Should we proceed?",
    },
    "playPauseBtn": 'When the “pause” button is clicked, all the screener inquiries will be stopped. To refresh them, please, click “play” button or reload page',
  },
  "formLogin": {
    "title": "Start using the Cryptovizor",
    "subtitle": "Sign in to your account",
    "email": "E-mail",
    "password": "Password",
    "forgotPassword": "Forgot Password?",
    "signIn": "Sign in",
    "signUp": "Sign up",
    "MainPage": "Main page",
    "registerTitle": "New to us? ",
    "registerSubtitle": "If you do not have an account yet, it’s a perfect time to get it ",
    "greeting": "Hi,",
    "2FAinfo": "Enter code from 2FA app",
  },
  "formRegister": {
    "title": "Registration",
    "subtitle": "Create a new account ",
    "name": "Nickname",
    "email": "E-mail",
    "password": "Password",
    "repeatPassword": "Repeat Password",
    "signUp": "Registration",
    "goBack": "Back",
    "text": "By clicking the register button you agree to ",
    "linkText": "privacy policy "
  },
  "formRecovery": {
    "title": "Password reset",
    "subtitle": "You will receive an email with instructions on how to reset your password in a few minutes",
    "back": "Back",
    "reset": "Reset",
    "setNewPassword": "Please set your new password",
    "new": "New password",
    "repeat": "Confirm new password"
  },
  "formSignUp": {
    "title": "Sign up ",
    "subtitle": "You will receive an email with a confirmation link within 15 minutes",
    "confirm": "Please confirm email",
    "sendLink": "Send confirmation email link",
    "logout": "Logout",
    "signIn": "Sign in",
    "confirmationTitle": "Email confirmation",
    "expiredText": "Unfortunately, the time allotted for clicking on the link and the glass. To complete the registration, try to log in and click the button with a re-order of the letter",
  },
  "delta": {
    "nav": "Navigation",
    "navDescription": "Orderbook details of the area or below the candle",
    "datePlaceholder": "dd/mm/yy hh:mm ➜ dd/mm/yy hh:mm",
    "datePlaceholderMobile": "Select period",
    "expand": "Expand",
    "quoteVolumeDelta": "Difference between buy and sell volume (market orders)",
    "quoteVolume": "Trading volume in USD+USDT",
    quoteDate: 'Unavailable dates in the calendar mean there is no downloaded data on the chart. Navigate to the desired date on the chart to download the data.',
  },
  "trends": {
    "sendByTrend": "Send by trend",
    "datePlaceholder": "dd/mm/yy hh:mm",
    "perfectlyFit": "Perfectly fit",
    "onlyPositive": "Up only",
    "onlyNegative": "Down only",
    "summary": "Summary",
    "table": "Table",
    "up": "Up",
    "down": "Down",
    "notifactionGroup": "Group messages",
    "notifactionGroupHints": "You will receive trend changes every 5 minutes",
    "notifactionGroupHintsSecond": "Each change in the trend of coins will be sent separately"
  },
  "lineRunner": {
    "notallowedTooltip": "Ticker line is avaliable in the PRO subscription",
    "title": " Running line",
    "common": "General parameters",
    "emptyParams": "Empty parameters",
    "param": "Parameter",
    "addParam": "Add parameter",
    "coins": "Coins",
    "addCoin": "Add coin",
    "emptyCoins": "Empty coins",
    "coin": "Coin",
    "setupParams": "Setup parameters",
    "save": "Save",
    "requiredField": "Required field",
    "notValidate": "The data is not valid",
    "show": 'Running tape display',
  },
  "maSettings": {
    "notallowedTooltip": "Available in PRO and PRO+ALERTS",
    "description": "The are values restriction requirements, minimum - 5 and maximum - 365. The higher MA can not be lower then the small one",
    "low": "Low МА",
    "high": "High МА",
    "validateRange": "Minimum - 5 and maximum - 365",
    "validateOrder": "The higher MA can not be lower then the small one",
    "maDisplayType1": "Show absolute values",
    "maDisplayType2": "Show comparison in %",
    "maType1": "Show distance in % from MA to Price",
    "maType2": "Show distance in % from Price to MA",
    "maTypeTitle": "Interest calculation method",
    "maDisplayTypeError": "At least one display option must be chosen"
  },
  "onboarding": {
    "back": "Back",
    "next": "Next",
    "of": "of",
    "deltaPage": onboardingDeltaData
  },
  "referral": {
    "have_code": "I have a referral code",
    "input": {
      "placeholder": "Referral code"
    }
  },
  emojiPicker: {
    search: 'Search emojis...',
    categories: {
      recents: 'Recent Emojis',
      smileys: 'Smileys & Emotion',
      people: 'People & Body',
      animals: 'Animals & Nature',
      food: 'Food & Drink',
      activities: 'Activities',
      travel: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom'
    },
    notFound: 'No emojis found'
  },
  validateMessages: {
    coinIsRequired: 'Coin - required field',
    sendingTypeIsRequired: 'Sending type - required field',
    repeatabilityIsRequired: 'Repeatability - required field',
    missingAlertType: 'Missing notification type',
    timeframeIsRequired: 'Timeframe - required field',
    parameterIsRequired: 'Parameter - required field',
    valueIsRequired: 'Value - required field',
  },
  pageNotFound: {
    title: 'Error',
    subtitle: 'Page not found',
    link: 'Go to start page',
  },
  pagePermissionDenied: {
    title: 'Error',
    subtitle: 'Page access denied',
    link: 'Go to start page',
  },
  pageTechnicalWorks: {
    title: 'The service is undergoing technical work. The service will not be available from 15:00 to 18:00 UTC.',
  },
  tvPropsDropdown: {
    tooltip: 'Notifications',
    hideNotifications: 'Hide notifications',
    hideSignalsPlot: 'Hide signals marks',
    showNotifications: 'Show notifications',
    showSignalsPlot: 'Show signals marks',
    removeSignalsPlot: 'Remove signals marks',
    signalsPlotRemoved: 'Signals marks {pair} removed',
    signalsPlotRemoveNone: 'No signals marks for<br>{pair}',
    confirmRemoveSignalsPlot: 'Deleting signals marks',
    confirmRemoveSignalsPlotDesc: 'Confirm deletion of signal marks from the chart',
  },
  pluralization: {
    days: "day | days",
    hours: "hour | hours",
    minutes: "minute | minutes",
    seconds: "second | seconds",
  },
}
