<template>
<div class="c-app w-100 h-100 position-absolute moveBanner justify-content-center align-items-center" :class="{ 'c-dark-theme': $store.getters.darkMode }">
  <CCard class="px-5 pt-5 pb-5 overflow-auto">
        <CSelect
          :label="$t('lk.account.selectLang')"
          :value.sync="locale"
          :options="$store.state.localeOptions"
          class="moveBanner__localSelect"
        />
    <h1 class="text-center mb-4">
      {{contemt[locale].title}}
    </h1>
    <p v-html="contemt[locale].string1">
    </p>
    <p v-html="contemt[locale].string2">

    </p>
    <p class="text-center mb-3">
      {{contemt[locale].subTitle}}
    </p>
    <p class="mb-3" v-html="contemt[locale].string3">
    </p>
    <p class="text-right">
      {{contemt[locale].string4}} <br/>
      <img v-if="$store.getters.darkMode" :src="`${BUILD_PUBLIC_PATH}/img/svg/cryptovisorLogo.svg`">
      <img v-else :src="`${BUILD_PUBLIC_PATH}/img/svg/cryptovisorLogoLight.svg`">
    </p>
  </CCard>
</div>
</template>

<script>
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default {
  name: "MoveBanner",
  data() {
    return {
      BUILD_PUBLIC_PATH: BUILD_PUBLIC_PATH,
      contemt:{
        en:{
          title: "Dear users!!",
          string1: 'Our screener has moved to the new domain <a href="https://panel.cvizor.com/" target="_blank">panel.cvizor.com</a>, all your data has been saved and migrated.',
          string2: 'You will be automatically redirected to the new address after 30 seconds. If this does not happen, please go to the new address manually: <a href="https://panel.cvizor.com/" target="_blank">https://panel.cvizor.com/</a>',
          subTitle: "Enjoy your time!",
          string3: 'You can read the instructions on how to use the screener at <a href="https://cvizor.com/manual" target="_blank">https://cvizor.com/manual</a>. If you have any questions, please do not hesitate to contact us via <a href="https://t.me/cvizorchat" target="_blank">live chat</a> or feedback <a href="https://cvizor.com/contacts" target="_blank">form</a>.',
          string4: "Regards, team"
        },
        ru:{
          title: "Уважаемые пользователи!",
          string1: 'Наш скринер переехал на новый домен <a href="https://panel.cvizor.com/" target="_blank">panel.cvizor.com</a>, все ваши данные были сохранены и перенесены.',
          string2: 'Вы будете автоматически перенаправлены на новый адрес через 30 секунд. Если этого не произошло, то перейдите на новый адрес вручную: <a href="https://panel.cvizor.com/" target="_blank">https://panel.cvizor.com/</a>',
          subTitle: "Приятного пользования!",
          string3: 'Ознакомиться с инструкциями по работе со скринером вы можете по адресу <a href="https://cvizor.com/manual" target="_blank">https://cvizor.com/manual</a>. По всем вопросам обращайтесь в <a href="https://t.me/cvizorchat" target="_blank">телеграм чат</a> или через <a href="https://cvizor.com/contacts" target="_blank">форму</a> обратной связи.',
          string4: "С уважением, команда"
        }
      }
    }
  },
  computed: {
    locale: {
      get () {
        return this.$root.$i18n.locale
      },
      set (lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
p{
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.9rem;
}
.moveBanner{
  z-index: 99999;
  & .card{
    width: 80%;
    max-height: 80%;
  }
  &__localSelect{
    width: fit-content;
  }
  & img{
    max-width: 146px;
    max-height: 38px;
  }
}
</style>

