import Vue from 'vue'
import Vuex from 'vuex'
import trendsPage from "@/vuex/trendsPage"
import user from "@/vuex/user"
import lineRunner from "@/vuex/lineRunner"
import mediaQuery from "@/vuex/mediaQuery"
import browserNotify from "@/vuex/browserNotify"
import promo from "@/vuex/promo"
import axios from "axios"
import has from "lodash/has"
import screenerSettings from "@/assets/js/screenerSettings"
import screenerDataAdapter from "@/assets/js/screenerDataAdapter"
import screenerGraphAdapter from "@/assets/js/screenerGraphAdapter"
import screenerTrendAdapter from "@/assets/js/screenerTrendAdapter"
import i18n from "@/i18n"
import debounce from "lodash/debounce"
import {isArray, merge} from "lodash"
import {OnboardingSaveClass} from "@/assets/js/onboarding/OnboardingStep.class"
import headerItems from './vuex/headerItems'

Vue.use(Vuex)

// const initDarkMode = localStorage.getItem('darkMode') === null || localStorage.getItem('darkMode') === 'true'
const state = {
  lastExchange: 'binance',
  lastScreen: 'spot',

  lineRunnerPaused: false,
  routerSpinner: false,
  localeOptions: [{value: 'ru', label: 'RU'}, {value: 'en', label: 'EN'}],
  settings: {},
  h: {},
  isSetVideojsBeforeRequest: false,
  deltaMap: {},
  raketa: [],
  pairs: [],
  isBrowserTabActive: true,
  tablesData: [],
  cancelToken: null,
  initLocale: null,
}

const actions = {
  get ({dispatch, state}) {
    if(state.user?.userData?.isGuest){
      return Promise.resolve({
        status: 200
      })
    }
    axios.get('/api/v1/users/settings').then(({status, data}) => {
      if (status < 300 && data?.data) {
        dispatch('setInit', data.data)
      }
    })
  },
  setInit({state, commit}, value) {
    // commit('SET_INIT', payload)
    const payload = merge(state.settings, value)

    // screener "search"
    if (has(payload, 'screener.selectedPairs')) {
      screenerSettings._selectedPairs = payload.screener.selectedPairs
      screenerSettings._selectedPairsPerp = payload.screener.selectedPairsPerp
    }

    // tablesView page
    if (has(payload, 'tablesView.sortCol')) {
      screenerDataAdapter._sortCol = payload.tablesView.sortCol
    }
    if (has(payload, 'tablesView.sortType')) {
      screenerDataAdapter._sortType = payload.tablesView.sortType
    }
    if (has(payload, 'tablesView.sortTypeBase')) {
      screenerDataAdapter._sortTypeBase = payload.tablesView.sortTypeBase
    }
    if (has(payload, 'tablesView.activeCol')) {
      screenerDataAdapter._activeCol = payload.tablesView.activeCol
    }
    if (has(payload, 'tablesView.isRsi')) {
      screenerDataAdapter._isRsi = payload.tablesView.isRsi
    }
    if (has(payload, 'tablesView.filterMA200Trend')) {
      screenerDataAdapter._filterMA200Trend = payload.tablesView.filterMA200Trend
    }
    if (has(payload, 'tablesView.isSlidingVolume')) {
      screenerDataAdapter._isSlidingVolume = payload.tablesView.isSlidingVolume
    }

    // graphsView page
    if (has(payload, 'graphsView.sortCol')) {
      screenerGraphAdapter._sortCol = payload.graphsView.sortCol
    }
    if (has(payload,'graphsView.sortType')) {
      screenerGraphAdapter._sortType = payload.graphsView.sortType
    }
    if (has(payload, 'graphsView.activePeriod')) {
      screenerSettings.graphsActivePeriod = payload.graphsView.activePeriod
    }
    if (has(payload, 'graphsView.isCollapsed')) {
      commit('SET_KEY', {
        namespace: 'graphsView',
        key: 'isCollapsed',
        value: payload.graphsView.isCollapsed
      })
    }
    if (has(payload, 'graphsView.numberOfCharts')) {
      commit('SET_KEY', {
        namespace: 'graphsView',
        key: 'numberOfCharts',
        value: payload.graphsView.numberOfCharts
      })
    }

    // trendsView page
    if (has(payload, 'trendsView.sortCol')) {
      screenerTrendAdapter._sortCol = payload.trendsView.sortCol
    }
    if (has(payload, 'trendsView.sortType')) {
      screenerTrendAdapter._sortType = payload.trendsView.sortType
    }

    // overallVolume component
    if (has(payload, 'overallVolume.enableNavigation')) {
      commit('SET_KEY', {
        namespace: 'overallVolume',
        key: 'enableNavigation',
        value: payload.overallVolume.enableNavigation
      })
    }
    // const filterDate = payload?.overallVolume?.filterDate
    // if (filterDate && filterDate.start && filterDate.end) {
    //   commit('overallVolume/setFilterDate', {
    //     start: new Date(filterDate.start),
    //     end: new Date(filterDate.end)
    //   })
    // }

    // watchLists on account page
    // if (has(payload, 'createdWatchlists')) {

    //   const arr = payload.createdWatchlists.map(a => new WatchListsItem(a))
    //   screenerSettings._watchLists = arr
    // } else {
    //   const defaultArray = [
    //     new WatchListsItem({name: 'List 1', color: 'danger'}),
    //     new WatchListsItem({name: 'List 2', color: 'success'}),
    //     new WatchListsItem({name: 'List 3', color: 'watch-list-primary'}),
    //     new WatchListsItem({name: 'List 4', color: 'warning'})
    //   ]
    //   screenerSettings._watchLists = defaultArray
    // }

    // locale
    if (has(payload, 'global.locale')) {
      const getLocale = (value) => ['ru', 'en'].indexOf(value) !== -1 ? value : 'ru'
      const lang = getLocale(payload.global.locale)

      if(i18n.locale !== lang){
        i18n.locale = lang
        commit('SET_KEY', {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
      state.initLocale = lang
    }

    // darkMode
    if (has(payload, 'global.darkMode')) {
      commit('SET_KEY', {
        namespace: 'global',
        key: 'darkMode',
        value: payload.global.darkMode
      })
    }

    // onboarding
    if (has(payload, 'global.onboarding')) {
      commit('SET_KEY', {
        namespace: 'global',
        key: 'onboarding',
        value: new OnboardingSaveClass(payload.global.onboarding)
      })
    }

    // lineRunner
    if (has(payload, 'lineRunner.select')) {
      const arr = payload.lineRunner.select
      commit('lineRunner/SET_SELECT', arr)
    }
    if (has(payload, 'lineRunner.generalParams')) {
      const arr = payload.lineRunner.generalParams
      commit('lineRunner/SET_GENERAL_PARAMS', arr)
    }

    if (has(payload, 'lineRunner.showLineRunner')) {
      commit('lineRunner/SET_SHOW_LINE_RUNNER', payload.lineRunner.showLineRunner)
    }

    // MA settings
    if (has(payload, 'ma.high')) {
      commit('SET_KEY', {
        namespace: 'ma',
        key: 'high',
        value: payload.ma.high
      })
    }
    if (has(payload, 'ma.low')) {
      commit('SET_KEY', {
        namespace: 'ma',
        key: 'low',
        value: payload.ma.low
      })
    }

    //treemap
    if (has(payload, 'treemap.tf')) {
      commit('SET_KEY', {
        namespace: 'treemap',
        key: 'tf',
        value: payload.treemap.tf
      })
    }
    if (has(payload, 'treemap.param')) {
      commit('SET_KEY', {
        namespace: 'treemap',
        key: 'param',
        value: payload.treemap.param
      })
    }
    if (has(payload, 'treemap.filterColor')) {
      commit('SET_KEY', {
        namespace: 'treemap',
        key: 'filterColor',
        value: payload.treemap.filterColor
      })
    }
    if (has(payload, 'treemap.viewCount')) {
      commit('SET_KEY', {
        namespace: 'treemap',
        key: 'viewCount',
        value: payload.treemap.viewCount
      })
    }

    if (has(payload, 'notifications.collapseNotifySettings')) {
      commit('SET_KEY', {
        namespace: 'notifications',
        key: 'collapseNotifySettings',
        value: payload.notifications.collapseNotifySettings
      })
    }
  },
  setKey({dispatch, commit}, payload) {
    commit('SET_KEY', payload)
    return dispatch('save', payload.namespace)
  },
  setKeyImmediately({dispatch, commit}, payload) {
    commit('SET_KEY', payload)
    return dispatch('saveImmediately', payload.namespace)
  },
  save: debounce(({dispatch}, namespace) => {
    return dispatch('saveRequest', namespace)
  }, 1000),
  saveImmediately({dispatch}, namespace) {
    return dispatch('saveRequest', namespace)
  },
  saveRequest ({state}, namespace) {
    if(state.user?.userData?.isGuest){
      return Promise.resolve({
        status: 200
      })
    }
    if(isArray(namespace)){
      let data = namespace.map(item => {
        return {
          namespace: item,
          attach: state.settings[item]
        }
      })
      return axios.post('api/v1/users/settings', data)
    }
    return axios.post('api/v1/users/settings', {
      namespace: namespace,
      attach: state.settings[namespace]
    })
  }
}

const mutations = {
  setLineRunnerPaused (state, payload) {
    state.lineRunnerPaused = payload
  },
  setLastExchange (state, payload) {
    if (payload) state.lastExchange = payload;
  },
  setLastScreen (state, payload) {
    if (payload) state.lastScreen = payload;
  },

  setCancelToken(state, paylod){
    state.cancelToken = paylod
  },
  setTablesData(state, payload = []){
    state.tablesData = payload
    // localStorage.setItem('tablesData', JSON.stringify(payload))
  },
  setIsBrowserTabActive(state, payload){
    state.isBrowserTabActive = payload
  },
  setPairs(state, payload){
    state.pairs = payload
  },
  setRouterSpinner (state, payload) {
    this.state.routerSpinner = payload
  },
  SET_INIT(state, payload) {
    state.settings = payload
  },
  SET_KEY(state, payload) {
    if (
      payload &&
      payload.namespace &&
      payload.key
    ) {
      if (!state.settings[payload.namespace]) {
        state.settings[payload.namespace] = {}
      }

      state.settings = {
        ...state.settings,
        [payload.namespace]: {
          ...state.settings[payload.namespace],
          [payload.key]: payload.value
        }
      }

      const isLocale = payload.namespace === 'global' && payload.key === 'locale'
      const isDarkMode = payload.namespace === 'global' && payload.key === 'darkMode'

      if (isDarkMode) {
        localStorage.setItem('settings.global.darkMode', state.settings.global.darkMode || '')
      }

      if (isLocale) {
        localStorage.setItem('settings.global.locale', state.settings.global.locale)
      }

      // localStorage.setItem('settings', JSON.stringify(state.settings))
      // state.settings[payload.namespace][payload.key] = payload.value
    } else {
      console.warn('Не правильный формат установки настроек', payload)
    }
  }
}

const getters = {
  getLineRunnerPaused (state) {
    return state.lineRunnerPaused
  },
  getLastExchange (state) {
    return state.lastExchange;
  },
  getLastScreen (state) {
    return state.lastScreen;
  },

  cancelToken: state => state.cancelToken,
  tablesData: state => state.tablesData,
  isBrowserTabActive: state => state.isBrowserTabActive,
  pairs: state => state.pairs,
  theme:  state => {
    const value = state.settings?.global?.darkMode
    return (value === undefined || value === null || value) ? 'dark' : 'light'
  },
  themeCapitalize: (state, getters) => {
    const str = getters.theme
    return str[0].toUpperCase() + str.slice(1)
  },
  darkMode: (state, getters) => {
    return getters.theme === 'dark'
  },
  onboarding: state => {
    return state.settings?.global?.onboarding || {}
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    headerItems,
    trendsPage,
    user,
    lineRunner,
    mediaQuery,
    browserNotify,
    promo,
  }
})
