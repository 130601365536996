// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['113 173', `
<g>
<path d="M105.5 4L109.5 13L105.5 11.5L101.5 13L105.5 4Z" fill="#4b4b54"/>
<path d="M2 171C36.5 158.667 105.5 107.5 105.5 11.5M105.5 11.5L109.5 13L105.5 4L101.5 13L105.5 11.5Z" stroke="url(#paint0_linear_5751_49424)" stroke-width="3" stroke-linecap="round"/>
</g>
<defs>
<linearGradient id="paint0_linear_5751_49424" x1="106" y1="17.5" x2="2.5" y2="171" gradientUnits="userSpaceOnUse">
<stop stop-color="#4b4b54"/>
<stop offset="1" stop-color="#4b4b54" stop-opacity="0"/>
</linearGradient>
</defs>
`]
