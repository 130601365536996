<template>
  <div class="screenerTableSettings">
    <div class="screenerTableSettings__header">
      <div class="screenerTableSettings__tabs">
        <TabSelector
          v-model="selectedScreenType"
          :class="'tabSelector'"
          :options="screensList"
          :hide-title="true"
        />
      </div>
    </div>

    <transition-group
      tag="div"
      class="row"
      name="list"
    >
      <CCol
        v-for="(item, i) in watchLists"
        :key="`${i}-watchlistItem`"
        col="12"
        md="6"
        xl="4"
        class="list-item"
      >
        <watch-item
          :is-custom="i > 3"
          :index="i"
          :data="item"
          :change-handler="changeHandler"
          :save-handler="saveHandle"
          :is-futures="isFutures"
          @delete="openAttentionModal(i)"
        />
      </CCol>
      <CCol
        ref="add-btn"
        key="add-btn"
        col="12"
        md="6"
        xl="4"
        class="list-item"
      >
        <CButton
          v-tooltip="{
              content: $t('lk.settings.addWatchlist'),
            }"
          class="shadow-none add-btn"
          color="primary"
          @click="addWatchList"
        >
          <CIcon name="cil-plus" size="lg"/>
        </CButton>
      </CCol>
    </transition-group>

    <CModal
      :title="$t('lk.settings.deleteWatchlistModal.title', { name: watchList && watchList.name ? watchList.name : '' })"
      centered
      scrollable
      :show="!!watchList"
      @update:show="closeAttentionModal"
    >
      <p>{{ $t('lk.settings.deleteWatchlistModal.subtitle') }}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CButton
            color="primary"
            class="mr-2"
            @click="deleteWatchList"
          >
            {{$t('general.yes')}}
          </CButton>
          <CButton
            color="dark"
            @click="closeAttentionModal"
          >
            {{$t('general.no')}}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import WatchItem from "@/components/account/WatchItem.vue";
import screenerSettings from "@/assets/js/screenerSettings";
import randomColor from "randomcolor";
import TabSelector from "@/components/training/tabSelector.vue";

export default {
  name: "WatchLists",
  components: {
    TabSelector,
    WatchItem
  },
  data() {
    return {
      settings: screenerSettings,
      randomColor,
      watchListIndex: null,
      selectedScreenType: 'spot',
      screensList: [
        { label: 'SPOT', key: 'spot' },
        { label: 'FUTURES', key: 'futures' }
      ]
    }
  },
  computed: {
    watchLists() {
      return this.isFutures ? this.settings.watchListsBinancePerp : this.settings.watchLists
    },
    watchList() {
      return this.isFutures ? this.settings.watchListsBinancePerp[this.watchListIndex] : this.settings.watchLists[this.watchListIndex]
    },
    isFutures() {
      return this.selectedScreenType === 'futures'
    },
  },
  methods: {
    addWatchList() {
      const obj = {
        name: 'List ' + (this.watchLists.length + 1),
        customColor: randomColor(),
      }

      if (this.isFutures) {
        this.settings.addWatchListBinancePerp(obj)
      } else  {
        this.settings.addWatchList(obj)
      }
    },
    enter() {
      this.$refs["add-btn"].scrollIntoView({
        behavior: 'smooth',
      })
    },
    openAttentionModal (index) {
      this.watchListIndex = index
    },
    closeAttentionModal () {
      this.watchListIndex = null
    },
    deleteWatchList() {
      if (this.isFutures) {
        this.settings.deleteWatchListBinancePerp(this.watchListIndex)
      } else {
        this.settings.deleteWatchList(this.watchListIndex)
      }

      this.closeAttentionModal()
    },
    changeHandler(a,b) {
      if (this.isFutures) {
        this.settings.setWatchListsBinancePerpItem(a, b)
      } else {
        this.settings.setWatchListsItem(a, b)
      }
    },
    saveHandle() {
      if (this.isFutures) {
        this.settings.promiseSaveWatchBinancePerp()
      } else {
        this.settings.promiseSaveWatch()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
}

.add-btn {
  font-size: 2rem;
  line-height: 0;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
}

.list-item {
  transition: all .5s;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(2rem);
}

.list-leave-active {
  position: absolute;
}

.tabSelector {
  /deep/ .btn-group {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    flex-direction: row !important;
  }

  /deep/ .button-custom {
    border-bottom-right-radius: 0;
    background: transparent;
    display: flex;
    gap: 5px;

    &._active {
      background: var(--second-card-bg);
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }

  &._level-2 /deep/ ._active {
    background: map-get($theme-colors, $key: 'third-card-bg');

    .c-dark-theme & {
      background: map-get($theme-colors__dt, $key: 'third-card-bg');
    }
  }
}

.screenerTableSettings{
  &__header{
    display: flex;
    justify-content: space-between;
  }
  &__tabs{
    display: flex;
    gap: 30px;
  }
}
</style>
