<template>
  <div :class="['radio-button', {'_checked': checked}]">
    <input
      :id="value"
      :value="value"
      type="radio"
      name="radio"
      :checked="checked"
      @change="$emit('change',$event.target.value)"
    />
    <span class="radio-button__checkmark"></span>
    <div class="radio-button__label">
      {{ label }}
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VRadioButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    currentValue: {
      required: true,
      type: String,
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed:{
    checked(){
      return this.currentValue === this.value
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-button {
  display: flex;
  position: relative;
  cursor: pointer;
  height: fit-content;
  user-select: none;
  & *{
    transition: 0.2s;
  }
  & input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }
  &__checkmark {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    background: #FFFFFF;
    border: 1px solid #C8CED3;
    border-radius: 50%;
    margin-right: 8px;
  }
  & input:checked ~ &__checkmark {
    display: flex;
    background: #4A90E2;
    justify-content: center;
    align-items: center;
  }

  &__checkmark:after {
    content: '';
    opacity: 0;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }

  & input:checked ~ &__checkmark:after {
    opacity: 1;
  }
}
</style>

