<template>
  <v-popover
    :handleResize="false"
    :open="open"
    :offset="15"
    trigger="manual"
    container=".c-app"
    placement="top-start"
    v-bind="popoverOptions"
    v-on="$listeners"
    @show="setColorPicker"
    @hide="hideEvent"
  >
    <slot/>

    <template slot="popover">
      <div
        ref="color-picker"
        class="color-picker"
        :class="{'_dark-theme': $store.getters.darkMode}"
        @mouseleave="$emit('update:open', false)"
      />
    </template>
  </v-popover>
</template>

<script>
import colorPicker from 'tui-color-picker';
import randomColor from 'randomcolor';

export default {
  name: "ColorPicker",
  props: {
    color: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    popoverOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      colorPickerInstance: null,
    }
  },
  beforeDestroy() {
    this.destroyColorPicker()
  },
  methods: {
    setColorPicker() {
      this.colorPickerInstance = colorPicker.create({
        container: this.$refs["color-picker"],
        usageStatistics: false,
        preset: randomColor({ count: 16 }),
        color: this.color,
      })

      this.colorPickerInstance.on('selectColor', (e) => {
        this.$emit('update:color', e.color)
        this.$emit('selectColor', e.color)
      });
    },
    destroyColorPicker() {
      if (this.colorPickerInstance) {
        try {
          this.colorPickerInstance.destroy()
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },
    hideEvent() {
      this.$emit('update:open', false)
      setTimeout(() => {
        this.destroyColorPicker()
      }, 150)
    },
  },
}
</script>

<style scoped lang="scss">
.color-picker {
  border-radius: .25rem;
  padding: .5rem;
  background-color: transparent;

  /deep/ {
    input.tui-colorpicker-palette-toggle-slider {
      display: none;
    }

    .tui-colorpicker-slider-container {
      display: block !important;
    }

    .tui-colorpicker-palette-hex {
      width: 87%;
      margin: .25rem .25rem .25rem 0;
      padding: 0 .5rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      color: #5c6873;
      background-color: #fff;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        color: #5c6873;
        background-color: #fff;
        border-color: #8ad4ee;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.26);
      }
    }
  }

  &._dark-theme {
    /deep/ {
      .tui-colorpicker-palette-hex {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.09);
      }

      .tui-colorpicker-huebar-handle {
        fill: white;
      }

      .tui-colorpicker-palette-button.tui-colorpicker-selected {
        border-color: white;
      }
    }
  }
}

.v-popover {
   position: absolute;
 }
</style>
