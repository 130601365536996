var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    _vm._g(
      _vm._b(
        {
          attrs: {
            handleResize: false,
            open: _vm.open,
            offset: 15,
            trigger: "manual",
            container: ".c-app",
            placement: "top-start",
          },
          on: { show: _vm.setColorPicker, hide: _vm.hideEvent },
        },
        "v-popover",
        _vm.popoverOptions,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      _c("template", { slot: "popover" }, [
        _c("div", {
          ref: "color-picker",
          staticClass: "color-picker",
          class: { "_dark-theme": _vm.$store.getters.darkMode },
          on: {
            mouseleave: function ($event) {
              return _vm.$emit("update:open", false)
            },
          },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }