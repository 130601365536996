var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-md-center mb-2 flex-column flex-md-row",
        },
        [
          _c("h3", { staticClass: "mb-0 mr-3" }, [
            _vm._v(_vm._s(_vm.$t("lineRunner.common"))),
          ]),
          _c(
            "CLoadingButtonCustom",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.toolTipContent,
                  expression: "toolTipContent",
                },
              ],
              staticClass: "mt-3 mt-md-0",
              class: { "--disabled": !_vm.hasAccess },
              attrs: {
                color: "success",
                loading: _vm.loadingRequest,
                disabled: _vm.loadingRequest || _vm.isGuest,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-save" } }),
              _vm._v(" " + _vm._s(_vm.$t("lineRunner.save")) + " "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mt-4 mb-3 mt-md-0 mb-md-0 ml-md-3 d-flex align-items-center",
            },
            [
              _c("label", { staticClass: "d-block mb-0 mr-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("lineRunner.show")) + " "),
              ]),
              _c("CSwitch", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.toolTipContent,
                    expression: "toolTipContent",
                  },
                ],
                staticClass: "flex-shrink-0",
                attrs: {
                  color: "primary",
                  shape: "pill",
                  disabled: !_vm.hasAccess,
                  checked: _vm.$store.state.lineRunner.showLineRunner,
                },
                on: { "update:checked": _vm.toggleLineRunner },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.generalParams.length
        ? _c("p", [_vm._v(_vm._s(_vm.$t("lineRunner.emptyParams")))])
        : _vm._e(),
      _c(
        "CRow",
        { staticClass: "mb-3" },
        [
          _vm._l(_vm.generalParams, function (param, index) {
            return _c(
              "CCol",
              {
                key: index,
                staticClass: "param",
                attrs: { col: "12", md: "6", lg: "4" },
              },
              [
                _c(
                  "CRow",
                  [
                    _c(
                      "CCol",
                      { attrs: { col: "4" } },
                      [
                        _c("CSelect", {
                          staticClass: "mb-0",
                          attrs: {
                            size: "sm",
                            value: param.tf,
                            label: "TF",
                            placeholder: "TF",
                            "is-valid": _vm.$get(param, "errors.tf")
                              ? false
                              : null,
                            "invalid-feedback": _vm.$get(param, "errors.tf"),
                            options: _vm.tfOptions,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(param, "tf", $event)
                            },
                            change: function ($event) {
                              return param.validateTf()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { col: "6", md: "5" } },
                      [
                        _c("CSelect", {
                          staticClass: "mb-0",
                          attrs: {
                            size: "sm",
                            value: param.label,
                            label: _vm.$t("lineRunner.param"),
                            "is-valid": _vm.$get(param, "errors.label")
                              ? false
                              : null,
                            "invalid-feedback": _vm.$get(param, "errors.label"),
                            placeholder: "param",
                            options: _vm.paramOptions,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(param, "label", $event)
                            },
                            change: function ($event) {
                              return param.validateLabel()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      {
                        staticClass: "indent-btn-sm",
                        attrs: { col: "2", md: "3" },
                      },
                      [
                        _c(
                          "CButton",
                          {
                            attrs: { color: "danger", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.generalParams.splice(index, 1)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-x" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.generalParams.length < 3
            ? _c(
                "CCol",
                { attrs: { col: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "CButton",
                    {
                      class: { "indent-btn-sm": _vm.generalParams.length },
                      attrs: { color: "primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          _vm.generalParams.push(new _vm.Param())
                        },
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-plus" } }),
                      _vm._v(" " + _vm._s(_vm.$t("lineRunner.addParam")) + " "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("h3", [_vm._v(_vm._s(_vm.$t("lineRunner.coins")))]),
      !_vm.coins.length
        ? _c("p", [_vm._v(_vm._s(_vm.$t("lineRunner.emptyCoins")))])
        : _vm._e(),
      _vm._l(_vm.coins, function (item, i) {
        return _c(
          "CRow",
          { key: i, staticClass: "item" },
          [
            _c(
              "CCol",
              { attrs: { col: "12" } },
              [
                _c(
                  "CRow",
                  { staticClass: "mb-3" },
                  [
                    _c("CCol", { attrs: { col: "10", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-0" },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("lineRunner.coin"))),
                          ]),
                          _c("v-select-input", {
                            staticClass: "mb-0",
                            attrs: {
                              classes: _vm.$get(item, "errors.coin")
                                ? "is-invalid"
                                : "",
                              options: _vm.coinsOptions,
                              clearable: false,
                              placeholder: "coin",
                              loading: _vm.isLoadingCoins,
                              disabled: _vm.isLoadingCoins,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.toListCoin($event, item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "no-options",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("general.emptySearch")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "option",
                                  fn: function (slotScope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "select_options",
                                          style: {
                                            color: slotScope.option.isWatchList
                                              ? slotScope.option.color
                                              : null,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(slotScope.option.label) +
                                              " "
                                          ),
                                          slotScope.option.color
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "circle",
                                                  style: {
                                                    color:
                                                      slotScope.option.color,
                                                  },
                                                },
                                                [_vm._v(" ● ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.coin,
                              callback: function ($$v) {
                                _vm.$set(item, "coin", $$v)
                              },
                              expression: "item.coin",
                            },
                          }),
                          _vm.$get(item, "errors.coin")
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" " + _vm._s(item.errors.coin) + " "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "CCol",
                      { attrs: { col: "2", md: "1" } },
                      [
                        _c(
                          "CButton",
                          {
                            staticClass: "indent-btn",
                            attrs: { color: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.coins.splice(i, 1)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-x" } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      {
                        staticClass:
                          "indent-switch d-flex flex-md-column align-items-center align-items-md-start mt-3 mt-md-2",
                        attrs: { col: "12", md: "5" },
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "d-block mb-0 mr-3 mb-md-2" },
                          [_vm._v(_vm._s(_vm.$t("lineRunner.setupParams")))]
                        ),
                        _c("CSwitch", {
                          staticClass: "flex-shrink-0",
                          attrs: {
                            color: "primary",
                            shape: "pill",
                            checked: item.customParams,
                          },
                          on: {
                            "update:checked": [
                              function ($event) {
                                return _vm.$set(item, "customParams", $event)
                              },
                              function ($event) {
                                return _vm.setCustomParams(item)
                              },
                            ],
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { col: "12" } },
              [
                !item.params.length
                  ? _c("p", { class: { "text-danger": item.errors.params } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lineRunner.emptyParams")) + " "
                      ),
                    ])
                  : _vm._e(),
                item.customParams
                  ? _c(
                      "CRow",
                      { staticClass: "mb-3" },
                      [
                        _vm._l(item.params, function (param, index) {
                          return _c(
                            "CCol",
                            {
                              key: index,
                              staticClass: "param",
                              attrs: { col: "12", md: "6", lg: "4" },
                            },
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { col: "4" } },
                                    [
                                      _c("CSelect", {
                                        staticClass: "mb-0",
                                        attrs: {
                                          size: "sm",
                                          value: param.tf,
                                          label: "TF",
                                          placeholder: "TF",
                                          options: _vm.tfOptions,
                                          "is-valid": _vm.$get(
                                            param,
                                            "errors.tf"
                                          )
                                            ? false
                                            : null,
                                          "invalid-feedback": _vm.$get(
                                            param,
                                            "errors.tf"
                                          ),
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(param, "tf", $event)
                                          },
                                          change: function ($event) {
                                            return param.validateTf()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { col: "6", md: "5" } },
                                    [
                                      _c("CSelect", {
                                        staticClass: "mb-0",
                                        attrs: {
                                          size: "sm",
                                          value: param.label,
                                          label: _vm.$t("lineRunner.param"),
                                          placeholder: "param",
                                          options: _vm.paramOptions,
                                          "is-valid": _vm.$get(
                                            param,
                                            "errors.label"
                                          )
                                            ? false
                                            : null,
                                          "invalid-feedback": _vm.$get(
                                            param,
                                            "errors.label"
                                          ),
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              param,
                                              "label",
                                              $event
                                            )
                                          },
                                          change: function ($event) {
                                            return param.validateLabel()
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.emptySearch"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { col: "2", md: "3" } },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "indent-btn-sm",
                                          attrs: {
                                            color: "danger",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return item.params.splice(
                                                index,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-x" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        item.params.length < 3
                          ? _c(
                              "CCol",
                              { attrs: { col: "12", md: "6", lg: "4" } },
                              [
                                _c(
                                  "CButton",
                                  {
                                    class: {
                                      "indent-btn-sm": item.params.length,
                                    },
                                    attrs: { color: "primary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        item.params.push(new _vm.Param())
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-plus" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lineRunner.addParam")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "controls" },
        [
          _c(
            "CButton",
            {
              staticClass: "mr-3",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  _vm.coins.push(new _vm.Coin({ params: _vm.generalParams }))
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-plus" } }),
              _vm._v(" " + _vm._s(_vm.$t("lineRunner.addCoin")) + " "),
            ],
            1
          ),
          _c(
            "CLoadingButtonCustom",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.toolTipContent,
                  expression: "toolTipContent",
                },
              ],
              class: { "--disabled": !_vm.hasAccess },
              attrs: {
                color: "success",
                loading: _vm.loadingRequest,
                disabled: _vm.loadingRequest || _vm.isGuest,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-save" } }),
              _vm._v(" " + _vm._s(_vm.$t("lineRunner.save")) + " "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }