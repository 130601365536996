import store from "@/store";
const toastr = require('toastr')

export default function (target, to, from, next) {
  const accept = store.getters['user/getPermissions'](target)
  if (accept) {
    next()
  }
  else {
    if(to.name === 'lk-study'){
      next({
        name: 'lk-subscribe',
        query: {
          study: 1
        }
      })
    }else{
      toastr.error(`Страница "${to.name}" не доступна`, 'Ошибка')
      if (from.name) {
        next({name: '403'})
      } else {
        next(store.getters["user/homepage"])
      }
    }
  }
}
