// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['22 22', `
<g filter="url(#filter0_i_6187_56686)">
<path d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11Z" fill="url(#paint0_linear_6187_56686)"/>
</g>
<circle opacity="0.16" cx="11" cy="11" r="8.5" stroke="#181818"/>
<defs>
<filter id="filter0_i_6187_56686" x="0" y="0" width="27" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="5" dy="6"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.32 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_6187_56686"/>
</filter>
<linearGradient id="paint0_linear_6187_56686" x1="3.38462" y1="2.96154" x2="19.4615" y2="19.0385" gradientUnits="userSpaceOnUse">
<stop offset="0.197917" stop-color="#D9A031"/>
<stop offset="0.390625" stop-color="#DAAF5B"/>
<stop offset="0.578125" stop-color="#D9A031"/>
</linearGradient>
</defs>
`]
