import store from "@/store";

export default async function () {
  if (store.getters["user/isLogged"]) {
    return true
  } else if(!localStorage.getItem('accessToken')) {
    return({name: 'auth'})
  } else {
    const load = (payload) => store.commit('setRouterSpinner', payload)

    try {
      load(true)
      await store.dispatch('user/getCurrent')
      return true
    } catch (e) {
      console.error(e)
      store.commit( 'user/SET_ACCESS_TOKEN', null)
      return({name: 'auth'})
    } finally {
      load(false)
    }
  }
}
