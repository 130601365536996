import { get } from 'lodash'
import screenerSettings from '@/assets/js/screenerSettings'

export class DataItem {
  constructor(init) {
    this._all_tf = null
    this._bounce = null
    this.class = null

    this.id = get(init, 'coin.id', null)
    this.coin = {
      label: get(init, 'coin.label', '—'),
      icon: get(init, 'coin.icon', ''),
      current: get(init, 'coin.price', '—')
    }

    screenerSettings.trendsPeriods.forEach(tf => {
      this[tf] = get(init, tf)
    })

    this.all_tf = (() => { // присвоит результат выполнения функции
      if (this.allTfPositive) return 1
      if (this.allTfNegative) return -1
      return 0
    })()

    this.bounce = (() => { // присвоит результат выполнения функции
      if (this.all_tf === 0) {
        if (this.bouncePositive) return 1
        if (this.bounceNegative) return -1
        return 0
      } else {
        return 0
      }
    })()

    if (this.bounce === 1) this.bounceClasses = 'text-success'
    if (this.bounce === -1) this.bounceClasses = 'text-danger'
  }

  get allTfPositive () {
    return screenerSettings.trendsPeriods.every(item => this[item] > 0)
  }
  get allTfNegative () {
    return screenerSettings.trendsPeriods.every(item => this[item] < 0)
  }
  set all_tf (value) {
    this._all_tf = value
  }
  get all_tf () {
    return this._all_tf
  }

  get bouncePositive () {
    const arr = screenerSettings.trendsPeriods
    const lowTfList = arr.slice(0, arr.length/2)
    const highTfList  = arr.slice(arr.length/2, arr.length)
    const highValue = highTfList.every(item => this[item] < 0)
    const lowValue = lowTfList.every(item => this[item] > 0)
    return highValue && lowValue
  }
  get bounceNegative () {
    const arr = screenerSettings.trendsPeriods
    const lowTfList = arr.slice(0, arr.length/2)
    const highTfList  = arr.slice(arr.length/2, arr.length)
    const highValue = highTfList.every(item => this[item] > 0)
    const lowValue = lowTfList.every(item => this[item] < 0)
    return highValue && lowValue
  }
  set bounce (value) {
    this._bounce = value
  }
  get bounce () {
    return this._bounce
  }
}
