import { TimeRange } from "@/assets/js/screenerDataClasses/TimeRange.class"
import get from 'lodash/get'
import screenerSettings from "@/assets/js/screenerSettings"
import WatchListsItem from "@/assets/js/screenerSettings/WatchListsItem.class";
export class DataItem {
  constructor(init) {
    this.id = get(init, 'coin.id', null)
    this.coin = {
      label: get(init, 'coin.label', '—'),
      icon: get(init, 'coin.icon', ''),
      current: get(init, 'coin.current', '—'), // use on trends page in coin cell
      watchLists: Array.isArray(get(init, 'coin.watchLists')) ? init.coin.watchLists.map(el => new WatchListsItem(el)) : []
    }
    this.pair = screenerSettings.pairsMap.findOne(this.coin.label)

    screenerSettings.tablesIntervals.forEach(tf => {
      const obj = get(init, tf)

      if (obj) {
        this[tf] = new TimeRange(obj)
      }
    })

    this._classes = get(init, '_classes', '')
  }
}
