<template>
  <CRow class="justify-content-center">
    <CCol
      :class="['c-col', {'p-0 w-100': inModal}]"
      :col="!inModal && '11'"
      :md="!inModal && '9'"
      :lg="!inModal && '7'"
      :xl="!inModal && 'auto'"
    >
      <CCardGroup class="ccard-group">
        <CCard
          :class="{'ccard-login--inModal': inModal}"
          class="ccard-login col-xl-7 shadow-none"
          color="second-card-bg"
        >
          <CCardBody class="ccard-body">
            <CForm @submit.prevent="submit">
              <div class="cheader">
                <a :href="homepage_url">
                  <logo-wrap :auth="true" />
                </a>
                <div class="d-flex align-items-center">
                  <div
                    class="mr-3 cursor-pointer"
                    @click="darkMode = !darkMode"
                  >
                    <CIcon
                      v-if="darkMode"
                      name="cil-sun"
                    />
                    <CIcon
                      v-else
                      name="cil-moon"
                    />
                  </div>
                  <CSelect
                    :value.sync="locale"
                    :options="$store.state.localeOptions"
                    class="mb-0"
                  />
                </div>
              </div>
              <template v-if="google2fa_enabled && currentUser">
                <h1 class="title text-white">
                  {{ $t('formLogin.greeting') }} {{ currentUser.name }}
                </h1>
                <p class="subtitle text-muted">{{ $t('formLogin.2FAinfo') }}</p>
              </template>
              <template v-else>
                <h1 class="title text-white">{{ $t('formLogin.title') }}</h1>
                <p class="subtitle text-muted">{{ $t('formLogin.subtitle') }}</p>
              </template>
              <CInput
                class="cinput rounded-right"
                v-model="email"
                :placeholder="$t('formLogin.email')"
                autocomplete="email"
                :is-valid="detectErrorField(errors.email)"
                :invalid-feedback="errors.email"
              >
                <template #prepend-content><CIcon name="mail" /></template>
              </CInput>
              <CInput
                class="cinput rounded-right"
                v-model="password"
                :placeholder="$t('formLogin.password')"
                type="password"
                autocomplete="password"
                :is-valid="detectErrorField(errors.password)"
                :invalid-feedback="errors.password"
              >
                <template #prepend-content><CIcon name="cil-lock-locked" /></template>
              </CInput>
              <CInput
                v-if="google2fa_enabled"
                class="cinput rounded-right"
                autocomplete="one-time-code"
                inputmode="numeric"
                v-model="code"
                :placeholder="$t('lk.account.6digitCode')"
              />
              <CRow>
                <CCol xl="6">
                  <CLoadingButtonCustom
                    block
                    color="primary"
                    class="cbtn-submit shadow-none"
                    type="submit"
                    :loading="loadingRequest"
                    :disabled="loadingRequest"
                  >
                    {{ $t('formLogin.signIn') }}
                  </CLoadingButtonCustom>
                </CCol>
                <CCol
                  xl="6"
                  class="subactions"
                >
                  <CButton
                    color="link"
                    class="cbtn-recovery text-primary"
                    :to="{ name: 'password-recovery', params: { email } }"
                  >
                    {{ $t('formLogin.forgotPassword') }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
        <CCard
          :class="{'ccard-register--inModal': inModal}"
          color="primary"
          text-color="white"
          class="ccard-register col-xl-4 shadow-none"
        >
          <CCardBody class="ccard-body h-100">
            <h1 class="title text-white">{{ $t('formLogin.registerTitle') }}</h1>
            <p class="subtitle text-muted">{{ $t('formLogin.registerSubtitle') }}</p>
            <CButton
              class="cbtn"
              color="white"
              variant="outline"
              :to="{ name: 'register' }"
            >
              {{ $t('formLogin.signUp') }}
            </CButton>
            <CButton
              class="cbtn cbtn-homepage"
              :class="'cbtn-homepage-' + locale"
              color="white"
              variant="outline"
              :href="homepage_url"
            >
              {{ $t('formLogin.MainPage') }}
            </CButton>
          </CCardBody>
        </CCard>
      </CCardGroup>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from '@/components/LogoWrap'
import { mapActions, mapGetters } from 'vuex'
import { formatErrorObject, detectErrorField } from '@/assets/js/axios/responseErrorHandler'
export default {
  name: 'Signin',
  components: {
    LogoWrap,
  },
  props: {
    redirect: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detectErrorField,
      loadingRequest: false,
      email: '',
      password: '',
      errors: {},
      google2fa_enabled: false,
      code: '',
    }
  },
  computed: {
    ...mapGetters('user', ['homepage']),
    currentUser() {
      return this.$store.state.user.userData
    },
    locale: {
      get() {
        return this.$root.$i18n.locale
      },
      set(lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit('SET_KEY', {
          namespace: 'global',
          key: 'locale',
          value: lang,
        })
      },
    },
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(val) {
        this.$store.commit('SET_KEY', {
          namespace: 'global',
          key: 'darkMode',
          value: val,
        })
      },
    },
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if (hp && hp.slice(-1) !== '/') {
        hp += '/'
      }
      if (this.locale === 'ru') {
        hp += 'ru/'
      }
      return hp
    },
  },
  methods: {
    ...mapActions('user', ['login']),
    submit() {
      this.loadingRequest = true
      let data = {
        email: this.email,
        password: this.password,
        device_name: Date.now().toString(),
      }

      if (this.google2fa_enabled) {
        data.code = this.code
      }

      this.login(data)
        .then(({ status, data }) => {
          if (status < 300) {
            this.errors = {}
            if (data?.code) {
              this.google2fa_enabled = true
            } else {
              this.$toastr.success(this.$t('lk.account.loginCompleted'))
              if(this.redirect){
                this.$router.push(this.homepage)
              }
              if(this.inModal){
                this.$emit('loggedIn')
                window.location.reload()
              }
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            const errorObj = error?.response?.data?.errors
            this.errors = formatErrorObject(errorObj)
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.col-xs {
  @include media-breakpoint-down(xs) {
    flex: 0 0 16.875rem;
    max-width: 16.875rem;
  }
}
.ccard-group {
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}
.ccard-login {
  padding: 0;

  &--inModal{
    margin: 0 !important;
    border-radius: 0.25rem 0.25rem 0 0 !important;
    max-width: 100% !important;
    @include media-breakpoint-down(xs) {
      .ccard-body {
        padding: 3.75rem 5.125rem 3.75rem !important;
      }
    }
    @include media-breakpoint-up(xl) {
      max-width: 60% !important;
    }
  }
  .ccard-body {
    display: flex;
    flex-direction: column;
  }
  .cheader {
    margin-bottom: 1.5rem;
    //text-align: center;
    display: flex;
    justify-content: space-between;
  }
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .cinput {
    /deep/ .form-control {
      height: 2.75rem;

      @include media-breakpoint-down(lg) {
        font-size: 1rem !important;
      }
    }
  }
  .cbtn-submit {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 2.75rem;
  }
  .subactions {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .cbtn-recovery {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 0;

    .ccard-body {
      padding: 1.25rem 0.9375rem;
    }
    .cheader {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
    }
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      margin-bottom: 1.875rem;
    }
    .cinput {
      margin-bottom: 0.5rem;
    }
    .subactions {
      margin-top: 1.25rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 3.75rem 5.125rem 3.75rem;
    }
    .subtitle {
      margin-bottom: 1.5rem;
    }
    .cinput {
      margin-bottom: 1rem;
    }
    .subactions {
      margin-top: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 4rem 5rem 5.3125rem 4rem;
    }
    .cheader {
      //text-align: start;
      display: flex;
      justify-content: space-between;
    }
    .title {
      text-align: start;
    }
    .subtitle {
      text-align: start;
      margin-bottom: 2.8125rem;
    }
    .subactions {
      justify-content: end;
      flex-direction: row;
      margin-top: 0;
    }
    .cbtn-register {
      display: none;
    }
  }
}
.ccard-register {
  padding: 0;

  &--inModal{
    margin: 0 !important;
    max-width: 100% !important;
    border-radius: 0 0.25rem 0.25rem 0 !important;
    @include media-breakpoint-down(xs) {
      .ccard-body {
        padding: 4.25rem 5.125rem 4.125rem !important;
      }
    }
    @include media-breakpoint-up(xl) {
      max-width: 40% !important;
    }
  }
  .ccard-body {
    display: flex;
    flex-direction: column;
  }
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .cbtn {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 1.5rem 0;
    width: 100%;
    height: 2.75rem;
  }
  .cbtn-homepage {
    border-color: transparent;
    transition: border-color 1s;
    margin: 1rem auto 0 auto;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 0;

    .ccard-body {
      padding: 2.75rem 0.9375rem 2.625rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      margin-bottom: 1.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    border-radius: 0 0 0.25rem 0.25rem !important;

    .ccard-body {
      padding: 4.25rem 5.125rem 4.125rem;
    }
    .subtitle {
      margin-bottom: 3.5rem;
    }
  }
  @include media-breakpoint-up(xl) {
    border-radius: 0 0.25rem 0.25rem 0 !important;

    .ccard-body {
      padding: 6.875rem 4rem 5.4375rem;
      align-items: start;
    }
    .title {
      text-align: start;
    }
    .subtitle {
      text-align: start;
      margin-bottom: 0;
    }
  }
}

.c-col {
  padding: 1rem 0;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
  }
}
</style>
