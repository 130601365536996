export default {
  namespaced: true,
  state: () => ({
    deviceWidth: window.innerWidth,
  }),
  actions: {},
  mutations: {
    SET_DEVICE_WIDTH (state, payload) {
      state.deviceWidth = payload
    }
  },
  getters: {
    device (state) {
      switch (true) {
        case state.deviceWidth >= 1440:
          return 'xxl'
        case state.deviceWidth >= 1200:
          return 'xl'
        case state.deviceWidth >= 992:
          return 'lg'
        case state.deviceWidth >= 768:
          return 'md'
        case state.deviceWidth >= 576:
          return 'sm'
        default:
          return 'xs'
      }
    },

    isSmallMobile (state, getters) {
      return 'xs' === getters.device
    },
    isMobile(state, getters) {
      return ['xs', 'sm'].some(el => el === getters.device)
    },
    isTablet(state, getters) {
      return 'md' === getters.device
    },
    isLaptop(state, getters) {
      return 'lg' === getters.device
    },
    isDesktop(state, getters) {
      return ['xl', 'xxl'].some(el => el === getters.device)
    },
    isDesktopXXL(state, getters) {
      return ['xxl'].some(el => el === getters.device)
    }
  }
}
