import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default [
  {
    header: 'Welcome to Cryptovizor service',
    text: '<div class="text-center">Cryptovizor is the most convenient trading analytics assistant. Let’s take a look on how it works:)<span>',
    img: BUILD_PUBLIC_PATH + '/img/png/onboarding-1.png',
  },
  {
    header: 'DELTA tool is designed to track BTC trading volume and delta.',
    text: `The data is tracked from the following exchanges and covers BTC trading pairs mentioned below:
    <ul>
        <li>Binance USDT, USDC, BUSD</li>
        <li>Bitfinex USD</li>
        <li>Bitstamp USD, USDT</li>
        <li>Coinbase USD</li>
        <li>FTX USD, USDT</li>
        <li>Huobi USDT</li>
        <li>Kraken USD, USDT</li>
        <li>KuCoin USDT</li>
    </ul>
    DELTA tool helps to analyze the data of all trading pairs and exchanges as well as to understand the current market situation.
    `,
  },
  {
    tooltips: [
      {
        header: 'Ticker',
        text: 'You can switch between the BTC trading pairs of the chosen exchange.',
        placement: 'top-start',
        offset: [300, 20],
        maxWidth: 800,
        theme: 'transparent top-start-arrow-position',
      },
      {
        header: 'TradingView price chart',
        text: 'Has got the basic functions as TradingView terminal itself. All the available tools are located on the left side of the panel. They are grouped by the tool type.',
        placement: 'right',
        offset: [0, 70],
      },
      {
        text: 'Price chart is auto-saved every 5 seconds',
        placement: 'right-start',
        offset: [10, 70],
        theme: 'transparent right-start-arrow-position',
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Markets Volume',
        text: 'This tool reflects the cumulative data of each exchange of all tracked trading pairs.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        text: 'It reflects the data of the current candlestick.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'OVERALL',
        text: 'This tool is an exchange weighted average price, total DELTA and trading volume of all trading pairs of the chosen exchanges.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'To get the price chart of a specific exchange and it’s BTC trading pairs you need to click on that exchange logo',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'The DELTA column',
        text: 'Reflects the difference between the volume of buy trades and the volume of the sell trades (calculated as USDt/USD ratio)',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'The VOLUME column',
        text: 'Reflects the trading volume of USDt + USD',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Sort by date',
        text: 'You can choose the time frame of the Markets Volume tool. It  helps to check the cumulative delta, cumulative trading volume of all exchanges and of each exchange separately.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Overall Volume',
        text: 'This tool reflects detailed data of each chosen cablestick of the chosen time frame.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Navigation',
        text: 'The Overall Volume tool would reflect the chosen value of the  candlestick which you click on when the Navigation feature is active.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'USDT/USD trading volume indicator. The indicator volume reflects the same data as the Overall Volume tool.',
        placement: 'right',
        offset: [100, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'Delta indicator (USDT / USD), data is the same as Overall Volume tool.',
        placement: 'right-end',
        offset: [-80, 70],
      }
    ],
  },
]
