// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const mail = ['13 8', `
<path d="M0.153809 1.93188V6.06825L3.59138 4.00007L0.153809 1.93188Z" fill="currentColor"/>
<path d="M12.846 6.06825V1.93188L9.40845 4.00007L12.846 6.06825Z" fill="currentColor"/>
<path d="M7.51988 5.13632C7.23929 5.30668 6.88459 5.40905 6.49996 5.40905C6.11534 5.40905 5.76064 5.3065 5.47574 5.13378L5.48005 5.13632L4.44132 4.51123L0.153809 7.09087V7.99996H12.8461V7.09087L8.55861 4.51123L7.51988 5.13632Z" fill="currentColor"/>
<path d="M0.153809 0V0.909091L6.092 4.48182C6.20419 4.55 6.34607 4.59091 6.49996 4.59091C6.65386 4.59091 6.79574 4.55 6.90974 4.48091L6.90793 4.48182L12.8461 0.909091V0L0.153809 0Z" fill="currentColor"/>
`]

