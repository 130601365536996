function fallbackCopyTextToClipboard (text, callback = () => {
}) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    callback(document.execCommand('copy'));
  } catch(err) {
    callback(false);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard (text, callback = () => {
}) {
  if(!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }

  navigator.clipboard
           .writeText(text)
           .then(function() {
             callback(true);
           })
           .catch(function() {
             callback(false);
           });
}
