import moment from 'moment';
import WatchListsItem from '@/assets/js/screenerSettings/WatchListsItem.class';

export default {
  id: -1,
  isGuest: true,
  name: 'Guest',
  email: 'guest@cvizor.com',
  permissions: {
    view_delta: true,
    view_screener: true,
    view_heat_map: true,
    use_custom_ma: true
  },
  settings: {
    global: {
      locale: localStorage.getItem('settings.global.locale') || 'en',
    },
    graphsView: {
      sortCol: 'volatility',
      sortType: 'desc',
      isCollapsed: true,
      activePeriod: '1h'
    }
  },
  tariffIsActive: true,
  tariff: {
    id: 'basic',
    active: true,
    name: {ru: "Basic", en: "Basic"}
  },
  tariff_days_left: 1,
  tariff_expired: moment().utc().format('YYYY-MM-DD') + ' 23:59:59',
  tariff_is_active: true,
  createdWatchlists: [
    new WatchListsItem({id: 'list1', name: 'List 1', color: 'danger'}),
    new WatchListsItem({id: 'list2', name: 'List 2', color: 'success'}),
    new WatchListsItem({id: 'list3', name: 'List 3', color: 'watch-list-primary'}),
    new WatchListsItem({id: 'list4', name: 'List 4', color: 'warning'})
  ],
  tg_link: false
}
