var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "screenerTableSettings" },
    [
      _c("div", { staticClass: "screenerTableSettings__header" }, [
        _c(
          "div",
          { staticClass: "screenerTableSettings__tabs" },
          [
            _c("TabSelector", {
              class: "tabSelector",
              attrs: { options: _vm.screensList, "hide-title": true },
              model: {
                value: _vm.selectedScreenType,
                callback: function ($$v) {
                  _vm.selectedScreenType = $$v
                },
                expression: "selectedScreenType",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "transition-group",
        { staticClass: "row", attrs: { tag: "div", name: "list" } },
        [
          _vm._l(_vm.watchLists, function (item, i) {
            return _c(
              "CCol",
              {
                key: `${i}-watchlistItem`,
                staticClass: "list-item",
                attrs: { col: "12", md: "6", xl: "4" },
              },
              [
                _c("watch-item", {
                  attrs: {
                    "is-custom": i > 3,
                    index: i,
                    data: item,
                    "change-handler": _vm.changeHandler,
                    "save-handler": _vm.saveHandle,
                    "is-futures": _vm.isFutures,
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.openAttentionModal(i)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "CCol",
            {
              key: "add-btn",
              ref: "add-btn",
              staticClass: "list-item",
              attrs: { col: "12", md: "6", xl: "4" },
            },
            [
              _c(
                "CButton",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.$t("lk.settings.addWatchlist"),
                      },
                      expression:
                        "{\n            content: $t('lk.settings.addWatchlist'),\n          }",
                    },
                  ],
                  staticClass: "shadow-none add-btn",
                  attrs: { color: "primary" },
                  on: { click: _vm.addWatchList },
                },
                [_c("CIcon", { attrs: { name: "cil-plus", size: "lg" } })],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("lk.settings.deleteWatchlistModal.title", {
              name:
                _vm.watchList && _vm.watchList.name ? _vm.watchList.name : "",
            }),
            centered: "",
            scrollable: "",
            show: !!_vm.watchList,
          },
          on: { "update:show": _vm.closeAttentionModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary" },
                          on: { click: _vm.deleteWatchList },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: { click: _vm.closeAttentionModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("lk.settings.deleteWatchlistModal.subtitle"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }