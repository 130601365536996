import Param from "@/assets/js/lineRunner/Param.class"
import i18n from "@/i18n"

export default class Coin {
  constructor(init) {
    this.coin = init?.coin || null
    this.params = init?.params ? init.params.map(i => new Param(i)) : []
    this.customParams = init?.customParams || false
    this.errors = {
      params: null,
      coin: null
    }
  }
  validateCoin () {
    if (!this.coin?.id) {
      this.errors.coin = i18n.t('lineRunner.requiredField')
      return false
    } else {
      this.errors.coin = ''
      return true
    }
  }
  validateParams () {
    let hasError = false
    if (!this.params.length) {
      this.errors.params = i18n.t('lineRunner.emptyParams')
      hasError = true
    } else {
      this.errors.params = ''
      this.params.forEach(param => {
        if (!param.validate()) hasError = true
      })
    }
    return !hasError
  }
  validate () {
    let hasError = false
    if (!this.validateCoin()) hasError = true
    if (!this.validateParams()) hasError = true
    const successValidate = !hasError
    return successValidate
  }
  toSave () {
    return {
      coin: this.coin,
      params: this.params,
      customParams: this.customParams
    }
  }
}
