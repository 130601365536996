import i18n from "@/i18n";

export default class Param {
  constructor(init) {
    this.tf = init?.tf || ''
    this.label = init?.label || ''
    this.errors = {
      tf: null,
      label: null
    }
  }
  validateLabel () {
    if (!this.label) {
      this.errors.label = i18n.t('lineRunner.requiredField')
      return false
    } else {
      this.errors.label = null
      return true
    }
  }
  validateTf () {
    if (!this.tf) {
      this.errors.tf = i18n.t('lineRunner.requiredField')
      return false
    } else {
      this.errors.tf = null
      return true
    }
  }
  validate () {
    let hasError = false
    if (!this.validateTf()) hasError = true
    if (!this.validateLabel()) hasError = true

    const successValidate = !hasError
    return successValidate
  }
}
