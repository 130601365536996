import Ticker from "@/assets/js/trendsDataClasses/Ticker.class";
import TickerSimple from "@/assets/js/trendsDataClasses/TickerSimple.class";
//import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'
import axios from "axios";
import intersectionBy from "lodash/intersectionBy";
import PairItem from "@/assets/js/screenerSettings/PairItem.class";
import orderBy from "lodash/orderBy";
import moment from 'moment'
import { sortByAlphabet } from "@/assets/js/helpers";
import store from "@/store";

export default {
  namespaced: true,
  state: () => ({
    itemsAll: [],
    itemsAllPercents: null,
    itemsTF: [],
    itemsStrata: [],
    activeKeyColumns: [],
    time: null,
    raketa: {},
    selectedTickers: [],
    sortPositive: null,
    sortNegative: null,
    sortStrata: null,
    tickerList: [],
    futuresTickerList: [],
  }),
  mutations: {
    setSelectedTickers (state, arr) {
      const sort = (x, y) => {
        return x.label.localeCompare(y.label, 'en', {ignorePunctuation: true, sensitivity: 'base'});
      }
      if (Array.isArray(arr)) {
        state.selectedTickers = arr.map(el => new PairItem(el)).slice().sort(sort)
      }
    },
    setItems (state, arr) {
      state.itemsAll = arr.map(v => new Ticker(v))
    },
    setItemsStrata (state, arr) {
      state.itemsStrata = arr.map(v => new TickerSimple(v))
    },
    setRaketa (state, payload) {
      state.raketa = payload
    },
    setItemsAllPercents (state, payload) {
      state.itemsAllPercents = payload
    },
    setItemsTF (state, arr) {
      state.itemsTF = arr.map(item => new TickerSimple(item))
    },
    setActiveCol (state, payload = {}) {
      const col = payload?.col
      const direction = payload?.direction
      const keys = Object.keys(state.itemsAllPercents)
      const activeIndex = state.activeKeyColumns.findIndex(el => el.col === col)
      let activeClass = ''
      const setActiveClass = () => {
        activeClass = direction === 'up' ? 'bg-cell-success' : 'bg-cell-danger'
      }

      if (payload) {
        if (activeIndex > -1) {
          const remove = state.activeKeyColumns[activeIndex].direction === direction

          if (remove) {
            state.activeKeyColumns.splice(activeIndex, 1)
            activeClass = ''
          } else {
            state.activeKeyColumns[activeIndex].direction = direction
            setActiveClass()
          }
        } else {
          state.activeKeyColumns.push({col, direction})
          setActiveClass()
        }
      } else {
        state.activeKeyColumns = []
      }

      state.itemsAll.forEach(item => {
        keys.forEach(field => {
          if (col === field) {
            item._cellClasses[field] = activeClass
          } else if (!payload) {
            item._cellClasses[field] = ''
          }
        })
      })
    },
    setSortPositive (state, payload = null) {
      state.sortPositive = payload
    },
    setSortNegative (state, payload = {}) {
      state.sortNegative = payload
    },
    setSortStrata (state, payload = {}) {
      state.sortStrata = payload
    },
    setTime (state, payload) {
      const newTimeUnix = moment(payload).unix()
      const currentTimeUnix = moment().unix()
      if(newTimeUnix > currentTimeUnix){
        state.time = new Date(moment.unix(currentTimeUnix).utc().format())
      }else{
        state.time = payload
      }

    },
    setTickerList (state, arr) {
      if (Array.isArray(arr)) {
        state.tickerList = arr.map(el => new PairItem(el))
      }
    },
    setFuturesTickerList () {
      if (!Array.isArray(this.tickerList)) {
        this.futuresTickerList = []
      } else {
        this.futuresTickerList = this.tickerList.filter(a => a.is_futures)
      }
    },
  },
  actions: {
    // setTimeAction ({dispatch, commit}, payload) {
    //   commit('setTime', payload)
    //   return dispatch('getData')
    // },
    getData ({ commit, state}) {
      const params = {}

      if (state.time) params.date = state.time.getTime() / 1000

      const screen = store.getters.getLastScreen;
      const exchange = store.getters.getLastExchange;

      const getURL = screen === 'spot'
        ? 'api/v2/trends'
        : `/api/v3/${exchange}_perp/trends`

      return axios.get(getURL, {
        params: params
      })
        .then((res) => {
          const {data, status} = res
          if (status < 300) {
            if (data?.data?.raketa) {
              commit('setRaketa', data.data.raketa)
            }
            if (data?.data?.strata) {
              commit('setItemsStrata', data.data.strata)
            }
            if (data?.data?.all?.percents) {
              commit('setItemsAllPercents', data.data.all.percents)
            }
            if (data?.data?.all?.data) {
              commit('setItems', data.data.all.data)
            }

            return res
          }
        })
    }
  },
  getters: {
    onlyPositive (state) {
      return state.itemsAll.filter(item => item.trend === 'up')
    },
    onlyPositiveResult (state, getters) {
      // результирующий геттер, обьединяющий сортировку и фильтрацию (фильтрации пока нет)
      return getters.sortedOnlyPositive
    },
    sortedOnlyPositive (state, getters) {
      const col = state.sortPositive?.col
      const direction = state.sortPositive?.direction
      if (!col || !direction) return getters.onlyPositive

      const arr = clone(getters.onlyPositive)
      return sortingArr(arr, col, direction)
    },
    onlyNegative (state) {
      return state.itemsAll.filter(item => item.trend === 'down')
    },
    onlyNegativeResult (state, getters) {
      // результирующий геттер, обьединяющий сортировку и фильтрацию (фильтрации пока нет)
      return getters.sortedOnlyNegative
    },
    sortedOnlyNegative (state, getters) {
      const col = state.sortNegative?.col
      const direction = state.sortNegative?.direction
      if (!col || !direction) return getters.onlyNegative

      //const arr = cloneDeep(getters.onlyNegative) ломала работу поиска по клику в трендах так как создавался клон и изменния ишли уже внутри него
      return sortingArr(getters.onlyNegative, col, direction)
    },
    sortedItems (state) {
      if (!state.activeKeyColumns.length) return state.itemsAll

      const arr = clone(state.itemsAll)
      const keys = state.activeKeyColumns.map((el) => el.col)
      const orders = state.activeKeyColumns.map((el) => el.direction === 'up' ? 'desc' : 'asc')

      return orderBy(arr, keys, orders)
    },
    filteredItems (state) {
      const selectedTickers = state.selectedTickers.filter(v => !v.disabledOnTrends)

      if (!selectedTickers.length) return state.itemsAll

      return state.itemsAll.filter(item => {
        const tickerLabel = item?.ticker
        if (tickerLabel) {
          return selectedTickers
            .map(v => v.ticker)
            .includes(tickerLabel)
        } else {
          return false
        }
      })
    },
    itemsAllResult (state, getters) {
      return intersectionBy(getters.sortedItems, getters.filteredItems, 'ticker')
    },
    getBtcTickerOnly (state) {
      return state.itemsAll.find(el => el.ticker.toLowerCase().trim() === 'btc')
    },
    getBtcPTickerOnly (state) {
      return state.itemsAll.find(el => el.ticker.toLowerCase().trim() === 'btc.p')
    },
    itemsStrataResult (state, getters) {
      // результирующий геттер, обьединяющий сортировку и фильтрацию (фильтрации пока нет)
      return getters.sortedItemsStrata
    },
    sortedItemsStrata (state) {
      const col = state.sortStrata?.col
      const direction = state.sortStrata?.direction
      if (!col || !direction) return state.itemsStrata

      const arr = clone(state.itemsStrata)
      return sortingArr(arr, col, direction)
    },
    raketaValue (state) {
      const val = state.raketa?.val
      if (val || val === 0) return val
      return '—'
    },
    raketaUp (state) {
      const val = state.raketa?.up
      if (val || val === 0) return val
      return '—'
    },
    raketaDown (state) {
      const val = state.raketa?.down
      if (val || val === 0) return val
      return '—'
    },
    percentsKeys (state) {
      if (state.itemsAllPercents) {
        return Object.keys(state.itemsAllPercents)
      }
      return []
    },
    tickerList (state) {
      return state.itemsAll.map(v => ({
        ...v,
        id: v.ticker,
        ticker: v.ticker
      }))
    },
    tickerFuturesList (state) {
      return state.itemsAll.map(v => ({
        ...v,
        id: v.ticker,
        ticker: v.ticker
      }))
    },
    tickerListFiltered (state) {
      if (!state.tickerList.length) return []
      return sortByAlphabet(state.tickerList, 'ticker')
    }
  }
}

/**
 * @param {Array} arr
 * @param {string} col
 * @param {string} dir
 * @param {boolean} reverseDir example sort by ticker needed revers
 * @returns {Array}
 */
function sortingArr(arr, col, dir, reverseDir = false) {
  return arr.sort((a, b) => {
    let more = null
    let less = null
    switch (dir) {
      case 'up':
        more = -1
        less = 1
        break
      case 'down':
      default:
        more = 1
        less = -1
        break
    }

    if (reverseDir) {
      more *= -1
      less *= -1
    }

    if (a[col] === b[col]) return 0
    return a[col] < b[col] ? more : less
  })
}
