import checkChangeEmail from "@/assets/js/routerFunctions/checkChangeEmail"
import beforeEnterAuth from "@/assets/js/routerFunctions/beforeEnterAuth"
import beforeEnterVerify from '@/assets/js/routerFunctions/beforeEnterVerify'
import saveRefHandler from '@/assets/js/routerFunctions/saveRefHandler'
import {routeLang, routeWithLang} from '@/router';
import store from '@/store'
import guestUser from '@/assets/js/guestUser'

export default async function (to, from, next) {
  await checkChangeEmail(to)
  const authResult = await beforeEnterAuth()
  const verifyResult = beforeEnterVerify()

  saveRefHandler(to)

  if (typeof authResult === 'object') {
    if(to.meta.isAccessAny){
      const locale = routeLang(to)
      if(locale){
        localStorage.setItem('settings.global.locale', locale)
        guestUser.settings.global.locale = locale
      }
      guestUser.settings.global.darkMode = localStorage.getItem('settings.global.darkMode')
      await store.commit('user/SET_USER_DATA', guestUser)
      await store.commit('user/SET_USER_WATCHLISTS', guestUser.createdWatchlists)
      await store.commit('user/SET_USER_WATCHLISTS_BINANCE_PERP', guestUser.createdWatchlists)
      await store.dispatch('setInit', guestUser.settings, {root: true})
      next()
    }else{
      next(routeWithLang(authResult, to))
    }
  } else if (typeof verifyResult === 'object') {
    next(routeWithLang(verifyResult, to))
  } else {
    next()
  }
}
