import { get, has } from 'lodash'
import axios from "axios";
import moment from "moment";
import Tariff from "@/assets/js/tariff/Tariff.class";

// const permissionChangeFields = ['newTariffExpired', 'blocked']

const ACCESS_LEVEL_CONFIG = {
  'basic': 0,
  'trial_pro': 1,
  'pro': 1,
  'pro_alerts': 2,
  'trial_pro_alerts': 2
}

export default class User {
  /**
   * сущность пользователь
   * @param {Object} init
   * @property {number} id
   * @property {string} name
   * @property {string} email
   * @property {Date | null} lastLoginAt
   * @property {Date | null} createdAt
   * @property {number} tariffDaysLeft
   * @property {Date | null} tariffExpired
   * @property {Object} tariff
   * @property {boolean} tariffIsActive
   * @property {object} permissions текущие права на сервере
   * @property {boolean} permissionTableLoad флаг обновления пользователя в таблице с правами
   * @property {boolean} emailVerifiedAt
   * @property {number} telegramId
   * @property {string} tgLink
   // * @property {string} oldPermissionState отслеживание изменений прав на странице permissions
   */
  constructor(init = {}) {
    this.id = get(init, 'id', null)
    this.isGuest = get(init, 'isGuest', false)
    this.blocked = has(init, 'active') ? !init.active : false
    this.newBlocked = this.blocked
    this.name = get(init, 'name', '')
    this.email = get(init, 'email', '')
    this.lastLoginAt = get(init, 'last_login_at', null)
    this.createdAt = get(init, 'created_at', null)
    this.tariffDaysLeft = get(init, 'tariff_days_left', 0)
    this.newTariffDaysLeft = this.tariffDaysLeft
    this.tariffExpired = get(init, 'tariff_expired', null)
    this.newTariffExpired = this.tariffExpired
    this.tariff = get(init, 'tariff') ? new Tariff(init.tariff) : new Tariff()
    this.tariffIsActive = get(init, 'tariff_is_active', false)
    this.permissions = get(init, 'permissions', {})
    this.education_flows_user = get(init, 'education_flows_user', [])
    this.events_users = get(init, 'events_users', [])
    this.eduTariffs = get(init, 'eduTariffs', [])
    //@todo hasTariffWeb3 deprecated
    this.hasTariffWeb3 = this.eduTariffs.includes('web3_edu')
    this.permissionTableLoad = false
    this.emailVerifiedAt = get(init, 'email_verified_at', false)
    this.telegramId = get(init, 'telegram_id', null)
    this.tg_nick = get(init, 'tg_nick', this.telegramId)
    this.tgLink = get(init, 'tg_link', null)
    // this.oldPermissionState = this.stateToStr(permissionChangeFields)
    this.referralCode = get(init, 'referral_code', '')
    this.balance = get(init, 'balance', 0)
    this.refBalance = get(init, 'referral_balance', 0)
    this.refIncome = get(init, 'referral_income', 0)
    this.google2faEnabled = get(init, 'google2fa_enabled', false)
    this.refCount = get(init, 'referrals_count', 0)
    this.enableTrends = get(init, 'tt_enable_trends', false)
    this.firstLoadDelta = get(init, 'first_load_delta', false)
    this.events = get(init, 'events', [])
    this.referrer = get(init, 'referrer', '')
    this.newEnableTrends = this.enableTrends
    this.settings = get(init, 'settings', {})
    this.trendsBotEnabled = get(init, 'trends_bot_enabled', false)

    this.balance = this.balance.toFixed(2)
    this.refBalance = this.refBalance.toFixed(2)

    this.createdWatchlists = get(init, 'createdWatchlists', [])
    this.createdWatchlistsBinancePerp = get(init, 'createdWatchlistsBinancePerp', [])
    this.telegram = init?.telegram?.chat ? init?.telegram : null
    this.meta_wallet = get(init, 'meta_wallet', null)
    this.author_id = get(init, 'author_id', null)

    this.setPromotions(
      get(init, 'promotions', []),
      get(init, 'promoSummary', {})
    )
  }

  setPromotions (promotions, promoSummary) {
    this.promotions = (promotions || []).map(item => {
      item.startAt = moment(item.start).utc(true)
      item.endAt = moment(item.end).utc(true)
      return item
    }).filter(item => {
      return moment().utc().isBetween(item.startAt, item.endAt)
    })
    this.promoSummary = promoSummary || {}
  }

  get hasPermissionTableChange () {
    return (
      this.newBlocked !== this.blocked ||
      this.newTariffExpired !== this.tariffExpired ||
      this.newEnableTrends !== this.enableTrends
    )
    // const newPermissionState = this.stateToStr(permissionChangeFields)
    // if (this.oldPermissionState.length !== newPermissionState.length) return true
    // return this.oldPermissionState !== newPermissionState
  }

  // stateToStr(arrFields) {
  //   if (Array.isArray(arrFields) && arrFields.length) {
  //     const obj = {}
  //     arrFields.forEach(item => {
  //       obj[item] = this[item]
  //     })
  //     return JSON.stringify(obj)
  //   }
  //
  //   return ''
  // }

  update () {
    const formData = new URLSearchParams()
    if (this.name) formData.append('name', this.name)
    if (this.email) formData.append('email', this.email)
    if (this.newTariffExpired !== this.tariffExpired) formData.append('tariff_expired', moment(this.newTariffExpired).format('YYYY-MM-DD'))
    if (this.newBlocked !== this.blocked) formData.append('active', this.newBlocked ? '0' : '1')
    if (this.newEnableTrends !== this.enableTrends) formData.append('tt_enable_trends', this.newEnableTrends ? '0' : '1')

    return axios.put('/api/v1/users/' + this.id, formData)
  }

  resetTelegram () {
    return axios.put(`/api/v1/users/${this.id}/reset-telegram`)
  }

  static getAll(params) {
    return axios.get('api/v1/users', {params})
      .then(res => {
        let arr = []
        if (res?.data?.data && Array.isArray(res?.data?.data)) {
          arr = res.data.data.map(item => new User(item))
        }
        return {
          ...res,
          data: {
            data: arr,
            meta: res.data.meta
          }
        }
      })
  }

  static getRefAll(params) {
    return axios.get('api/v2/referrals', {params})
      .then(res => {
        let arr = []
        if (res?.data?.data && Array.isArray(res?.data?.data)) {
          arr = res.data.data.map(item => new User(item))
        }
        return {
          ...res,
          data: {
            data: arr,
            meta: res.data.meta
          }
        }
      })
  }

  getAccess(requiredLevel, withoutTrial = false){
    const t = this.tariff?.id || ''
    if(!this.tariffIsActive || !t || ACCESS_LEVEL_CONFIG[t] === undefined || (withoutTrial && t.includes('trial_'))){
      return false
    }
    return ACCESS_LEVEL_CONFIG[t] >= requiredLevel
  }
}
