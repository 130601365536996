// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['86 86', `
<g clip-path="url(#clip0)">
  <path d="M42.9998 14.957C35.7834 14.957 29.9128 20.8274 29.9128 28.044C29.9128 35.2607 35.7832 41.1311 42.9998 41.1311C50.2165 41.1311 56.0868 35.2605 56.0868 28.044C56.0868 20.8276 50.2165 14.957 42.9998 14.957ZM42.9998 37.3918C37.8474 37.3918 33.6521 33.1965 33.6521 28.044C33.6521 22.8916 37.8474 18.6963 42.9998 18.6963C48.1523 18.6963 52.3476 22.8916 52.3476 28.044C52.3476 33.1965 48.1525 37.3918 42.9998 37.3918Z" fill="#20A8D8"/>
  <path d="M69.1739 52.3486C66.0967 52.3486 63.0829 53.1899 60.458 54.7791C59.5794 55.3176 59.2951 56.4654 59.8298 57.3479C60.3719 58.2304 61.5161 58.5108 62.3986 57.9761C64.4363 56.7383 66.7771 56.0877 69.1739 56.0877C76.3904 56.0877 82.2609 61.9581 82.2609 69.1747C82.2609 70.2067 83.0985 71.0443 84.1305 71.0443C85.1625 71.0443 86.0002 70.2067 86.0002 69.1747C86 59.8979 78.4507 52.3486 69.1739 52.3486Z" fill="currentColor"/>
  <path d="M25.5383 54.7753C22.9171 53.1899 19.9035 52.3486 16.8261 52.3486C7.5493 52.3486 0 59.8979 0 69.1747C0 70.2067 0.837623 71.0443 1.86962 71.0443C2.90162 71.0443 3.73924 70.2067 3.73924 69.1747C3.73907 61.9581 9.60962 56.0877 16.8261 56.0877C19.2229 56.0877 21.5635 56.7383 23.5977 57.9722C24.4763 58.507 25.628 58.2265 26.1665 57.3441C26.7049 56.4616 26.4206 55.3137 25.5383 54.7753Z" fill="currentColor"/>
  <path d="M69.1739 29.9131C64.0215 29.9131 59.8262 34.1084 59.8262 39.2609C59.8262 44.4133 64.0215 48.6086 69.1739 48.6086C74.3264 48.6086 78.5217 44.4133 78.5217 39.2609C78.5217 34.1084 74.3264 29.9131 69.1739 29.9131ZM69.1739 44.8695C66.0816 44.8695 63.5652 42.3532 63.5652 39.2609C63.5652 36.1685 66.0816 33.6522 69.1739 33.6522C72.2662 33.6522 74.7826 36.1685 74.7826 39.2609C74.7826 42.3532 72.2661 44.8695 69.1739 44.8695Z" fill="currentColor"/>
  <path d="M17.0917 29.9131C11.9353 29.9131 7.7439 34.1084 7.7439 39.2609C7.7439 44.4133 11.9392 48.6086 17.0917 48.6086C22.2441 48.6086 26.4394 44.4133 26.4394 39.2609C26.4394 34.1084 22.2478 29.9131 17.0917 29.9131ZM17.0917 44.8695C13.9993 44.8695 11.483 42.3532 11.483 39.2609C11.483 36.1685 13.9993 33.6522 17.0917 33.6522C20.184 33.6522 22.7004 36.1685 22.7004 39.2609C22.7004 42.3532 20.1838 44.8695 17.0917 44.8695Z" fill="currentColor"/>
  <path d="M42.9999 44.8691C29.5989 44.8691 18.6956 55.7724 18.6956 69.1735C18.6956 70.2055 19.5332 71.0431 20.5652 71.0431C21.5972 71.0431 22.4348 70.2055 22.4348 69.1735C22.4348 57.8327 31.6592 48.6084 42.9999 48.6084C54.3407 48.6084 63.5651 57.8327 63.5651 69.1735C63.5651 70.2055 64.4027 71.0431 65.4347 71.0431C66.4667 71.0431 67.3043 70.2055 67.3043 69.1735C67.3043 55.7724 56.401 44.8691 42.9999 44.8691Z" fill="#20A8D8"/>
</g>
<defs>
  <clipPath id="clip0">
    <rect width="86" height="86" fill="currentColor"/>
  </clipPath>
</defs>
`]
