import {get, isObject} from "lodash";

export default class OnboardingStepClass {
  constructor(init = {}) {
    this.header = get(init, 'header', '')
    this.text = get(init, 'text', '')
    this.img = get(init, 'img', '')
    this.tooltips = Array.isArray(get(init, 'tooltips')) ? init.tooltips.map(el => new OnboardingTooltipClass(el)) : []
    this.tippyInstances = Array.isArray(get(init, 'tippyInstances')) ? init.tippyInstances : []
    this.element = get(init, 'element', null)
  }

  get isBanner() {
    return !this.tooltips.length && (this.header || this.text || this.img)
  }
}

export class OnboardingTooltipClass {
  constructor(init = {}) {
    if (isObject(init)) {
      Object.entries(init).forEach(([k, v]) => {
        this[k] = v
      })
    }

    this.header = get(init, 'header', '')
    this.text = get(init, 'text', '')
  }
}

export class OnboardingSaveClass {
  constructor(init = {}) {
    if (isObject(init)) {
      Object.entries(init).forEach(([k, v]) => {
        const val = Number(v)

        this[k] = isNaN(val) || val < 0 ? 0 : val
      })
    }
  }
}
