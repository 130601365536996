var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "CModal",
        {
          staticClass: "loginModal",
          attrs: { show: _vm.show, centered: "", size: "xl" },
        },
        [
          _c("CIcon", {
            staticClass: "loginModal__close",
            attrs: { name: "cil-x" },
            nativeOn: {
              click: function ($event) {
                return _vm.close.apply(null, arguments)
              },
            },
          }),
          _c("Signin", {
            attrs: { inModal: true, redirect: false },
            on: { loggedIn: _vm.logInHandler },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }