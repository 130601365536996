var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c("h3", { staticClass: "title" }, [_vm._v(" MA ")]),
      _c("p", [_vm._v(_vm._s(_vm.$t("maSettings.description")))]),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: "12", sm: "6" } },
            [
              _c("CInput", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.toolTipContent,
                    expression: "toolTipContent",
                  },
                ],
                staticClass: "ma-input",
                attrs: {
                  disabled: _vm.notAllowedChangeMa,
                  value: _vm.low,
                  label: _vm.$t("maSettings.low"),
                  "invalid-feedback": _vm.errors.low,
                  "is-valid": _vm.detectErrorField(_vm.errors.low),
                },
                on: { change: _vm.changeLow },
              }),
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { col: "12", sm: "6" } },
            [
              _c("CInput", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.toolTipContent,
                    expression: "toolTipContent",
                  },
                ],
                staticClass: "ma-input",
                attrs: {
                  disabled: _vm.notAllowedChangeMa,
                  value: _vm.high,
                  label: _vm.$t("maSettings.high"),
                  "invalid-feedback": _vm.errors.high,
                  "is-valid": _vm.detectErrorField(_vm.errors.high),
                },
                on: { change: _vm.changeHigh },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ma-display-types" },
        [
          _c("CInputCheckbox", {
            attrs: {
              checked: _vm.maDisplayValue.includes("absolute"),
              label: _vm.$t("maSettings.maDisplayType1"),
            },
            on: {
              change: function ($event) {
                return _vm.handleCheckbox("absolute", $event.srcElement.checked)
              },
            },
          }),
          _c("CInputCheckbox", {
            attrs: {
              checked: _vm.maDisplayValue.includes("percent"),
              label: _vm.$t("maSettings.maDisplayType2"),
            },
            on: {
              change: function ($event) {
                return _vm.handleCheckbox("percent", $event.srcElement.checked)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "ma-type-wrapper" }, [
        _c("span", { staticClass: "ma-type-wrapper__title" }, [
          _vm._v(" " + _vm._s(_vm.$t("maSettings.maTypeTitle")) + ": "),
        ]),
        _c(
          "div",
          { staticClass: "ma-type-wrapper__types" },
          [
            _c(
              "VRadioButton",
              {
                attrs: {
                  value: "ma_price",
                  "current-value": _vm.maType,
                  name: "maType",
                },
                on: { change: _vm.radioChangeHandler },
              },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "ma-type-wrapper__item_img select" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/maToPrice.png"),
                          alt: "Ma to Price",
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "ma-type-wrapper__item_img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/maToPriceDesc.png"),
                        alt: "Ma to Price screen",
                      },
                    }),
                  ]),
                ]),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("maSettings.maType1")) + " "),
                ]),
              ]
            ),
            _c(
              "VRadioButton",
              {
                attrs: {
                  value: "price_ma",
                  "current-value": _vm.maType,
                  name: "maType",
                },
                on: { change: _vm.radioChangeHandler },
              },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "ma-type-wrapper__item_img select" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/priceToMa.png"),
                          alt: "Price to MA",
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "ma-type-wrapper__item_img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/priceToMaDesc.png"),
                        alt: "Price to MA screen",
                      },
                    }),
                  ]),
                ]),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("maSettings.maType2")) + " "),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "CLoadingButtonCustom",
        {
          attrs: {
            color: "success",
            loading: _vm.loadingRequest,
            disabled: _vm.loadingRequest,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }