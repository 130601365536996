import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default [
  {
    header: 'Приветствуем вас в сервисе Cryptovizor',
    text: '<div class="text-center">Cryptovizor — незаменимый помощник для трейдера. Далее мы расскажем как здесь всё устроено. Очень советуем досмотреть до конца:)<span>',
    img: BUILD_PUBLIC_PATH + '/img/png/onboarding-1.png',
  },
  {
    header: 'Модуль DELTA предназначен для отслеживания объемов торгов и дельты BTC.',
    text: `Сбор данных производится со следующих бирж и торговых пар:
    <ul>
        <li>Binance USDT, USDC, BUSD</li>
        <li>Bitfinex USD</li>
        <li>Bitstamp USD, USDT</li>
        <li>Coinbase USD</li>
        <li>FTX USD, USDT</li>
        <li>Huobi USDT</li>
        <li>Kraken USD, USDT</li>
        <li>KuCoin USDT</li>
    </ul>
    С помощью этого модуля у вас появляется возможность анализировать данные по всем торговым парам и биржам и видеть общую картину рынка.
    `,
  },
  {
    tooltips: [
      {
        header: 'Тикер',
        text: 'Здесь вы можете переключаться между парами к BTC внутри выбранной биржи.',
        placement: 'top-start',
        offset: [300, 20],
        maxWidth: 800,
        theme: 'transparent top-start-arrow-position',
      },
      {
        header: 'График TradingView',
        text: 'Имеет весь базовый функционал. Все доступные инструменты рисования расположены на панели слева. Они делятся на подгруппы в зависимости от типа инструмента.',
        placement: 'right',
        offset: [0, 70],
      },
      {
        text: 'Автосохранение разметки на графике производится каждые 5 секунд',
        placement: 'right-start',
        offset: [10, 70],
        theme: 'transparent right-start-arrow-position',
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Markets Volume',
        text: 'В данном блоке представлена информация кумулятивно по каждой бирже с учетом всех торговых пар.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        text: 'Это текущий таймфрейм на вашем графике. В данном блоке будет отображаться информация о текущей свече.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'OVERALL',
        text: 'Это график среднебиржевой цены, а также общая Delta и Volume всех торговых пар представленных бирж.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'Клик по названию биржи открывает график выбранной биржи и всех её торговых пар BTC',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Столбец DELTA',
        text: 'Отображает разницу между объемом сделок на покупку и объемом сделок на продажу, единица измерения USDt\\USD',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Столбец Volume',
        text: 'Отображает объем торгов USDT+USD',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Фильтр по дате',
        text: 'Вы можете задать необходимый вам временной диапазон для блока Markets Volume. Таким образом, вы сможете узнать кумулятивную дельту, кумулятивный объем торгов по всем биржам и по каждой в отдельности.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Overall Volume',
        text: 'В этом блоке отображается детализированная информация по каждой свече выбранного вами таймфрейма.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: 'Навигация',
        text: 'Если активна эта функция, то при клике по свече на графике, в блоке  Overall Volume выделится выбранное значение.',
        placement: 'left',
        offset: [0, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'Индикатор объема в USDT\\USD, данные в индикаторе совпадают с блоком Overall Volume.',
        placement: 'right',
        offset: [100, 70],
      }
    ],
  },
  {
    tooltips: [
      {
        header: '',
        text: 'Индикатор Delta в USDT\\USD, данные совпадают с блоком Overall Volume.',
        placement: 'right-end',
        offset: [-80, 70],
      }
    ],
  },
]
