var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "screenerTableSettings" },
    [
      _c("div", { staticClass: "screenerTableSettings__header" }, [
        _c(
          "div",
          { staticClass: "screenerTableSettings__tabs" },
          [
            _c("TabSelector", {
              class: "tabSelector",
              attrs: { options: _vm.screensList, "hide-title": true },
              model: {
                value: _vm.selectedScreenType,
                callback: function ($$v) {
                  _vm.selectedScreenType = $$v
                },
                expression: "selectedScreenType",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "CCard",
        { class: "ccard shadow-none", attrs: { color: "second-card-bg" } },
        [
          _c(
            "CCardBody",
            { class: "ccard-body" },
            [
              _c("div", { staticClass: "screenerTableSettings__header" }, [
                _c(
                  "div",
                  { staticClass: "screenerTableSettings__tabs w-100" },
                  [
                    _c("TabSelector", {
                      class: "tabSelector _level-2",
                      attrs: { options: _vm.tabsList, "hide-title": true },
                      model: {
                        value: _vm.selectedTab,
                        callback: function ($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "CCard",
                {
                  key: _vm.selectedScreenType,
                  class: "ccard shadow-none",
                  attrs: { color: "third-card-bg" },
                },
                [
                  _c(
                    "CCardBody",
                    { class: "ccard-body" },
                    [
                      _c("TimeFrameSettings", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === 0,
                            expression: "selectedTab === 0",
                          },
                        ],
                        attrs: {
                          "screen-type": _vm.selectedScreenType,
                          "active-settings-count": _vm.activeSettingsCount,
                        },
                        on: {
                          "change-time-frame-settings":
                            _vm.setActiveTfSettingsCount,
                        },
                      }),
                      _c("ScreenerColumnsSettings", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === 1,
                            expression: "selectedTab === 1",
                          },
                        ],
                        attrs: {
                          "screen-type": _vm.selectedScreenType,
                          "active-settings-count": _vm.activeSettingsCount,
                        },
                        on: {
                          "change-screener-columns-settings":
                            _vm.setActiveColumnSettingsCount,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }