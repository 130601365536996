import router from '@/router/index'
import store from '@/store'
import {has} from 'lodash'
import i18n from "@/i18n";
const toastr = require('toastr')

export default function (error) {
	if (error.response) {
		const statusCode = error.response.status
		let errorText = ''
		if (has(error, 'response.data.errors')) {
			errorText = JSON.stringify(error.response.data.errors)
		} else if (has(error, 'response.data.message')) {
			errorText = error.response.data.message
		} else if (has(error, 'response.data.messages')) {
			errorText = error.response.data.messages
		} else if (has(error, 'response.statusText')) {
			errorText = error.response.statusText
		} else {
			errorText = JSON.stringify(error.response)
		}

		switch (statusCode) {
		case 401:
      store.commit('user/SET_ACCESS_TOKEN', null)
      if(router.currentRoute && (!router.currentRoute.meta || !router.currentRoute.meta.isAccessAny)){
        store.dispatch('user/toggleShowLoginModal', {show: true})
      }
			break
		case 404:
			toastr.error(errorText, `${i18n.t('general.error')} ${statusCode}`)
			//router.push('/404')
			break
		case 422:
      if (error?.config?.hideErrorNotify) return;

			if (has(error, 'response.data.errors')) {
				if (typeof error.response.data.errors === 'object') {
					Object.values(error.response.data.errors)
						.map(function (msg) {
							if (Array.isArray(msg)) {
								msg.map(str => toastr.error(str, `${i18n.t('general.error')} ${statusCode}`))
							} else {
								toastr.error(msg, `${i18n.t('general.error')} ${statusCode}`)
							}
						})
				} else {
					errorText = JSON.stringify(error.response.data.errors)
					toastr.error(errorText, `${i18n.t('general.error')} ${statusCode}`)
				}
			}
			break
		default:
			toastr.error(errorText, `${i18n.t('general.error')} ${statusCode}`)
		}
	} else {
		toastr.error(error, i18n.t('general.serverIsNotAvailable'))
	}
}

export function formatErrorObject(obj = {}) {
  const newObj = {}
  for (const key in obj) {
    const str = obj[key].reduce((item, accum) => {
      return `${accum} ${item}`
    }, '')
    newObj[key] = str
  }
  return newObj
}
export function detectErrorField(value) {
  if (value) {
    return false
  } else {
    return null
  }
}

export function textErrorFromResponse(response, separator1 = '<br>', separator2 = ', ') {
  let err = [
    (response.status ? `[${response.status}] ` : '') + `${response.statusText || i18n.t('general.error')}`
  ]
  let error = ''
  if (has(response, 'data.errors')) {
    error = response.data.errors
  } else if (has(response, 'data.message')) {
    error = response.data.message
  } else if (has(response, 'data.messages')) {
    error = response.data.messages
  }
  if(error){
    if(typeof error === 'object'){
      error = Object.values(error)
    }
    if(Array.isArray(error)){
      error.map(function (msg) {
        if(typeof msg === 'object'){
          msg = Object.values(error)
        }
        if(Array.isArray(msg)){
          msg = msg.join(separator2)
        }
        if(typeof msg !== 'string'){
          msg = JSON.stringify(msg)
        }
        err.push(msg)
      })
    }else{
      if (typeof error !== 'string') {
        error = JSON.stringify(error)
      }
      err.push(error)
    }
  }
  return err.join(separator1)
}
