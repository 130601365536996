import {
  cilApps,
  cilListRich,
  cilInfo,
  cibBitcoin,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilArrowLeft,
  cilArrowRight,
  cilArrowBottom,
  cilArrowTop,
  cilBullhorn,
  cilCalendar,
  cilCloudDownload,
  cilCheck,
  cilDollar,
  cilList,
  cilLockLocked,
  cilVerticalAlignTop,
  cilMoon,
  cilPencil,
  cilSave,
  cilSettings,
  cilSpreadsheet,
  cilSun,
  cilUser,
  cilUserPlus,
  cilLink,
  cilCopy,
  cilEducation,
  cilVideo,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilX,
  cilSearch,
  cilGraph,
  cilMoney,
  cilImage,
  cilLockUnlocked,
  cilCloudUpload,
  cilPlus,
  cilReload,
  cilChartLine,
  cilDelete,
  cilClock,
  cilCheckCircle,
  cilBarChart,
  cilResizeHeight,
  cilLayers,
  cilCode,
  cilHappy,
  cilWallet,
  cilMediaPause,
  cilMediaPlay,
  cibLetsEncrypt,
  cilChevronCircleDownAlt,
  cilWarning,
  cibTelegramPlane,
  cilBook,
  cilGroup,
  cilDescription,
  cilResizeBoth,
  cilResizeWidth,
  cilFullscreenExit,
  cilFullscreen,
  cilTrash,
  cilApplications
} from '@coreui/icons'
import { logo } from './logo'
import { fileExcel } from './file-excel'
import { arrowTrends } from './arrow-trends'
import { arrowStrong } from './arrow-strong'
import { arrowTrendsO } from './arrow-trends-o'
import { trendsStar } from './trends-star'
import { mail } from './mail'
import { bitcoin } from './bitcoin'
import { keyhole } from './keyhole'
import { key } from './key'
import appsIcon from './appsIcon'
import arrowFilled from './arrow-filled'
import fileOk from './file-ok'
import fileError from './file-error'
import curator from './curator'
import table from './table'
import coin_1 from './coin_1'
import coin_3 from './coin_3'
import coin_2 from './coin_2'
import rows from './rows'
import certificate from './certificate'
import onlineCourse from './online-course'
import person1 from './person1'
import star from './star'
import person from './person'
import users from './users'
import exchange from './exchange'
import flag from './flag'
import eyeOpen from './eye-open'
import eyeClose from './eye-close'
import treemap from './treemap'
import { menu } from './menu'
import bell from './bell'
import tarining from './training'
import check from './check'
import complain from './complain'
import show from './show'
import unshow from './unshow'
import emptyList from './emptyList'
import pointer from './pointer'
import copy from './copy'




export const iconsSet = Object.assign({}, {
  cilMediaSkipBackward,
  cilMediaSkipForward,
  logo,
  coin_1,
  coin_3,
  coin_2,
  fileExcel,
  arrowTrends,
  arrowStrong,
  arrowTrendsO,
  trendsStar,
  mail,
  bitcoin,
  keyhole,
  complain,
  key,
  arrowFilled,
  appsIcon,
  fileOk,
  fileError,
  curator,
  rows,
  table,
  show,
  unshow,
  certificate,
  onlineCourse,
  person1,
  star,
  person,
  users,
  exchange,
  flag,
  eyeOpen,
  eyeClose,
  treemap,
  menu,
  bell,
  check,
  emptyList,
  pointer,
  copy
}, {
  cilListRich,
  cilApps,
  cilApplications,
  cilInfo,
  cilArrowLeft,
  cilArrowRight,
  cilArrowBottom,
  cilArrowTop,
  cilBullhorn,
  cilCalendar,
  cilCloudDownload,
  cilCheck,
  cilDollar,
  cilList,
  cilLockLocked,
  cilMoon,
  cilPencil,
  cilSave,
  cilSettings,
  cilSpreadsheet,
  cilVerticalAlignTop,
  cilSun,
  cilUser,
  cilUserPlus,
  cilLink,
  cilCopy,
  cilEducation,
  cilVideo,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilX,
  cilCheckCircle,
  cibTelegramPlane,
  cilSearch,
  cilGraph,
  cilMoney,
  cilImage,
  cilLockUnlocked,
  cilCloudUpload,
  cilPlus,
  cilReload,
  cilChartLine,
  cilDelete,
  cilBarChart,
  cilResizeHeight,
  cilLayers,
  cilClock,
  cilCode,
  cilHappy,
  cilWallet,
  cilMediaPause,
  cilMediaPlay,
  cibLetsEncrypt,
  cilChevronCircleDownAlt,
  cilWarning,
  tarining,
  cilBook,
  cilGroup,
  cilDescription,
  cilResizeBoth,
  cilResizeWidth,
  cilFullscreenExit,
  cilFullscreen,
  cilTrash
}, {
  cibBitcoin
})
