export default {
  namespaced: true,
  state: () => ({
    showPromoModal: false,
    promoModalContent: {},
  }),
  actions: {
    toggleShowPromoModal({ commit }, { show, content = { id: 'pro' } }) {
      commit('SET_PROMO_MODAL_CONTENT', content)
      commit('SET_SHOW_PROMO_MODAL', show)
    },
  },
  mutations: {
    SET_SHOW_PROMO_MODAL(state, payload) {
      state.showPromoModal = payload
    },
    SET_PROMO_MODAL_CONTENT(state, payload){
      state.promoModalContent = payload
    }
  },
  getters: {
    showPromoModal: store => store.showPromoModal,
    promoModalContent: store => store.promoModalContent
  }
}
