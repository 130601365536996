<template>
  <div class="d-flex align-items-center overflow-hidden">
    <span v-if="!hideTitle" class="title-custom font-weight-bold text-nowrap mr-2">
      {{$t('lk.subscribe.payment')}}
    </span>
    <div class="overflow-auto w-100 btn-group">
      <CButton
          v-for="(item, i) in options"
          :key="i"
          color="scroll-handler"
          :class=" value === item.key ? 'btn shadow-none button-custom text-white _active' : 'btn shadow-none button-custom text-white' "
          :variant="value === item.key ? '' : 'outline'"
          @click="$emit('input', item.key)"
        >
          <CIcon v-if="item.iconName" :name="item.iconName"/>
          {{item.label}}
        </CButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [ Number, String ],
    options: Array,
    hideTitle: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.btn-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;

  @include media-breakpoint-up(md) {
    flex-direction: row;

  }
}

.button-custom {
  padding: 14px 20px;
  border-radius: 0;
  border: none;
  background: var(--second-card-bg);

  @include media-breakpoint-up(md) {
    padding: 11.5px 20px;
    max-width: 150px;

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 150px;
  }

  &:before {
    box-shadow: none !important;
  }

  &._active {
    background: var(--scroll-handler);
  }
}
</style>
