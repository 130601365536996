var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CCard",
        { staticClass: "shadow-none", attrs: { color: "second-card-bg" } },
        [
          _c(
            "color-picker",
            {
              attrs: { open: _vm.openColorPicker, color: _vm.customColor },
              on: {
                "update:open": function ($event) {
                  _vm.openColorPicker = $event
                },
                "update:color": function ($event) {
                  _vm.customColor = $event
                },
                selectColor: function ($event) {
                  _vm.hasChanges = true
                },
              },
            },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.openColorPicker
                          ? null
                          : _vm.$t("lk.settings.changeColorWatchlist"),
                      },
                      expression:
                        "{\n        content: openColorPicker ? null : $t('lk.settings.changeColorWatchlist'),\n      }",
                    },
                  ],
                  staticClass: "flag _btn",
                  on: {
                    click: function ($event) {
                      _vm.openColorPicker = true
                    },
                  },
                },
                [
                  _c("CIcon", {
                    style: { color: _vm.customColor },
                    attrs: { width: "1rem", name: "flag" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          placeholder: _vm.$t("lk.account.formNameList"),
                          disabled: _vm.isGuest,
                        },
                        on: {
                          input: function ($event) {
                            _vm.hasChanges = true
                          },
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "shadow-none",
                          attrs: {
                            block: "",
                            color: "primary",
                            loading: _vm.loadingFile,
                            disabled: _vm.loadingFile || _vm.isGuest,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$refs.fileInput.click()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lk.account.download")) + " "
                          ),
                        ]
                      ),
                      _c("input", {
                        ref: "fileInput",
                        staticClass: "d-none",
                        attrs: { type: "file", accept: ".txt" },
                        on: { change: _vm.loadFile },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { col: "10" } },
                    [
                      _c("CTextarea", {
                        staticClass: "mb-0",
                        attrs: {
                          placeholder: _vm.$t("lk.account.formTickers"),
                          rows: "5",
                          disabled: _vm.isGuest,
                        },
                        on: {
                          input: function ($event) {
                            _vm.hasChanges = true
                          },
                        },
                        model: {
                          value: _vm.pairsString,
                          callback: function ($$v) {
                            _vm.pairsString = $$v
                          },
                          expression: "pairsString",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    {
                      staticClass: "d-flex flex-column align-items-end",
                      attrs: { col: "2" },
                    },
                    [
                      _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t("general.saveChanges"),
                              },
                              expression:
                                "{\n            content: $t('general.saveChanges'),\n          }",
                            },
                          ],
                          staticClass: "shadow-none mb-1",
                          class: { "cursor-not": _vm.disabledSaveBtn },
                          attrs: {
                            color: "success",
                            disabled: _vm.isGuest || !_vm.hasChanges,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveList()
                            },
                          },
                        },
                        [
                          _vm.loadingList
                            ? _c("CSpinner", {
                                attrs: { color: "primary", size: "sm" },
                              })
                            : _c("CIcon", { attrs: { name: "cil-save" } }),
                        ],
                        1
                      ),
                      !_vm.isFutures
                        ? _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t(
                                      "general.saveChangesAndToLineRunner"
                                    ),
                                  },
                                  expression:
                                    "{\n            content: $t('general.saveChangesAndToLineRunner'),\n          }",
                                },
                              ],
                              staticClass: "shadow-none mb-1",
                              class: { "cursor-not": _vm.disabledSaveBtn },
                              attrs: {
                                color: "info",
                                disabled: _vm.isGuest || !_vm.hasChanges,
                              },
                              on: { click: _vm.showToLineRunnerModal },
                            },
                            [
                              _vm.loadingList
                                ? _c("CSpinner", {
                                    attrs: { color: "primary", size: "sm" },
                                  })
                                : _c("CIcon", {
                                    attrs: { name: "cilVerticalAlignTop" },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "lk.settings.downloadWatchlist"
                                ),
                              },
                              expression:
                                "{\n            content: $t('lk.settings.downloadWatchlist'),\n          }",
                            },
                          ],
                          staticClass: "shadow-none mb-1",
                          class: { "cursor-not": _vm.disabledDownloadBtn },
                          attrs: {
                            color: "warning",
                            disabled: _vm.isGuest || !_vm.hasChanges,
                          },
                          on: { click: _vm.downloadList },
                        },
                        [
                          _c("CIcon", {
                            attrs: { name: "cil-cloud-download" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t("lk.settings.resetWatchlist"),
                              },
                              expression:
                                "{\n            content: $t('lk.settings.resetWatchlist'),\n          }",
                            },
                          ],
                          staticClass: "shadow-none",
                          class: { "cursor-not": _vm.disabledResetBtn },
                          attrs: {
                            color: "danger",
                            disabled: _vm.isGuest || !_vm.hasChanges,
                          },
                          on: { click: _vm.resetList },
                        },
                        [_c("CIcon", { attrs: { name: "cil-x" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isCustom
                ? _c(
                    "CButton",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: _vm.$t("lk.settings.deleteWatchlist"),
                          },
                          expression:
                            "{\n        content: $t('lk.settings.deleteWatchlist'),\n      }",
                        },
                      ],
                      staticClass: "shadow-none delete-btn",
                      attrs: { color: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete")
                        },
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.$t("lk.settings.uploadFile"),
                centered: "",
                scrollable: "",
                show: _vm.showChangeNameModal,
              },
              on: {
                "update:show": function ($event) {
                  return _vm.changeNameHandler(false)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "w-100 d-flex justify-content-between" },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeNameHandler(true)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: { color: "dark" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeNameHandler(false)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("p", [
                _vm._v(
                  " " + _vm._s(_vm.$t("lk.settings.changeNameNote")) + " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("lk.settings.editLineRunnerModal.title"),
            centered: "",
            scrollable: "",
            show: _vm.toLineRunnerRequest,
          },
          on: {
            "update:show": function ($event) {
              _vm.toLineRunnerRequest = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary" },
                          on: { click: _vm.toLineRunner },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.toLineRunnerRequest = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("lk.settings.editLineRunnerModal.subtitle"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }