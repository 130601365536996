// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['86 86', `
<g clip-path="url(#clip0)">
  <path d="M61.6333 43C51.3424 43 43 51.3424 43 61.6333C43 71.9243 51.3424 80.2667 61.6333 80.2667C71.9243 80.2667 80.2667 71.9243 80.2667 61.6333C80.2548 51.3474 71.9193 43.0118 61.6333 43ZM61.6333 77.4C52.9257 77.4 45.8667 70.341 45.8667 61.6333C45.8667 52.9257 52.9257 45.8667 61.6333 45.8667C70.341 45.8667 77.4 52.9257 77.4 61.6333C77.3898 70.3367 70.3367 77.3898 61.6333 77.4Z" fill="currentColor"/>
  <path d="M70.2332 55.9008C70.2332 53.5259 68.3081 51.6008 65.9332 51.6008H64.4999V48.7341H61.6332V51.6008H60.2113L60.2343 48.7456L57.3676 48.7227L57.3447 51.6008H53.0332V54.4675H55.8999V68.8008H53.0332V71.6675H57.3447L57.3676 74.5456L60.2343 74.5227L60.2113 71.6675H61.6332V74.5341H64.4999V71.6675H65.9332C68.3081 71.6675 70.2332 69.7423 70.2332 67.3675V64.5008C70.2294 63.4396 69.831 62.4176 69.1152 61.6341C69.831 60.8506 70.2294 59.8287 70.2332 58.7675V55.9008ZM67.3665 67.3675C67.3665 68.159 66.7248 68.8008 65.9332 68.8008H58.7665V63.0675H65.9332C66.7248 63.0675 67.3665 63.7092 67.3665 64.5008V67.3675ZM67.3665 58.7675C67.3665 59.559 66.7248 60.2008 65.9332 60.2008H58.7665V54.4675H65.9332C66.7248 54.4675 67.3665 55.1092 67.3665 55.9008V58.7675Z" fill="currentColor"/>
  <path d="M61.6333 37.2666C48.1759 37.2666 37.2666 48.1759 37.2666 61.6333C37.2666 75.0907 48.1759 85.9999 61.6333 85.9999C75.0907 85.9999 85.9999 75.0907 85.9999 61.6333C85.9849 48.1822 75.0844 37.2817 61.6333 37.2666ZM61.6333 83.1333C49.7592 83.1333 40.1333 73.5074 40.1333 61.6333C40.1333 49.7592 49.7592 40.1333 61.6333 40.1333C73.5074 40.1333 83.1333 49.7592 83.1333 61.6333C83.1198 73.5018 73.5018 83.1198 61.6333 83.1333Z" fill="currentColor"/>
  <path d="M24.3667 5.7334C14.0758 5.7334 5.7334 14.0758 5.7334 24.3667C5.7334 34.6577 14.0758 43.0001 24.3667 43.0001C34.6527 42.9882 42.9882 34.6527 43.0001 24.3667C43.0001 14.0758 34.6577 5.7334 24.3667 5.7334ZM24.3667 40.1334C15.6634 40.1232 8.61028 33.0701 8.60006 24.3667C8.60006 15.6591 15.6591 8.60006 24.3667 8.60006C33.0744 8.60006 40.1334 15.6591 40.1334 24.3667C40.1334 33.0744 33.0744 40.1334 24.3667 40.1334Z" fill="currentColor"/>
  <path d="M24.3667 0C10.9093 0 0 10.9093 0 24.3667C0 37.8241 10.9093 48.7333 24.3667 48.7333C37.8178 48.7183 48.7183 37.8178 48.7333 24.3667C48.7333 10.9093 37.8241 0 24.3667 0ZM24.3667 45.8667C12.4981 45.8532 2.8801 36.2352 2.86667 24.3667C2.86667 12.4926 12.4926 2.86667 24.3667 2.86667C36.2408 2.86667 45.8667 12.4926 45.8667 24.3667C45.8667 36.2408 36.2408 45.8667 24.3667 45.8667Z" fill="currentColor"/>
  <path d="M24.3666 22.9335C22.036 22.9335 20.0666 21.6205 20.0666 20.0668C20.0666 18.5131 22.036 17.2001 24.3666 17.2001C26.6972 17.2001 28.6666 18.5131 28.6666 20.0668C28.6666 20.8584 29.3084 21.5001 30.1 21.5001C30.8915 21.5001 31.5333 20.8584 31.5333 20.0668C31.5333 17.2976 29.0665 14.9828 25.8 14.4496V11.4668H22.9333V14.4496C19.6667 14.9828 17.2 17.2976 17.2 20.0668C17.2 23.2287 20.4149 25.8001 24.3666 25.8001C26.6972 25.8001 28.6666 27.1131 28.6666 28.6668C28.6666 30.2205 26.6972 31.5335 24.3666 31.5335C22.036 31.5335 20.0666 30.2205 20.0666 28.6668C20.0666 27.8752 19.4248 27.2335 18.6333 27.2335C17.8417 27.2335 17.2 27.8752 17.2 28.6668C17.2 31.436 19.6667 33.7508 22.9333 34.284V37.2668H25.8V34.284C29.0665 33.7508 31.5333 31.436 31.5333 28.6668C31.5333 25.5049 28.3183 22.9335 24.3666 22.9335Z" fill="#20A8D8"/>
  <path d="M61.6333 14.3335H55.06L58.6935 10.6986L56.6668 8.67188L49.5732 15.7669L56.6668 22.8619L58.6935 20.8351L55.06 17.2002H61.6333C68.7545 17.2081 74.5254 22.9791 74.5333 30.1002V37.2669H77.4V30.1002C77.3898 21.3968 70.3367 14.3438 61.6333 14.3335Z" fill="currentColor"/>
  <path d="M27.8999 63.1384L25.8732 65.1651L29.5067 68.8001H24.3668C17.2456 68.7922 11.4746 63.0212 11.4668 55.9001V48.7334H8.6001V55.9001C8.61031 64.6034 15.6634 71.6565 24.3668 71.6667H29.5067L25.8732 75.3017L27.8999 77.3284L34.9935 70.2334L27.8999 63.1384Z" fill="currentColor"/>
</g>
<defs>
  <clipPath id="clip0">
    <rect width="86" height="86" fill="currentColor"/>
  </clipPath>
</defs>
`]
