<template>
  <div class="">
    <div class="title-wrap align-items-center">
      <div class="mr-auto d-flex align-items-center">
        <h1 class="mb-0 mr-3 title">
          {{ $t('lk.header.settings') }}
        </h1>
      </div>
    </div>

    <CRow>
      <CCol col="12" class="mb-3" v-if="!isGuest">
        <CButton
          color="primary"
          @click="$store.dispatch('user/logoutAll')"
        >
          {{ $t('lk.account.signOutAll') }}
        </CButton>
      </CCol>
      <CCol col="12" md="3" class="mb-3">
        <CSelect
          :label="$t('lk.account.selectLang')"
          :value.sync="locale"
          :options="$store.state.localeOptions"
          class="mb-0"
        />
      </CCol>
      <CCol
        v-if="$store.getters['user/getPermissions']('view_notifications')"
        col="12"
        md="3"
        class="mb-3 d-flex justify-content-center flex-column notify-wrap"
      >
        <web-push-toggle />
<!--        v-if="swRegistration && !isLoading && !swIsBlocked && isSubscribed"-->
        <template
          v-if="false"
        >
          <CTextarea
            v-model="notifyJson"
            placeholder="JSON"
            rows="5"
            class="mb-0 mt-1"
          />
          <CLoadingButtonCustom
            color="primary"
            class="shadow-none"
            :loading="loadingSendNotify"
            :disabled="!notifyJson || loadingSendNotify"
            block
            @click.native="sendNotify"
          >
            {{ $t('general.save') }}
          </CLoadingButtonCustom>
        </template>
      </CCol>
      <CCol col="12" md="4" class="mt-3 d-flex align-items-center">
        <label
          for="isGraphPausedSwitch"
          class="mb-0"
        >
          {{ $t('lk.account.pauseUpdateOnChart') }}
        </label>
        <span
          v-tooltip="{content: $t('lk.account.pauseUpdateOnChartHint')}"
          class="rounded-circle popover-wrap d-inline-flex justify-content-center ml-2"
        >
          ?
        </span>
        <CSwitch
          class="mr-2 ml-1"
          color="primary"
          shape="pill"
          :disabled="isGraphPausedLoading || isGuest"
          :checked.sync="isGraphPaused"
          id="isGraphPausedSwitch"
        />
      </CCol>
    </CRow>

    <hr class="mb-4 mt-0"/>

    <time-range-selector
      :hideTitle="true"
      :options="filteredTabList"
      v-model="selectedTab"
      class="mb-4"
    />
    <watch-lists v-if="selectedTab === 0"/>
    <line-runner-setup v-if="selectedTab === 1"/>
    <ma-settings v-if="selectedTab === 2"/>
    <screener-table-settings v-if="selectedTab === 3"/>


    <!-- <CTabs variant="pills" color="primary">
      <CTab title="Watch lists" class="custom-tab" v-if="$store.getters['user/getPermissions']('view_screener')">
        <watch-lists/>
      </CTab>
      <CTab :title="$t('lineRunner.title')" class="custom-tab"
            v-if="$store.getters['user/getPermissions']('view_run_line')">
        <line-runner-setup/>
      </CTab>
      <CTab title="MA" class="custom-tab" v-if="showMASettings">
        <ma-settings/>
      </CTab>
    </CTabs> -->
  </div>
</template>

<script>
import TimeRangeSelector from '@/components/training/tabSelector'
import WatchLists from "@/components/account/WatchLists";
import LineRunnerSetup from "@/components/account/LineRunnerSetup";
import MaSettings from "@/components/account/MaSettings";
import ScreenerTableSettings from "@/components/account/ScreenerTableSettings";
import {mapActions, mapState, mapGetters} from "vuex";
import WebPushToggle from "@/components/notifications/WebPushToggle";

export default {
  name: "Account",
  components: {
    WatchLists,
    LineRunnerSetup,
    MaSettings,
    WebPushToggle,
    TimeRangeSelector,
    ScreenerTableSettings
  },
  data() {
    return {
      // showMASettings: this.$store.getters['user/getPermissions']('use_custom_ma'),
      notifyJson: '{ "body": "Yay it works.", "icon": "https://ttt.oceanstart.dev/android-chrome-192x192.png", "image": "https://sun9-64.userapi.com/sun9-64/impg/xVfgurfT9j5rilIGQzmXj_Wqf4RqYhqWyJEwzA/skAcgffKbUk.jpg?size=604x604&quality=96&sign=9a8016d3146d5807f121085473ea460b&type=album", "click_action": "https://ttt.oceanstart.dev/", "lang": "en"   }',
      loadingSendNotify: false,
      selectedTab: 0,
      isGraphPausedLoading: false,
    }
  },
  async mounted(){
    await this.$store.dispatch('user/getCurrent')
    if(!this.$store.state.settings.screener || !this.$store.state.settings.screener?.hasOwnProperty('pauseWhenOpeningChart')){
      this.isGraphPaused = true
    }
  },
  computed: {
    isGraphPaused: {
      get() {
        if(this.isGuest){
          return false
        }
        return this.$store.state.settings?.screener?.pauseWhenOpeningChart || false
      },
      set(newValue) {
        this.isGraphPausedLoading = true
        this.$store.commit('SET_KEY', {
          namespace: 'screener',
          key: 'pauseWhenOpeningChart',
          value: newValue
        })
        this.$store.dispatch('saveRequest', ['screener']).finally(() => {
          this.isGraphPausedLoading = false
        })
      }
    },
    filteredTabList(){
      return this.tabList.filter(item => this.getTabShow(item.permission))
    },
    ...mapState({
      swRegistration: state => state.browserNotify.swRegistration,
      swIsBlocked: state => state.browserNotify.swIsBlocked,
      isSubscribed: state => state.browserNotify.isSubscribed,
      isLoading: state => state.browserNotify.isLoading,
    }),
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    locale: {
      get() {
        return this.$root.$i18n.locale
      },
      set(lang) {
        this.$root.$i18n.locale = lang
        this.$store.dispatch("setKey", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    },
    tabList(){
      return [
        {
          label: 'Watch lists',
          key: 0,
          permission: 'view_screener'
        },
        {
          label: this.$t('lineRunner.title'),
          key: 1,
          permission: 'view_run_line'
        },
        {
          label: 'MA',
          key: 2,
          permission: 'use_custom_ma'
        },
        {
          label: this.$t('screener.tableScreener'),
          key: 3,
          permission: 'view_screener'
        },
      ]
    }
  },
  methods: {
    getTabShow(permission){
      return this.$store.getters['user/getPermissions'](permission)
    },
    ...mapActions({
      sendTestNotify: 'browserNotify/sendTestNotify',
    }),
    sendNotify() {
      this.loadingSendNotify = true

      try {
        this.sendTestNotify({body: this.notifyJson})
          .then(() => {
            this.$toastr.success('Notification sent')
          })
          .finally(() => {
            this.loadingSendNotify = false
          })
      } catch (e) {
        this.loadingSendNotify = false
        this.$toastr.error('Sending error')
      }
    },
  }
}
</script>


<style lang="scss" scoped>
//@import "@/assets/scss/style";

.title {
  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
  }

}

.icon {
  margin: 0;
}

.tg-button {
  width: 13rem;
}

.verify-message {
  font-size: 0.75rem;
}

.custom-tab {
  /deep/ .nav-link {
    color: var(--primary);

    &.active {
      background: var(--primary);
      color: var(--primary-contrast);
    }
  }
}

.title-wrap {
  display: none;

  @include media-breakpoint-up(sm) {
    display: flex;
    margin-bottom: 1.75rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.25rem;
  }
}

.notify-wrap {
  @include media-breakpoint-up(md) {
    margin-top: 1.8125rem;
  }
}
.popover-wrap{
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 1rem;
    flex-shrink: 0;
    height: 1rem;
    font-size: 0.75rem;
    background-color: var(--second-card-bg);
  }
</style>
