import router, {documentTitleDefault, routeLang} from '@/router';
import store from '@/store';
import i18n from '@/i18n';

export function setDocumentTitle (route = null, appTitleMapUri = null) {
  if(route === null){
    route = router.currentRoute
    if(route && route.path === '/'){
      return
    }
  }
  if(!route){
    return
  }

  const titleMap = appTitleMapUri || window.appTitleMapUri || {}
  const alias = route.path.replace(/^\/?(ru|en)?\//, '');
  if(titleMap[alias] && titleMap[alias][i18n.locale]){
    document.title = titleMap[alias][i18n.locale]
  }else if (route.meta && route.meta.defaultTitle){
    document.title = typeof route.meta.defaultTitle === 'function' ? route.meta.defaultTitle() : route.meta.defaultTitle
  }else{
    document.title = documentTitleDefault
  }
}

export default async function (to, from, next) {
  const rLang = routeLang(to)
  if(rLang){
    if(!store.state.initLocale){
      await store.dispatch('setInit', {
        global: {
          locale: rLang,
        }
      })
    }else if(rLang !== i18n.locale) {
      const newRaw = {
        name: to.name,
        params: to.params,
        query: to.query,
        hash: to.hash,
        replace: true
      }
      newRaw.params.lang = i18n.locale === 'ru' ? 'ru' : undefined
      next(newRaw)
      return
    }
  }
  setDocumentTitle(to)
  next()
}
