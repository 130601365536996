import moment from "moment";
import { get } from "lodash";

export class GraphData {
  constructor(init = {}, random = false) {
    this.id = get(init, 'id', null)
    this.x = []
    this.y = []

    if (random) {
      this.pair = 'BTCUSD'
      this.close = (Math.round((Math.random() * 100) * 100)) / 100
      this.closePct = (Math.round((Math.random() * 100) * 100)) / 100
      this.open = (Math.round((Math.random() * 100) * 100)) / 100
      this.volatility = (Math.round((Math.random() * 100) * 100)) / 100
      this.color = '#e1a82d'
      this.width = 1

      for (let i = 24; i >= 0; i--) {
        const x = moment().utc().subtract(i, 'm').format('HH:mm DD.MM')
        this.x.push(x)
        this.y.push(Math.round((Math.random() * 1000)))
      }

      Object.assign(this, init) // todo temp for test data
    } else {
      this.pair = get(init, 'label', '')
      this.close = get(init, 'close', '—')
      this.closePct = get(init, 'close_pct', '—')
      this.open = get(init, 'open', '—')
      this.volatility = get(init, 'volatility', '—')
      this.color = get(init, 'color', '')
      this.width = get(init, 'width', 1)

      if (get(init, 'x') && Array.isArray(init.x)) {
        this.x = init.x
      }
      if (get(init, 'y') && Array.isArray(init.y)) {
        this.y = init.y
      }
    }

    this.closePctStr = typeof this.closePct === 'number' ? this.closePct + '%' : this.closePct
    this.volatilityStr = typeof this.volatility === 'number' ? this.volatility + '%' : this.volatility
  }
}
