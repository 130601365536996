import store from "@/store"
import router from '@/router';

export default async function (route) {
  if(route.query && route.query.data && route.query.expires && route.query.signature){
    const load = (payload) => store.commit('setRouterSpinner', payload)
    try {
      load(true)
      await store.dispatch('user/confirmChange', route.query)
    } catch (e) {
      console.error(e)
    } finally {
      load(false)
      await router.replace({name: route.name})
    }
  }
}
