import axios from "axios"
import {EnableDataClass} from "@/assets/js/browserNotify/EnableData.class"
import {DisableDataClass} from "@/assets/js/browserNotify/DisableData.class"
import {NOTIFICATIONS_TURNED_OFF_MANUALLY, BUILD_PUBLIC_PATH} from "@/assets/js/constants"

export default {
  namespaced: true,
  state: () => ({
    swRegistration: null,
    swIsBlocked: false,
    isSubscribed: false,
    isLoading: false,
    applicationServerPublicKey: null,
    subscription: null,
    braveError: false,
    isBrave: false,
    isSafari: false,
  }),
  getters: {
    applicationServerKey(store) {
      if (store.applicationServerPublicKey) {
        const padding = '='.repeat((4 - store.applicationServerPublicKey.length % 4) % 4);
        const base64 = (store.applicationServerPublicKey + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      } else return null
    }
  },
  actions: {
    getKeys({commit}) {
      return axios.get('/api/v2/push-service/enable')
        .then(res => {
          if (res?.data?.status) {
            commit('SET_APP_PUB_KEY', res?.data?.key)
            return res
          } else {
            throw Error('Error getting key')
          }
        })
        .catch((error) => {
          commit('SET_APP_PUB_KEY', null)
          throw Error(error)
        })
    },
    enableNotify(_, data) {
      return axios.post('/api/v2/push-service/enable', new EnableDataClass(data))
        .then(res => {
          if (res?.data?.status) {
            return res
          } else {
            throw Error('Subscription error')
          }
        })
        .catch((error) => {
          throw Error(error)
        })
    },
    sendTestNotify(_, data) {
      return axios.post('/api/v2/push-service/test', data)
        .then(res => {
          if (res?.data?.status) {
            return res
          } else {
            throw Error('Sending error')
          }
        })
        .catch((error) => {
          throw Error(error)
        })
    },
    disableNotify(_, data) {
      return axios.post('/api/v2/push-service/disable', new DisableDataClass(data))
        .then(res => {
          if (res?.data?.status) {
            return res
          } else {
            throw Error('Subscription error')
          }
        })
        .catch((error) => {
          throw Error(error)
        })
    },
    swRegistrationAction({commit, dispatch}) {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        return navigator.serviceWorker.register(BUILD_PUBLIC_PATH + '/sw.js')
          .then((swReg) => {

            return dispatch('getKeys')
              .then(() => {
                commit('SET_SW', swReg)

                // Set the initial subscription value
                return swReg.pushManager.getSubscription()
                  .then((subscription) => {
                    dispatch('updateSubscriptionOnServer', subscription)
                    dispatch('updateBtn')
                  })
                  .catch((error) => {
                    commit('SET_SW', null)
                    throw Error(error)
                  })
              })
              .catch((error) => {
                commit('SET_SW', null)
                throw Error(error)
              })
          })
          .catch((error) => {
            commit('SET_SW', null)
            throw Error('Service Worker Error' + error)
          });
      } else if ('safari' in window && 'pushNotification' in window.safari) {
        commit('SET_SW', null)
        commit('SET_IS_SAFARI', true)
      } else {
        commit('SET_SW', null)
        throw Error('Service Worker Error');
      }
    },
    subscribeUser({dispatch, state, getters, commit}) {
      commit('SET_IS_LOADING', true)
      if (state.isSafari) {
        const permissionData = window.safari.pushNotification.permission(process.env.VUE_APP_SAFARI_WEBSITE_PUSH_ID)

        return dispatch('checkRemotePermissionSafari', permissionData)
      } else if (getters.applicationServerKey) {
        return state.swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: getters.applicationServerKey
        })
          .then((subscription) => {
            localStorage.removeItem(NOTIFICATIONS_TURNED_OFF_MANUALLY)
            commit('SET_BRAVE_ERROR', false)
            dispatch('updateSubscriptionOnServer', subscription)
          })
          .catch((err) => {
            commit('SET_BRAVE_ERROR', !!navigator.brave)
            commit('SET_IS_LOADING', false)
            dispatch('updateSubscriptionOnServer', null)
            throw Error('Failed to subscribe the user: ' + err)
          })
          .finally(() => {
            dispatch('updateBtn')
          })
      } else {
        commit('SET_IS_LOADING', false)
        dispatch('updateSubscriptionOnServer', null)
        dispatch('updateBtn')
        throw Error('Subscription error')
      }
    },
    unsubscribeUser({state, dispatch, commit}) {
      commit('SET_IS_LOADING', true)
      return state.swRegistration.pushManager.getSubscription()
        .then((subscription) => {
          commit('SET_BRAVE_ERROR', false)
          if (subscription) {
            localStorage.setItem(NOTIFICATIONS_TURNED_OFF_MANUALLY, '1')
            return subscription.unsubscribe();
          }
        })
        .catch((error) => {
          commit('SET_BRAVE_ERROR', !!navigator.brave)
          commit('SET_IS_LOADING', false)
          throw Error('Error unsubscribing' + error);
        })
        .finally(() => {
          dispatch('updateSubscriptionOnServer', null);
          dispatch('updateBtn')
        });
    },
    updateSubscriptionOnServer({commit, dispatch, state}, subscription) {
      commit('SET_IS_LOADING', true)

      if (subscription) {
        commit('SET_SUBSCRIPTION', subscription)
        console.log('subscription', subscription.toJSON())

        return dispatch('enableNotify', subscription)
          .then(() => {
            commit('SET_IS_SUBSCRIBED', true)
          })
          .catch((error) => {
            commit('SET_IS_SUBSCRIBED', false)
            throw Error(error)
          })
          .finally(() => {
            commit('SET_IS_LOADING', false)
          })
      } else {
        if (state.subscription) {
          return dispatch('disableNotify', new DisableDataClass(state.subscription))
            .catch((error) => {
              throw Error(error)
            })
            .finally(() => {
              commit('SET_IS_SUBSCRIBED', false)
              commit('SET_IS_LOADING', false)
            })
        } else {
          commit('SET_IS_SUBSCRIBED', false)
          commit('SET_IS_LOADING', false)
        }
      }
    },
    updateBtn({dispatch, commit}) {
      if (Notification.permission === 'denied') {
        commit('SET_IS_BLOCKED', true)
        dispatch('updateSubscriptionOnServer', null);
      }
    },
    checkRemotePermissionSafari({dispatch, commit, rootState}, permissionData) {
      if (permissionData.permission === 'default') {
        // This is a new web service URL and its validity is unknown.
        try {
          window.safari.pushNotification.requestPermission(
            process.env.VUE_APP_SAFARI_WEBSITE_PUSH_URL, // The web service URL.
            process.env.VUE_APP_SAFARI_WEBSITE_PUSH_ID,     // The Website Push ID.
            {
              userId: `${rootState.user.userData.id}`,
            }, // Data that you choose to send to your server to help you identify the user.
            (e) => dispatch('checkRemotePermissionSafari', e)         // The callback function.
          )
        } catch (e) {
          commit('SET_IS_SUBSCRIBED', false)
          commit('SET_IS_LOADING', false)
          commit('SET_IS_BLOCKED', false)
        }
      } else if (permissionData.permission === 'denied') {
        commit('SET_IS_SUBSCRIBED', false)
        commit('SET_IS_LOADING', false)
        commit('SET_IS_BLOCKED', true)
        // The user said no.
      } else if (permissionData.permission === 'granted') {
        // The web service URL is a valid push provider, and the user said yes.
        // permissionData.deviceToken is now available to use.
        commit('SET_IS_SUBSCRIBED', true)
        commit('SET_IS_LOADING', false)
        console.log(permissionData.deviceToken)
      } else {
        commit('SET_IS_SUBSCRIBED', false)
        commit('SET_IS_LOADING', false)
        commit('SET_IS_BLOCKED', false)
      }
    }
  },
  mutations: {
    SET_SW(state, payload) {
      state.swRegistration = payload
    },
    SET_IS_SUBSCRIBED(state, payload) {
      state.isSubscribed = payload
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_IS_BLOCKED(state, payload) {
      state.swIsBlocked = payload
    },
    SET_APP_PUB_KEY(state, payload) {
      state.applicationServerPublicKey = payload
    },
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = new EnableDataClass(payload)
    },
    SET_BRAVE_ERROR(state, payload) {
      state.braveError = payload
    },
    SET_IS_BRAVE(state, payload) {
      state.isBrave = payload
    },
    SET_IS_SAFARI(state, payload) {
      state.isSafari = payload
    },
  }
}
