// Example of SVG converted to temp array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export default ['99 98', `
<g >
<circle cx="40" cy="40" r="36" stroke="#4b4b54" stroke-width="8"/>
<line x1="70.6569" y1="68" x2="95" y2="92.3431" stroke="#4b4b54" stroke-width="8" stroke-linecap="round"/>
<circle cx="27" cy="31" r="5" fill="#4b4b54"/>
<circle cx="55" cy="31" r="5" fill="#4b4b54"/>
<path d="M25 56C25 56 30.9048 50 40.746 50C50.5873 50 56 56 56 56" stroke="#4b4b54" stroke-width="6" stroke-linecap="round"/>
</g>
`]
