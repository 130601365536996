import {get, intersectionBy, cloneDeep, isString, isNumber} from 'lodash'
import { DataItem } from "@/assets/js/screenerDataClasses/DataItem.class"
import screenerSettings from "@/assets/js/screenerSettings"
import store from "@/store";

export default {
  _data: [],
  _sortCol: null,
  _sortType: null,
  _sortTypeBase: 'absolute',
  _activeCol: '',
  _isRsi: true,
  _isSlidingVolume: false,
  _filterMA200Trend: null,

  get sortCol () {
    return this._sortCol
  },
  set sortCol (val) {
    if (!val || isString(val)) {
      this._sortCol = val
      store.dispatch("setKey", {
        namespace: 'tablesView',
        key: 'sortCol',
        value: val
      })
    }
  },

  get sortType () {
    return this._sortType
  },
  set sortType (val) {
    if (!val || isString(val)) {
      this._sortType = val
      store.dispatch("setKey", {
        namespace: 'tablesView',
        key: 'sortType',
        value: val
      })
    }
  },

  get sortTypeBase () {
    return this._sortTypeBase
  },
  set sortTypeBase (val) {
    if (isString(val)) {
      this._sortTypeBase = val
      store.dispatch("setKey", {
        namespace: 'tablesView',
        key: 'sortTypeBase',
        value: val
      })
    }
  },

  get activeCol () {
    return this._activeCol
  },
  set activeCol (val) {
    if (isString(val)) {
      this._activeCol = val
      store.dispatch("setKey", {
        namespace: 'tablesView',
        key: 'activeCol',
        value: val
      })
    }
  },

  get filterMA200Trend () {
    return this._filterMA200Trend
  },
  set filterMA200Trend (val) {
    if (!val || isString(val) || isNumber(val)) {
      this._filterMA200Trend = val
      store.dispatch("setKey", {
        namespace: 'tablesView',
        key: 'filterMA200Trend',
        value: val
      })
    }
  },

  get isRsi () {
    return this._isRsi
  },
  set isRsi (val) {
    this._isRsi = !!val
    store.dispatch("setKey", {
      namespace: 'tablesView',
      key: 'isRsi',
      value: val
    })
  },

  get isSlidingVolume () {
    return true
    return this._isSlidingVolume
  },
  set isSlidingVolume (val) {
    this._isSlidingVolume = val
    store.dispatch("setKey", {
      namespace: 'tablesView',
      key: 'isSlidingVolume',
      value: val
    })
  },

  get data () {
    return intersectionBy(this.sortedData, this.filteredData, 'id')
  },
  set data (val) {
    if (val && Array.isArray(val)) {
      this._data.splice(0)
      this._data.push(...val.map(a => new DataItem(a)))
    } else {
      this._data.splice(0)
    }
  },

  get sortedData () {
    if (!this.sortCol || !this.sortType) return this._data

    const showDeltaColumns = store.getters['user/getPermissions']('use_custom_ma')

    const arr = cloneDeep(this._data)

    let convertType = null
    let more = null
    let less = null
    switch (this.sortType) {
      case 'asd':
        more = 1
        less = -1
        break
      case 'desc':
      default:
        more = -1
        less = 1
        break
    }

    switch (this.sortCol) {
      case 'coin.label':
        more *= -1
        less *= -1
        convertType = 'coin'
        break
      case 'ma50trend':
      case 'ma200trend':
        convertType = 'trend'
        break
      case 'price':
      case 'pricePct':
      case 'volatility':
      case 'ma200':
      case 'ma50':
      case 'price_ma200_difference_perc':
      case 'ma200_price_difference_perc':
      case 'price_ma50_difference_perc':
      case 'ma50_price_difference_perc':
      case 'volume':
      case 'volumePct':
      case 'slidingVolume':
      case 'slidingVolumePct':
      case 'rsi':
      case 'rsiPct':
      case 'move':
        convertType = 'selected'
        break
      case 'tf1w_quote_volume_delta':
      case 'tf_quote_volume_delta':
        if (showDeltaColumns) {
          convertType = 'selected'
          break
        }
      // eslint-disable-next-line no-fallthrough
      default:
        if (this.isRsi) {
          convertType = this.sortTypeBase === 'absolute' ? 'rsi' : 'rsiPct'
        } else if (this.isSlidingVolume) {
          convertType = this.sortTypeBase === 'absolute' ? 'slidingVolume' : 'slidingVolumePct'
        } else {
          convertType = this.sortTypeBase === 'absolute' ? 'volume' : 'volumePct'
        }
    }

    arr.sort((a, b) => {
      const {valA, valB} = convertSort(a, b, this.sortCol, this.activeCol, this.sortType, convertType)

      if (convertType !== 'coin') {
        if (typeof valA === 'string') return 1
        if (typeof valB === 'string') return -1
      }
      if (valA === valB) return 0
      return valA < valB ? less : more
    })

    return arr
  },

  get filteredData () {
    let arr = this._data
    if (this.filterByPair.length) {
      arr = this.filterByPair
    }
    if (this.filterMA200Trend) {
      arr = arr.filter(item => item[this.activeCol].ma200trend === this.filterMA200Trend)
    }
    return arr
  },

  get filterByPair () {
    const arr = screenerSettings.selectedPairs.filter(el => !el.disabledOnScreener)

    if (!arr.length) return this._data

    return this._data.filter(item => {
      const coinLabel = get(item, 'coin.label', null)
      if (coinLabel) {
        return arr
          .map(coin => coin.label)
          .includes(coinLabel)
      } else {
        return false
      }
    })
  },

  get coinList () {
    return this._data.map(item => item.coin)
  },

  get trendMA1 () {
    const arr = this.filterByPair.filter(a => a[this.activeCol].ma200trend === 1)
    return arr.length
  },
  get trendMA2 () {
    const arr = this.filterByPair.filter(a => a[this.activeCol].ma200trend === 2)
    return arr.length
  },
  get trendMA3 () {
    const arr = this.filterByPair.filter(a => a[this.activeCol].ma200trend === 3)
    return arr.length
  }
}

/**
 *
 * @param a {DataItem} первый сравниваемый параметр
 * @param b {DataItem} второй сравниваемый параметр
 * @param colName {string} название столбца по которому идёт сортировка
 * @param colActive {string} название базового выбранного столбца (в левой таблице)
 * @param sortType {string|number} тип сортировки (asd/desc/1/2/3)
 * @param convertType {string} тип обработки сравниваемых значений
 * @returns {{valB: null, valA: null}}
 */
function convertSort(a, b, colName, colActive, sortType, convertType) {
  let valA = null
  let valB = null

  switch (convertType) {
    case 'coin':
      valA = get(a, 'coin.label', null)
      valB = get(b, 'coin.label', null)
      break
    case 'rsi':
      valA = get(a, `${colName}.rsi`, null)
      valB = get(b, `${colName}.rsi`, null)
      break
    case 'rsiPct':
      valA = get(a, `${colName}.rsiPct`, null)
      valB = get(b, `${colName}.rsiPct`, null)
      break
    case 'volume':
      valA = get(a, `${colName}.volume`, null)
      valB = get(b, `${colName}.volume`, null)
      break
    case 'volumePct':
      valA = get(a, `${colName}.volumePct`, null)
      valB = get(b, `${colName}.volumePct`, null)
      break
    case 'slidingVolume':
      valA = get(a, `${colName}.slidingVolume`, null)
      valB = get(b, `${colName}.slidingVolume`, null)
      break
    case 'slidingVolumePct':
      valA = get(a, `${colName}.slidingVolumePct`, null)
      valB = get(b, `${colName}.slidingVolumePct`, null)
      break
    case 'selected':
      valA = get(a, `${colActive}.${colName}`, null)
      valB = get(b, `${colActive}.${colName}`, null)
      break
    case 'trend':
      valA = get(a, `${colActive}.${colName}`, null)
      valA = Number(valA === sortType) // return 1 || 0
      valB = get(b, `${colActive}.${colName}`, null)
      valB = Number(valB === sortType) // return 1 || 0
      break
  }

  return {
    valA,
    valB
  }
}

export const sortTypeBaseList = ['absolute', '%']
