var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    { staticClass: "justify-content-center" },
    [
      _c(
        "CCol",
        {
          class: ["c-col", { "p-0 w-100": _vm.inModal }],
          attrs: {
            col: !_vm.inModal && "11",
            md: !_vm.inModal && "9",
            lg: !_vm.inModal && "7",
            xl: !_vm.inModal && "auto",
          },
        },
        [
          _c(
            "CCardGroup",
            { staticClass: "ccard-group" },
            [
              _c(
                "CCard",
                {
                  staticClass: "ccard-login col-xl-7 shadow-none",
                  class: { "ccard-login--inModal": _vm.inModal },
                  attrs: { color: "second-card-bg" },
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "ccard-body" },
                    [
                      _c(
                        "CForm",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "cheader" }, [
                            _c(
                              "a",
                              { attrs: { href: _vm.homepage_url } },
                              [_c("logo-wrap", { attrs: { auth: true } })],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-3 cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        _vm.darkMode = !_vm.darkMode
                                      },
                                    },
                                  },
                                  [
                                    _vm.darkMode
                                      ? _c("CIcon", {
                                          attrs: { name: "cil-sun" },
                                        })
                                      : _c("CIcon", {
                                          attrs: { name: "cil-moon" },
                                        }),
                                  ],
                                  1
                                ),
                                _c("CSelect", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    value: _vm.locale,
                                    options: _vm.$store.state.localeOptions,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.locale = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.google2fa_enabled && _vm.currentUser
                            ? [
                                _c("h1", { staticClass: "title text-white" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("formLogin.greeting")) +
                                      " " +
                                      _vm._s(_vm.currentUser.name) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "subtitle text-muted" },
                                  [_vm._v(_vm._s(_vm.$t("formLogin.2FAinfo")))]
                                ),
                              ]
                            : [
                                _c("h1", { staticClass: "title text-white" }, [
                                  _vm._v(_vm._s(_vm.$t("formLogin.title"))),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "subtitle text-muted" },
                                  [_vm._v(_vm._s(_vm.$t("formLogin.subtitle")))]
                                ),
                              ],
                          _c("CInput", {
                            staticClass: "cinput rounded-right",
                            attrs: {
                              placeholder: _vm.$t("formLogin.email"),
                              autocomplete: "email",
                              "is-valid": _vm.detectErrorField(
                                _vm.errors.email
                              ),
                              "invalid-feedback": _vm.errors.email,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function () {
                                  return [
                                    _c("CIcon", { attrs: { name: "mail" } }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c("CInput", {
                            staticClass: "cinput rounded-right",
                            attrs: {
                              placeholder: _vm.$t("formLogin.password"),
                              type: "password",
                              autocomplete: "password",
                              "is-valid": _vm.detectErrorField(
                                _vm.errors.password
                              ),
                              "invalid-feedback": _vm.errors.password,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function () {
                                  return [
                                    _c("CIcon", {
                                      attrs: { name: "cil-lock-locked" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _vm.google2fa_enabled
                            ? _c("CInput", {
                                staticClass: "cinput rounded-right",
                                attrs: {
                                  autocomplete: "one-time-code",
                                  inputmode: "numeric",
                                  placeholder: _vm.$t("lk.account.6digitCode"),
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                { attrs: { xl: "6" } },
                                [
                                  _c(
                                    "CLoadingButtonCustom",
                                    {
                                      staticClass: "cbtn-submit shadow-none",
                                      attrs: {
                                        block: "",
                                        color: "primary",
                                        type: "submit",
                                        loading: _vm.loadingRequest,
                                        disabled: _vm.loadingRequest,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("formLogin.signIn")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "subactions",
                                  attrs: { xl: "6" },
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      staticClass: "cbtn-recovery text-primary",
                                      attrs: {
                                        color: "link",
                                        to: {
                                          name: "password-recovery",
                                          params: { email: _vm.email },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("formLogin.forgotPassword")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "CCard",
                {
                  staticClass: "ccard-register col-xl-4 shadow-none",
                  class: { "ccard-register--inModal": _vm.inModal },
                  attrs: { color: "primary", "text-color": "white" },
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "ccard-body h-100" },
                    [
                      _c("h1", { staticClass: "title text-white" }, [
                        _vm._v(_vm._s(_vm.$t("formLogin.registerTitle"))),
                      ]),
                      _c("p", { staticClass: "subtitle text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("formLogin.registerSubtitle"))),
                      ]),
                      _c(
                        "CButton",
                        {
                          staticClass: "cbtn",
                          attrs: {
                            color: "white",
                            variant: "outline",
                            to: { name: "register" },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("formLogin.signUp")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          staticClass: "cbtn cbtn-homepage",
                          class: "cbtn-homepage-" + _vm.locale,
                          attrs: {
                            color: "white",
                            variant: "outline",
                            href: _vm.homepage_url,
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("formLogin.MainPage")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }